import React from 'react';
import { Select, Row, Col, Checkbox } from 'antd';
import { isValid, isValidArray } from "../../utils/utilities";
import './users-select.style.scss';

const { Option } = Select;

const renderUsers = (agents) => {
    let usersArray = [];
    if (isValidArray(agents) && agents.length > 0) {
        agents.forEach(element => {
            if (isValid(element.name)) {
                usersArray.push(
                    <Option 
                        key={element.id} 
                        value={element.id} 
                        user={element}
                    >
                        <Row align="middle">
                            <Col style={{ marginLeft: '8px' }}>
                                <span className='font-size-12'>{element.name}</span>
                            </Col>
                        </Row>
                    </Option>
                );
            }
        });
    }
    return usersArray;
};


export const SingleUserSelect = ({
    agents = [],
    selectedUsers = [],
    onChange = () => {},
    renderBtnToGetServices = () => {}
}) => {
   

    return (
        <div className="users-select-wrapper margin-bottom-10">
            <Row align="middle" gutter={[0, 0]}>
                <Col>
                    <div className="users-select-label font-size-12 font-weight-bold" >Select Users</div>
                </Col>
                <Col>
                    <Select
                        size="large"
                        showSearch
                        placeholder="Select users"
                        optionFilterProp="children"
                        onChange={onChange}
                        value={selectedUsers}
                        // filterOption={(input, option) =>
                        //     option.children.props.children[1].props.children
                        //         .toLowerCase()
                        //         .indexOf(input.toLowerCase()) >= 0
                        // }
                        bordered={true}
                        className="users-select"
                        dropdownClassName="users-select-popup" // Changed from popupClassName to dropdownClassName                       
                        style={{ height: '100%', lineHeight: '32px', minWidth: '200px', backgroundColor: '#E7DBFF' }} // Changed background color of selected item
                        showArrow
                    >
                        {renderUsers(agents, selectedUsers)}
                    </Select>
                </Col>
                <Col flex="auto" style={{marginLeft: 10}}>
                    {renderBtnToGetServices()}
                </Col>
            </Row>
        </div>
    );
}; 