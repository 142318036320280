import React from 'react';
import { Document, Page,  StyleSheet,PDFDownloadLink } from '@react-pdf/renderer';
import Title from './common/Title';
import Row from './common/Row';
import Subtext from './common/Subtext';
import Table from './common/Table';
import {getArrayfromString, isValid, isValidArray, phoneNumberValidation } from '../../utils/utilities';
import SubTextBold from './common/SubTextBold';
import { View } from '@react-pdf/renderer';
import { Button } from 'antd';
import ImageRender from './common/Image';
import Line from './common/Line';
import { convertDateStringToDate, getDateTimeReadableFormat, getLocalDate, getLocalTime, getTimeStampInReadableFormat, getUIDateFormat, incrementDays, setTimeToDateObject, subractTime } from '../../utils/date.utils';
import { roundNumber } from '../../utils/math.utils';

const getGstNumber=(schedule,route,travels)=>{
    if(isValid(route.gst_number)){
        return route.gst_number;
    }else if(isValid(schedule.gst_number)){
        return schedule.gst_number;
    }else{
        return travels.gst_number
    }
}

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor:'#FFFFFF'
  },
  colblock:{
    justifyContent:'space-between'
  },
});

const getListItem=(text)=>(
            <Subtext style={{fontSize:8}}>
                {text}.
            </Subtext>
)

const getCancellationPolicies=(ticketDetails)=>{
    let cancellationRules=[] , 
    cancellationPolicies=ticketDetails.cancellationPolicies,
    tripstartDate=ticketDetails.tripRoute.start_date,
    routestartTime=ticketDetails.route.start_time,
    penalty=null;

    var tripDate = setTimeToDateObject(tripstartDate,routestartTime);

    if(isValidArray(cancellationPolicies)&&cancellationPolicies.length>0){
        cancellationPolicies.forEach((policy)=>{
            if(policy.deductionAmount === -1){
                cancellationRules.push(
                    getListItem(`  • Cancelling between ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.toValue))} and ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.fromValue))} is non refundable.`)
                );
            }
            else{
                    if(policy.is_percentage === 1){
                        penalty = `${policy.deductionAmount}%`;
                    }
                    else{
                        penalty = `Rs ${policy.deductionAmount}`
                    }
                    if(policy.toValue === 0){
                        cancellationRules.push(
                            getListItem(`  • The penalty for cancelling before ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.fromValue))} is ${penalty}.`)
                        );
                    }
                    else{
                        cancellationRules.push(
                            getListItem(`  • The penalty for cancelling between ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.toValue))} and ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.fromValue))} is ${penalty}.`)
                        );
                    }
                }
        })
    }
    return <View>
        {cancellationRules}
    </View>
}


const discountedFare = (ticket)=>{
    if(isValid(ticket.discounted_fare)){
        return ticket.discounted_fare;
    }else{
        let discountedFare= ticket.fare;
        if(isValid(ticket.direct_discount)){
            discountedFare=discountedFare-ticket.direct_discount;
        }
        return roundNumber(discountedFare);
    }   
}

const getRefundAmount=(travellers)=>{
    let refundAmount=0 , isCancelledTravellerPresent=false;
    travellers.forEach(element => {
        if(element.traveller_status === "Cancelled"){
            isCancelledTravellerPresent=true;
            refundAmount = refundAmount+(element.amount_paid-element.cancellation_penalty);
        }
    });
    return {refundAmount,isCancelledTravellerPresent}
}


const travellerColumns=[
    {
        title:"Seat no",
        key:"seat_number",
        dataIndex:"seat_number",
        pdfRender: ({text}) => text
    },
    {
        title:"Passenger",
        key:"full_name",
        dataIndex:"full_name",
        pdfRender: ({text}) => text
    },
    {
        title:"Sex",
        key:"gender",
        dataIndex:"gender",
        pdfRender: ({text}) => text
    },
    {
        title:"Age",
        key:"age",
        dataIndex:"age",
        pdfRender: ({text}) => text
    },
    {
        title:"Boarding time",
        key:"pickup_location",
        pdfRender : ({text, record,}) => {
            if(isValidArray(record.pickup_location)&&isValid(record.pickup_location.timing)){
                let reportDate ='';
                if(record.pickup_location.origin_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(record.start_date,record.pickup_location.origin_day));
                    reportDate = reportDate +" ("+incrementDate + ")";
                }
                return  getTimeStampInReadableFormat(record.pickup_location.timing) + reportDate;
            }else{
                return ''
            }
        }
    },
    {
        title:"Boarding point",
        key:"location",
        pdfRender : ({text, record, index}) => {
            if(isValidArray(record.pickup_location) && isValid(record.pickup_location.location)){
                return  record.pickup_location.location
            }else{
                return ''
            }
        }
    },
    {
        title:"Drop point",
        key:"dropPoint",
        dataIndex:"dropPoint",
        pdfRender: ({text}) => text
    },
    {
        title:"Drop time",
        key:"dropTime",
        dataIndex:"dropTime",
        pdfRender: ({text}) => text
    },
    {
        title:"Fare",
        key:"fare",
        dataIndex:"fare",
        pdfRender: ({text}) => text
    },
    {
        title:"Status",
        key:"status",
        dataIndex:"traveller_status",
        pdfRender: ({text}) => text
    }
];

const renderTravellers =(travellers,tripRoute,dropTime,dropPoint)=>{
    
    let travellerRows=[];
    travellers.forEach((row,index) => {
        row.start_date = tripRoute.start_date;
        row.dropTime = dropTime;
        row.dropPoint = dropPoint;
      let travellerCells=[];
      travellerColumns.forEach((column)=>{
        travellerCells.push(
          <Table.Cell style={{width:column.pdfWidth}}>
            {column.pdfRender({text:row[column.dataIndex],record:row,index})}
          </Table.Cell> 
        );
      });
      travellerRows.push(
        <Table.Row key={row.id}>
          {travellerCells}
        </Table.Row>
      )
    });

    return(
        <View style={{margin:5}}>
            <Table>
            <Table.Header>
                {travellerColumns.map(column => (
                    <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
                ))}
            </Table.Header>
            <Table.Body>
                    {travellerRows}
            </Table.Body>
            </Table>
        </View>
    )
}

export const getAddressComponent=(address)=>{
    let components=[];
    let addressVars=getArrayfromString(address,'##');
    if(isValidArray(addressVars)&&addressVars.length>0){
        addressVars.forEach((row)=>{
            let values=getArrayfromString(row,'@@');
            if(isValidArray(values)&&values.length>0){
                if(values.length>=2){
                    if(phoneNumberValidation(values[1])){
                        components.push(<SubTextBold strong> {values[0]} : <a href={`tel:+91${values[1]}`}>{values[1]}</a></SubTextBold>)
                    }else{
                        components.push( <SubTextBold strong> {values[0]} : {values[1]}</SubTextBold>)
                    }
                }else{
                    if(phoneNumberValidation(values[0])){
                        // components.push(<SubTextBold strong> <a href={`tel:+91${values[0]}`}>{values[0]}</a></SubTextBold>)
                    }
                    else{
                        components.push(<SubTextBold strong> {values[0]}</SubTextBold>)
                    }
                }
            }
        })
    }
    return  (
    <Row>
        {components}
    </Row>
    )
}

// Create Document Component
const MyDocument = ({
    travels,
    agent,
    agentAddress,
    bus,
    ticket,
    route,
    tripRoute,
    schedule,
    travellers,
    dropDetails,
    ticketInfo
}) => {
    

 let gstNumber=getGstNumber(schedule,route,travels);
 let address=travels.address;
 let phoneNumber=travels.phone_number;
 let bookingTime = `${getUIDateFormat(ticket.booking_time)},\xa0\xa0${getLocalTime(ticket.booking_time)}`;
 let {isCancelledTravellerPresent , refundAmount} = getRefundAmount(travellers);
 let isDiscountExits=isValid(ticket.direct_discount)&&ticket.direct_discount>0?true:false;
 let webSiteUrl=travels.website_url;
 let logoUrl = travels.logo_url;

 let dropTime='',dropPoint='';
 if(isValidArray(dropDetails) && isValid(dropDetails.timing)){
    dropTime = getTimeStampInReadableFormat(dropDetails.timing);
    if(route.destination_day>0 || dropDetails.destination_day>0){
        let destinationDayNo=0;
        if(route.destination_day>route.origin_day){
            destinationDayNo=route.destination_day-route.origin_day;
        }
        let dayValue = destinationDayNo + dropDetails.destination_day;
        let incrementDate = getUIDateFormat(incrementDays(tripRoute.start_date,dayValue));
        dropTime = dropTime +" ("+incrementDate + ")";
    }
    dropPoint = dropDetails.location;
 }

 return(
        <Document>
          <Page style={styles.page}>
            <Row style={styles.colblock}>
             <View>
                <Title>
                    {isValid(logoUrl) ? (
                        <ImageRender src={logoUrl} />
                    ) : (
                        global.operatorName
                    )}
                </Title>
             </View>
             <View>
                {getAddressComponent(address)} 
                <SubTextBold>{phoneNumber}</SubTextBold>
                <SubTextBold>GST-No#{gstNumber}</SubTextBold>
                <Line />
                <SubTextBold> Booked By {agent.name}</SubTextBold>
                <SubTextBold>
                    {
                        agentAddress!==''&&
                        agentAddress.address
                    }
                </SubTextBold>
                <SubTextBold strong>
                    {
                        agentAddress!==''&&
                        agentAddress.landmark
                    }
                </SubTextBold>
             </View>
            </Row>
            <Line />
            <Row style={styles.colblock}>
                <View style={{
                        width:'50%'
                    }}>
                    <Row>
                        <SubTextBold>Bus Name: </SubTextBold>
                        <Subtext>{bus.description}</Subtext>
                    </Row>
                </View>
                <View style={{
                        width:'50%'
                    }}>
                    <Row>
                        <SubTextBold>Transaction ID: </SubTextBold>
                        <Subtext>{ticket.pnr}</Subtext>
                    </Row>
                </View>
            </Row>
            <Row style={styles.colblock}>
                <View style={{
                        width:'50%'
                    }}>
                    <Row>
                        <SubTextBold>From: </SubTextBold>
                        <Subtext>{route.origin}</Subtext>
                    </Row>
                </View>
                <View style={{
                        width:'50%'
                    }}>
                    <Row>
                        <SubTextBold>To: </SubTextBold>
                        <Subtext>{route.destination}</Subtext>
                    </Row>
                </View>
            </Row>
            <Row style={styles.colblock}>
                {isValid(tripRoute.start_date)&&
                    <View style={{
                        width:'50%'
                    }}>
                        <Row>
                            <SubTextBold>Travel Date: </SubTextBold>
                            <Subtext>{getLocalDate(convertDateStringToDate(tripRoute.start_date.split('T')[0]))}</Subtext>
                        </Row>
                    </View>
                }
                <View style={{width:'50%'}}>
                    <Row>
                        <SubTextBold>Phone Number: </SubTextBold>
                        <Subtext>{ticket.phone_number}</Subtext>
                    </Row>
                </View>
            </Row>
            <Row style={styles.colblock}>
                <View style={{width:'50%'}}>
                    <Row>
                        <SubTextBold>Booked Date/Time(IST): </SubTextBold>
                        <Subtext>{bookingTime}</Subtext>
                    </Row>
                </View>
                <View style={{width:'50%'}}>
                    <Row>
                        <SubTextBold>Agent Name: </SubTextBold>
                        <Subtext>{agent.name}</Subtext>
                    </Row>
                </View>
            </Row>
            <Row >
                <View style={{width:'50%'}}>
                    <Row>
                        <SubTextBold>Agent Phone No: </SubTextBold>
                        <Subtext>{agent.phone_number}</Subtext>
                    </Row>
                </View>
                <View style={{width:'50%'}}>
                    
                </View>
            </Row>
            <Row>
                <SubTextBold>Trip Description: </SubTextBold>
                <Subtext>{schedule.description}</Subtext>
            </Row>
            <View>
                {renderTravellers(travellers,tripRoute,dropTime,dropPoint)}
            </View>
            <Row>
                <SubTextBold style ={{}}>Trip Fare: </SubTextBold>
                <Subtext>{roundNumber(ticket.fare)}</Subtext>
            </Row>
            {isDiscountExits&&
                <Row>
                    <SubTextBold style ={{}}>Discount: </SubTextBold>
                    <Subtext>{roundNumber(ticket.direct_discount)}</Subtext>
                </Row>
            }
            {isDiscountExits&&
                <Row>
                    <SubTextBold style ={{}}>Discounted trip fare: </SubTextBold>
                    <Subtext>{discountedFare(ticket)}</Subtext>
                </Row>
            }
            <Row>
                <SubTextBold style ={{}}>SGST: </SubTextBold>
                <Subtext>{roundNumber(ticket.sgst)}</Subtext>
                <SubTextBold  style ={{marginLeft:2}}>CGST: </SubTextBold>
                <Subtext>{roundNumber(ticket.cgst)}</Subtext>
            </Row>
            <Row>
                <SubTextBold  style ={{}}>Amount Paid: </SubTextBold>
                <Subtext>{roundNumber(ticket.amount_paid)}</Subtext>
            </Row>
            {isCancelledTravellerPresent&&
            <View>
                <Row>
                    <SubTextBold style ={{}}>Can.Penalty(Rs.): </SubTextBold>
                    <Subtext>{roundNumber(ticket.cancellation_penalty)}</Subtext>
                </Row>
                <Row>
                    <SubTextBold style ={{}}>Refund(Rs.): </SubTextBold>
                    <Subtext>{roundNumber(refundAmount)}</Subtext>
                </Row>
            </View>
            }
            <Row style={{marginTop:5}}>
                <SubTextBold>Terms and conditions: </SubTextBold>
            </Row>
            <View>
                <Subtext style={{fontSize:8}}>
                    1.Passengers are expected to report at boarding point at least 15 minutes before departure time, failing which the tickets will be cancelled automatically.
                </Subtext>
                <Subtext style={{fontSize:8}}>
                    2.Management will not responsible for loss, theft or damage to the luggage or other
                    belongings of the passengers.
                </Subtext>
                <Subtext style={{fontSize:8}}>
                    3.In case of cancellation, there will be a penalty charged to the customer based on the
                    cancellation time as described below.
                </Subtext>
                {getCancellationPolicies(ticketInfo)}
                <Subtext style={{fontSize:8}}>
                    {`Children aged 6 years (>=6 years) or children with height greater than or equal to 130 cms will be charged for full ticket.`}
                </Subtext>
                <Subtext style={{fontSize:8}}>
                    4.Luggages above 25 KG will be chargeable.
                </Subtext>
                <Subtext style={{fontSize:8}}>
                    5.For additional terms and conditions, please visit {webSiteUrl+"/tandc"}
                </Subtext>
            </View>
          </Page>
        </Document>
      )
};

export const TicketPdf = ({
    ticketDetails
}) =>{

    const {
        ticket,
        agent,
        traveller,
        route,
        pickupDetails,
        dropDetails,
        schedule,
        trip,
        bus,
        travels,
        agentAddress,
        tripRoute
    } = ticketDetails;

    return (
        <div className=''>
            <PDFDownloadLink 
                document={<MyDocument 
                travels={travels}
                agent={agent}
                agentAddress={agentAddress}
                bus={bus}
                ticket={ticket}
                route={route}
                tripRoute={tripRoute}
                schedule={schedule}
                travellers={traveller}
                dropDetails={dropDetails}
                ticketInfo={ticketDetails}
             />} fileName={`ticket-${ticket.id}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
              <Button>
                Export
              </Button>
            </PDFDownloadLink>
        </div>
    )
}