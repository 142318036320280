import React from "react";
import './trip-create.style.scss';
import {CreateTripForm} from './create-trip-form/create-trip-form';
import { AddStops } from "./add-stops/add-stops";


export const TripCreateRoute = (props) => {
    const {
        onCallBackSetDirections,
        masterCities,
        busesData,
        isTripCityCreated,
        addStop,
        tripScheduleId,
        setShowVerifyPage,
        setHeaderStep,
        stepDataVal,
        selectedSeatNumbers,
        setSelectedSeatNumbers,
        setShowSelectCity,
        showSelectCity,
        setDistance,
        setDuration,
        addStopComponent,
        setEnableLocationPick,
        customClassName,
        childRef,
        setCitiesChanged
    }= props;

    return (
        <div className={`side-nav ${customClassName}`} >
            <div style= {{alignItems: 'center'}}>
                {addStop?
                <AddStops
                    tripScheduleId = {tripScheduleId}
                    setShowVerifyPage = {setShowVerifyPage}
                    setHeaderStep = {setHeaderStep}
                    setEnableLocationPick = {setEnableLocationPick}
                    childRef={childRef}
                />
                :<CreateTripForm
                    onCallBackSetDirections={onCallBackSetDirections}
                    masterCities = {masterCities}
                    isTripCityCreated = {isTripCityCreated}
                    busesData = {busesData}
                    tripScheduleId = {tripScheduleId}
                    selectedSeatNumbers={selectedSeatNumbers} 
                    setSelectedSeatNumbers={setSelectedSeatNumbers}
                    setHeaderStep ={setHeaderStep}
                    headerFormPos = {stepDataVal['headerFormPos']}
                    setShowSelectCity = {setShowSelectCity}
                    showSelectCity = {showSelectCity}
                    setDistance={setDistance}
                    setDuration={setDuration}
                    addStopComponent={addStopComponent}
                    setCitiesChanged={setCitiesChanged}
                />
                }
            </div>
        </div>
    )
}