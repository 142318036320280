import { UPDATE_SEAT_DETAILS , ADD_CLIENT_SIDE_DISCOUNT, UPDATE_EXTRA_SEAT_COUNT, ON_EXTRA_SEAT_FORM_CHANGE, RESET_BLOCKED_SEAT_TICKET_ID} from './action-types';

export const updateSelectedSeats = (seatId,seatObject,passengerInfo) => {
    return {
        type: UPDATE_SEAT_DETAILS,
        payload: {
            seatId,seatObject,passengerInfo
        }
    }
  }

  
export const addClientSideDiscount = (discountPerSeat) => {
    return {
        type: ADD_CLIENT_SIDE_DISCOUNT,
        payload: {
            discountPerSeat
        }
    }
  }

export const updateExtraSeatCount = (isAdd, seatCount, extra_index) => {
    return {
        type: UPDATE_EXTRA_SEAT_COUNT,
        payload: {
            isAdd,
            seatCount,
            extra_index
        }
    }
}

export const onExtraSeatFormChangeAction = (extraSeatInfo) => {
    return {
        type: ON_EXTRA_SEAT_FORM_CHANGE,
        payload: {...extraSeatInfo}
    }
}
   
export const resetBlockedSeatTicketId = () => {
    return {
        type: RESET_BLOCKED_SEAT_TICKET_ID
    }
}

