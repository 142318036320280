import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { addMonth, subtractMonth } from "../utils/date.utils";
import { Tabs } from 'antd';
import { StopsTab } from '../components/temporary-changes/stops-tab/stops-tab.component';
import { CitiesRemovedTab } from '../components/temporary-changes/cities-removed/cities-removed.component';
import { CitiesAddedTab } from '../components/temporary-changes/cities-added/cities-added.component';
import { TimeChangesTab } from '../components/temporary-changes/time-changes/time-changes.component';
import moment from 'moment';
import '../styles/temporary-changes.style.scss';
import { getTemporaryChangesByMonth } from "../actions/temporary-changes.action";
import { loadingAction } from "../actions/loading-actions";
import { useDispatch } from "react-redux";

const { TabPane } = Tabs;

const DrawLine =()=>{

    return(
        <div className="temporary-changes-underline">

        </div>
    )
}

const Title = () =>{
        return (
            <div className="title-temporary-changes">
                <h3>
                    Temporary Changes
                </h3>
                <DrawLine />
            </div>
        )
}

export const TemporaryChangesPage = () => {
    const [selectedDate, setSelectedDate] = useState(moment());
    const [stopsRemovedData, setStopsRemovedData] = useState([]);
    const [stopsAddedData, setStopsAddedData] = useState([]);
    const [citiesRemovedData, setCitiesRemovedData] = useState([]);
    const [timeChangesData, setTimeChangesData] = useState([]);
    const [selectedTab, setSelectedTab] = useState("stops-removed");

    const dispatch = useDispatch();
    useEffect(()=>{
        onChangeTab(selectedTab);
    },[selectedTab,selectedDate]);

    const onChange = (date) => {
        if (date) {
            setSelectedDate(date);
        }
    };

    const setLoading = (loading) => {
        dispatch(loadingAction(loading));
    }

    const onChangeTab = (key) => {     
        setLoading(true);   
        switch(key){
            case "stops-removed":
                getTemporaryChangesByMonth(selectedDate.month()+1,selectedDate.year(),true,false,false,false,(data)=>{
                    setStopsRemovedData(data);
                    setLoading(false);
                },()=>{
                    console.log("Failure");
                    setLoading(false);
                });
                break;
            case "stops-added":
                getTemporaryChangesByMonth(selectedDate.month()+1,selectedDate.year(),false,true,false,false,(data)=>{
                    setStopsAddedData(data);
                    setLoading(false);
                },()=>{
                    console.log("Failure");
                    setLoading(false);
                });
                break;
            case "cities-removed":
                getTemporaryChangesByMonth(selectedDate.month()+1,selectedDate.year(),false,false,true,false,(data)=>{
                    setCitiesRemovedData(data);
                    setLoading(false);
                },()=>{
                    console.log("Failure");
                    setLoading(false);
                }); 
                break;
            case "time-changes":
                getTemporaryChangesByMonth(selectedDate.month()+1,selectedDate.year(),false,false,false,true,(data)=>{
                    setTimeChangesData(data);
                    setLoading(false);
                },()=>{
                    console.log("Failure");
                    setLoading(false);
                });
                break;
            default:
                setLoading(false);
                break;
        }
    }

    return (
        <div className="temporary-changes-page-root">
            <Row justify="space-between" align="middle">
                    <Col>
                        <Title />
                    </Col>
                    <Col xs={{span:14}} sm={{span:14}} md={{span:12}} lg={{span:6}} xl={{span:5}}>
                        <Row>
                            <Col>
                                <Button className="schedule-left-arrow" onClick={() => {
                                    setSelectedDate(subtractMonth(selectedDate, 1));
                                }}>
                                    <LeftOutlined />
                                </Button>
                            </Col>
                            <Col>
                                <DatePicker 
                                    defaultValue={selectedDate}
                                    value={selectedDate}
                                    size="large"
                                    format="MMM YYYY"
                                    onChange={onChange} 
                                    picker="month" 
                                    className="temporary-changes-date-picker"
                                />
                            </Col>
                            <Col>
                                <Button className="schedule-right-arrow" onClick={() => {
                                    setSelectedDate(addMonth(selectedDate, 1));
                                }}>
                                    <RightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            <div className="temporary-changes-page-inner-div margin-top-10">

                <Tabs defaultActiveKey="1" 
                className="temporary-changes-tabs"
                onChange={(key)=>{
                    setSelectedTab(key);
                }}>
                    <TabPane tab="Stops Removed" key="stops-removed">
                        <StopsTab selectedDate={selectedDate} data={stopsRemovedData} isStopsRemoved={true} isStopsAdded={false}/>
                    </TabPane>
                    <TabPane tab="Stops Added" key="stops-added">
                        <StopsTab selectedDate={selectedDate} data={stopsAddedData} isStopsRemoved={false} isStopsAdded={true} />
                    </TabPane>
                    <TabPane tab="Cities Removed" key="cities-removed">
                        <CitiesRemovedTab selectedDate={selectedDate} data={citiesRemovedData} />
                    </TabPane>
                    <TabPane tab="Time Changes" key="time-changes">
                        <TimeChangesTab selectedDate={selectedDate} data={timeChangesData} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}; 