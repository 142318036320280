/**
 * @author Rejo Varghese
 * @description Modal body of Logs
 */
import React, { useState, useEffect } from 'react';
import './logs-modal.style.scss';
import { LOGS_CUSTOMER_DATA , LOGS_DROP_TIMINGS, LOGS_EDIT_FARE, LOGS_FOR_ADMIN_BLOCK_SEATS, LOGS_FOR_DRIVER_PH_NO_UPDATE, LOGS_FOR_RELEASE_SEATS, LOGS_PICK_UP_TIMINGS , LOGS_SEAT_FARE, LOGS_TRACKING_SMS_SENT, LOGS_MANAGEMENT_ALERT_SMS, LOGS_BUS_CHANGE} from '../../../utils/constant';
import { Typography , Table} from 'antd';
import { getArrayfromString, isValid, isValidString, isValidArrayWithValues } from '../../../utils/utilities';
import { getFormattedTime, getLocalDateAndTime } from '../../../utils/date.utils';
import { isMobile } from 'react-device-detect';
import useColumnSearch from '../../../ui-components/table-column-search';
import { useDispatch, useSelector } from 'react-redux';
import { getBusDetailsAction } from '../../../actions/trip-create.actions';
import { tripCreateReducerSelector } from '../../../selector/trip-create.selector';

const { Text } = Typography;

const CustomerData = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;
    const updateData = selectedLog.updatedData;
    const {travellers} = rawData;
    let travellerUpdate = updateData.travellers;

    const renderColumns = (row,text,key) => {

        if (isValid(travellerUpdate)&&isValid(travellerUpdate[row.traveller_id])&&isValid(travellerUpdate[row.traveller_id][key])) {
            return {
                props: {
                    className : "updateTableBorder"
                },
                children: <Text>{text}</Text>
            };
        }
        else {
           return (<Text>{text}</Text>)
        }
    }

    const columns = [
        {
            title: "Seat no",
            dataIndex: "seat_number",
            key: "seat_number",
            render: (text,row) => {
                return renderColumns(row,text,"seat_number")
            }
        },
        {
            title: "Name",
            dataIndex: "full_name",
            key: "full_name",
            render: (text,row) => {
                return renderColumns(row,text,"full_name")
            }
        },
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
            render: (text,row) => {
                return renderColumns(row,text,"age")
            }
        },
        {
            title: "Sex",
            dataIndex: "gender",
            key: "gender",
            render: (text,row) => {
                return renderColumns(row,text,"gender")
            }
        },
        {
            title: "Boarding",
            dataIndex: "pickup_location",
            key: "pickup_location",
            render: (text,row) => {
                return renderColumns(row,text,"pickup_location")
            }
        }
    ]

    return (
        <div>
            <Text style= {{fontWeight: 'bold'}}>PNR: {rawData.pnr}</Text>
            <br />
            <Text style= {{fontWeight: 'bold'}}>Phone: </Text>
            <Text className = {isValid(updateData.phone_number)?'updateBox': ''}>{rawData.phone_number}</Text>
            <br />
            <Text style= {{fontWeight: 'bold'}}>Email: </Text>
            <Text className = {isValid(updateData.email_id)?'updateBox': ''}>{rawData.email_id}</Text>
            <br /><br />
            {isValidArrayWithValues(travellers)&&<Table
                dataSource={travellers}
                columns={columns}
                pagination={false}
                bordered
                scroll={{
                    x: 400
                }}
            />}
        </div>
    )
}

const EditFareLog = (props) => {
    const {
        selectedLog,
        supportedFare,
        seatGroupIds
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Origin-Destination',
            dataIndex: 'origin',
            key: 'origin',
            render: (text,row) => {
                return (
                    <div>
                        {row.origin} - {row.destination}
                    </div>
                )
            }
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'user_name',
            key: 'user_name'
        }
    ];

    if(supportedFare === 1){
        columns.push({
            title: "Fare",
            dataIndex: 'fare',
            key: 'fare'
        });
    }

    if(isValidString(seatGroupIds)){
        let seatsArray=getArrayfromString(seatGroupIds);
        seatsArray.forEach(fareJsonKey => {
            columns.push({
            title: fareJsonKey,
            dataIndex:'fare_json',
            key: 'fare_json',
            render: (text,rowIndex ,viewIndex) => {
                let fareJsonObject={};
                if(isValidString(text)){
                  fareJsonObject=JSON.parse(text);
                  text=fareJsonObject[fareJsonKey];
                }
                return text;
              }
          })
        });
    }

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}


const PickupPointsLogs = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            filters:selectedLog.cityFilters,
            onFilter: (value, record) => record.city.indexOf(value) === 0,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            filters:selectedLog.locationFilters,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
        },
        {
            title: 'Time',
            dataIndex: 'timing',
            key: 'timing',
            render:(text)=>{
                if(isValid(text)){
                    return `${getFormattedTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by'
        }
    ];

  

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}

const DropPointsLogs = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            filters:selectedLog.cityFilters,
            onFilter: (value, record) => record.city.indexOf(value) === 0,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            filters:selectedLog.locationFilters,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
        },
        {
            title: 'Time',
            dataIndex: 'timing',
            key: 'timing',
            render:(text)=>{
                if(isValid(text)){
                    return `${getFormattedTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by'
        }
    ];

  

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}


const SeatFareLogs = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Origin',
            dataIndex: 'origin',
            key: 'origin',
            filters:selectedLog.cityFilters,
            onFilter: (value, record) => record.city.indexOf(value) === 0,
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
            filters:selectedLog.locationFilters,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
        },
        {
            title: 'Seat No',
            dataIndex: 'seat_number',
            key: 'seat_number',
            ...useColumnSearch(
                {dataIndexExt:"seat_number"}
            ),
        },
        {
            title: 'Fare',
            dataIndex: 'fare',
            key: 'fare'
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by'
        }
    ];

  

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}

const ReleaseSeats = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Seat No',
            dataIndex: 'seat_number',
            key: 'seat_number',
            ...useColumnSearch(
                {dataIndexExt:"seat_number"}
            ),
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'user_name',
            key: 'user_name'
        }
    ];

  

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}



const AdminBlockedSeats = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Seat No',
            dataIndex: 'seat_number',
            key: 'seat_number',
            ...useColumnSearch(
                {dataIndexExt:"seat_number"}
            ),
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'user_name',
            key: 'user_name'
        }
    ];

  

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}


const DriverPhNoUpdate = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Phone Number',
            dataIndex: 'phone_no',
            key: 'phone_no',
            ...useColumnSearch(
                {dataIndexExt:"phone_no"}
            ),
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by'
        }
    ];

  

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false} 
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}

const TrackingSmsLogs = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Phone Number',
            dataIndex: 'sent_phone',
            key: 'sent_phone',
            ...useColumnSearch(
                {dataIndexExt:"sent_phone"}, true
            ),
        },
        {
            title: 'SMS sent time',
            dataIndex: 'sending_time',
            key: 'sending_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Sent By',
            dataIndex: 'sent_by',
            key: 'sent_by'
        }
    ];

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false}
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}

const ManagementAlertSMSLogs = (props) => {
    const {
        selectedLog
    } = props;

    const rawData = selectedLog.rawData;

    const columns=[
        {
            title: 'Phone Number',
            dataIndex: 'sent_phone',
            key: 'sent_phone',
            ...useColumnSearch(
                {dataIndexExt:"sent_phone"}, true
            ),
        },
        {
            title: 'SMS sent time',
            dataIndex: 'sending_time',
            key: 'sending_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'action_type',
            key: 'action_type'
        },
        {
            title: 'Sent By',
            dataIndex: 'sent_by',
            key: 'sent_by'
        }
    ];

    return (
        <div>
           <Table
                dataSource={rawData}
                columns={columns}
                pagination={false}
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )
}

const BusChangeLogs = (props) => {
    const {
        selectedLog
    } = props;

    const [busLogsData, setBusLogsData] = useState([]);

    const dispatch = useDispatch();
    const tripCreateReducer = useSelector(tripCreateReducerSelector);

    const {
        busesData
    } = tripCreateReducer;

    useEffect(() => {
        if (isValid(selectedLog)&& selectedLog.action === LOGS_BUS_CHANGE){
            dispatch(getBusDetailsAction());
        }
    }, [selectedLog]);

    const rawData = selectedLog.rawData;

    useEffect(() => {
        if(isValidArrayWithValues(busesData)&&isValidArrayWithValues(rawData)){
            let tempLogData = [];
            rawData.forEach(logData => {
                const oldBusData = busesData.find(bus => bus.id === logData.old_bus_id);
                const newBusData = busesData.find(bus => bus.id === logData.bus_id);
                tempLogData.push({...logData, old_bus_description: oldBusData.description, old_bus_total_seats: oldBusData.number_of_seats, new_bus_description: newBusData.description, new_bus_total_seats: newBusData.number_of_seats});
            });
            setBusLogsData(tempLogData.reverse());
        }
    }, [busesData, rawData]);


    const columns=[
        {
            title: 'Old Bus description',
            dataIndex: 'old_bus_description',
            key: 'old_bus_description',
        },
        {
            title: 'Old bus total seats',
            dataIndex: 'old_bus_total_seats',
            key: 'old_bus_total_seats',
        },
        {
            title: 'New bus description',
            dataIndex: 'new_bus_description',
            key: 'new_bus_description',
        },
        {
            title: 'New bus total seats',
            dataIndex: 'new_bus_total_seats',
            key: 'new_bus_total_seats',
        },
        {
            title: 'Updated time',
            dataIndex: 'operation_time',
            key: 'operation_time',
            render:(text)=>{
                if(isValid(text)){
                    return `${getLocalDateAndTime(text)}`;
                }else{
                    return text
                }
            }
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by'
        }
    ];

    return (
        <div>
           <Table
                dataSource={busLogsData}
                columns={columns}
                pagination={false}
                className="edit-fare-table"
                scroll={isMobile?{
                    x: 400
                }:null}
            />
        </div>
    )


}


export const LogsModalBody = (props) => {
    const {
        selectedLog,
        supportedFare,
        seatGroupIds
    } = props;

    switch(selectedLog.action) {
        case LOGS_CUSTOMER_DATA:
            return <CustomerData selectedLog = {selectedLog} />

        case LOGS_EDIT_FARE:
            return <EditFareLog selectedLog = {selectedLog}  supportedFare={supportedFare} seatGroupIds={seatGroupIds}/>;

        case LOGS_PICK_UP_TIMINGS:
            return <PickupPointsLogs selectedLog = {selectedLog} />;
        
        case LOGS_DROP_TIMINGS:
            return <DropPointsLogs selectedLog = {selectedLog} />;

        case LOGS_SEAT_FARE:
            return <SeatFareLogs selectedLog = {selectedLog} />;
    
        case LOGS_FOR_RELEASE_SEATS:
            return <ReleaseSeats  selectedLog = {selectedLog}/>;

        case LOGS_FOR_ADMIN_BLOCK_SEATS:
            return <AdminBlockedSeats selectedLog = {selectedLog}/>;

        case LOGS_FOR_DRIVER_PH_NO_UPDATE:
                return <DriverPhNoUpdate selectedLog = {selectedLog}/>;

        case LOGS_MANAGEMENT_ALERT_SMS:
                return <ManagementAlertSMSLogs selectedLog = {selectedLog} />;
        case LOGS_TRACKING_SMS_SENT:
            return <TrackingSmsLogs selectedLog = {selectedLog}/>;

        case LOGS_BUS_CHANGE:
            return <BusChangeLogs selectedLog = {selectedLog}/>;

        default:
            return null;
    }
}