import React from 'react';
import { Collapse } from 'antd';
import './stops.style.scss';
import { StopPanel } from './stop-panel.component';


export const StopsTab = ({ selectedDate, data,isStopsRemoved=false,isStopsAdded=false }) => {
    return (
        <div className="stops-removed-tab">
            {data && data.length > 0 ? (
                <Collapse
                    expandIconPosition="left"
                    ghost
                >
                    {data.map((item, index) => (
                        <Collapse.Panel 
                            key={index}
                            header={`${index + 1}. ${item.description}`}
                        >
                            <StopPanel 
                                item={item}
                                index={index}
                                selectedDate={selectedDate}
                                isStopsRemoved={isStopsRemoved}
                                isStopsAdded={isStopsAdded}
                            />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <p className="no-data">No stops {isStopsRemoved ? 'removed' : 'added'} for the selected date.</p>
            )}
        </div>
    );
};