import React, { useState } from 'react';
import { Button, Modal, InputNumber, message } from 'antd';
import { updateAdvanceBookingDays } from './advance-booking-days.module';

export const AdvanceBookingDays = ({ scheduleId, onSuccess, advanceBookingDays  }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [days, setDays] = useState(advanceBookingDays);
    const [loading, setLoading] = useState(false);

    const handleOk = async () => {
        try {
            setLoading(true);
            await updateAdvanceBookingDays(scheduleId, days);
            message.success('Advance booking days updated successfully');
            setIsModalVisible(false);
            onSuccess?.();
        } catch (error) {
            message.error('Failed to update advance booking days');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button onClick={() => setIsModalVisible(true)}>
                Advance Booking Days
            </Button>
            <Modal
                title="Set Advance Booking Days"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => setIsModalVisible(false)}
                confirmLoading={loading}
            >
                <InputNumber
                    min={0}
                    max={365}
                    value={days}
                    onChange={(value) => setDays(value)}
                    placeholder="Enter number of days"
                    style={{ width: '100%' }}
                />
            </Modal>
        </>
    );
}; 