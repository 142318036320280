import React, { useEffect, useState } from "react";
import { Button, Col, Row, message, Checkbox, Divider  } from "antd";
import {
    DownOutlined,
    MailOutlined,
    MobileOutlined,
    SendOutlined
   } from '@ant-design/icons';
import { WhatsAppTextShare } from "../custom-components/custom-component";
import { getTextOfTicket } from "./open-booked-ticket-modal.component";
import { getTicketById, sendTicketUpdateMailById, sendTicketUpdateSMSById } from "../../actions/ticket.action";
import { isValid, isValidArray } from "../../utils/utilities";
import { sendTrackingNotificationByTicketId } from "../bus-cancel-components/send-driver-details-modal/send-driver-details-modal.module";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";

const getVechicleDetails = (scheduleVechicleId,tripVechicleId)=>{

    let vechicleInfoExits = false;
    if(isValid(tripVechicleId)){
        vechicleInfoExits = true;
    }

    if(isValid(scheduleVechicleId)){
        vechicleInfoExits = true;
    }

    return vechicleInfoExits
}

export const NotifyButton = (props) => {

    const{
        onClick,
        showDownArrow=true
    }=props;

    return (
        <div>
            <Button onClick={onClick} style= {{}}>
            <SendOutlined />
                Notify {showDownArrow && <DownOutlined />}
            </Button>
        </div>
    );
}



export const NotifyComponent = (props) => {

    const{
        ticketInfo,
        selectedNotifyTickets,
        ticketId
    }=props;

    const [sendTicketDetails,setSendTicketDetails] = useState(false);
    const [sendDrivingDetails,setSendDrivingDetails] = useState(false);
    const [selectedTicketInfo,setSelectedTicketInfo] = useState(null);

    const dispatch = useDispatch();

    const setLoading =(value)=>{
     dispatch(loadingAction(value));   
    }

    useEffect(()=>{
        if(isValid(ticketId)){  
            setLoading(true);
            getTicketById(ticketId,(info)=>{
                setSelectedTicketInfo(info);
                setLoading(false);
            },(errMsg)=>{
                setLoading(false);
                message.error(errMsg);
            });

        }else if(isValidArray(ticketInfo)){
            setSelectedTicketInfo(ticketInfo);
        }

    },[ticketId,ticketInfo]);
      

      if(isValidArray(selectedTicketInfo)&&selectedNotifyTickets.indexOf(selectedTicketInfo.ticket.id) != -1){

        let isConfirmedTicket = selectedTicketInfo.ticket.ticket_status == "Confirmed";
        let vechicleInfoExits = getVechicleDetails(selectedTicketInfo.schedule.vechicle_id,selectedTicketInfo.trip.vechicle_id);

        return (
            <div>
                <Divider />
                        <Row justify='center' align='center'>
                            <Col>
                                <Row gutter={[16,16]}> 
                                    <Col>
                                        <Checkbox 
                                        disabled={!isConfirmedTicket}
                                            value={sendTicketDetails} 
                                            onChange={(e)=>{
                                                setSendTicketDetails(e.target.checked);
                                            }}

                                        >Ticket Details</Checkbox>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Checkbox 
                                            disabled={!vechicleInfoExits}
                                            value={sendDrivingDetails}
                                            onChange={(e)=>{
                                                setSendDrivingDetails(e.target.checked);
                                            }}
                                                    >Bus Tracking Details</Checkbox>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Button 
                                        size="large"
                                        icon={<MailOutlined />}
                                        onClick={()=>{    
                                            console.log(sendTicketDetails);
                                            if(sendTicketDetails || sendDrivingDetails){
                                                if(sendTicketDetails){
                                                    setLoading(true);
                                                    sendTicketUpdateMailById(selectedTicketInfo.ticket.id,(succMsg)=>{
                                                        message.success(succMsg);
                                                        setLoading(false);
                                                    },(errMsg)=>{
                                                        setLoading(false);
                                                        message.error(errMsg);
                                                    });
                                                }
                                                if(sendDrivingDetails){
                                                    message.warning("Driver details cannot send via email!");
                                                }
                                            } else {
                                                message.warning("Please select at least one option to send.");
                                            }
                                        }}>
                                            Send Mail
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                        size="large"
                                        icon={<MobileOutlined />}
                                        onClick={()=>{ 
                                            if(sendTicketDetails || sendDrivingDetails){
                                                if(sendTicketDetails){
                                                    setLoading(true);
                                                    sendTicketUpdateSMSById(selectedTicketInfo.ticket.id,(succMsg)=>{
                                                        setLoading(false);
                                                        message.success(succMsg);
                                                    },(errMsg)=>{
                                                        setLoading(false);
                                                        message.error(errMsg);
                                                    });
                                                }
                                                if(sendDrivingDetails){
                                                    setLoading(true);
                                                    sendTrackingNotificationByTicketId(selectedTicketInfo.ticket.id,()=>{
                                                        message.success("Driver details sms sent successfully!");
                                                        setLoading(false);
                                                    },(msg)=>{
                                                        setLoading(false);
                                                        message.error(msg);
                                                    });
                                                }
                                            } else {
                                                message.warning("Please select at least one option to send.");
                                            }
                                        }}>Send SMS</Button>
                                    </Col>
                                    <Col>
                                        <WhatsAppTextShare
                                            shareId="ticketSummary"
                                            zoomValue={0.5}
                                            buildText={getTextOfTicket(selectedTicketInfo)}
                                            phoneNumber={selectedTicketInfo.ticket.phone_number}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
            </div>
        );
      }else{
        return null;
      }
}
