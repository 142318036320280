import { takeLatest, put, all, fork, call } from 'redux-saga/effects';
import {
    GET_AGENT_COLOURS,
    SET_AGENT_COLOURS,
    UPDATE_AGENT_COLOURS,
    LOADING_ACTION
} from '../actions/action-types';
import { message } from 'antd';

export function* getAgentColorsSaga(action) {
    try {
        yield put({
            type: LOADING_ACTION,
            payload: true
        });

        const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
        } = action.payload;

        const apiResult = yield call(api, url, apiPayload);
        
        if (apiResult.status === "SUCCESS") {
            yield put({
                type: SET_AGENT_COLOURS, 
                payload: apiResult.payload
            });
            if (onSuccess) onSuccess();
        } else {
            message.error("Failed to get agent portal colours");
            if (onFailure) onFailure();
        }
    } catch (error) {
        console.log("fetching agent colors: ", error);
        message.error("Failed to get agent portal colours");
        if (action.payload.onFailure) action.payload.onFailure();
    } finally {
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
}

// Add new saga for updating colors
export function* updateAgentColorsSaga(action) {
    try {
        yield put({
            type: LOADING_ACTION,
            payload: true
        });

        const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
        } = action.payload;

        const apiResult = yield call(api, url, apiPayload);
        
        if (apiResult.status === "SUCCESS") {
            message.success("Colors updated successfully");
            if (onSuccess) onSuccess();
        } else {
            message.error("Failed to update agent portal colours");
            if (onFailure) onFailure();
        }
    } catch (error) {
        console.log("updating agent colors: ", error);
        message.error("Failed to update agent portal colours");
        if (action.payload.onFailure) action.payload.onFailure();
    } finally {
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
}

function* watchAgentColourSaga() {
    yield takeLatest(GET_AGENT_COLOURS, getAgentColorsSaga);
    yield takeLatest(UPDATE_AGENT_COLOURS, updateAgentColorsSaga); // Add watcher for update action
}

export default function* watchAgentColourPageSaga() {
    yield all([
        fork(watchAgentColourSaga)
    ]);
}

