import { Button, Col, Form , Input , Row, Select  } from "antd";
import React from "react";
import { createStaff, createDriver} from './../../actions/staff-registration.action';
import { StaffTypesSelect } from './../../components/search-components/staff-types-select/staff-types-select.component';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { STAFF_DRIVER } from "../../utils/constant";

const { Option } = Select;

export const StaffRegistrationForm = (props) => {

    let {
        loadData,
        staffTypes
    } = props;

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const onFinish = (values) =>{
        create(values);
    }

    const create =(values)=>{
        setLoading(true);
        createStaff({...values},()=>{
            setLoading(false);
            loadData();
        },()=>{
            setLoading(false);
        });
        if (values.staffType==STAFF_DRIVER){
           createDriver({...values},()=> {},()=>{});
        }
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

        return (
            <div className="staff-form-root">
                <div className="fontWeightBold">
                    New Staff 
                </div>
                <div className="staff-form-inside">
                    <Form
                        form={form}
                        initialValues={{ }}
                        className = "staff-form"
                        onFinish={onFinish}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Form.Item
                            label="Name"
                            rules={[{ required: true, message: 'Please input name' }]}
                            name="name"
                            >
                                <Input placeholder="Name"/>
                        </Form.Item>
                        <Form.Item
                                label="Phone"
                                rules={[{ required: true, message: 'Please phone number' }]}
                                name="phoneNumber"
                                >
                                <Input placeholder="Phone Number"  />
                        </Form.Item>
                        <StaffTypesSelect 
                                data={staffTypes}
                        />
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button className="exit-btn"
                                onClick={()=>{
                                    form.resetFields();
                                }}
                                >Cancel</Button>
                            </Col>
                            <Col>
                                <Button htmlType="submit">Create</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
}