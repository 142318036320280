import React, { useState, useEffect } from 'react';
import { Collapse, Checkbox, Button, Row, Col, message } from 'antd';
import './services-list.style.scss';
import { DownOutlined, MinusSquareFilled, CheckSquareFilled } from '@ant-design/icons';
import { isValidArray } from '../../utils/utilities';
import { ThreeStateCheckbox } from '../../ui-components/ThreeStateCheckbox';

const { Panel } = Collapse;

export const ServicesList = ({ 
    servicesData = {}, 
    selectedUsers, 
    onUpdateServices = () => {},
    onReset = () => {} 
}) => {
    const [selectedServices, setSelectedServices] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (servicesData) {
            setOriginalData(servicesData);
            initializeSelectedServices(servicesData);
        }
    }, [servicesData]);

    const initializeSelectedServices = (data) => {
        const services = {};
        Object.keys(data).forEach(city => {
            services[city] = data[city].schedules.map(schedule => ({
                ...schedule,
                isSelected: schedule.service_access_restricted.length === 0
            }));
        });
        setSelectedServices(services);
        
        // Check if all services are selected and update selectAll state
        updateSelectAllState(services);
    };

    const handleSelectAll = (checked) => {
        setSelectAll(checked);
        
        const updatedServices = {};
        Object.keys(selectedServices).forEach(city => {
            updatedServices[city] = selectedServices[city].map(schedule => ({
                ...schedule,
                isSelected: checked
            }));
        });
        setSelectedServices(updatedServices);
    };

    const handleCitySelect = (city, checked) => {
        const updatedServices = {
            ...selectedServices,
            [city]: selectedServices[city].map(schedule => ({
                ...schedule,
                isSelected: checked
            }))
        };
        setSelectedServices(updatedServices);
        updateSelectAllState(updatedServices);
    };

    const handleScheduleSelect = (city, scheduleId, checked) => {
        const updatedServices = {
            ...selectedServices,
            [city]: selectedServices[city].map(schedule => 
                schedule.id === scheduleId 
                    ? { ...schedule, isSelected: checked }
                    : schedule
            )
        };
        setSelectedServices(updatedServices);
        updateSelectAllState(updatedServices);
    };

    const updateSelectAllState = (services) => {
        const allSchedules = Object.values(services).flat();
        const selectedCount = allSchedules.filter(schedule => schedule.isSelected).length;
        
        if (selectedCount === 0) {
            setSelectAll(false);
        } else if (selectedCount === allSchedules.length) {
            setSelectAll(true);
        } else {
            setSelectAll('indeterminate');
        }
    };

    const handleUpdate = () => {
        if (!selectedUsers || selectedUsers.length === 0) {
            message.warning('Please select users');
            return;
        }

        const changes = [];
        Object.keys(selectedServices).forEach(city => {
            selectedServices[city].forEach(schedule => {
                const originalSchedule = originalData[city].schedules.find(s => s.id === schedule.id);
                const wasRestricted = originalSchedule.service_access_restricted.length > 0;
                const isNowRestricted = !schedule.isSelected;
                
                if (wasRestricted !== isNowRestricted) {
                    changes.push({
                        schedule_id: schedule.id,
                        agent_ids: selectedUsers,
                        restricted: isNowRestricted
                    });
                }
            });
        });

        if (changes.length === 0) {
            message.warning('No changes to update');
            return;
        }

        onUpdateServices(changes);
    };

    const handleReset = () => {
        initializeSelectedServices(originalData);
        setSelectAll(false);
        onReset();
    };

    const isCitySelected = (city) => {
        const schedules = selectedServices[city] || [];
        const selectedCount = schedules.filter(schedule => schedule.isSelected).length;
        
        // Return an object with checked and indeterminate states
        return {
            checked: selectedCount === schedules.length,
            indeterminate: selectedCount > 0 && selectedCount < schedules.length
        };
    };

    if (!isValidArray(servicesData) || Object.keys(servicesData).length === 0) {
        return (
            <div className='services-list-container'>
                <span className='font-size-16 font-weight-bold'>No services found</span>
            </div>
        );
    }

    return (
        <div className="services-list-container">
            <span className='font-size-16 font-weight-bold'>Select Services From Origin Cities Below:</span>
            <div style={{display: 'flex', flexDirection:'row'}}>
                <ThreeStateCheckbox
                    state={selectAll}
                    onChange={handleSelectAll}
                    className="select-all-checkbox"
                />
                <span className='font-size-12 font-weight-bold'>Select All</span>
            </div>

            <Collapse className="cities-collapse" bordered={false} showArrow={false}>
                {Object.keys(selectedServices).map(city => {
                    const {checked, indeterminate} = isCitySelected(city);
                    return (
                        <Panel 
                            showArrow={false}
                            className='city-panel'
                            header={
                            <div className='city-header'>
                                <div style={{display: 'flex', flexDirection:'row'}}>
                                    <ThreeStateCheckbox
                                        state={indeterminate ? 'indeterminate' : checked}
                                        onChange={(checked) => { 
                                            handleCitySelect(city, checked);
                                        }}                                        
                                        className="select-all-checkbox"
                                    />
                                    <span className='margin-left-10 font-size-12'>{city}</span>
                                </div>
                                <DownOutlined className='collapse-arrow' />
                            </div>
                        }
                        key={city}
                    >
                        <Row gutter={[0, 0]}>
                            {selectedServices[city].map(schedule => (
                                <Col xs={24} md={12} key={schedule.id}>
                                    <div className="schedule-item">
                                        <div style={{display: 'flex', flexDirection:'row'}}>
                                            <ThreeStateCheckbox
                                                state={schedule.isSelected}
                                                onChange={(checked) => handleScheduleSelect(city, schedule.id, checked)}
                                            />
                                            <span className='margin-left-10 font-size-12'>{schedule.description}</span>  
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Panel>
                    );
                })}
            </Collapse>

            <Row className="action-buttons" gutter={16}>
                <Col>
                    <Button onClick={handleReset}>
                        Reset
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                </Col>
            </Row>
        </div>
    );
}; 