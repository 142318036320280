import React, { useState , useEffect} from 'react';
import { getStopsAddedByTripIds, getStopsRemovedByTripIds } from '../../../actions/temporary-changes.action';
import { Collapse, Row, Col } from 'antd';
import { CalendarOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import moment from 'moment';
import './stops.style.scss';
import { getDate } from '../../../utils/date.utils';
import { DisplayCalendar } from '../display-calender/display-calender.component';

export const StopPanel = ({ item, index,selectedDate,isStopsRemoved=false,isStopsAdded=false }) => {
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [expandedCities, setExpandedCities] = useState([]);

    useEffect(() => {
        if (item.trip_ids) {
            if(isStopsRemoved){         
                getStopsRemovedByTripIds(
                    item.trip_ids,
                    (data) => {
                    // Initialize showCalendar as false for each location
                    const enhancedData = Object.entries(data).reduce((acc, [city, locations]) => {
                        acc[city] = Object.entries(locations).reduce((locAcc, [loc, details]) => {
                            locAcc[loc] = { ...details, showCalendar: false };
                            return locAcc;
                        }, {});
                        return acc;
                    }, {});
                    setAdditionalInfo(enhancedData);
                },
                () => {
                    console.log("Failure");
                }
            );
            }
            if(isStopsAdded){
                getStopsAddedByTripIds(
                    item.trip_ids,
                    (data) => {
                        // Initialize showCalendar as false for each location
                        const enhancedData = Object.entries(data).reduce((acc, [city, locations]) => {
                            acc[city] = Object.entries(locations).reduce((locAcc, [loc, details]) => {
                                locAcc[loc] = { ...details, showCalendar: false };
                                return locAcc;
                            }, {});
                            return acc;
                        }, {});
                        setAdditionalInfo(enhancedData);   
                    },
                    () => {
                        console.log("Failure");
                    }
                );
            }
        }
    }, [item.trip_ids]);

    return (
        <div className="stops-removed-details" key={index}>    
            {additionalInfo && (
                <div className="stops-timeline">
                    <Row className="stops-header">
                        <Col xs={8} md={4}>City</Col>
                        <Col xs={8} md={4}>Location</Col>
                        <Col xs={8} md={4}>Dates {isStopsRemoved ? "Removed" : "Added"}</Col>
                    </Row>
                    <Collapse
                        expandIconPosition="left"
                        ghost
                        className="custom-collapse"
                        expandIcon={() => null}
                        activeKey={expandedCities}
                        onChange={(keys) => setExpandedCities(keys)}
                    >
                        {Object.entries(additionalInfo).map(([cityName, locations], cityIndex) => (
                            <Collapse.Panel 
                                key={cityName}
                                header={
                                    <Row align="middle" style={{ width: '100%' }}>
                                        <Col style={{ marginRight: '8px' }}>
                                            {expandedCities.includes(cityName) ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                                        </Col>
                                        <Col>{cityName}</Col>
                                    </Row>
                                }                                
                                className={`city-panel`}
                            >
                                {Object.entries(locations).map(([locationName,location], idx) => (
                                    <Row key={idx} className="location-item" style={{ 
                                        position: 'relative',
                                        paddingLeft: '24px'
                                    }}>
                                        <div style={{
                                            position: 'absolute',
                                            left: '12px',
                                            top: 0,
                                            bottom: 0,
                                            width: '1px',
                                            backgroundColor: '#d9d9d9'
                                        }} />
                                        <Col xs={8} md={4}></Col>
                                        <Col xs={16} md={8}>
                                            <div className="value-tag-container">
                                                <Row justify="space-between" style={{ width: '100%' }}>
                                                    <Col xs={12} md={12}>{locationName}</Col>
                                                    <Col xs={12} md={12}>
                                                        {location[isStopsRemoved ? "datesRemoved" : "datesAdded"].map(date => getDate(moment(date))).join(', ')}
                                                        <CalendarOutlined 
                                                            style={{ marginLeft: '8px', cursor: 'pointer',fontSize: '16px',fontWeight: 'bold' }}
                                                            className={additionalInfo[cityName][locationName].showCalendar ? "expanded-calendar-icon" : "collapsed-calendar-icon"}
                                                            onClick={() => {
                                                                const currentState = additionalInfo[cityName][locationName].showCalendar || false;
                                                                setAdditionalInfo({
                                                                    ...additionalInfo,
                                                                    [cityName]: {
                                                                        ...additionalInfo[cityName],
                                                                        [locationName]: {
                                                                            ...additionalInfo[cityName][locationName],
                                                                            showCalendar: !currentState
                                                                        }
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                {additionalInfo[cityName][locationName].showCalendar && (
                                                    <DisplayCalendar
                                                        selectedDate={selectedDate}
                                                        dates={location[isStopsRemoved ? "datesRemoved" : "datesAdded"]}
                                                        isRemoved={isStopsRemoved}
                                                        flexEnd={true}
                                                    />
                                                )}
                                            </div>  
                                        </Col>
                                    </Row>
                                ))}
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </div>
            )}
        </div>
    );
}; 