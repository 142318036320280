import React, { useState } from "react";
import { Row,Col, Select , Typography} from "antd";
import './render-tabs.style.scss';
import { RenderScheduleFareBlock } from "../render-fare/render-fare.component";
import { ScheduleCalendar } from "../../shared/schedule-calendar/schedule-calendar.component";


export const ScheduleEditFareExtend = (props) => {
    const {
        selectedDate,
        scheduleData,
        isEditFareOpened
    } = props;

    const [currentSelectedDates, setCurrentSelectedDates] = useState([]);
    const [openCalenderData, setOpenCalenderData] = useState({});

    return (
        <div className="extend-block-root">
            <ScheduleCalendar 
                selectedDate={selectedDate}
                onDatesChange={setCurrentSelectedDates}
                showLegend={true}
                scheduleData={scheduleData}
                loadCalendarData={isEditFareOpened}
            />
            {scheduleData.trips_created === 1 &&
                <Row gutter={[16,16]} justify='center' align="middle">
                    <Col>
                        <RenderScheduleFareBlock
                            selectedDate={selectedDate}
                            scheduleData={scheduleData}
                            selectedDates={currentSelectedDates}
                        />
                    </Col>
                </Row>
            }
        </div>
    )
}
