import React,{useState , useRef , useContext} from "react";
import { Spin,Modal,Button, message, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import   {useReactToPrint} from 'react-to-print';
import './custom-component.scss';
import * as htmlToImage from 'html-to-image';
import { DeviceContext } from './../../components/device-provider/device-provider';
import Icon from '@ant-design/icons';
import PrintIcon from './../../images/print.png';
import { isValidString } from "../../utils/utilities";
import {
    WhatsAppOutlined
} from '@ant-design/icons';

const { confirm } = Modal;
    
export const List=(props)=> {
    const dataSource = props.dataSource;
    const listItems = dataSource.map((item) =>
      // Correct! Key should be specified inside the array.
      props.renderItem(item)
    );
    return  listItems;
  }


export const WhatsAppTextShare=({buildText,phoneNumber})=>{

        const {
            isMobile
        }=useContext(DeviceContext);
    
        if(isMobile){
            return(
                <React.Fragment>
                    <Button   
                    size="large"
                    icon={<WhatsAppOutlined />}
                    type="link"
                    className="shareButton" 
                    href={`https://wa.me/+91${phoneNumber}?text=${buildText}`}
                    >
                         Share
                    </Button>
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                    <Button
                        size="large"
                        icon={<WhatsAppOutlined />}
                        type="link"
                        className="shareButton"
                        href={`https://web.whatsapp.com/send?text=${buildText}&phone=+91${phoneNumber}`}
                        target={"_blank"}
                    >
                        Share
                    </Button>
                </React.Fragment>
            )
        }
    }

export const WhatsAppShare=({renderComponent})=>{

    
const handleOnSubmit= async()=>{
    try {
        htmlToImage.toBlob(document.getElementById("shareDiv"),{style:{'background-color': 'white',padding:'10px',width:'600px'},quality:0.99,pixelRatio:1})
        .then(function (blob) {
            const file = new File([blob], 'share.png', {type: blob.type});
            var filesArray = [file];
        
            if(navigator.canShare && navigator.canShare({ files: filesArray })) {
              navigator.share({
                text: 'Ticket details',
                files: filesArray,
                title: 'Ticket details',
              });
            }else{
                message.error("Share feature not supported by browser!")
            }
        });

      } catch (err) {
        message.error("Some thing went wrong!")
        console.error("Share failed:", err.message);
      }
}
    const {
        isMobile
    }=useContext(DeviceContext);

    if(isMobile){
        return(
            <React.Fragment>
                <Button type="link" onClick={handleOnSubmit} className="shareButton" href="https://wa.me/+919160647553?text=https://stackoverflow.com/questions/46295905/react-native-share-image">
                     Share
                </Button>
                <div className="moveDivTOhidden">
                    <div id="shareDiv" className="shareDiv">
                        {renderComponent()}
                    </div>
                </div>
            </React.Fragment>
        )
    }else{
        return null
    }
}

export const showConfirm=(func,title,message,okText="Ok",onCancel=()=>{}) =>{
   confirm({
    title:title,
    content: message ,
    okText:okText,
    onOk() {
       return func();
    },
    onCancel() {
        onCancel();
    }
  });
}


export const Printer = ({printerClassName,toolTipText,showPrintIcon,renderComponent,showComponent=false,zoomValue,checkValidation=(onPrint)=>{
    onPrint();
}})=>{
    const contentRef = useRef();

    const handlePrint = useReactToPrint({
        contentRef
      });

    
    const verifyAndPrint= ()=>{
        checkValidation(handlePrint);
    }

    return(
        <div>
            {showPrintIcon?
                <div>
                    {isValidString(toolTipText)?
                            <Tooltip placement="topLeft" title={toolTipText}>
                                <Button ghost onClick={verifyAndPrint} className={`open-select-seat-btn`}>
                                    <Icon component={() => (<img src={PrintIcon} style={{width:'22px',padding:'1px'}} />)} />
                                </Button>
                            </Tooltip>
                        :
                        <Button ghost onClick={verifyAndPrint} className={`open-select-seat-btn`}>
                            <Icon component={() => (<img src={PrintIcon} style={{width:'22px',padding:'1px'}} />)} />
                        </Button>
                    }
                </div>
                :
                <Button className={`print-button ${printerClassName}`} onClick={verifyAndPrint}>Print</Button>
            }
            <div className={showComponent?"":"printer-render"}>
                <div ref={contentRef} className={showComponent?"":"printer-render"}
                style={{zoom:zoomValue}}
                >
                    {renderComponent()}
                </div>
            </div>
        </div>
     
    )
}

export const LoadingSpinner = ({color="#FFFFFF",fontSize=24}) => {
    const antIcon = <LoadingOutlined style={{ fontSize: fontSize ,color:color}} spin />;
    return (
        <Spin indicator={antIcon}  />
    );
}


export const CustomModal = ({
    disabled = false,
    exitButtonName="Exit",
    modalTitle="",
    buttonName="",buttonClassName="",onButtonClick=()=>{}, children,onOk=()=>{},okButtonName=''})=>{
    const [visible,setVisible]=useState(false);

    const showModal=()=>{
        setVisible(true);
    }
  
    const hideModal=()=>{
      setVisible(false);
    }
  
    return (
            <div>
                <Button 
                 disabled={disabled}
                 type="primary" 
                 className={buttonClassName}
                 onClick={async (e)=>{
                     let bool=await onButtonClick(e);
                     if(bool){
                         showModal();
                     }
                 }}>
                    {buttonName}
                </Button>
                <Modal
                title={modalTitle}
                visible={visible}
                onOk={async ()=>{
                    let bool=await onOk(buttonName);
                    if(bool){
                        hideModal();
                    }
                }}
                onCancel={hideModal}
                okText={okButtonName}
                cancelText={exitButtonName}
                cancelButtonProps={{className:"exit-btn"}}
                >
                    {children}
                </Modal>
            </div>
    )
}