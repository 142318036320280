import { 
Row,Col,Typography, Table, message, Button,Popover,Input,Select, Tabs, Tooltip, Modal
} from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { fetchCancelledTickets, fetchReleasedSeats, fetchShiftedPassengers, fetchTripSheet } from "../../../actions/reports.action";
import './trip-tickets.style.scss';
import {
    EllipsisOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import { SeatClickTicketPopUp } from "../../ticket-summary-components/seat-click-ticket-popup.component";
import { useHistory } from "react-router-dom";
import { selectedScheduleSeatsSelector, selectedScheduleSelector } from "../../../selector/home.selector";
import { isValid, isValidArray, checkUpdatedValueFromObjects, getCloneArray, getTravelsDetailsByKey } from "../../../utils/utilities";
import { 
    useDispatch,
    useSelector
} from 'react-redux';
import { fetchSeatLayout, setPickupPointDetailsForSelectedSchedule, setSelectedScheduleSeats } from "../../../actions/home.action";
import { updateTransactionDetails } from "../../../actions/ticket.action";
import { loadingAction } from "../../../actions/loading-actions";
import { getLocalDateAndTime } from "../../../utils/date.utils";
import useColumnSearch from './../../../ui-components/table-column-search';
import ZoomComponent from './../../custom-components/zoom-component/zoom.component';
import { useContext } from "react";
import { DeviceContext } from "../../device-provider/device-provider";
import { getPickupLocationByTripId } from "../../ticket-summary-components/seat-click-ticket.module";
import moment from 'moment';
import { OTARefund } from "./ota_refund.component";
import { NotifyButton, NotifyComponent } from "../../ticket-summary-components/notify.component";

const getConfirmedTickets=(confirmed)=>{
    let onlyConfirmedTickets = [],onlyBlockedTickets = [];
    for (let index = 0; index < confirmed.length; index++) {
        const element = confirmed[index];
        if(element.traveller_status == "Confirmed"){
            onlyConfirmedTickets.push(element);
        }else if(element.traveller_status == "Blocked"){
            onlyBlockedTickets.push(element);
        }
    }
    return {onlyConfirmedTickets,onlyBlockedTickets};
}   


const {
    Text
} = Typography;

const {
    Option,
    OptGroup
} = Select;

const renderBoardingPoints=(availableBoardings)=>{
    let availableBoardingsComponents=[];
    if(isValidArray(availableBoardings)){
        for (const key in availableBoardings) {
            if (Object.hasOwnProperty.call(availableBoardings, key)) {
                const boardingPoints = availableBoardings[key];
                let optionBoardingPoints=[];
                if(isValidArray(boardingPoints)&&boardingPoints.length>0){
                    boardingPoints.forEach(element => {
                        optionBoardingPoints.push(<Option value={element.id} data={element}>{element.location}</Option>);
                    });
                }
                availableBoardingsComponents.push(<OptGroup label={key}>{optionBoardingPoints}</OptGroup>);
            }
        }
    }
    return availableBoardingsComponents;
}

const renderDropPoints=(availableDrops)=>{

    let availableDropsComponents=[];
    if(isValidArray(availableDrops)&&availableDrops.length>0){
        availableDrops.forEach(element => {
            availableDropsComponents.push(<Option value={element.id}>{element.location}</Option>)
        });
    }
    return availableDropsComponents;
}

const renderAvailableSeats=(gender,bookedSeat,seats)=>{
    let seatAvailableOptions=[];
    seatAvailableOptions.push(<Option key={bookedSeat.id} value={bookedSeat.seat_number}>{bookedSeat.seat_number}</Option>);
    if(isValidArray(seats)&&seats.length>0){
        seats.forEach(seat => {
            if(seat.seat_number!=="Empty"&&(!isValid(seat.seat_status)||seat.seat_status === "")){
                if(gender==='F'){
                    if(seat.ladies_seat===1||seat.gents_seat===0){
                        seatAvailableOptions.push(<Option key={seat.id} value={seat.seat_number}>{seat.seat_number}</Option>);
                    }
                }else{
                    if(!(seat.ladies_seat===1)){
                        seatAvailableOptions.push(<Option key={seat.id} value={seat.seat_number}>{seat.seat_number}</Option>);
                    }
                }
            }
        });
    }
    return seatAvailableOptions;
}

const getFiltersOfReleasedTickets=(releasedSeats)=>{
    let releasedBoardings=[],releasedDrops=[],releasedAgents=[];
    let testReleasedBoardings=[],testReleasedDrops=[],testReleasedAgents=[];    
    return {releasedBoardings,releasedDrops,releasedAgents,releasedTicketsCount:releasedSeats.length};    
}

const getFiltersOfShiftedTickets=(shiftedSeats)=>{
    let shiftedBoardings=[],shiftedDrops=[],shiftedAgents=[];
    let testShiftedBoardings=[],testShiftedDrops=[],testShiftedAgents=[];
    return {shiftedBoardings,shiftedDrops,shiftedAgents,shiftedTicketsCount:shiftedSeats.length};   
}

const getFiltersOfConfirmTickets=(confirmTickets)=>{
    let confirmBoardings = [], confirmDrops = [], confirmAgents = [];
    let testconfirmBoardings = [], testconfirmDrops = [], testconfirmAgents = [];

    if(isValidArray(confirmTickets) && confirmTickets.length > 0){
        confirmTickets.forEach((ticket) => {
            // Handle boarding points
            if(isValid(ticket.location) && testconfirmBoardings.indexOf(ticket.location) === -1){
                testconfirmBoardings.push(ticket.location);
                confirmBoardings.push({
                    text: ticket.location,
                    value: ticket.location
                });
            }

            // Handle drop points
            if(isValid(ticket.drop_location) && testconfirmDrops.indexOf(ticket.drop_location) === -1){
                testconfirmDrops.push(ticket.drop_location);
                confirmDrops.push({
                    text: ticket.drop_location,
                    value: ticket.drop_location
                });
            }

            // Handle agents
            if(isValid(ticket.agent_name) && testconfirmAgents.indexOf(ticket.agent_name) === -1){
                testconfirmAgents.push(ticket.agent_name);
                confirmAgents.push({
                    text: ticket.agent_name,
                    value: ticket.agent_name
                });
            }
        });
    }

    return {
        confirmBoardings,
        confirmAgents,
        confirmDrops,
        confirmTicketsCount: confirmTickets.length
    };
}


const getFiltersOfCancelledTickets=(cancelledTickets)=>{
    let  cancelledBys =[],cancelledTravellerStatuses=[];
    let  testcancelledBys =[],testcancelledTravellerStatuses=[];

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach((ticket)=>{
            if(testcancelledBys.indexOf(ticket.cancelled_by) == -1){
                testcancelledBys.push(ticket.cancelled_by);
                cancelledBys.push({
                    text:ticket.cancelled_by,
                    value:ticket.cancelled_by
                })   
            }

            if(testcancelledTravellerStatuses.indexOf(ticket.traveller_status) == -1){
                testcancelledTravellerStatuses.push(ticket.traveller_status);
                cancelledTravellerStatuses.push({
                    text:ticket.traveller_status,
                    value:ticket.traveller_status
                })   
            }

        });
    }

    return{
        cancelledBys,cancelledTravellerStatuses,cancelTicketsCount:cancelledTickets.length
    }
}

const ShiftedSeatsSection = ({ title, data, columns, tooltipText }) => (    
    <div className="shifted-seats-section">
        <Row className="section-header" align="middle">
            <Col>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {title} ({data?.length || 0})
                </Typography.Title>
            </Col>
            <Col style={{ marginLeft: 8 }}>
                <Tooltip title={tooltipText}>
                    <InfoCircleOutlined style={{ color: '#1890ff' }} />
                </Tooltip>
            </Col>
        </Row>
        {data?.length > 0 && (
            <Table 
                className="app-table"
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 400 }}
            />
        )}
    </div>
);

export const TripTicketsComponent = (props) => {

    let {
        tripId
    } = props;

    const [confirmTickets,setConfirmTickets] = useState([]);
    const [cachedConfirmTickets,setCachedConfirmTickets] = useState([]);
    const [filters, setFilters] = useState({
        confirmBoardings: [],
        confirmDrops: [],
        confirmAgents: [],
        confirmTicketsCount: 0
    });
    const [blockedTickets,setBlockedTickets] = useState([]);
    const [vechicleInfo,setVechicleInfo] = useState({
        scheduleVechicleId:null,
        tripVechicleId:null
    });


    const [cancelledTickets,setCancelledTickets] = useState([]);
    const [notBoardedSeats , setNotBoardedSeats]=useState([]);

    const [editId,setEditId] = useState(null);
    const [viewTicket,setViewTicket] = useState({
        ticketId:null,
        agentId:null
    })

    const history = useHistory();
    const dispatch = useDispatch();

    const {
        isMobile
    }=useContext(DeviceContext);

    const moveToViewCancel=(pnr)=>{
        history.push('/view-cancel?pnr='+pnr);
    }

    const updateConfirmTicketsState=(index,value,label,pickupLocation)=>{
        confirmTickets[index][label] = value;
        if(label==="pickup_location_id"){
            confirmTickets[index]['location'] = pickupLocation.location;
        }
        setConfirmTickets([...confirmTickets]);
    }

    const selectedSchedule=useSelector(selectedScheduleSelector);
    const seats = useSelector(selectedScheduleSeatsSelector);

    useEffect(() => {
        const {
            confirmBoardings,
            confirmDrops,
            confirmAgents,
            confirmTicketsCount
        } = getFiltersOfConfirmTickets(confirmTickets);
        
        setFilters(prevFilters => ({
            ...prevFilters,
            confirmBoardings,
            confirmDrops,
            confirmAgents,
            confirmTicketsCount
        }));
    }, [confirmTickets]);
    

    useEffect(()=>{
        let{
            cancelledBys,
            cancelledTravellerStatuses,
            cancelTicketsCount
        } = getFiltersOfCancelledTickets(cancelledTickets);
        setFilters(prevFilters => ({
            ...prevFilters,
            cancelledBys,
            cancelledTravellerStatuses,
            cancelTicketsCount
        }));
    },[cancelledTickets]);

    useEffect(()=>{
        let{
            cancelledBys,
            cancelledTravellerStatuses,
            cancelTicketsCount
        } = getFiltersOfCancelledTickets(notBoardedSeats);
        setFilters(prevFilters => ({
            ...prevFilters,
            notBoardedCancelledBys: cancelledBys,
            notBoardedCancelledTravellerStatuses: cancelledTravellerStatuses,
            notBoardedSeatsTicketsCount: cancelTicketsCount
        }));
    },[notBoardedSeats]);

    useEffect(()=>{
            feacthSeats();
    },[]);

    const feacthSeats=()=>{
        if(isValidArray(selectedSchedule)){
            dispatch(fetchSeatLayout(selectedSchedule.trip_route_id,(seatsData)=>{
                let fetchedSeats = seatsData.seats;
                setSeats(fetchedSeats);
            },(errmsg)=>{
                message.error(errmsg);
                setLoading(false);
            }));
        }
    }

    const setSeats=(data)=>{
        dispatch(setSelectedScheduleSeats(data));
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onUpdateSuccess=()=>{
        setEditId(null);
        setLoading(false);
    }

    const onUpdateFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    let pickupTimings= {} , allPickUpTimings={};

    if(isValidArray(selectedSchedule)){
        // Note : We need show ticket origin and search origin
        pickupTimings = selectedSchedule.pickupTimings;
        allPickUpTimings = selectedSchedule.pickupTimingsByTripCity;
    }

    const setPickupTimingsByTripCity =(info)=>{
        dispatch(setPickupPointDetailsForSelectedSchedule(info));
      }

      useEffect(()=>{
        if (isValidArray(selectedSchedule)&&!isValid(selectedSchedule.pickupTimingsByTripCity)) {
            getPickupLocationByTripId(selectedSchedule.schedule_id,selectedSchedule.start_date,selectedSchedule.trip_id, setPickupTimingsByTripCity)
        }
      },[selectedSchedule])


    const confirmTicketsColumns=[
        {
            title:'Seat#',
            dataIndex:'seat_number',
            key:'seat_number',
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    return (
                        <Select value={row.seat_number} className="seat-number-select"
                        onChange={(value)=>{
                            updateConfirmTicketsState(index,value,'seat_number');
                        }}
                        >
                        {renderAvailableSeats(row.gender , cachedConfirmTickets[index] , seats)}
                        </Select>)                
                    }
                    else{
                        return text;
                }
            }
        },
        {
            title:'Name',
            dataIndex:'full_name',
            key:'full_name',
            width:150,
            ...useColumnSearch(
                {dataIndexExt:"full_name"}
            ),
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    return(
                        <Input value = {text} style={{width:'130px'}}  onChange={(e)=>{
                            updateConfirmTicketsState(index,e.target.value,'full_name');
                        }}/>
                    )
                }else{
                    return(
                        <Popover 
                        trigger={["focus"]}
                        content={
                        <div>
                            <Row gutter={[16,16]} justify="center"  align="middle" style={{width:'150px'}}>
                                <Col>
                                    <Button className="outline-button" onClick={(e)=>{
                                        setViewTicket({
                                            ticketId:row.ticket_id,
                                            agentId:row.agent_id
                                        });
                                    }}>View</Button>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} justify="center"  align="middle">
                                <Col>
                                    <Button 
                                    className="outline-button"  
                                    onClick={()=>{
                                        setEditId(row.travellerId);
                                    }}>Edit</Button>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} justify="center"  align="middle">
                                <Col>
                                    <Button 
                                    className="outline-button"  
                                    onClick={()=>{
                                        moveToViewCancel(row.pnr);
                                    }}>Cancel</Button>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} justify="center"  align="middle">
                                <Col>
                                    <Button 
                                    className="outline-button"  
                                    onClick={()=>{
                                        moveToViewCancel(row.pnr);
                                    }}>Not boarded</Button>
                                </Col>
                            </Row>
                        </div>}
                    >
                        <Row justify="center" align="middle">
                            <Col span={20}>{text}</Col>
                            <Col span={4}>
                            <Button  
                                    type="text" 
                                    className="outline-button three-dots-button"  
                                    icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                                />
                            </Col>
                        </Row>
                    </Popover>
                    )
                }
              }
        },
        {
            title:'Phone',
            dataIndex:'phone_number',
            key:'phone_number',
            ...useColumnSearch(
                {dataIndexExt:"phone_number"}
            ),
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    return <Input value = {text} style={{width:'130px'}}  onChange={(e)=>{
                        updateConfirmTicketsState(index,e.target.value,'phone_number');
                    }}/>
                }else{
                    return text;
                }
            }
        },
        {
            title:'Boarding',
            dataIndex:'location',
            key:'location',
            filters: filters.confirmBoardings,
            onFilter: (value, record) => record.location === value,
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    let newPickupTimings={};
                    //  Note : We need show ticket origin and search origin
                    if(isValidArray(selectedSchedule)&&isValid(selectedSchedule.origin)){
                        newPickupTimings[selectedSchedule.origin] = pickupTimings;
                    }
                    if(isValidArray(allPickUpTimings)&&isValid(allPickUpTimings[row.pick_up_city])){
                        newPickupTimings[row.pick_up_city] = allPickUpTimings[row.pick_up_city];
                    }
                    return (
                        <Select className="boarding-point-select" value={row.pickup_location_id}
                        onChange={(value,optionData)=>{
                            let{
                                data
                            } = optionData;
                            updateConfirmTicketsState(index,value,"pickup_location_id",data);
                       }}
                       >
                           {renderBoardingPoints(newPickupTimings)}
                       </Select>
                    )
                }else{
                    return text;
                }
            }
        },
        {
            title:'Drop',
            dataIndex:'drop_location',
            key:'drop_location',
            filters: filters.confirmDrops,
            onFilter: (value, record) => record.drop_location === value,
            render:(text)=>{
                    return text;
                // Not supported drop point change
                // if(row.travellerId === editId){
                //     return (
                //         <Select className="seat-gender-select" value={row.drop_location_id}
                //         onChange={(value)=>{
                //             updateConfirmTicketsState(index,value,"drop_location_id")
                //        }}
                //        >
                //            {renderDropPoints(dropTimings)}
                //        </Select>
                //     )
                // }else{
                //     return text;
                // }
            }
        },
        {
            title:'Agent',
            dataIndex:'agent_name',
            key:'agent_name',
            filters: filters.confirmAgents,
            onFilter: (value, record) => record.agent_name === value,
        },
        {
            title:'Booking time',
            dataIndex:'booking_time',
            render:(text,row)=>{
                return getLocalDateAndTime(text);
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.ticket_id - b.ticket_id,
            sortDirections :['ascend', 'descend']
        }
    ];

    const cancelledTicketsColumns=[
        {
            title:'Seat#',
            dataIndex:'seat_number',
            key:'seat_number'
        },
        {
            title:'Name',
            dataIndex:'full_name',
            key:'full_name',
            ...useColumnSearch(
                {dataIndexExt:"full_name"}
            ),
            render:(text,row)=>{
                    return(
                        <Popover 
                        content={
                        <div>
                            <Row gutter={[16,16]} justify="center"  align="middle" style={{width:'150px'}}>
                                <Col>
                                    <Button className="outline-button" onClick={()=>{
                                        setViewTicket({
                                            ticketId:row.ticket_id,
                                            agentId:row.agent_id
                                        });
                                    }}>View</Button>
                                </Col>
                                <Col>
                                    <OTARefund 
                                       travellerStatus={row.traveller_status}
                                       busCancellation={row.bus_cancellation}
                                       agentPrvilleage={row.agent_privilege}
                                       automaticRefund={row.automatic_refund}
                                       traveller={row}
                                       reload={reload}
                                    />
                                </Col>
                            </Row>
                        </div>}
                    >
                        <Row justify="center" align="middle" >
                            <Col span={20}>{text}</Col>
                            <Col span={4}>
                                <Button  
                                    type="text" 
                                    className="outline-button three-dots-button"  
                                    icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                                />
                            </Col>
                        </Row>
                    </Popover>
                    )
                }
        },
        {
            title:'Phone',
            dataIndex:'phone_number',
            key:'phone_number',
            ...useColumnSearch(
                {dataIndexExt:"phone_number"}
            ),
        },
        {
            title:'Cancel Time',
            dataIndex:'cancellation_time',
            key:'cancellation_time',
            render : (text) => {
                if(isValid(text)){
                    return getLocalDateAndTime(text)
                }else{
                    return ''
                }
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.cancellation_time).unix() - moment(b.cancellation_time).unix(),
            sortDirections :['ascend', 'descend']
        },
        {
            title:'Cancelled By',
            dataIndex:'cancelled_by',
            key:'cancelled_by',
            filters:filters.cancelledBys,
            onFilter: (value, record) => record.cancelled_by.indexOf(value) === 0,
        },
        {
            title:'Status',
            dataIndex:'traveller_status',
            key:'traveller_status',
            filters:filters.cancelledTravellerStatuses,
            onFilter: (value, record) => record.traveller_status.indexOf(value) === 0,
        },
        {
            title:'Refund',
            dataIndex: 'automatic_refund',
            key: 'automatic_refund',
            render: (text) => {
                if(text) {
                    return "Success"
                }
                else {
                    return null
                }
            }
        }
    ];

    const notBoardedColumns=[
        {
            title:'Seat#',
            dataIndex:'seat_number',
            key:'seat_number'
        },
        {
            title:'Name',
            dataIndex:'full_name',
            key:'full_name',
            ...useColumnSearch(
                {dataIndexExt:"full_name"}
            ),
            render:(text,row)=>{
                    return(
                        <Popover 
                        content={
                        <div>
                            <Row gutter={[16,16]} justify="center"  align="middle" style={{width:'150px'}}>
                                <Col>
                                 <Button className="outline-button" onClick={()=>{
                                    setViewTicket({
                                        ticketId:row.ticket_id,
                                        agentId:row.agent_id
                                    });
                                }}>View</Button>
                                </Col>
                            </Row>
                        </div>}
                    >
                        <Row justify="center" align="middle" >
                            <Col span={20}>{text}</Col>
                            <Col span={4}>
                                <Button  
                                    type="text" 
                                    className="outline-button three-dots-button"  
                                    icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                                />
                            </Col>
                        </Row>
                    </Popover>
                    )
                }
        },
        {
            title:'Phone',
            dataIndex:'phone_number',
            key:'phone_number',
            ...useColumnSearch(
                {dataIndexExt:"phone_number"}
            ),
        },
        {
            title:'Cancel Time',
            dataIndex:'cancellation_time',
            key:'cancellation_time',
            render : (text) => {
                if(isValid(text)){
                    return getLocalDateAndTime(text)
                }else{
                    return ''
                }
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.cancellation_time).unix() - moment(b.cancellation_time).unix(),
            sortDirections :['ascend', 'descend']
        },
        {
            title:'Cancelled By',
            dataIndex:'cancelled_by',
            key:'cancelled_by',
            filters:filters.notBoardedCancelledBys,
            onFilter: (value, record) => record.cancelled_by.indexOf(value) === 0,
        },
        {
            title:'Status',
            dataIndex:'traveller_status',
            key:'traveller_status',
            filters:filters.notBoardedCancelledTravellerStatuses,
            onFilter: (value, record) => record.traveller_status.indexOf(value) === 0,
        }
    ];

    if(isValid(editId)){
        confirmTicketsColumns.push({
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            width:250,
            render:(text,row)=>{
                if(editId === row.travellerId){
                    return(
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button 
                                className="cancel-button"
                                onClick={()=>{
                                    setEditId(null);
                                    setConfirmTickets(cachedConfirmTickets);
                                }}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button 
                                    onClick={()=>{
                                        let ticketObject = {
                                            ticket:{
                                                id:row.ticket_id,
                                                phone_number:row.phone_number,
                                                trip_id:row.trip_id,
                                                trip_route_id:row.trip_route_id,
                                                email_id:row.email_id,
                                                remarks:row.remarks
                                            },
                                            traveller:[
                                                {
                                                    seat_number:row.seat_number,
                                                    traveller_status:row.traveller_status,
                                                    id:row.travellerId,
                                                    full_name:row.full_name,
                                                    pickup_location:{
                                                        id:row.pickup_location_id
                                                    },
                                                    gender:row.gender
                                                }
                                            ],
                                            trip:{
                                                id:row.trip_id
                                            }
                                        }
                                        let rowObj, tktObj;
                                        let keyList = ['ticket_id','phone_number','email_id','remarks','seat_number','traveller_status','travellerId','full_name','pickup_location_id','gender'];
                                        cachedConfirmTickets.map((tkts) => {
                                            if (row.travellerId == tkts.travellerId) {
                                                rowObj = copyJsonKeyVal(keyList, {...row});
                                                tktObj = copyJsonKeyVal(keyList, {...tkts});
                                            }
                                        })
                                        if(checkUpdatedValueFromObjects(rowObj,tktObj)) {
                                            setLoading(true);
                                            updateTransactionDetails(ticketObject,onUpdateSuccess,onUpdateFailure);
                                        }
                                        else {
                                            message.warn("No Value to Update");
                                            onUpdateSuccess();
                                        }
                                    }}
                                >Save</Button>
                            </Col>
                        </Row>)
                }else{
                    return null
                }
            }
        })
    }

    const copyJsonKeyVal = (keyList,obj) => {
        let returnObject = {};
        keyList.map((key) => {
            returnObject[key] = obj[key];
        })
        return returnObject
    }

    const onSuccess = (data) => {
        let {onlyBlockedTickets,onlyConfirmedTickets} = getConfirmedTickets(data.tripSheet);
        setConfirmTickets(onlyConfirmedTickets);
        setBlockedTickets(onlyBlockedTickets);
        let copiedObject = getCloneArray(onlyConfirmedTickets);
        setCachedConfirmTickets(copiedObject);
        setNotBoardedSeats(data.notBoardedSeats);
        if(isValidArray(data.vechicleInfo)){
            setVechicleInfo({
                scheduleVechicleId:data.vechicleInfo.schedule_vechicle_id,
                tripVechicleId:data.vechicleInfo.trip_vechicle_id
            });
        }
    }

    const onFailure = (msg) => {
        message.error(msg);
    }

    const fetchReport = async(id)=>{
        return await fetchTripSheet(id,onSuccess,onFailure);
    }

    const onCancelledSuccess=(data)=>{
        setCancelledTickets(data);
    }

    const onReleasedSuccess=(data)=>{
        setReleasedSeats(data);
    }

    const onReleasedFailure=(msg)=>{
        message.error(msg);
    }       

    const [shiftedFrom, setShiftedFrom] = useState([]);
    const [shiftedTo, setShiftedTo] = useState([]);

    const onShiftedSuccess = (data) => {            
        setShiftedFrom(data.shiftedFrom);
        setShiftedTo(data.shiftedTo);
    }   

    const onShiftedFailure=(msg)=>{
        message.error(msg);
    }   

    const onCancelledFailure=(msg)=>{
        message.error(msg);
    }

    const reload=()=>{
        fetchReport(tripId);
    }

    useEffect(()=>{
        reload();
    },[tripId]);
    
    const [notifyModalVisible, setNotifyModalVisible] = useState(false);
    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const [notifyBtnClickedRow,setNotifyBtnClickedRow] = useState(null);


    confirmTicketsColumns.push({
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, row) => {
            return (
                <>
                    <NotifyButton 
                        onClick={() => {
                            setNotifyBtnClickedRow(row);
                            setSelectedTicketId(row.ticket_id);
                            setNotifyModalVisible(true);
                        }}
                        showDownArrow={false}
                    />
                </>
            );
        }
    });

    var blockedTicketColumns=confirmTicketsColumns;

    const { TabPane } = Tabs;

    const renderHeader = (title, count, showing) => (
        <Row className="tab-content-header">
            <Col>
                <Typography.Title level={5}>
                    {title} ({count}) Showing({showing || 0})
                </Typography.Title>
            </Col>
        </Row>
    );

    const [releasedSeats, setReleasedSeats] = useState([]);

    useEffect(()=>{
        let{
            releasedBys,
            releasedTravellerStatuses,
            releasedTicketsCount
        } = getFiltersOfReleasedTickets(releasedSeats);
        setFilters(prevFilters => ({
            ...prevFilters,
            releasedBys,
            releasedTravellerStatuses,
            releasedTicketsCount
        }));
    },[releasedSeats]);

    const releasedSeatsColumns = [
        {
            title: 'Seat#',
            dataIndex: 'seat_number',
            key: 'seat_number'
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name'        
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            ...useColumnSearch({dataIndexExt: "phone_number"})
        },
        {
            title: 'Release Time',
            dataIndex: 'release_time',
            key: 'release_time',
            render: (text) => isValid(text) ? getLocalDateAndTime(text) : '',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.release_time).unix() - moment(b.release_time).unix(),
            sortDirections: ['ascend', 'descend']
        }
    ];

    const shiftedCommonColumns = [
        {
            title: 'Seat#',
            dataIndex: 'seat_number',
            key: 'seat_number'
        },
        {
            title: 'PNR',
            dataIndex: 'pnr',
            key: 'pnr'
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name'
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by'
        }
    ];

    const shiftedOutColumns = [
        ...shiftedCommonColumns,
        {
            title: 'Rescheduled PNR',
            dataIndex: 'original_pnr',
            key: 'original_pnr',
            render: (text) => (
                <a href={`/view-cancel?pnr=${text}`} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            )
        }
    ];

    const shiftedInColumns = [
        ...shiftedCommonColumns,
        {
            title: 'Original PNR',
            dataIndex: 'rescheduled_pnr',
            key: 'rescheduled_pnr',
            render: (text) => (
                <a href={`/view-cancel?pnr=${text}`} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            )
        }
    ];

    let showBlockedSeats  = getTravelsDetailsByKey('blocked_tickets_in_trip_sheet');



    return (
        <ZoomComponent isZoomEnabled={isMobile}>
            <div className="trip-tickets-root tab-block">
                <Tabs defaultActiveKey="1" centered onChange={(key)=>{
                    if(key == '3'){
                        fetchCancelledTickets(tripId,onCancelledSuccess,onCancelledFailure);
                    }else if(key == '5'){
                        fetchReleasedSeats(tripId,onReleasedSuccess,onReleasedFailure);
                    }else if(key == '6'){
                        fetchShiftedPassengers(tripId,onShiftedSuccess,onShiftedFailure);
                    }
                }}>     
                    <TabPane 
                        tab="Confirmed Seats"
                        key="1"
                    >
                        {renderHeader("Confirmed Seats", confirmTickets.length, filters.confirmTicketsCount)}
                        <Table 
                            columns={confirmTicketsColumns}
                            dataSource={confirmTickets}
                            className="app-table"
                            pagination={false}
                            onChange={(pagination,tableFilters,sorter,extra)=>{
                                filters.confirmTicketsCount = extra.currentDataSource.length;
                                setFilters({...filters});
                            }}
                            scroll={{ x: 400 }}
                        />
                    </TabPane>

                    {showBlockedSeats && (
                        <TabPane 
                            tab="Blocked Seats"
                            key="2"
                        >
                            {renderHeader("Blocked Seats", blockedTickets.length, filters.releasedTicketsCount)}
                            {blockedTickets.length > 0 && (
                                <Table 
                                    columns={blockedTicketColumns}
                                    dataSource={blockedTickets}
                                    className="app-table"
                                    pagination={false}
                                    onChange={(pagination,tableFilters,sorter,extra)=>{
                                        filters.releasedTicketsCount = extra.currentDataSource.length;
                                        setFilters({...filters});
                                    }}
                                    scroll={{ x: 400 }}
                                />
                            )}
                        </TabPane>
                    )}

                    <TabPane 
                        tab="Cancelled"
                        key="3"
                    >
                        {renderHeader("Cancelled", cancelledTickets.length, filters.cancelTicketsCount)}
                        {cancelledTickets.length > 0 && (
                            <Table 
                                columns={cancelledTicketsColumns}
                                dataSource={cancelledTickets}
                                className="app-table"
                                pagination={false}
                                onChange={(pagination,tableFilters,sorter,extra)=>{
                                    filters.cancelTicketsCount = extra.currentDataSource.length;
                                    setFilters({...filters});
                                }}
                                scroll={{ x: 400 }}
                            />
                        )}
                    </TabPane>

                    <TabPane 
                        tab="Not Boarded Seats"
                        key="4"
                    >
                        {renderHeader("Not Boarded Seats", notBoardedSeats.length, filters.notBoardedSeatsTicketsCount)}
                        {notBoardedSeats.length > 0 && (
                            <Table 
                                columns={notBoardedColumns}
                                dataSource={notBoardedSeats}
                                className="app-table"
                                pagination={false}
                                onChange={(pagination,tableFilters,sorter,extra)=>{
                                    filters.notBoardedSeatsTicketsCount = extra.currentDataSource.length;
                                    setFilters({...filters});
                                }}
                                scroll={{ x: 400 }}
                            />
                        )}
                    </TabPane>

                    <TabPane 
                        tab="Released Seats"
                        key="5"
                    >
                        {renderHeader("Released Seats", releasedSeats.length, filters.releasedTicketsCount)}
                        {releasedSeats.length > 0 && (
                            <Table 
                                columns={releasedSeatsColumns}
                                dataSource={releasedSeats}
                                className="app-table"
                                pagination={false}
                                onChange={(pagination,tableFilters,sorter,extra)=>{
                                    filters.releasedTicketsCount = extra.currentDataSource.length;
                                    setFilters({...filters});
                                }}
                                scroll={{ x: 400 }}
                            />
                        )}
                    </TabPane>

                    <TabPane 
                        tab="Shifted Seats"
                        key="6"
                    >
                        <div className="shifted-seats-container" style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                            <ShiftedSeatsSection 
                                title="Shifted Out"
                                data={shiftedFrom}
                                columns={shiftedOutColumns}
                                tooltipText="Passengers who were shifted from this bus to another bus"
                            />
                            <ShiftedSeatsSection 
                                title="Shifted In"
                                data={shiftedTo}
                                columns={shiftedInColumns}
                                tooltipText="Passengers who were shifted to this bus from another bus"
                            />
                        </div>
                    </TabPane>
                </Tabs>
                <SeatClickTicketPopUp 
                        ticketId = {viewTicket.ticketId}
                        agentId = {viewTicket.agentId}
                        children={null}
                        autoLoad={true} 
                />
                <Modal
                    title="Notify"
                    visible={notifyModalVisible}
                    onCancel={() => setNotifyModalVisible(false)}
                    footer={null}
                    destroyOnClose
                >
                    {notifyBtnClickedRow && (
                        <>
                            <Text>
                                { `Ticket info for PNR `} 
                                <Text strong>
                                    "{notifyBtnClickedRow.pnr}"
                                </Text>
                                { ` is going to be sent.`}

                            </Text>
             
                            <NotifyComponent    
                                ticketId={selectedTicketId}
                                selectedNotifyTickets={selectedTicketId ? [selectedTicketId] : []}
                            />
                        </>
                    )}
                </Modal>
            </div>
        </ZoomComponent>
    );
}