import React from 'react';
import { Tooltip } from 'antd';
import { getLocalDateAndTime } from '../../utils/date.utils';
import { isValid, checkActionAllowed, isValidArrayWithValues } from '../../utils/utilities';
import { TOOLTIP_HIDE_BOOK_INFO_OTHER_USER, getSameUser } from '../../utils/constant';

const getPassenger=(passenger,index)=>{
    let displayPassengerName = passenger.full_name;
    if (!isValid(global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING'])||global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING']==0){
        displayPassengerName = displayPassengerName + ","+passenger.gender;
    }
    displayPassengerName = displayPassengerName + ","+passenger.seat_number;

    let header = 'Booking';
    if(passenger.traveller_status == "Blocked"){
        header = 'Blocked';
    }
    
    return(
        <div className='passengerDetailsBlock'>
            <span>Passenger {index+1}</span>
            <div>{passenger.origin} to {passenger.destination}</div>
            <div>{displayPassengerName}</div>
            <div>Pickup({passenger.pickup_location_name})</div>
            <div>Drop({passenger.drop_location_name})</div>
            <div>{header} By({passenger.user_name})</div>
            <div>{header} Time({getLocalDateAndTime(passenger.booking_time)})</div>
            <div>Amount Paid({passenger.amount_paid})</div>
        </div>
    )
}

const getPassengerList=(passengers)=>{

    let passengersList=[];
    let hideToolTip = checkActionAllowed(TOOLTIP_HIDE_BOOK_INFO_OTHER_USER);

        passengers.forEach((element,index) => {
            if (hideToolTip){
                if (getSameUser(element.agent_id)){
                    passengersList.push(getPassenger(element,index));
                }
            }
            else {
                passengersList.push(getPassenger(element,index));
            }

        });
    return passengersList;
}

export const  ShowMultiplePassengers =(props)=> {

    const{
        passengers
    } = props;

    if(passengers.length == 0){
        return null
    }
    
    let toolTipInfo = <div className={'passengerCountBlock'}>
                        <div className='passengerCount'>{passengers.length}</div>
                    </div>;

    let passengersList = getPassengerList(passengers);

    if (isValidArrayWithValues(passengersList)){
        return (
            <Tooltip title={passengersList} trigger={"hover"}>
                {toolTipInfo}
            </Tooltip>
        );
    }
    else {
        return toolTipInfo;
    }
}