import React, { useContext } from 'react';
import {Button, Col, Row} from "antd";
import './scrollable-calendar.component.scss';
import {
    LeftCircleOutlined,
    RightCircleOutlined
  } from '@ant-design/icons';
import { DeviceContext } from '../device-provider/device-provider';
import { isMomentDate, getIstMomentDate, getCurrentDateInIST, incrementDays, setDateFormat } from '../../utils/date.utils';
import { isValid } from '../../utils/utilities';

export const ScrollableCalendar = props => {
    const {
        changeScrollableDate,
        selectedDate,
    } = {...props};

    let date = selectedDate;

    let {
        isMobile
    } = useContext(DeviceContext);

    /* This if block is added to prevent the page not loading */
    if (!isMomentDate(date)) {
        if (isValid(date)) {
            date = getIstMomentDate(date);
        }
        else {
            date = getCurrentDateInIST();
        }
    }

    let date1 = incrementDays(date,-2), date2 = incrementDays(date,-1),
        date3 = incrementDays(date,1), date4 = incrementDays(date,2);


    const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];


    const decrementDate=()=>{
        changeScrollableDate(date3);
    }

    const incrementDate=()=>{
        changeScrollableDate(date2);
    }

    return(
        <Row gutter={16} justify='center' align='middle'> 
            <Col span={24}>
                <div className="scrollable-calendar">
                    <Button  
                        className="outline-button scroll-calender-left-button"  
                        icon={<LeftCircleOutlined  className='scroll-icon-left'/>} 
                        onClick={incrementDate}
                        type="text"
                    />
                    {!isMobile&&
                        <div className='scrollable-date scrollable-date-first' onClick={()=>changeScrollableDate(date1)}>
                            <span className='scroll-date'> {setDateFormat(date1, 'ddd, D MMM')} </span>
                        </div>
                    }
                    <div className='scrollable-date scrollable-date-mobile-first' onClick={()=>changeScrollableDate(date2)}>
                        <span className='scroll-date'> {`${setDateFormat(date2, 'ddd, D')} `}
                        {isMobile?<br />:null}
                        {setDateFormat(date2, 'MMM')}
                        </span>
                    </div>
                    <div className='scrollable-date active'>
                        <span className='scroll-date'> {`${setDateFormat(date, 'ddd, D')} `}
                        {isMobile?<br />:null}                         
                        {setDateFormat(date, 'MMM')}
                        </span>
                    </div>
                    <div className='scrollable-date scrollable-date-mobile-last' onClick={()=>changeScrollableDate(date3)}>
                        <span className='scroll-date'> {setDateFormat(date3, 'ddd, D')}
                        {isMobile?<br />:null}                        
                        {setDateFormat(date3, 'MMM')}
                        </span>
                    </div>
                    {!isMobile&&
                        <div className='scrollable-date scrollable-date-last' onClick={()=>changeScrollableDate(date4)}>
                            <span className='scroll-date'> {setDateFormat(date4, 'ddd, D MMM')} </span>
                        </div>
                    }
                    <Button  
                        className="outline-button scroll-calender-right-button"  
                        icon={<RightCircleOutlined className='scroll-icon-left'/>} 
                        onClick={decrementDate}
                        type='text'
                    />
                </div>
            </Col>
        </Row>
    );
};

ScrollableCalendar.defaultProps = {

};

ScrollableCalendar.propTypes = {

};
