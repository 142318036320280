import { message } from 'antd';
import { isValidArray } from '../../utils/utilities';
import { BLOCK_BY_ADMIN_SEATS_URL } from './../../utils/api-url';

export const handleBlockByAdminSeats=(tripId,tripRouteId,selectedSeats,isPermanent,onSuccess=()=>{},onFailure=()=>{})=>{
    let {
        seatNumbers
    } = getReleaseSeatNumbers(selectedSeats);
    fetch(BLOCK_BY_ADMIN_SEATS_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tripId: tripId,
        seatNumbers: seatNumbers,
        blockedBy:global.userName,
        status:"Blocked",
        isPermanent:isPermanent,
        updatedBy:global.userName,
        tripRouteId:tripRouteId
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "SUCCESS") {
          message.success(data.Message);
        } else {
          message.error(`Block failed because of ${data.Message}`);
        }
      })
      .then((data) => {
        onSuccess();
      })
      .catch((reason) => {
        message.error("Block seats failed because of", reason);
        onFailure()
      }); 
}

const getReleaseSeatNumbers=(seats)=>{
    let releaseSeatNumbers=[];
    if(isValidArray(seats)&&seats.length>0){
        seats.forEach(element => {
            releaseSeatNumbers.push(element.seatNumber);
        });
    }
    return {seatNumbers:releaseSeatNumbers};
}