import React, { useContext, useState } from "react";
import { Button, Table,Modal } from 'antd';
import { getLocalDateAndTime } from "../../utils/date.utils";
import { isValid } from "../../utils/utilities";
import { DeviceContext } from '../device-provider/device-provider';
import ZoomComponent from "../custom-components/zoom-component/zoom.component";


export const TicketLogsComponent = (props) => {

    const{
        logs
    }=props;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const {
        ticketLogs,
        travellerLogs
    } = logs;

    const handleCancel=()=>{
        setIsModalVisible(false);
    }

    const handleOk=()=>{
        setIsModalVisible(false);
    }

    const {
        isMobile
    }=useContext(DeviceContext);

    let ticketLogsColumns=[
        {
            title: 'Ticket Satus',
            dataIndex: 'ticket_status',
            key: 'ticket_status'
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number'
        }
    ];

    if(!isValid(global.travelsAppConfig['HIDE_EMAIL_FROM_BOOKING'])||global.travelsAppConfig['HIDE_EMAIL_FROM_BOOKING']==0){
        ticketLogsColumns.push({
            title: 'Email Id',
            dataIndex: 'email_id',
            key: 'email_id'
        });
    }
    ticketLogsColumns.push({
        title:'Operation Time',
        dataIndex: 'operation_time',
        key: 'operation_time',
        render:(text)=>{
            if(isValid(text)){
                return `${getLocalDateAndTime(text)}`;
            }else{
                return text
            }
        }
    });

    let travellerLogsColumns = [
        {
            title: 'Seat #',
            dataIndex: 'seat_number',
            key: 'seat_number'
        },
        {
            title: 'Name ',
            dataIndex: 'full_name',
            key: 'full_name'
        },
        {
            title: 'Traveller Status',
            dataIndex: 'traveller_status',
            key: 'traveller_status'
        }
    ];

    if(!isValid(global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING'])||global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING']==0){
        travellerLogsColumns.push({
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender'
        });
    }

    travellerLogsColumns.push({
        title:'Operation Time',
        dataIndex: 'operation_time',
        key: 'operation_time',
        render:(text)=>{
            if(isValid(text)){
                return `${getLocalDateAndTime(text)}`;
            }else{
                return text
            }
        }
    })

    return(
        <ZoomComponent isZoomEnabled={isMobile}>
            <div className='tab-block'>
                <Modal
                        title={null}
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        width="90vw"
                        className=""
                    >
                    <div>Ticket Logs</div>
                    <Table 
                        dataSource={ticketLogs} 
                        columns={ticketLogsColumns}
                        pagination={{defaultPageSize:5}}
                        className="ticketLogs"
                    
                    />
                    <div>Traveller Logs</div>
                    <Table 
                        className="travellerLogs"
                        dataSource={travellerLogs} 
                        columns={travellerLogsColumns}
                        pagination={{defaultPageSize:5}}
                    />
                </Modal>
                <Button onClick={()=>{
                    setIsModalVisible(true);
                }}>Show Logs</Button>
            </div>
        </ZoomComponent>
    )
}