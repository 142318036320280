
import { message } from 'antd';
import { isValid } from '../../utils/utilities';
import { SEND_OTA_INTEGRATION_URL } from './../../utils/api-url';
import { postCall } from "./../../utils/network.util";

export const sendOTAIntegrationMail=(toAddress,ccAddress,ota,operatorCode,onSuccess=()=>{},onFailure=()=>{})=>{
    if(isValid(toAddress)){
        var updated = postCall(SEND_OTA_INTEGRATION_URL, {
            toAddress,ccAddress,ota,operatorCode
        });
      updated.then((data) => {
        if(data.status=="SUCCESS"&&data.payload.Status=="SUCCESS"){
            message.success("Email sent successfully");
            onSuccess();
        }else{
            message.error("Falied to sent email.");
            onFailure();
        }
      })
      .catch((reason)=> {
        message.error("Falied to sent email.");
        onFailure();
      })
    }else{
        message.error("To mail is manditory.")
        onFailure();
    }
  }