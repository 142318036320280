import React, { useEffect, useState ,useContext } from 'react';
import { Form, Input, Row , Col , Select  , Typography , Checkbox, message} from 'antd';
import { passengerInfoSelector } from './../../selector/passenger-form.selector';
import { useDispatch, useSelector } from 'react-redux';
import { isValidArray,getValuesOfJSonObject,isValid, isValidArrayWithValues } from '../../utils/utilities';
import { getTimeStampInReadableFormat , getAPIDateFormat , getFormatedDate , incrementDays , getUIDateFormat} from './../../utils/date.utils';
import {getDetailsToBookTicket,getTicketFare , PassengerSeatsTable  , getDetailsToCancelTicket , getConfirmedPassengersCount} from './change-bus.module';
import './change-bus.style.scss';
import { changeTicket , onPassengerFormChange} from './../../actions/home.action';
import { CustomModal } from './../custom-components/custom-component';
import { ACTION_BOOK_TICKET_ALLOWED } from './../../utils/constant';
import { checkActionAllowed } from './../../utils/utilities';
import { resetPassengerForm } from './../../actions/ticket.action';
import { DeviceContext } from './../../components/device-provider/device-provider';
import ArrowDownIcon from './../../images/arrow-down.png';
import Icon from '@ant-design/icons';


const renderAmountdiv=(fareDifference)=>{
    return (
        <div>
             {fareDifference>=0?
                 <Row justify='center'>
                     <Col>
                        <Text strong>Amount to Collect: {fareDifference}</Text>
                        <DrawLine />
                     </Col>
                </Row>
                :
                <Row justify="center">
                    <Col>
                        <Text strong>Amount to Refund: {-fareDifference}</Text>
                        <DrawLine />
                    </Col>
                </Row>
            }
        </div>
    )
}

const getTicketDetails =(ticket,schedule,trip,route,travellers)=>{
    let desc = '' , travelDetails = '';
    if(isValid(schedule.description)){
        desc = schedule.description
    }
    if(isValid(trip.trip_start_date)){
        let date = getAPIDateFormat(trip.trip_start_date);
            if(route.origin_day>0){
                date = incrementDays(date,route.origin_day);
            }
        travelDetails = getFormatedDate(date,true,true,true,'/');
    }
    if(isValidArray(route)){
        travelDetails = travelDetails +" | "+ route.origin +" to "+route.destination;
    }
    if(isValidArray(travellers)&&travellers.length>0){
        let index = 0;
        travelDetails = travelDetails + " | Seat# ";
        travellers.forEach(element => {
            let seatNumber = element.seat_number;
            if(!isValid(seatNumber)){
                seatNumber = element.seatNumber;
            }
            travelDetails = travelDetails + seatNumber;
            index++;
            if(index < travellers.length){
                travelDetails = travelDetails + ","
            }   
        });
    }
  
    return {
        description:desc,
        travelDetails:travelDetails
    };
}

const DrawLine =()=>{

    return(
        <div className="passenger-form-underline">

        </div>
    )
}

const {
    Text
} = Typography;

let bookButtonType="Change";

const { Option } = Select;

const renderOptions=(passengers)=>{
    let passengerArray=[];
    if(isValidArray(passengers)&&passengers.length>0){
        passengers.forEach(element => {
            if(isValidArray(element)&&isValid(element.full_name)&&element.traveller_status === "Confirmed"){
                let displayPassengerName = element.full_name;
                if (!isValid(global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING'])||global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING']==0){
                    displayPassengerName = displayPassengerName + "("+element.gender+")";
                }
                passengerArray.push(<Option searchValue ={displayPassengerName} value={element.full_name} passenger={element}>{displayPassengerName}</Option>)
            }
        });
    }
    return passengerArray;
}

const getDropPointsDate=(selectedSchedule,selectedDate)=>{
    let destinationDayNo=0;
    if(selectedSchedule.destination_day>selectedSchedule.origin_day){
        destinationDayNo = selectedSchedule.destination_day - selectedSchedule.origin_day;
    }
    let incrementDate = incrementDays(selectedDate,destinationDayNo);
    return incrementDate;   
}

const getPointValue=(value,landmark)=>{
    return (<div>
                {value}
                {isValid(landmark)?
                <span style={{fontSize:11}}>
                    {landmark}
                </span>
                :null
                }
            </div>);
}

const renderBoardingsPoints=(boardingPoints,selectedDate)=>
{
    let boardingPointsArray=[] , nextDayBoardingPoints=[];
    if(isValidArray(boardingPoints)&&boardingPoints.length>0){
        boardingPoints.forEach(element => {
            let desc= element.location +" " +getTimeStampInReadableFormat(element.timing);
            if(isValid(element)){
                if(element.origin_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(selectedDate,element.origin_day));
                    desc = desc +" ("+incrementDate + ")";
                    nextDayBoardingPoints.push(<Option searchValue={desc} value={element.id}>{getPointValue(desc,element.landmark)}</Option>);
                }else{
                    boardingPointsArray.push(<Option searchValue={desc} value={element.id}>{getPointValue(desc,element.landmark)}</Option>);
                }   
            }
        });
    }
    return boardingPointsArray.concat(nextDayBoardingPoints);
}

const renderDropPoints=(dropPoints,selectedDate,selectedSchedule)=>
{
    let dropDate = selectedDate;
    dropDate=getDropPointsDate(selectedSchedule,selectedDate);
    let dropPointsArray=[];
    if(isValidArray(dropPoints)&&dropPoints.length>0){
        dropPoints.forEach(element => {
            let desc= element.location +" " +getTimeStampInReadableFormat(element.timing);
            if(isValid(element)){
                if(element.destination_day>0||selectedSchedule.destination_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(dropDate,element.destination_day));
                    desc = desc +" ("+incrementDate + ")";
                    dropPointsArray.push(<Option searchValue={desc} value={element.id}>{getPointValue(desc,element.landmark)}</Option>);
                }else{
                    dropPointsArray.push(<Option searchValue={desc} value={element.id}>{getPointValue(desc,element.landmark)}</Option>)
                }
            }        
        });
    }
    return dropPointsArray;
}

export const PassengerForm = (props) => {

    const{
        selectedSchedule,
        fetchSeats ,
        stopPoint,
        selectedDate ,
        oldPassengers,
        oldTicket,
        agent,
        oldSchedule,
        oldTrip,
        oldRoute,
        setSelectedTripRoute,
        oldTicketDetails
    } = props;

  const [form] = Form.useForm();

  const dispatch=useDispatch();

  const {
    isDesktop
    }=useContext(DeviceContext);

  const {
      selectedSeats,
      emailId,
      phoneNumber,
      boardingId,
      dropId
    }=useSelector(passengerInfoSelector);

  const [seats,setSeats]=useState([]);
  const [formValues , setFormValues]=useState({});
  const [isRefunded,setIsRefunded]=useState(false);
  const [waiveCharge,setWaiveCharge]=useState(true);
  const [isNotify,setIsNotify]=useState(false);
  const [isWebTicket, setIsWebTicket] = useState(false);
  const [customerIntiated, setCustomerIntiated] = useState(true);
  const [dropLocationId, setDropLocationId] = useState(null);
  const [pickupLocationId, setPickupLocationId] = useState(null);

  useEffect(() => {
      let pickupTimings = selectedSchedule.pickupTimings, dropTimings = selectedSchedule.dropTimings;
      let oldPickupLocation = oldTicketDetails.pickupDetails.location;
      let oldDropLocation = oldTicketDetails.dropDetails.location;
      if (isValidArrayWithValues(pickupTimings)) {
          pickupTimings.map((pickup) => {
              if (pickup.location == oldPickupLocation) {
                  setPickupLocationId(pickup.id);
              }
          })
      }
      if (isValidArrayWithValues(dropTimings)) {
        dropTimings.map((drop) => {
            if (drop.location == oldDropLocation) {
                setDropLocationId(drop.id);
            }
        })
    }
  }, [selectedSchedule.pickupTimings, selectedSchedule.dropTimings])

  useEffect(()=>{
    let seatsArray=getValuesOfJSonObject(selectedSeats);
    setSeats(seatsArray);
    form.setFieldsValue({
        passengerDetails:seatsArray
    })
  },[selectedSeats])

  useEffect(() => {
      if (agent.name.toLowerCase()=="website") {
          setIsWebTicket(true);
      }
      else {
          setIsWebTicket(false);
      }
  },[agent])

  useEffect(()=>{
    form.setFieldsValue({
        emailId:emailId,
        phoneNumber:phoneNumber,
        boardingPointId: pickupLocationId,
        dropPointId:dropLocationId

    })
  },[emailId,phoneNumber,pickupLocationId,dropLocationId])

  if(!isValidArray(seats)||seats.length<=0){
      return null
  }

  const handleSubmit =  (e ) => {
    e.preventDefault();
    return form.validateFields()
    .then(values => {
        let count = getConfirmedPassengersCount(oldPassengers);
        if(count === seats.length){
            values.emailId = oldTicket.email_id;
            values.phoneNumber = oldTicket.phone_number;
            setFormValues(values);
            return true;
        }else{
            message.error(`Existing ticket has ${count} passengers. Select ${count} seats to proceed.`);
            return false
        }
       
    }).catch((err)=>{
      return false;
    })
}

const onSuccess=()=>{
    fetchSeats(selectedSchedule.trip_route_id);
    resetForm();
    setSelectedTripRoute(selectedSchedule.trip_route_id)
    dispatch(resetPassengerForm());
}

const bookAction=()=>{
        let newTicket = getDetailsToBookTicket(formValues,selectedSchedule,oldTicket);
        let cancelTicket = getDetailsToCancelTicket(oldTicket,oldPassengers,customerIntiated);
        cancelTicket['trip'] = oldTrip;
        cancelTicket['schedule'] = oldSchedule;
        let insertOldTicket = false;

        if((waiveCharge&&!isOldTicketAsHigher)||(!isRefunded&&isOldTicketAsHigher)){
            insertOldTicket = true;
        }
      dispatch(changeTicket(cancelTicket,newTicket,insertOldTicket,isNotify,onSuccess));
}

const resetForm=()=>{
    form.setFieldsValue({
        passengerDetails:[],
        phoneNumber:null,
        emailId:null,
        boardingPointId:null,
        dropPointId:null
    })
}

const onFormChange=({
    fullName,
    age,
    gender,
    seatId
})=>{
    dispatch(onPassengerFormChange({
        fullName,
        age,
        gender,
        seatId
    }))
}
let {
    fareDifference,
    isOldTicketAsHigher
} = getTicketFare(seats,oldTicket,isRefunded,waiveCharge);

const ConfirmBody=()=>{
    oldTrip.trip_start_date = oldTrip.start_date;
    let oldTicketFormatedText = getTicketDetails(oldTicket,oldSchedule,oldTrip,oldRoute,oldPassengers);
    let newTicketFormatedText = getTicketDetails(formValues,selectedSchedule,selectedSchedule.trip,selectedSchedule,formValues.passengerDetails);
    
    return (
        <div>
            <div>
                <div className='fontWeightBold'>
                    Confirm Bus Change
                </div>
                <DrawLine />
            </div>
            <Row>
                <Col>
                    <Text>
                        <Text strong>Old Bus :</Text>
                        {oldTicketFormatedText.description}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text>DOJ : {oldTicketFormatedText.travelDetails}</Text>
                    </Text>
                </Col>
            </Row>
            <Row justify='center' gutter={16}>
                <Col>
                    <div className="arrow-block">
                            <Icon component={() => (<img src={ArrowDownIcon} style={{width:'20px'}} />)} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>New Bus :</Text>
                        {newTicketFormatedText.description}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text>DOJ : {newTicketFormatedText.travelDetails}</Text>
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>No of seats : </Text>
                        {seats.length}
                    </Text>
                </Col>
            </Row>
            <div>
                <PassengerSeatsTable 
                    formValues={formValues}
                    boardingPoints={selectedSchedule.pickupTimings}
                />
            </div>
            {renderAmountdiv(fareDifference)}
        </div>
    )
}
   
if(!isValidArray(selectedSchedule)||!isValidArray(selectedSchedule.trip)||!isValid(selectedSchedule.trip.id)){
    return null
}
  return (
    <div>
        <h3>Passenger Details</h3>
        <DrawLine />
        <Row gutter={[16,0]}>
            <Col>
                <Text className="seatNumberInput">
                    Seat #
                </Text>
            </Col>
            {isDesktop&&
                <Col className='passengerNameLabel'>
                    <Text>
                        Passenger Name *
                    </Text>
                </Col>
            }
            {isDesktop&&
            <Col className='fareLabel'>
                <Text>
                    Fare 
                </Text>
            </Col>
            }
        </Row>
        <Form
        form={form}
        layout="vertical"
        initialValues={{ passengerDetails: []}}
        onValuesChange={(changedValues,allValues)=>{
       
        }}
        >
            <Form.List name="passengerDetails">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, id,fieldKey, ...restField }) => {
                    return(
                    <div className="passenger-block">
                        <Row gutter={[16,16]}>
                            <Col>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'seatNumber']}
                                    fieldKey={[fieldKey, 'seatNumber']}
                                    rules={[{ required: false, message: 'Please enter seat number' }]}
                                >
                                    <Input placeholder="Seat #" disabled={true} className="seatNumberInput" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'fullName']}
                                    fieldKey={[fieldKey, 'fullName']}
                                    rules={[{ required: true, message: 'Please enter passenger name' }]}
                                >
                                     <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select a passenger"
                                        optionFilterProp="childrens"
                                        filterOption={(input, option) =>
                                            option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        bordered={true}
                                        className='passengerNameLabel'
                                        onChange={(value,{passenger})=>{    
                                            let passengerDetails = form.getFieldValue('passengerDetails');
                                            passengerDetails[key].gender=passenger.gender;
                                            passengerDetails[key].age = passenger.age;
                                            form.setFieldsValue({
                                                passengerDetails:passengerDetails
                                            });
                                            onFormChange({
                                                fullName:passenger.full_name,
                                                seatId:seats[key].id, 
                                                age:passenger.age,
                                                gender:passenger.gender
                                            });
                                        }}
                                    >
                                        {renderOptions(oldPassengers)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    // label="Fare"
                                    {...restField}
                                    name={[name, 'discountedCostWithoutGst']}
                                    fieldKey={[fieldKey, 'discountedCostWithoutGst']}
                                    rules={[{ required: false, message: 'Please enter seat number' }]}
                                >
                                    <Input placeholder="fare" disabled={true} className="fareInput" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    )})}
                </>
                )}
            </Form.List>
            <Row gutter={16}>
                <Col>
                <Form.Item
                    label="Boarding Point"
                    name="boardingPointId"
                    tooltip="Please select boarding point"
                    rules={[{ required: true, message: 'Please select boarding point' }]}
                >
                        <Select
                                size="large"
                                showSearch
                                placeholder="Select a Boarding Point"
                                optionFilterProp="children"
                                filterOption={(input , option) =>
                                        option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                bordered={true}
                                className="boarding-point-select-style"
                                >
                                {renderBoardingsPoints(stopPoint.pickupDetails,selectedDate)}
                        </Select>
                </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        label="Drop Point"
                        tooltip="Please select drop point"
                        rules={[{ required: true, message: 'Please select drop point' }]}
                        name="dropPointId"
                    >
                        <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select a Drop Point"
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    bordered={true}
                                    className="drop-point-select-style"
                                    >
                                    {renderDropPoints(stopPoint.dropDetails,selectedDate,selectedSchedule)}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>             
            {renderAmountdiv(fareDifference)}
            {isOldTicketAsHigher?
            <Row>
                <Checkbox 
                value={isRefunded}
                disabled = {isWebTicket}
                onChange={(e)=>{
                    setIsRefunded(e.target.checked);
                }}>Issue Refund</Checkbox>
            </Row>
            :
            <Row>
                <Checkbox 
                defaultChecked={waiveCharge}
                value={waiveCharge}
                disabled = {isWebTicket}
                onChange={(e)=>{
                    setWaiveCharge(e.target.checked);
                }}>Waive Charge</Checkbox>
            </Row>
            }
             <Row>
                <Checkbox 
                defaultChecked={isNotify}
                value={isNotify}
                onChange={(e)=>{
                    setIsNotify(e.target.checked);
                }}>Send SMS</Checkbox>
            </Row>
            <Row>
                <Checkbox 
                defaultChecked={customerIntiated}
                value={isNotify}
                onChange={(e)=>{
                    setCustomerIntiated(e.target.checked);
                }}>Customer initiated</Checkbox>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                {checkActionAllowed(ACTION_BOOK_TICKET_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={bookButtonType}
                                buttonClassName={"book-button"}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,bookButtonType);
                                }}
                                children={<ConfirmBody buttonType={bookButtonType}/>}
                                onOk={bookAction}
                                okButtonName={bookButtonType}
                            />
                    </Col>
                }
            </Row>
        </Form>
    </div>
  );
};