import React, { useState } from "react";
import { Row , Col , Popover, Table , Tabs , Button} from "antd";
import { getLocalDate , convertDateStringToDate , getTimeStampInReadableFormat, getAPIDateFormat } from "../../../utils/date.utils";
import { isValidArray , isValid} from "../../../utils/utilities";
import Icon from '@ant-design/icons';
import ArrowIcon from './../../../images/arrow.png';
import './search-result-item-card.style.scss';
import { ViaCitiesComponent } from '../../via-cities-component/via-cities.component';
import Clock from './../../../images/red-clock.png';
import { RenderFares as RenderFaresCommon } from './../../common-components/trip-fare-component/trip-fare.component';
import { StickyContainer, Sticky } from 'react-sticky';
import { EditFareComponent } from './../../edit-fare-component/edit-fare.component';
import { TripTicketsComponent } from "../../trip-tickets/trip-tickets-component/trip-tickets.component";
import { useEffect } from "react";
import { NewTimingTab } from "../../new-timing/new-timing-component/new-timing.component";
import { getShowNewTiming, getShowTicketsConfirmation, isAdminTabAllowed, isLogsTabAllowed, refreshBoardingPoints, refreshDropPoints } from "./search-result-item-card.module";
import LogsComponent from "../../logs-component/logs-component";
import { AdminTabComponent } from "../../admin-tab/admin-tab.component";
import { BusLocationComponent } from "../../bus-location/bus-location.component";
import { showUpdatebtn } from "../../../utils/constant";

const { TabPane } = Tabs;

let editFareKey ="Edit Fare";
let passengerKey ="Passengers";
let newTimingKey ="newTiming";
let logsKey = "Logs";
let adminKey = "Admin";
let busLocation = "Bus Location";

const getDropPointsDay=(selectedSchedule)=>{
    let destinationDayNo=0;
    if(selectedSchedule.destination_day>selectedSchedule.origin_day){
        destinationDayNo = selectedSchedule.destination_day - selectedSchedule.origin_day;
    }
    // let incrementDate = incrementDays(selectedDate,destinationDayNo);
    return destinationDayNo;   
}

const getBoardingPoints=(boardingPoints)=>
{
    let boardingPointsArray=[] , nextDayBoardingPoints=[];
    if(isValidArray(boardingPoints)&&boardingPoints.length>0){
        boardingPoints.forEach(element => {
            if(isValid(element)){
                if(element.origin_day>0){
                    // let incrementDate = getUIDateFormat(incrementDays(selectedDate,element.origin_day));
                    let day = Number(element.origin_day) + 1;

                    nextDayBoardingPoints.push({
                        location:element.location,
                        time:getTimeStampInReadableFormat(element.timing) +  ` (Day ${day})`,
                        day :element.origin_day
                    });
                }else{
                    boardingPointsArray.push({
                        location:element.location,
                        time:getTimeStampInReadableFormat(element.timing)
                    });
                }   
            }
        });
    }
    let pagination = false;
    if(nextDayBoardingPoints.length+boardingPointsArray.length>20){
        pagination = {defaultPageSize:20};
    }
    return (
            <Table 
                className="custom-padding-tb"
                columns={[{
                    title:"Location",
                    dataIndex:'location',
                    key:'location'
                    },
                    {
                        title:"Time",
                        dataIndex:'time',
                        key:'time'
                    }]
                }
                dataSource={boardingPointsArray.concat(nextDayBoardingPoints)}
                pagination={pagination}
            />
        )
}

const genderDropPoints=(dropPoints,selectedSchedule)=>
{
    let dropDay=getDropPointsDay(selectedSchedule);
    let dropPointsArray=[];
    if(isValidArray(dropPoints)&&dropPoints.length>0){
        dropPoints.forEach(element => {
            if(isValid(element)){
                if(element.destination_day>0||selectedSchedule.destination_day>0){
                    // let incrementDate = getUIDateFormat(incrementDays(dropDate,element.destination_day));
                    dropDay = element.destination_day + dropDay; 
                    let day = Number(dropDay) + 1;
                    dropPointsArray.push({
                        location:element.location,
                        time:getTimeStampInReadableFormat(element.timing) + ` (Day ${day})`,
                        day :day});
                }else{
                    dropPointsArray.push({
                        location:element.location,
                        time:getTimeStampInReadableFormat(element.timing)
                    })
                }
            }        
        });
    }
    let pagination = false;
    if(dropPointsArray.length>20){
        pagination = {defaultPageSize:20};
    }
    return (
        <Table 
        className="custom-padding-tb"
        columns={[{
            title:"Location",
            dataIndex:'location',
            key:'location'
            },
            {
                title:"Time",
                dataIndex:'time',
                key:'time'
            }]
        }
        dataSource={dropPointsArray}
        pagination={pagination}
        />
    );
}


const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar"  />
      )}
    </Sticky>
  );

export const SearchTabs = (props) => {

        const{
            tabkey,
            showEditFare = false,
            showTicketsConfirmation = true,
            newTimingChildren = null,
            showLogsTab = false,
            showAdminChildren =false
        } = props;

        return(
            <StickyContainer>
                 <Tabs 
                 destroyInactiveTabPane={true}
                 style={{}}
                 className={'tabs-in-search'}
                //  defaultActiveKey={props.defaultActiveKey}
                 activeKey={`${props.openTripRouteId}${tabkey}`}
                 renderTabBar={renderTabBar}
                 tabBarExtraContent={props.selectSeats}
                 onTabClick={(currentKey)=>{
                     if(props.openTripRouteId === props.tripRouteId){
                        props.setOpenTripRouteId(null);
                        props.setKey(null);
                     }
                 }}
                 onChange={(currentKey)=>{
                        if(currentKey === `${props.tripRouteId}${editFareKey}`){
                            if(props.openTripRouteId === props.tripRouteId && tabkey === editFareKey){
                                props.setOpenTripRouteId(null);
                                props.setKey(null);
                            }else{
                                props.setOpenTripRouteId(props.tripRouteId); 
                                props.setKey(editFareKey); 
                            }
                        }else if(currentKey === `${props.tripRouteId}${passengerKey}`){
                            if(props.openTripRouteId === props.tripRouteId && tabkey === passengerKey){
                                props.setOpenTripRouteId(null);
                                props.setKey(null);
                            }else{
                                props.setOpenTripRouteId(props.tripRouteId); 
                                props.setKey(passengerKey);
                            }
                        }else if(currentKey === `${props.tripRouteId}${newTimingKey}`){
                            if(props.openTripRouteId === props.tripRouteId && tabkey === newTimingKey){
                                props.setOpenTripRouteId(null);
                                props.setKey(null);
                            }else{
                                props.setOpenTripRouteId(props.tripRouteId); 
                                props.setKey(newTimingKey);
                            }
                        }else if(currentKey === `${props.tripRouteId}${logsKey}`){
                            if(props.openTripRouteId === props.tripRouteId && tabkey === logsKey){
                                props.setOpenTripRouteId(null);
                                props.setKey(null);
                            }else{
                                props.setOpenTripRouteId(props.tripRouteId);
                                props.setKey(logsKey);
                            }
                        }else if(currentKey === `${props.tripRouteId}${adminKey}`){
                            if(props.openTripRouteId === props.tripRouteId && tabkey === adminKey){
                                props.setOpenTripRouteId(null);
                                props.setKey(null);
                            }else{
                                props.setOpenTripRouteId(props.tripRouteId);
                                props.setKey(adminKey);
                            }
                        }else if(currentKey === `${props.tripRouteId}${busLocation}`){
                            if(props.openTripRouteId === props.tripRouteId && tabkey === busLocation){
                                props.setOpenTripRouteId(null);
                                props.setKey(null);
                            }else{
                                props.setOpenTripRouteId(props.tripRouteId);
                                props.setKey(busLocation);
                            }
                        }
                 }}>
                {showEditFare&&
                    <TabPane tab="Edit Fare" key={`${props.tripRouteId}${editFareKey}`}>
                        {props.fareChildren()}
                    </TabPane>
                }
                {showTicketsConfirmation&&
                    <TabPane tab="Passengers" key={`${props.tripRouteId}${passengerKey}`}>
                        {props.passengerListChildren()}
                    </TabPane>
                }
                {showAdminChildren&&
                    <TabPane tab="Manage" key={`${props.tripRouteId}${adminKey}`}>
                        {props.adminChildren()}
                    </TabPane>
                }
                {showLogsTab&&
                    <TabPane tab="Logs" key={`${props.tripRouteId}${logsKey}`}>
                        {props.logsChildren()}
                    </TabPane>
                }
                <TabPane tab={busLocation}  key={`${props.tripRouteId}${busLocation}`}>
                    {props.renderBusLocationChildren()}
                </TabPane>
                <TabPane tab="Stops" key={`${props.tripRouteId}${newTimingKey}`}>
                    {newTimingChildren()}
                </TabPane>
                </Tabs>
            </StickyContainer>
        )
}


export const CardDescription = (props) => {
    let {
        schedule
    } = props;

    let  tripStartDate='';
    if(isValidArray(schedule)&&isValidArray(schedule.trip)&&isValid(schedule.trip.start_date)){
        if(schedule.origin_day>0){
            tripStartDate = "("+getLocalDate(convertDateStringToDate(schedule.trip.trip_start_date.split("T")[0]))+")";
        }
    }
    return (
        <div className="fontWeightBold description-row">
            {schedule.trip.description} {tripStartDate}
            {schedule.cut_route_enabled?
                <span className ="numberCircle"><span style= {{fontSize:14}}>S</span></span>
                :null
            }
            {schedule.dynamic_pricing?
                <span className ="numberCircle" style={{marginLeft:5}}><span style= {{fontSize:14}}>₹</span></span>
                :null
            }
            <ViaCitiesComponent 
                travelDate = {schedule.trip.trip_start_date}
                scheduleId={schedule.schedule_id}
            />            
        </div>

        )
}

export const SelectSeatsButton = (props) => {
    let {
        isOpenSelectSeats,
        item,
        onFareUpdateSuccess,
        openTripRouteId,
        setOpenTripRouteId,
        resetSeats,
        onSelectSchedule=()=>{},
        tabkey , 
        setKey,
        refetchDetails,
        vechicleInfoExits
    } = props;

    useEffect(()=>{
        if(isValid(openTripRouteId)){
            if(openTripRouteId === item.trip_route_id){
                onSelectSchedule(item);
            }
        }else{
            onSelectSchedule(null);
        }
    },[openTripRouteId]);

    let className = isOpenSelectSeats&&tabkey === "Select Seats"?"open-select-seat-btn":'select-seat-btn';

    let selectSeats = <div className="select-seats-button">
                            <Button 
                            className={className} 
                            onClick={()=>{
                                if(tabkey==="Select Seats"){
                                    resetSeats();
                                    props.setOpenTripRouteId(null);
                                    setKey(null);
                                }else{
                                    props.fetchSeats();
                                    setKey("Select Seats");
                                }
                            }} 
                            ghost={isOpenSelectSeats&&tabkey==="Select Seats"}
                            >
                                Select seats
                            </Button>
                        </div>

        return (
            <div className="select-seats-div">
                <div className="tabs-block">
                    <SearchTabs 
                        showEditFare={props.showEditFare}
                        openTripRouteId={openTripRouteId}
                        tripRouteId={item.trip_route_id}
                        setOpenTripRouteId={setOpenTripRouteId}
                        defaultActiveKey={`${item.trip_route_id}${editFareKey}`}
                        tabkey={tabkey}
                        setKey={setKey}
                        resetSeats={()=>{
                            resetSeats();
                        }}
                        fareChildren={()=>{
                            return (
                                <EditFareComponent 
                                    scheduleDescription={item.description}
                                    travelDate={item.trip.start_date}
                                    selectedDates={[getAPIDateFormat(item.trip.start_date)]}
                                    scheduleId={item.schedule_id}
                                    seatGroupIds={item.bus.seat_group_ids}
                                    selectedBus={item.bus}
                                    onFareUpdateSuccess={onFareUpdateSuccess}
                                    supportedFare ={item.bus.supported_fare}
                                    tripDynamicPricing = {item.dynamic_pricing}
                                    onCancel={()=>{
                                        setKey(null);
                                        setOpenTripRouteId(null);
                                    }}
                                />
                            )
                        }}
                        selectSeats={selectSeats}
                        passengerListChildren={()=>{
                            return(
                                <TripTicketsComponent 
                                    tripId={item.trip.id}
                                />
                            )
                        }}
                        newTimingChildren={()=>{
                            return(
                                <NewTimingTab 
                                    travelDate={item.trip.start_date}
                                    scheduleId={item.schedule_id}
                                    tripId={item.trip_id}
                                    refetchDetails={refetchDetails}
                                    isDaySpecificExits={getShowNewTiming(item)}
                                    startTime={item.trip_start_time}
                                    scheduleDescription = {item.description}
                                    showUpdateBtns={showUpdatebtn()}
                                    importFrom={'SearchPage'}
                                />
                            )
                        }}
                        logsChildren={()=> {
                            return(
                                <LogsComponent
                                    tripId = {item.trip_id}
                                    scheduleId = {item.schedule_id}
                                    scheduleDescription = {item.description}
                                    travelDate = {item.trip.start_date}
                                    startTime = {item.trip_start_time}
                                    seatGroupIds = {item.bus.seat_group_ids}
                                    supportedFare ={item.bus.supported_fare}
                                />
                            )
                        }}
                        adminChildren={()=>{
                            return (
                                <AdminTabComponent 
                                    schedule={item}
                                    vechicleInfoExits={vechicleInfoExits}
                                />
                            );
                        }}
                        renderBusLocationChildren={()=>{
                            return(
                                <BusLocationComponent 
                                    tripId = {item.trip_id}
                                />
                            )
                        }}
                        showAdminChildren={isAdminTabAllowed()}
                        showTicketsConfirmation={getShowTicketsConfirmation()}
                        showLogsTab={isLogsTabAllowed()}
                    />
                </div>
            </div>
        )
}


export const RenderFares=({schedule})=>{

    return  (
        <RenderFaresCommon
                tripRouteFare={schedule.trip_route_fare}
                discountTripRouteFare={schedule.discounted_cost_without_gst}
                tripRouteFareJson={schedule.trip_route_fare_json}
                discountedTripRouteJson={schedule.discounted_trip_route_fare_json_with_out_gst}
                supportedFare ={schedule.bus.supported_fare}
        />);
}


export const RouteDetails=({schedule,selectedDate})=>{

    let boardingPointsDiv = getBoardingPoints(schedule.pickupTimings,selectedDate);
    let dropPointsDiv = genderDropPoints(schedule.dropTimings,schedule);

    return  (<div className="origin-destionation-div">
                <div className="origin-block">
                    <Popover content={boardingPointsDiv} title={
                        <Row justify="space-between" align="center">
                            <Col>
                                Boarding Points
                            </Col>
                            <Col>
                                <Button 
                                    onClick={()=>{
                                        refreshBoardingPoints(schedule.schedule_id,schedule.trip_id)
                                    }}
                                >Refresh Boarding Points</Button>
                            </Col>
                        </Row>}>
                        {schedule.origin} 
                    </Popover>
                    <div>
                        {getTimeStampInReadableFormat(schedule.start_time)} 
                    </div>
                </div>
                <div className="arrow-block">
                    <Icon component={() => (<img src={ArrowIcon} style={{width:'20px'}} />)} />
                </div>
                <div className="destination-block">
                    <Popover content={dropPointsDiv} title={
                        <Row justify="space-between" align="center">
                            <Col>
                                Drop Points
                            </Col>
                            <Col>
                                <Button
                                onClick={()=>{
                                        refreshDropPoints(schedule.schedule_id,schedule.trip_id)
                                }}
                                >Refresh Drop Points</Button>
                            </Col>
                        </Row>
                    }>
                        {schedule.destination} 
                    </Popover>
                    <div>
                        {getTimeStampInReadableFormat(schedule.end_time)}
                    </div>
                </div>
            </div>);
}


export const RenderNewTime=({schedule})=>{
    if(isValidArray(schedule.pickupTimings)&&isValidArray(schedule.pickupTimings[0])&&isValid(schedule.pickupTimings[0].trip_id)){
        return(
            <Row gutter= {24}>
                    <Col>
                        <div style= {{flexDirection:'row'}}>
                            <Icon component={() => (<img src={Clock} style={{width:'23px',padding:'1px'}} />)} />
                            <span style= {{margin:10, marginTop:5, color: "#EA4025"}}>New Start Time : <span style= {{fontWeight: 'bold'}}>{getTimeStampInReadableFormat(schedule.start_time)}</span></span>
                        </div>
                    </Col>
            </Row>
        )
    }else{
        return null
    }
}