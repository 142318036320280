import { GET_TEMPORARY_CHANGES_BY_MONTH_URL, GET_STOPS_REMOVED_BY_TRIP_IDS_URL, GET_STOPS_ADDED_BY_TRIP_IDS_URL } from "../utils/api-url";
import { postCall } from "../utils/network.util";


export const getTemporaryChangesByMonth = async (month,year,
  isStopsRemoved,isStopsAdded,isCitiesRemoved,isTimeChanges,
  onSuccess=()=>{},onFailure=()=>{}) => {
    var fetched = postCall(GET_TEMPORARY_CHANGES_BY_MONTH_URL, {
        month:month,
        year:year,
        isStopsRemoved:isStopsRemoved,
        isStopsAdded:isStopsAdded,
        isCitiesRemoved:isCitiesRemoved,
        isTimeChanges:isTimeChanges
      });
      fetched.then((data) => {        
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.payload);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Falied to update transaction");
      })
}   

export const getStopsRemovedByTripIds = async (tripIds,onSuccess=()=>{},onFailure=()=>{}) => {
    var fetched = postCall(GET_STOPS_REMOVED_BY_TRIP_IDS_URL, {
        tripIds:tripIds
      });
      fetched.then((data) => {        
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.payload);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Falied to update transaction");
      })
}

export const getStopsAddedByTripIds = async (tripIds,onSuccess=()=>{},onFailure=()=>{}) => {
    var fetched = postCall(GET_STOPS_ADDED_BY_TRIP_IDS_URL, {
        tripIds:tripIds
      });
      fetched.then((data) => {        
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.payload);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Falied to get stops added by trip ids");
      })
} 


