import { message } from 'antd';
import { isValid, isValidArray } from '../../utils/utilities';
import { RELEASE_SEATS_URL } from './../../utils/api-url';

export const handleReleaseSeats=(tripId,tripRouteId,selectedSeats,isPermanentRelease,onSuccess=()=>{},onFailure=()=>{})=>{
    let {
        seatNumbers,
        ticketId
    } = getReleaseSeatNumbers(selectedSeats);
    fetch(RELEASE_SEATS_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tripId: tripId,
        ticket_id: ticketId,
        seatNumbers: seatNumbers,
        updatedBy : global.userName,
        isManualReleased : true,
        tripRouteId:tripRouteId,
        isPermanent:isPermanentRelease
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "SUCCESS") {
          message.success("Ticket released successfully");
        } else {
          message.error(`Release failed because of ${data.Message}`);
        }
      })
      .then((data) => {
        onSuccess();
      })
      .catch((reason) => {
        message.error("Release failed because of", reason);
        onFailure()
      }); 
}

const getReleaseSeatNumbers=(seats)=>{
    let releaseSeatNumbers=[],ticketId=null;
    if(isValidArray(seats)&&seats.length>0){
        seats.forEach(element => {
            if(element.seatStatus==="Blocked" || !isValid(element.fullName)){
                releaseSeatNumbers.push(element.seatNumber);
                if(isValid(element.fullName)){
                  ticketId=element.ticketId;
                }
            }
        });
    }
    return {seatNumbers:releaseSeatNumbers,ticketId};
}