import React, { useEffect, useState } from "react";
import { Button ,Row,Col, Select, Calendar , Typography, message} from "antd";
import './render-tabs.style.scss';
import { getDates, addMonth, firstDayOfMonth, getAPIDateFormat, getDate, getDateMonthInReadable , getMonth, lastDayOfMonth, subtractMonth } from "../../../utils/date.utils";
import { isValidArray, removeArrayIndexValue } from "../../../utils/utilities";
import { formateDataByDate, getActiveStatus } from "./render-tabs.module";
import { activateSchedules, blockSchedules, getSchedulesByIdForDates, validateTripAction, getCities, sendTripActivateEmailToOtas } from "../../../actions/admin.action";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { showConfirm } from "../../custom-components/custom-component";
import { CalenderColorLegend } from "../calender-color-legend/calender-color-legend";
import { RedBusActivation } from "../../red-bus-activation/red-bus-activation.component";

const {Option} = Select;
const { Text } = Typography;

export const ScheduleActiveExtend = (props) => {

        const{
            selectedDate,
            scheduleData,
            isMobile,
            isScheduleOpened
        } = props;

        const [currentDate , setCurrentDate] = useState(selectedDate);
        const [nextDate , setNextDate] = useState(selectedDate);
        const [currentSelectedDates , setCurrentSelectedDates] = useState([]);
        const [openCalenderData , setOpenCalenderData] = useState({});

        const dispatch = useDispatch();

        useEffect(()=>{
            if(isScheduleOpened){
                reload();
            }
        },[isScheduleOpened,currentDate]);

        const reload=()=>{
            let sqlStartDate = firstDayOfMonth(currentDate);
            let sqlNextDate = lastDayOfMonth(currentDate);
            if(!isMobile){
                sqlNextDate = lastDayOfMonth(addMonth(currentDate,1));
            }
            setLoading(true);
            getSchedulesByIdForDates(scheduleData.id,sqlStartDate,sqlNextDate,onSuccess,onFailure);
        }

        useEffect(()=>{
            setCurrentDate(selectedDate);
        },[selectedDate]);

        useEffect(()=>{
            let secondDate = addMonth(currentDate,1);
            setNextDate(secondDate);
        },[currentDate]);

        let currentMonth = getMonth(currentDate);
        let nextMonth = getMonth(nextDate);

        const onSuccess=(activeData)=>{
            setLoading(false);
            setOpenCalenderData(formateDataByDate(activeData));
            setCurrentSelectedDates([]);
        }

        const setLoading = (value)=>{
            dispatch(loadingAction(value));
        }

        const onFailure=()=>{
            setLoading(false);
        }

        const onClickActivate=()=>{
            if(isValidArray(currentSelectedDates)&&currentSelectedDates.length>0){
                setLoading(true);
                validateTripAction(scheduleData.id,(responceData)=>{
                    let warningsList=[];
                        if(responceData.length>0){
                            let i=1;
                            responceData.forEach(text => {
                                warningsList.push(<div>{i}. {text}</div>);
                                warningsList.push(<br />);
                                i=i+1;
                            });
                        setLoading(false);
                        showConfirm(()=>{
                                setLoading(true);
                            activateSchedules(scheduleData.id,true,currentSelectedDates,(msg)=>{
                                message.success(msg);
                                setLoading(false);
                                reload();
                            },(errMsg)=>{
                                message.error(errMsg);
                                setLoading(false);
                            });
                        },
                        "Are you sure to proceed?",
                        (
                            <div>
                             {warningsList}
                            </div>
                          ),'Ok');
                      }else{
                        activateSchedules(scheduleData.id,true,currentSelectedDates,(msg)=>{
                            message.success(msg);
                            setLoading(false);
                            reload();
                        },(errMsg)=>{
                            message.error(errMsg);
                            setLoading(false);
                        });                  
                    }
                },()=>{
                    message.error("Schedule validation failed!");
                    setLoading(false);
                });
            }else{
                message.warning("Please select atleast one date to activate.")
            }
        }

        const emailOTAs = (routes) => {
            if(isValidArray(currentSelectedDates)&&currentSelectedDates.length>0){
                let routeCity = getCities(routes);
                let operatorName = scheduleData.operator_name;
                let scheduleDescription = scheduleData.description;
                let originDestination = scheduleData.origin+' to '+scheduleData.destination;
                let departureTime = scheduleData.start_time;
                let formatData = {routeCity, operatorName, scheduleDescription, originDestination, departureTime, dates: currentSelectedDates};
                sendTripActivateEmailToOtas(formatData, (data) => {
                    message.success(data);
                }, (err) => {message.error(err)})
            }
            else {
                message.warning("Please select atleast one date to activate.")
            }
        }

        const onBlockSuccess=(msg)=>{
            setLoading(false);
            reload();
            message.success(msg);
        }

        const onClickBlock=()=>{
            if(isValidArray(currentSelectedDates)&&currentSelectedDates.length>0){
                setLoading(true);
                blockSchedules(scheduleData.id,currentSelectedDates,'',false,onBlockSuccess,(data)=>{
                    setLoading(false);
                    if(data.search("Failed to block trips as tickets exists")!=-1){
                        showConfirm(()=>{
                          blockSchedules(scheduleData.id,currentSelectedDates,'',true,onBlockSuccess,()=>{
                            console.log("empty call");
                          });
                        },data + " Are you sure you want to block seats for those days and block rest of the trips.",'')
                      }else{
                        message.error(data);
                      }
                });
            }else{
                message.warning("Please select atleast one date to block.")
            }
        }

        const onPreviousClick =()=>{
            if(isMobile){
                setCurrentDate(subtractMonth(currentDate,1));
            }else{
                setCurrentDate(subtractMonth(currentDate,2));
            }
        }

        const onNextClick =()=>{
            if(isMobile){
                setCurrentDate(addMonth(currentDate,1));
            }else{
                setCurrentDate(addMonth(currentDate,2));
            }
        }


        return(
            <div className="extend-block-root">
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <div className="site-extended-calendar">
                            <Calendar 
                                value={currentDate}
                                fullscreen={false}
                                headerRender={()=>{
                                    return (
                                    <Row gutter={[16,16]} align="middle">
                                        <Col>
                                            <Button type="link" className="previous-calender-btn" onClick={onPreviousClick}>
                                                <Text className="normal-txt">Previous</Text>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Text strong>{getDateMonthInReadable(currentDate)}</Text>
                                        </Col>  
                                        {isMobile&&
                                            <Col>
                                                <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                    <Text className="normal-txt">Next</Text>
                                                </Button>
                                            </Col>
                                        }      
                                    </Row>
                                    )              
                                }}
                                dateFullCellRender={(date)=>{
                                    let day = getDate(date);
                                    let month = getMonth(date);
                                    if(month===currentMonth){
                                        let apidate = getAPIDateFormat(date);
                                        let {
                                            className,
                                            styles,
                                            onlyDateClassName,
                                            confirmedSeats,
                                            cutRouteEnabledClassName
                                        } = getActiveStatus(openCalenderData,apidate);
                                        let index = currentSelectedDates.indexOf(apidate);
                                        let selectedClassName = index!=-1?"schedule-date-selected":"";
                                        return (
                                            <div title={`Confirmed Seats:${confirmedSeats}`}
                                            className={`current-date ${className} edit-fare-date-block ${cutRouteEnabledClassName}`} style={styles}>
                                                <div
                                                className={`${selectedClassName} ${onlyDateClassName}`}>
                                                    {day}
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <div className={`next-month-date`} title="">
                                                <div>{day}</div>
                                            </div>
                                        )
                                    }
                                }}
                                mode="month"
                                onSelect={(date)=>{
                                    let oldDates = currentSelectedDates;
                                    let apidate = getAPIDateFormat(date);
                                    let index = oldDates.indexOf(apidate);
                                    if(index === -1){
                                        oldDates.push(apidate);
                                    }else{
                                        removeArrayIndexValue(oldDates,index);
                                    }
                                    setCurrentSelectedDates([...oldDates]);
                                }}
                            />
                        </div>
                    </Col>
                    {!isMobile&&
                    <Col>
                            <div className="site-extended-calendar">
                                <Calendar 
                                            value={nextDate}
                                            fullscreen={false}
                                            headerRender={()=>{
                                                return (
                                                <Row gutter={[16,16]} align="middle" justify="end">
                                                    <Col>
                                                        <Text strong>{getDateMonthInReadable(nextDate)}</Text>
                                                    </Col>
                                                    <Col>
                                                        <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                            <Text className="normal-txt">Next</Text>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                )              
                                            }}
                                            dateFullCellRender={(date)=>{
                                                let day = getDate(date);
                                                let month = getMonth(date);
                                                if(month===nextMonth){
                                                    let apidate = getAPIDateFormat(date);
                                                    let {
                                                        className,
                                                        styles,
                                                        onlyDateClassName,
                                                        confirmedSeats,
                                                        cutRouteEnabledClassName
                                                    } = getActiveStatus(openCalenderData,apidate);
                                                    let index = currentSelectedDates.indexOf(apidate);
                                                    let selectedClassName = index!=-1?"schedule-date-selected":"";
                                                    return (
                                                        <div title={`Confirmed Seats:${confirmedSeats}`}
                                                        className={`current-date ${className} edit-fare-date-block ${cutRouteEnabledClassName}`} style={styles}>
                                                            <div 
                                                            className={`${selectedClassName} ${onlyDateClassName}`}>
                                                                {day}
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return (
                                                        <div className={`next-month-date`}>
                                                            <div>{day}</div>
                                                        </div>
                                                    )
                                                }
                                            }}
                                            mode="month"
                                            onSelect={(date)=>{
                                                let oldDates = currentSelectedDates;
                                                let apidate = getAPIDateFormat(date);
                                                let index = oldDates.indexOf(apidate);
                                                if(index === -1){
                                                    oldDates.push(apidate);
                                                }else{
                                                    removeArrayIndexValue(oldDates,index);
                                                }
                                                setCurrentSelectedDates([...oldDates]);
                                            }}
                                        />
                            </div>
                        </Col>
                    }
                </Row>
                <Row justify="space-around" align="center">
                    <Col>
                        <CalenderColorLegend />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <Select
                        placeholder="Select Dates"
                        className="schedule-date-select"
                        onChange={(value)=>{
                                let dates = getDates(value);
                                setCurrentSelectedDates(dates);
                        }}
                        >
                            <Option value={'today'}>Today</Option>
                            <Option value={'tomorrow'}>Tomorrow</Option>
                            <Option value={'thisweek'}>This Week</Option>
                            <Option value={'thismonth'}>This Month</Option>
                            <Option value={'nextmonth'}>Next Month</Option>
                            <Option value={'weekdays'}>Week Days (This Month)</Option>
                            <Option value={'weekends'}>Weekend Days (This Month)</Option>
                            <Option value={'nextmonthweekdays'}>Week Days (Next Month)</Option>
                            <Option value={'nextmonthweekends'}>Weekend Days (Next Month)</Option>
                        </Select>
                    </Col>
                    <Col>
                        <Button className="schedule-block-button"
                            onClick={onClickBlock}
                        > 
                            Block
                        </Button>
                    </Col>
                    <Col>
                        <Button className="schedule-activate-button"
                            onClick={onClickActivate}
                        >
                            Activate
                        </Button>
                    </Col>
                    <Col>
                        <RedBusActivation 
                            scheduleId={scheduleData.id}
                            emailOTAs = {emailOTAs}
                        />
                    </Col>
                </Row>
            </div>
        )
}
