import React from "react";
import { Select } from 'antd';
import { MenuOutlined  } from "@ant-design/icons";
import { DeleteOutlined } from '@ant-design/icons';
import { isValid } from './../../../utils/utilities'
import { useSelector } from "react-redux";
import { selectedCitiesSelector } from "../../../selector/trip-create.selector";

const { Option } = Select;

export const CitySelect = (props) => {
    const {
        masterCities,
        cityIndex,
        updateSelectedCity,
        disabled,
        defaultCity
    }= props;

    let selectedCities = useSelector(selectedCitiesSelector)


    function onChange(value,info) {
        updateSelectedCity(cityIndex,info);
    }

    return (
        <div className="city-select-root">
                <div className="city-number">
                    {cityIndex+1}
                </div>
               <Select
                    suffixIcon={<MenuOutlined />}
                    showSearch
                    size="large"
                    style= {{backgroundColor: 'white',width: 200}}
                    placeholder="Select a city"
                    optionFilterProp="children"
                    onChange={onChange}
                    disabled= {disabled}
                    value = {defaultCity}
                    >
                        {masterCities.map(item => (
                            <Option key = {item.id} value= {item.city_name} data={item}>{item.city_name}{isValid(item.alias_city_name)?" / "+item.alias_city_name:null}</Option>
                        ))}
                    </Select>
                    {cityIndex !== selectedCities.length &&
                        <div className="marginLeft">
                            <DeleteOutlined
                                onClick={()=>{
                                    onChange(cityIndex,null);
                                }}
                                style={{
                                    fontSize: 16
                                }}
                                />
                        </div>
                    }
        </div>
    )
}
