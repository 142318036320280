import { 
    GET_AGENT_COLOURS_URL,
    UPDATE_AGENT_COLOURS_URL
} from "../utils/api-url";
import { postCall } from "../utils/network.util";
import {GET_AGENT_COLOURS, UPDATE_AGENT_COLOURS } from "./action-types";

export const getAgentColorDetails = (operatorCode, onSuccess=()=>{}, onFailure=()=>{}) => {
    return {
        type: GET_AGENT_COLOURS,
        payload: {
            url: GET_AGENT_COLOURS_URL,
            api: postCall,
            apiPayload: {
                operatorCode
            },
            onSuccess,
            onFailure
        }
    }
}

export const updateAgentColors = (operatorCode, colorData, onSuccess = () => {}, onFailure = () => {}) => {
    return {
        type: UPDATE_AGENT_COLOURS,
        payload: {
            url: UPDATE_AGENT_COLOURS_URL,
            api: postCall,
            apiPayload: {
                operatorCode,
                data: colorData
            },
            onSuccess,
            onFailure
        }
    }
}