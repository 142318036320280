import React, { useContext,useState } from "react";
import { useSelector } from "react-redux";
import { runSplashScreenSelector , userNameSelector} from './../../selector/user.selector';
import { MenuWeb } from './menu-web.component';
import { Row , Col , Dropdown, Button  , Menu, Divider} from 'antd';
import { DeviceContext } from './../../components/device-provider/device-provider';
import { MenuMobile } from './menu-mobile.component';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { logoutAction } from './../../actions/user.action';
import { useDispatch } from 'react-redux';
import { showConfirm } from './../custom-components/custom-component';
import { WEB_RELEASE_VERSION } from './../../app.config';
import { LOGIN_ACCESS_TOKEN_KEY , getAdminUrl, logoStyle} from './../../utils/constant';
import { removeLocalStorage } from './../../utils/utilities';
import qwikBusIcon from './../../images/qwikbus-logo.png';
import Icon from '@ant-design/icons';
import userIcon from './../../images/user-icon.png';
import { checkAgentsPage, checkAgentsTransactionPage, checkToViewServiceAccessPage, checkToViewTripSheetPermissionPage, checkToViewUserActions } from "./app.header.module";
import { resetTripCreate } from "../../actions/trip-create.actions";


const { SubMenu } = Menu;

const getLogoUrl = () => {
    
    if(global.travelsDetails&&global.travelsDetails.agent_portal_logo){
        return global.travelsDetails.agent_portal_logo;
    }else{  
        return global.logoUrl || qwikBusIcon;
    }
};


export const AppHeader = () => {

    const {
        isMobile
    }=useContext(DeviceContext);

    const [visibleMenu,setVisibleMenu]=useState(false);

    let adminUrl = getAdminUrl();

    const onVisibleChange=(flag)=>{
        setVisibleMenu(flag);
    }

    const onMenuItemClick=(e)=>{
        setVisibleMenu(false);
    }

    const runSplashScreen=useSelector(runSplashScreenSelector);
    const userName =useSelector(userNameSelector);

    let history = useHistory();
    const dispatch = useDispatch();

    const dashboardClick=()=>{
        history.push("/dashboard");
    }

    const searchClick=()=>{
        history.push("/home");
    }
    
    const viewCancelClick=()=>{
        history.push("/view-cancel");
    }

    const changePassword=()=>{
        history.push("/change-password");
    }

    const serviceAccessPage=()=>{
        history.push("/users/service-access");
    }   

    const logout=()=>{
        showConfirm(()=>{
            removeLocalStorage(LOGIN_ACCESS_TOKEN_KEY);
            dispatch(logoutAction());
        },"Log out Alert!","Do you want logout?");
    }

    const busCancelClick=()=>{
        history.push("/notify/bus-cancel");
    }


    const notificationClick=()=>{
        history.push("/notify/notification");
    }

    const onTripSheetClick=()=>{
        history.push("/report/tripsheet");
    }


    const onActiveBookingsClick=()=>{
        history.push("/report/viewActiveBookings");
    }

    const onBookedTicketsClick=()=>{
        history.push("/report/bookedtickets");
    }

    const onSalesReportClick=()=>{
        history.push("/report/salesreport");
    }

    const onViewPassengerInfoReportClick=()=>{
        history.push("/report/viewpassengersreport");
    }

    const onBlockedReportClick=()=>{
        history.push("/report/blockedreport");
    }

    const onTicketSearchReportClick=()=>{
        history.push("/report/ticketsearchreport");
    }

    const onTicketSearchV2ReportClick=()=>{
        history.push("/view-ticket-search");
    }

    const onRescheduledTicketReportClick=()=>{
        history.push("/report/rescheduledTicketreport");
    }

    const onMasterTripSheetReport=()=>{
        history.push("/report/mastertripsheetreport");
    }

    const onClickSmsReport=()=>{
        history.push("/report/smsreport");
    }
    
    const performanceReport=()=>{
        history.push("/report/performancereport");
    }

    const totalTransactionReport=()=>{
        history.push("/report/totaltransactionreport");
    }

    const cancelledSeatsReportClick=()=>{
        history.push("/report/cancelledticketreport");
    }

    const viewAbandonedReportClick=()=>{
        history.push("/report/abandonedreport");
    }

    const webTicketSalesReportClick=()=>{
        history.push("/report/webticketsalesreport");
    }

    const webTicketCancelReportClick=()=>{
        history.push("/report/webticketcancelreport");
    }

    const onClickTicketsBookedAfterTripStartTime=()=>{
        history.push("/report/ticketbookedafterdeparture");
    }

    const onClickSettlementReport=()=>{
        history.push("/report/settlementreport");
    }

    const onClickGstFilingReport=()=>{
        history.push("/report/gstfilingreport");
    }

    const onClickDemandDataReport=()=>{
        history.push("/report/demandDataReport");
    }

    const onClickServiceCountReport=()=>{
        history.push("/report/viewServiceCountReport");
    }

    const onClickCostSummaryReport=()=> {
        history.push("/report/costSummaryReport")
    }

    const onClickTransactionSummaryReport=()=> {
        history.push("/report/transactionSummaryReport")
    }

    const onClickTrackingSummaryReport=() => {
        history.push("/report/trackingSummaryReport")
    }

    const onClickAgentTransactionsPage = () =>{
        history.push("/accounts/agent-transactions");
    }

    const onClickAgentTripSheetPermissionPage = () =>{
        history.push("/accounts/trip-sheet-permission");
    }

    const onClickAgentsPage = () =>{
        history.push("/accounts/agents");
    }

    const tripCreatePage = () =>{
        dispatch(resetTripCreate());
        history.push("/trip-create");
    }

    const popularRoutesPage = () =>{
        history.push("/admin/popular-routes");
    }

    const userActionsPage = () =>{
        history.push("/admin/user-actions");
    }

    const operatorConfigClick = () =>{
        history.push("/admin/operatorconfig");
    }

    const colourConfigClick = () =>{
        history.push("/admin/colourconfig");
    }

    const onClickCitiesPage = () =>{
        history.push("/admin/cities");
    }

    const onClickAddressBookPage = () =>{
        history.push("/admin/addressbook");
    }


    const openAdminTripsPage=()=>{
        window.open(`${adminUrl}/trips`);
    }


    const openSchedulesPage=()=>{
        history.push("/admin/schedule");
    }

    const openAdminAgentsPage=()=>{
        window.open(`${adminUrl}/agents`);
    }

    const openAdminVechicleManagementPage=()=>{
        window.open(`${adminUrl}/vechicle-managemment`);
    }

    const staffClick =()=>{
        history.push("/staff");
    }


    const onClickNewTrip =()=>{
        history.push("/new-trip")
    }

    const onClickStandardStops =()=>{
        history.push("/standard-stops")
    }

    const onClickTicketSharing =()=>{
        history.push("/report/ticketsharingreport")
    }

    const onClickRevenue =()=>{
        history.push("/report/revenuereport")
    }

    const onClickTransactions =()=>{
        history.push("/report/prepaidtransactions")
    }

    const onPrepaidOTADashboardClick = () => {
        history.push("/prepaid-ota")
    }


    const navigateToHome=()=>{
        history.push("/home");
    }

    const preventDefault=(event)=>{
            event.preventDefault();
    }

    const renderMenuTxt=(txt,path)=>{
        return(
        <a href={path} onClick={preventDefault} target="_blank">{txt}</a>
        )
    }

    const onClickTemporaryChanges = () => {
        history.push("/admin/temporary-changes");
    }

    if(runSplashScreen){
        return (
        <div className="menu-heading-title">
            <img src={getLogoUrl()} style={logoStyle} />
        </div>
        )
    }
    if(isMobile){
        return (
            <Row className="menu-heading-title" justify="space-between">
                <Col>
                    <Dropdown 
                    onVisibleChange={onVisibleChange}
                    visible={visibleMenu}
                    overlay={<MenuMobile 
                        userName={userName}
                        onClick={onMenuItemClick}
                        onTicketSearchReportClick={onTicketSearchReportClick}
                        onTicketSearchV2ReportClick={onTicketSearchV2ReportClick}
                        onRescheduledTicketReportClick = {onRescheduledTicketReportClick}
                        onMasterTripSheetReport={onMasterTripSheetReport}
                        onClickSmsReport={onClickSmsReport}
                        onViewPassengerInfoReportClick={onViewPassengerInfoReportClick}
                        onSalesReportClick={onSalesReportClick}
                        onBookedTicketsClick={onBookedTicketsClick}
                        onTripSheetClick={onTripSheetClick}
                        notificationClick={notificationClick}
                        onBlockedReportClick={onBlockedReportClick}
                        logout={logout}
                        changePassword={changePassword}
                        viewCancelClick={viewCancelClick}
                        searchClick={searchClick}
                        busCancelClick={busCancelClick}
                        performanceReport={performanceReport}
                        totalTransactionReport={totalTransactionReport}
                        cancelledSeatsReportClick={cancelledSeatsReportClick}
                        viewAbandonedReportClick={viewAbandonedReportClick}
                        webTicketSalesReportClick={webTicketSalesReportClick}
                        webTicketCancelReportClick={webTicketCancelReportClick}
                        onClickTicketsBookedAfterTripStartTime={onClickTicketsBookedAfterTripStartTime}
                        onClickAgentTransactionsPage={onClickAgentTransactionsPage}
                        onClickAgentTripSheetPermissionPage={onClickAgentTripSheetPermissionPage}
                        onClickAgentsPage={onClickAgentsPage}
                        onClickSettlementReport={onClickSettlementReport}
                        onClickGstFilingReport={onClickGstFilingReport}
                        onClickDemandDataReport={onClickDemandDataReport}
                        onClickServiceCountReport={onClickServiceCountReport}
                        onClickCostSummaryReport={onClickCostSummaryReport}
                        onClickTransactionSummaryReport = {onClickTransactionSummaryReport}
                        onClickTrackingSummaryReport = {onClickTrackingSummaryReport}
                        openAdminTripsPage={openAdminTripsPage}
                        onClickCitiesPage={onClickCitiesPage}
                        openSchedulesPage={openSchedulesPage}
                        openAdminAgentsPage={openAdminAgentsPage}
                        openAdminVechicleManagementPage={openAdminVechicleManagementPage}
                        staffClick={staffClick}
                        tripCreatePage = {tripCreatePage}
                        popularRoutesPage = {popularRoutesPage}
                        userActionsPage = {userActionsPage}
                        operatorConfigClick = {operatorConfigClick}
                        colourConfigClick = {colourConfigClick}
                        onClickNewTrip = {onClickNewTrip}
                        onClickStandardStops = {onClickStandardStops}
                        onClickTicketSharing = {onClickTicketSharing}
                        onClickRevenue = {onClickRevenue}
                        onClickTransactions = {onClickTransactions}
                        dashboardClick={dashboardClick}
                        onPrepaidOTADashboardClick = {onPrepaidOTADashboardClick}
                        onClickAddressBookPage = {onClickAddressBookPage}
                        serviceAccessPage = {serviceAccessPage}
                        checkToViewServiceAccessPage = {checkToViewServiceAccessPage}
                        onClickTemporaryChanges = {onClickTemporaryChanges}
                        />} trigger={['click','hover']}>
                            <MenuOutlined />
                    </Dropdown>
                </Col>     
                <Col>
                    <Button 
                    type="text" 
                    className="outline-button"  
                    onClick={navigateToHome}>
                        <img src={getLogoUrl()} style={logoStyle} />
                    </Button>
                </Col>     
            </Row>
        )
    }
    
    return (
        <Row className="menu-heading-title" justify="space-between">
            <Col>
                <Button 
                type="text" 
                className="outline-button"  
                onClick={navigateToHome}>
                    <img src={getLogoUrl()} style={logoStyle} />
                </Button>
            </Col>     
            <Col flex="auto">
                <MenuWeb 
                    onClickAddressBookPage={onClickAddressBookPage}
                    onTicketSearchReportClick={onTicketSearchReportClick}
                    onTicketSearchV2ReportClick = {onTicketSearchV2ReportClick}
                    onRescheduledTicketReportClick = {onRescheduledTicketReportClick}
                    onMasterTripSheetReport={onMasterTripSheetReport}
                    onClickSmsReport={onClickSmsReport}
                    onViewPassengerInfoReportClick={onViewPassengerInfoReportClick}
                    onSalesReportClick={onSalesReportClick}
                    onBookedTicketsClick={onBookedTicketsClick}
                    onTripSheetClick={onTripSheetClick}
                    onActiveBookingsClick={onActiveBookingsClick}
                    notificationClick={notificationClick}
                    logout={logout}
                    changePassword={changePassword}
                    viewCancelClick={viewCancelClick}
                    searchClick={searchClick}
                    busCancelClick={busCancelClick}
                    onBlockedReportClick={onBlockedReportClick}
                    performanceReport={performanceReport}
                    totalTransactionReport={totalTransactionReport}
                    cancelledSeatsReportClick={cancelledSeatsReportClick}
                    viewAbandonedReportClick={viewAbandonedReportClick}
                    webTicketSalesReportClick={webTicketSalesReportClick}
                    webTicketCancelReportClick={webTicketCancelReportClick}
                    onClickTicketsBookedAfterTripStartTime={onClickTicketsBookedAfterTripStartTime}
                    onClickAgentTransactionsPage={onClickAgentTransactionsPage}
                    onClickAgentTripSheetPermissionPage={onClickAgentTripSheetPermissionPage}
                    onClickAgentsPage={onClickAgentsPage}
                    onClickSettlementReport={onClickSettlementReport}
                    onClickGstFilingReport={onClickGstFilingReport}
                    onClickDemandDataReport={onClickDemandDataReport}
                    onClickServiceCountReport={onClickServiceCountReport}
                    onClickCostSummaryReport={onClickCostSummaryReport}
                    onClickTransactionSummaryReport = {onClickTransactionSummaryReport}
                    onClickTrackingSummaryReport = {onClickTrackingSummaryReport}
                    openAdminTripsPage={openAdminTripsPage}
                    onClickCitiesPage={onClickCitiesPage}
                    openSchedulesPage={openSchedulesPage}
                    openAdminAgentsPage={openAdminAgentsPage}
                    openAdminVechicleManagementPage={openAdminVechicleManagementPage}
                    staffClick={staffClick}
                    tripCreatePage= {tripCreatePage}
                    popularRoutesPage = {popularRoutesPage}
                    userActionsPage = {userActionsPage}
                    operatorConfigClick = {operatorConfigClick}
                    colourConfigClick = {colourConfigClick}
                    onClickNewTrip = {onClickNewTrip}
                    onClickStandardStops = {onClickStandardStops}
                    onClickTicketSharing = {onClickTicketSharing}
                    onClickRevenue = {onClickRevenue}
                    onClickTransactions = {onClickTransactions}
                    dashboardClick={dashboardClick}
                    onPrepaidOTADashboardClick={onPrepaidOTADashboardClick}
                    onClickTemporaryChanges = {onClickTemporaryChanges}
                />
            </Col> 
            <Col>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]} breakpoint="lg" collapsedWidth="0">
                    <SubMenu 
                    key="profile"
                    icon={<Icon component={() => (<img src={userIcon} className='user-icon' />)} />}>
                         {checkToViewUserActions()&&
                            <Menu.Item key= "/user-actions" onClick= {userActionsPage}>
                            {renderMenuTxt("Roles & Permissions","/user-actions")} 
                            </Menu.Item>
                        }
                        {checkToViewTripSheetPermissionPage()&&
                            <Menu.Item key="/accounts/trip-sheet-permission" onClick={onClickAgentTripSheetPermissionPage}>
                            {renderMenuTxt("Trip Sheet Permissions","/accounts/trip-sheet-permission")}
                            </Menu.Item>
                        }  
                        {checkToViewServiceAccessPage()&&   
                            <Menu.Item key="/users/service-access" onClick={serviceAccessPage}>
                            {renderMenuTxt("Service Access","/users/service-access")}
                            </Menu.Item>
                        }
                         {checkAgentsPage()&&
                            <Menu.Item key="/accounts/agents" onClick={onClickAgentsPage}>
                            {renderMenuTxt("Agent Wallets","/accounts/agents")}
                            </Menu.Item>
                        }
                        {checkAgentsTransactionPage()&&
                            <Menu.Item key="/accounts/agent-transactions" onClick={onClickAgentTransactionsPage}>
                            {renderMenuTxt("Pre-paid Agent Ledger","/accounts/agent-transactions")}
                            </Menu.Item>
                        }
                        <Divider />
                        <Menu.Item key="/change-password" onClick={changePassword}>
                            {renderMenuTxt("Change Password","/change-password")} 
                        </Menu.Item>
                        <Menu.Item key="/log-out" onClick={logout}>Logout</Menu.Item>
                    </SubMenu>
                </Menu>
            </Col>
            <Col>
                <Button className="header-version">{userName+" "+WEB_RELEASE_VERSION}</Button>
            </Col>           
        </Row>
    );
}