import React, { useEffect, useState ,useContext } from 'react';
import { Form, Input, Row , Col , Radio , Select , Checkbox , Typography, DatePicker , TimePicker, message, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { blockedSeatTicketIdSelector, passengerInfoSelector, extraSeatInfoSelector } from './../../selector/passenger-form.selector';
import { useDispatch, useSelector } from 'react-redux';
import { isValidArray,getValuesOfJSonObject,isValid, isValidString, isValidArrayWithValues } from '../../utils/utilities';
import { getTimeStampInReadableFormat , convertDateStringToDate , getLocalDate , incrementDays , getUIDateFormat} from './../../utils/date.utils';
import {getDetailsToBookTicket,getTicketFare , PassengerSeatsTable , getAnyBlockedSeatsExit } from './passenger-form.module';
import './passenger-form.style.scss';
import { bookTicket,blockTicket , onPassengerFormChange} from './../../actions/home.action';
import { CustomModal } from './../custom-components/custom-component';
import { ACTION_BOOK_TICKET_ALLOWED , ACTION_BLOCK_TICKET_ALLOWED ,ACTION_PHONE_BLOCK_ALLOWED, ACTION_TO_UPDATE_AGENT_IN_TICKET, ACTION_TO_VIEW_SET_GENDER_BTN, ACTION_UPDATE_TICKET_REMARKS} from './../../utils/constant';
import { checkActionAllowed } from './../../utils/utilities';
import { ReleaseSeatsComponent } from './../release-seats/release-seats.component';
import { resetPassengerForm } from './../../actions/ticket.action';
import { BlockByAdminComponent } from './../block-by-admin/block-by-admin.component';
import { DeviceContext } from './../../components/device-provider/device-provider';
import { addMin ,setTimeToDateObject , getAPIDateFormat , getTimeFormat , getLocalDateAndTime} from './../../utils/date.utils';
import moment from 'moment';
import { SeatPriceComponent } from './../seat-price-component/seat-price.component';
import {
    AddDiscount,
    EditDiscount
} from './../discount-component/discount.component';
import { getAgentsByOperatorCode } from '../../actions/agents.actions';
import { ChangeGenderReservation } from '../change-gender-reservation/change-gender-reservation';
import { CopyPaymentLink } from '../copy-payment-link/copy-payment-link.component';
import { onExtraSeatFormChangeAction, updateExtraSeatCount } from '../../actions/passenger-form.action';

const {
    Text
} = Typography;

let blockButtonType="Block";
let bookButtonType="Book";
let phoneBlock="Phone block";
let sendPaymentLink ="Send payment link";


const { Option } = Select;

const DrawLine =({className})=>{

    return(
        <div className={className}>

        </div>
    )
}


const getDropPointsDate=(selectedSchedule,selectedDate)=>{
    let destinationDayNo=0;
    if(selectedSchedule.destination_day>selectedSchedule.origin_day){
        destinationDayNo = selectedSchedule.destination_day - selectedSchedule.origin_day;
    }
    let incrementDate = incrementDays(selectedDate,destinationDayNo);
    return incrementDate;   
}

const optionValueText = (location,time,incrementDate=null) =>{
    return (
        <Row style= {{whiteSpace: 'break-spaces'}}>
            <Col span={14} >
                {location}
            </Col>
            <Col span={10} >
                {isValid(incrementDate)?time+" ("+incrementDate + ")":time}
            </Col>
        </Row>
    )
}

const getPointValue=(value,landmark)=>{
    return (<div>
                {value}
                {isValid(landmark)?
                <span style={{fontSize:11}}>
                    {landmark}
                </span>
                :null
                }
            </div>);
}

const renderBoardingsPoints=(boardingPoints,selectedDate)=>
{
    let boardingPointsArray=[] , nextDayBoardingPoints=[];
    if(isValidArray(boardingPoints)&&boardingPoints.length>0){
        boardingPoints.forEach((element,index) => {
            let desc= element.location +" " +getTimeStampInReadableFormat(element.timing);
            let timing= getTimeStampInReadableFormat(element.timing);
            if(isValid(element)){
                if(element.origin_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(selectedDate,element.origin_day));
                    let value = optionValueText(element.location,timing,incrementDate)
                    desc = desc +" ("+incrementDate + ")";
                    nextDayBoardingPoints.push(<Option searchValue = {desc} value={element.id} className={[(index+1)%2==0?'point-even':'point-odd', element.pointPassed&&"passed-point"]}>
                            {getPointValue(value,element.landmark)}
                    </Option>);
                }else{
                    let value = optionValueText(element.location,timing)
                    boardingPointsArray.push(<Option searchValue = {desc} value={element.id} className={[(index+1)%2==0?'point-even':'point-odd', element.pointPassed&&"passed-point"]}>
                            {getPointValue(value,element.landmark)}
                    </Option>);
                }   
            }
        });
    }
    return boardingPointsArray.concat(nextDayBoardingPoints);
}



const renderDropPoints=(dropPoints,selectedDate,selectedSchedule)=>
{
    let dropDate = selectedDate;
    dropDate=getDropPointsDate(selectedSchedule,selectedDate);
    let dropPointsArray=[];
    if(isValidArray(dropPoints)&&dropPoints.length>0){
        dropPoints.forEach((element,index) => {
            let desc= element.location +" " +getTimeStampInReadableFormat(element.timing);
            let timing= getTimeStampInReadableFormat(element.timing);
            if(isValid(element)){
                if(element.destination_day>0||selectedSchedule.destination_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(dropDate,element.destination_day));
                    let value = optionValueText(element.location,timing,incrementDate)
                    desc = desc +" ("+incrementDate + ")";
                    dropPointsArray.push(<Option searchValue = {desc} value={element.id} className={(index+1)%2==0?'point-even':'point-odd'}>
                        {getPointValue(value,element.landmark)}
                    </Option>);
                }else{
                    let value = optionValueText(element.location,timing)
                    dropPointsArray.push(<Option searchValue = {desc} value={element.id} className={(index+1)%2==0?'point-even':'point-odd'}>
                        {getPointValue(value,element.landmark)}
                    </Option>)
                }
            }        
        });
    }
    return dropPointsArray;
}

export const PassengerForm = (props) => {

    const{
        selectedSchedule,
        fetchSeats ,
        stopPoint,
        selectedDate,
        onFareUpdateSuccess,
        setSelectedTripRoute
    } = props;

  const [form] = Form.useForm();
  let defalutTime = addMin(null,12).format('HH:mm:ss');
  let defalutDate = moment().utcOffset("+05:30");
  const [releaseDate , setReleaseDate] = useState(defalutDate);
  const [releaseTime , setReleaseTime] = useState(defalutTime);
  const [disableSetPrice , setDisableSetPrice] = useState(false);

  const dispatch=useDispatch();

  const {
    isDesktop
    }=useContext(DeviceContext);

  const {
      selectedSeats,
      emailId,
      phoneNumber,
      boardingId,
      dropId,
      timeOfRelease
    }=useSelector(passengerInfoSelector);

    const {
        extraSeatCount,
        extraSeatInfoList
    } = useSelector(extraSeatInfoSelector);

    const ticketId = useSelector(blockedSeatTicketIdSelector)

    let isPhoneBlockSeat= isValidString(timeOfRelease)?true:false;
    let isSendPhonePaymentLink = global.send_payment_link?true:false;
    
  const [seats,setSeats]=useState([]);
  const [formValues , setFormValues]=useState({});
  const [copyPassenger,setCopyPassengerInfo]=useState(false);
  const [boardingDropManditory,setBoardingDropManditory]=useState(true);
  const [discountValue,setDiscountValue]=useState(0);
  const [agentsList, setAgentsList] = useState({});
  const [agentCategories, setAgentCategories] = useState({
    'Self': [],
    'Online Agent': [],
    'Offline Agent': [],
    'Branch': []
  });
  const [selectedCategory, setSelectedCategory] = useState('Self');
  const [showPassengerDetails, setShowPassengerDetails] = useState(true);
  const [hideGenderFromBooking, setHideGenderFromBooking] = useState(false);
  const [hideAgeFromBooking, setHideAgeFromBooking] = useState(false);
  const [hideEmailFromBooking, setHideEmailFromBooking] = useState(false);

  const updateAgentList = (agentData) => {
    let categorizedAgents = {
        'Self': [],
        'Online Agent': [],
        'Offline Agent': [],
        'Branch': []
    };

    agentData.forEach((data) => {
        let privilege = data.privilege.toLowerCase();

        if (privilege === 'offline agent') {
            categorizedAgents['Offline Agent'].push(data);
        } else if (privilege.includes('office')) {
            categorizedAgents['Branch'].push(data);
        } else if (privilege.includes('agent')) {
            categorizedAgents['Online Agent'].push(data);
        }
    });

    setAgentCategories(categorizedAgents);
    setSelectedCategory('Self');
    setAgentsList(null);
  }

  let isAnyBlockedSeatExits=getAnyBlockedSeatsExit(seats);

  useEffect(() => {
      if (checkActionAllowed(ACTION_TO_UPDATE_AGENT_IN_TICKET)){
        getAgentsByOperatorCode(updateAgentList,(err)=> {console.log("Error in fetching agents by operator code", err)});
      }
      else {
          setAgentsList(null);
      }
      setHideGenderFromBooking(global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING']==1);
      setHideAgeFromBooking(global.travelsAppConfig['HIDE_AGE_FROM_BOOKING']==1);
      setHideEmailFromBooking(global.travelsAppConfig['HIDE_EMAIL_FROM_BOOKING']==1);
  },[])

  useEffect(()=>{
    if(isValid(timeOfRelease)){
        setReleaseDate(moment(getAPIDateFormat(timeOfRelease)));
        setReleaseTime(getTimeFormat(timeOfRelease));
    }else{
        setReleaseDate(defalutDate);
        setReleaseTime(defalutTime);
    }
  },[timeOfRelease]);

  useEffect(() => {
    if(!isValidArray(selectedSchedule)||!isValidArray(selectedSchedule.trip)||!isValid(selectedSchedule.trip.id)||!isValidArrayWithValues(seats)){
        setShowPassengerDetails(false);
    }
    else {
        setShowPassengerDetails(true);
    }
  }, [selectedSchedule, seats])

  useEffect(()=>{
      let seatsArray=getValuesOfJSonObject(selectedSeats);
      setSeats(seatsArray);
      let passengerDetails = seatsArray.map(seat => ({
        ...seat,
        gender: seat.gender || 'M'
      }));
    form.setFieldsValue({
        passengerDetails:passengerDetails
    });


    if(seatsArray.length === 0){
        setDisableSetPrice(false);
    }
  },[selectedSeats])

  useEffect(()=>{
    if(isValidArrayWithValues(extraSeatInfoList)) {
        form.setFieldsValue({
            extraSeatDetails:extraSeatInfoList
        });
    }
  },[extraSeatCount])

  useEffect(()=>{
    form.setFieldsValue({
        emailId:emailId,
        phoneNumber:phoneNumber,
        boardingPointId:boardingId,
        dropPointId:dropId

    })
  },[emailId,phoneNumber,boardingId,dropId])


  const handleSubmit =  (e , buttonType) => {
    e.preventDefault();
    if(buttonType===bookButtonType||buttonType===sendPaymentLink){
        setBoardingDropManditory(true);
    }else{
        setBoardingDropManditory(false);
    }
    return form.validateFields()
    .then(values => {
        setFormValues(values);
      return true;
    }).catch((err)=>{
      return false;
    })
}

const onSuccess=()=>{
    fetchSeats(selectedSchedule.trip_route_id);
    resetForm();
    setSelectedTripRoute(selectedSchedule.trip_route_id)
    dispatch(resetPassengerForm());
    setDiscountValue(0);
}

const onBookFailure = (loadSeats) => {
    if (loadSeats) {
        fetchSeats(selectedSchedule.trip_route_id);
    }
}

const bookAction=()=>{
    form.validateFields()
    .then(values => {
        dispatch(bookTicket(getDetailsToBookTicket(values,selectedSchedule,null,false,discountValue,ticketId,agentsList),onSuccess, onBookFailure));
    })
    .catch((err)=>{
        console.log(err);
    })
}

const blockAction =(buttonType)=>{
    let datetime = null ;
    let isSendPaymentLink = false;
    if((buttonType === phoneBlock || buttonType === sendPaymentLink) && isValid(releaseDate)){
        datetime =  setTimeToDateObject(releaseDate,releaseTime) ; 
        if(buttonType === sendPaymentLink){
            isSendPaymentLink = true;
        }
    }
    form.validateFields()
    .then(values => {
        dispatch(blockTicket(getDetailsToBookTicket(values,selectedSchedule,datetime , isSendPaymentLink,discountValue,ticketId,agentsList),onSuccess));
    })
    .catch((err)=>{
        console.log(err);
    })
}

const resetForm=()=>{
    form.setFieldsValue({
        passengerDetails:[],
        phoneNumber:null,
        emailId:null,
        boardingPointId:null,
        dropPointId:null
    })
}

const onFormChange=({
    fullName,
    age,
    gender,
    seatId
})=>{
    dispatch(onPassengerFormChange({
        fullName,
        age,
        gender,
        seatId
    }))
}

const onExtraSeatFormChange = (data) => {
    dispatch(onExtraSeatFormChangeAction({...data}));
}

const ConfirmBody=({isShowMins})=>{
    return (
        <div>
            <Row>
                <h3>
                    Confirm Booking
                </h3>
            </Row>
            <Row>
                <DrawLine className={"confirm-booking-underline marginBottom"}/>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>Travel Date : </Text>
                        {getLocalDate(convertDateStringToDate(selectedSchedule.trip.start_date.split("T")[0]))}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>Trip Description : </Text>
                        {selectedSchedule.description}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>No of seats : </Text>
                        {seats.length}
                    </Text>
                </Col>
            </Row>
            {isValid(global.travelsAppConfig['EXTRA_SEAT_BOOKING'])&&global.travelsAppConfig['EXTRA_SEAT_BOOKING']==1&&checkActionAllowed('EXTRA_SEATS_BOOKING_ALLOWED')&&
                <Row>
                    <Col>
                        <Text>
                            <Text strong>No of extra seats : </Text>
                            {extraSeatCount}
                        </Text>
                    </Col>
                </Row>
            }
            <div>
                <PassengerSeatsTable 
                    formValues={formValues}
                    boardingPoints={stopPoint.pickupDetails}
                    discountValue={discountValue}
                    bus={selectedSchedule.bus}
                />
            </div>
            {isShowMins &&
                <div className='margin'>
                    <DatePicker 
                     className='marginLeft marginTop'
                     placeholder="Select date"
                     defaultValue={defalutDate}
                     value={releaseDate}
                     format="DD-MM-YYYY"
                     onChange={(date)=>{
                         if(isValid(date)){
                            setReleaseDate(date);
                         }else{
                            setReleaseDate(moment().utcOffset("+05:30"));
                         }
                     }}
                    />
                    <TimePicker 
                    className='marginLeft marginTop remove-clear-icon'  
                    placeholder='"Select time'
                    defaultValue={moment(defalutTime, 'HH:mm:ss')}
                    value={moment(releaseTime, 'HH:mm:ss')}
                    use12Hours 
                    format="h:mm A" 
                    onChange={(time,timeString)=>{
                        if(isValid(time)){
                            setReleaseTime(time.format('HH:mm:ss'));
                        }else{
                            setReleaseTime('00:00:00');
                        }
                    }} 
                    style={{ width: 140 }}
                    />
                </div>
            }
            {checkActionAllowed(ACTION_TO_UPDATE_AGENT_IN_TICKET) && (
                <Row className="marginTop" gutter={[16,16]}>
                    <Col span={10}>
                        <Form.Item
                            label={<strong>Book as</strong>}
                            rules={[{ required: false, message: 'Please select category' }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                value={selectedCategory}
                                placeholder="Select Category"
                                onChange={(value) => {
                                    setSelectedCategory(value);
                                    if (value === 'Self') {
                                        setAgentsList(null);
                                        form.setFieldsValue({ agentId: global.agentId });
                                    } else {
                                        setAgentsList(agentCategories[value]);
                                        form.setFieldsValue({ agentId: null });
                                    }
                                }}
                                bordered={true}
                                style={{minWidth: '150px'}}
                            >
                                {Object.keys(agentCategories).map(category => (
                                    <Option key={category} value={category}>{category}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {selectedCategory !== 'Self' && (
                        <Col span={12}>
                            <Form.Item
                                label={<strong>Select User</strong>}
                                rules={[{ required: false, message: 'Please select an agent' }]}
                                name="agentId"
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    defaultValue={global.agentId}
                                    placeholder="Select User"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    bordered={true}
                                    disabled={!selectedCategory || selectedCategory === 'Self'}
                                    style={{minWidth: '150px'}}
                                >
                                    {agentsList.map(agent => (
                                        <Option key={agent.id} value={agent.id}>{agent.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    )
}

let {
    baseFare,
    cgst,sgst,
    discountedCostWithoutGst,
    totalCost
} = getTicketFare(seats,discountValue,selectedSchedule.bus, extraSeatInfoList);
   
let isDiscountExits=baseFare>discountedCostWithoutGst?true:false;

if (!showPassengerDetails&&extraSeatCount<1) {
    return null
}
  return (
    <div>
        {showPassengerDetails&&<><h3>Passenger Details</h3>
        <DrawLine className={"passenger-details-underline"}/>
        <div className='copy-same-name-txt'>
            <Checkbox 
            value={copyPassenger}
            onChange={(e)=>{
                setCopyPassengerInfo(e.target.checked);
                if(e.target.checked){
                    let passengerDetails=form.getFieldValue('passengerDetails');
                    for (let index = 1; index < passengerDetails.length; index++) {
                        passengerDetails[index].fullName=passengerDetails[0].fullName;
                        passengerDetails[index].age=passengerDetails[0].age;
                    }
                    form.setFieldsValue({
                        passengerDetails:[...passengerDetails]
                    })
                }
            }}
            >Copy same name and age</Checkbox>
        </div></>}
        <Form
        form={form}
        layout="vertical"
        initialValues={{ 
            passengerDetails: [], 
            extraSeatDetails: [],
            'passengerDetails[0].gender': 'M',
            'passengerDetails[1].gender': 'M',
            'passengerDetails[2].gender': 'M',
            'passengerDetails[3].gender': 'M',
            'passengerDetails[4].gender': 'M'
        }}
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const inputs = Array.from(document.querySelectorAll('input:not([type="radio"]), input[type="radio"]:checked, select'));
                const index = inputs.indexOf(document.activeElement);
                if (index > -1 && index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }
            }
        }}
        onValuesChange={(changedValues,allValues)=>{
            let allPassengerDetails=allValues.passengerDetails;
            let changedPassengerDetails=changedValues.passengerDetails;
            if(isValidArrayWithValues(allPassengerDetails)&&copyPassenger&&isValidArray(changedPassengerDetails)){
                for (let index = 0; index < changedPassengerDetails.length; index++) {
                    if(isValidArray(changedPassengerDetails[index])){
                        let {
                            fullName,
                            age
                        }=changedPassengerDetails[index];
                        for (let j = 0; j < allPassengerDetails.length; j++) {
                            if(isValid(fullName)){
                                allPassengerDetails[j].fullName=fullName;
                            }else if(isValid(age)){
                                allPassengerDetails[j].age=age;
                            }    
                        }
                        break;
                    }
                }
                form.setFieldsValue({
                    passengerDetails:[...allPassengerDetails]
                });
            }
            setDisableSetPrice(true);
        }}
        >
            {showPassengerDetails&&<>
            <Form.List name="passengerDetails">
            {(fields, { add, remove }) => (
                <>
            {fields.map(({ key, name, id,fieldKey, ...restField }) => {
                let isShowLabel = key==0&&isDesktop?true:false;
                return(
                    <div className="passenger-block">
                        <Row gutter={[16,16]}>
                            <Col>
                                <Form.Item
                                    label={isShowLabel?<strong>Seat #</strong>:null}
                                    {...restField}
                                    name={[name, 'seatNumber']}
                                    fieldKey={[fieldKey, 'seatNumber']}
                                    rules={[{ required: false, message: 'Please enter seat number' }]}
                                >
                                    <Input placeholder="Seat #" disabled={true} className="seatNumberInput" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={isShowLabel?<strong>Passenger name</strong>:null}
                                    {...restField}
                                    name={[name, 'fullName']}
                                    fieldKey={[fieldKey, 'fullName']}
                                    rules={[{ 
                                        required: boardingDropManditory,
                                        message: '',
                                        pattern: new RegExp(/^[a-zA-Z0-9\s\.]*$/)
                                    }]}
                                    tooltip="Only English alphabets, numbers and space are allowed"
                                >
                                    <Input 
                                    placeholder="Passenger Name" 
                                    className="passengerNameInput" 
                                    onChange={(e)=>{
                                        onFormChange({fullName:e.target.value,seatId:seats[key].id})
                                    }}
                                    />
                                </Form.Item>
                            </Col>
                            {!hideGenderFromBooking&&<Col>
                                <Form.Item
                                    {...restField}
                                    label={isShowLabel?<strong>Gender</strong>:null}
                                    name={[name, 'gender']}
                                    fieldKey={[fieldKey, 'gender']}
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        placeholder="Gender"
                                        onChange={(value)=>{
                                            onFormChange({gender:value,seatId:seats[key].id})
                                        }}
                                        style={{ width: 100 }}
                                        defaultValue="M"
                                    >
                                        <Option value="M">Male</Option>
                                        <Option value="F">Female</Option>
                                    </Select>
                                </Form.Item>
                            </Col>}
                            {!hideAgeFromBooking&&<Col>
                                    <Form.Item
                                        {...restField}
                                        label={isShowLabel?<strong>Age</strong>:null}
                                        name={[name, 'age']}
                                        fieldKey={[fieldKey, 'age']}
                                        rules={[{
                                            required: boardingDropManditory, 
                                            pattern: new RegExp(/^[0-9]*$/),
                                            message: '' }]}
                                        tooltip="Only numbers are allowed"
                                        >
                                        <Input placeholder={"Age"}  className="ageInput"
                                          onChange={(e)=>{
                                            onFormChange({age:e.target.value,seatId:seats[key].id})
                                        }}
                                        />
                                    </Form.Item>
                            </Col>}
                            <Col>
                                <Form.Item
                                    label={isShowLabel?<strong>Fare</strong>:null}
                                    {...restField}
                                    name={[name, 'discountedCostWithoutGst']}
                                    fieldKey={[fieldKey, 'discountedCostWithoutGst']}
                                    rules={[{ required: false, message: 'Please enter seat number' }]}
                                >
                                    <Input placeholder="fare" disabled={true} className="fareInput" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    )})}
                </>
                )}
            </Form.List>
            </>}
            {isValid(global.travelsAppConfig['EXTRA_SEAT_BOOKING'])&&global.travelsAppConfig['EXTRA_SEAT_BOOKING']==1&&checkActionAllowed('EXTRA_SEATS_BOOKING_ALLOWED')&&extraSeatCount>0&&
            <div>
                <h3>Extra Seat Details</h3>
                <Form.List name="extraSeatDetails">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, id, fieldKey, ...restField}) => {
                                let isShowLabel = key==0&&isDesktop?true:false;
                                return (
                                    <div>
                                        <Row gutter={[16,16]}>
                                            <Col>
                                                <Form.Item
                                                    label={isShowLabel?<strong>Name</strong>:null}
                                                    {...restField}
                                                    name={[name, 'extra_fullName']}
                                                    fieldKey={[fieldKey, 'extra_fullName']}
                                                    rules={[{
                                                        required: true,
                                                        message: '',
                                                        pattern: new RegExp(/^[a-zA-Z0-9\s\.]*$/)
                                                    }]}
                                                    tooltip="Only English alphabets, numbers and space are allowed"
                                                >
                                                    <Input
                                                        placeholder="Name"
                                                        className="passengerNameInput"
                                                        onChange={(e)=>{
                                                            onExtraSeatFormChange({extra_fullName:e.target.value, extra_index: name})
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {!hideGenderFromBooking&&<Col>
                                                <Form.Item
                                                    {...restField}
                                                    label={isShowLabel?<strong>Gender</strong>:null}
                                                    name={[name, 'extra_gender']}
                                                    fieldKey={[fieldKey, 'extra_gender']}
                                                    rules={[{ required: false, message: '' }]}
                                                >
                                                    <Radio.Group
                                                        onChange={(e)=>{
                                                            onExtraSeatFormChange({extra_gender:e.target.value, extra_index: name})
                                                        }}
                                                    >
                                                        <Radio value="M">M</Radio>
                                                        <Radio value="F">F</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>}
                                            {!hideAgeFromBooking&&<Col>
                                                <Form.Item
                                                    label={isShowLabel?<strong>Age</strong>:null}
                                                    name={[name, 'extra_age']}
                                                    fieldKey={[fieldKey, 'extra_age']}
                                                    rules={[{
                                                        required: false,
                                                        pattern: new RegExp(/^[0-9]*$/),
                                                        message: '' }]}
                                                    tooltip="Only numbers are allowed"
                                                >
                                                    <Input
                                                        placeholder="Age"
                                                        className="ageInput"
                                                        onChange={(e)=>{
                                                            onExtraSeatFormChange({extra_age:e.target.value, extra_index: name})
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>}
                                            <Col>
                                                <Form.Item
                                                    label={isShowLabel?<strong>Fare</strong>:null}
                                                    {...restField}
                                                    name={[name, 'extra_fare']}
                                                    fieldKey={[fieldKey, 'extra_fare']}
                                                    rules={[{ required: true, message: 'Please enter fare number' }]}
                                                >
                                                    <InputNumber placeholder="Fare" className="ageInput"
                                                        onChange={(value)=>{
                                                            onExtraSeatFormChange({extra_fare:value, extra_index: name})
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item
                                                    label={isShowLabel?<strong>Remarks</strong>:null}
                                                    {...restField}
                                                    name={[name, 'extra_remarks']}
                                                    fieldKey={[fieldKey, 'extra_remarks']}
                                                    rules={[{ required: true, message: 'Please enter remarks' }]}
                                                >
                                                    <Input placeholder="Remarks"
                                                        className="passengerNameInput"
                                                        onChange={(e)=>{
                                                            onExtraSeatFormChange({extra_remarks:e.target.value, extra_index: name})
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <DeleteOutlined
                                                    style={{marginTop:35, fontSize: 25, color: 'red'}}
                                                    onClick={() => dispatch(updateExtraSeatCount(false,1,name))}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </Form.List>
            </div>
            }
            <Row gutter={16} className="phone-number-block">
                <Col>
                    <Form.Item 
                     label={<strong>Phone no</strong>} 
                     rules={[{ required: boardingDropManditory, message: 'Please enter customer phone no' }]}
                     tooltip="Please enter customer phone no"
                     name="phoneNumber"
                     >
                        <Input placeholder="Phone no" className="defalut-input-style" maxLength={10}/>
                    </Form.Item>
                </Col>
                {!hideEmailFromBooking&&<Col>
                    <Form.Item
                        label={<strong>Email ID</strong>}
                        tooltip="Please enter customer email id"
                        name="emailId"
                    >
                        <Input placeholder="Email ID" className="defalut-input-style"/>
                    </Form.Item>
                </Col>}
            </Row>
            <Row gutter={16}>
                <Col>
                <Form.Item
                    label={<strong>Boarding Point</strong>}
                    name="boardingPointId"
                    tooltip="Please select boarding point"
                    rules={[{ required: boardingDropManditory, message: 'Please select boarding point' }]}
                >
                        <Select
                                size="large"
                                showSearch
                                placeholder="Select a Boarding Point"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                        option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                bordered={true}
                                className="boarding-point-select-style"
                                popupClassName="defalut-select-drop-down"
                                >
                                {renderBoardingsPoints(stopPoint.pickupDetails,selectedDate)}
                        </Select>
                </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        label={<strong>Drop Point</strong>}
                        tooltip="Please select drop point"
                        rules={[{ required: boardingDropManditory, message: 'Please select drop point' }]}
                        name="dropPointId"
                    >
                        <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select a Drop Point"
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    bordered={true}
                                    className="drop-point-select-style"
                                    >
                                    {renderDropPoints(stopPoint.dropDetails,selectedDate,selectedSchedule)}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {global.useRemarks === 1 && checkActionAllowed(ACTION_UPDATE_TICKET_REMARKS) &&
            <Row>
                <Col>
                    <Form.Item 
                        label={<strong>Remarks</strong>} 
                        rules={[
                            { required: false, message: 'Please enter remarks' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || (value.length < 25)) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error(`Remarks cannot be greater than 25 characters.`));
                                },
                              })
                        ]}
                        tooltip="Please enter remarks"
                        name="remarks"
                     >
                        <Input placeholder="Remarks" className="defalut-input-style"/>
                    </Form.Item>
                </Col>
            </Row>
            }
            {isDiscountExits&&
             <Row align="middle">  
                <Col>
                    <Text 
                    // delete={isDiscountExits?true:false}
                    strong                    
                    >Ticket Fare : </Text>{baseFare}
                </Col>
                {discountValue > 0 &&
                    <Col>
                        <AddDiscount 
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                        />
                    </Col>
                }
            </Row>
            }
            {discountValue>0&&
                <Row align='middle'>
                    <Col>
                        <Text strong>Discount : </Text>{discountValue}
                    </Col>
                    <Col>
                        <EditDiscount 
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                        />
                    </Col>
                </Row>
            }
            <Row align='middle'>
                    <Col>
                        <Text strong>
                            {isDiscountExits?"Discounted ":''}Ticket Fare : 
                        </Text>
                        {discountedCostWithoutGst}
                    </Col>
                    {!isDiscountExits&&
                        <Col>
                            <AddDiscount 
                                discountValue={discountValue}
                                setDiscountValue={setDiscountValue}
                            />
                        </Col>
                    }
            </Row>
            <Row>
                <Col>
                    <Text strong>CGST : </Text>{cgst}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text strong>SGST : </Text>{sgst}
                </Col>
            </Row>
            <Row align='middle'>
                <Col>
                    <Text strong>Amount Paid : </Text>{totalCost} 
                </Col>
            </Row>
            <Row gutter={[16,16]}>
                {checkActionAllowed(ACTION_BOOK_TICKET_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={bookButtonType}
                                buttonClassName={"book-button"}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,bookButtonType);
                                }}
                                children={<ConfirmBody buttonType={bookButtonType}/>}
                                onOk={bookAction}
                                okButtonName={bookButtonType}
                            />
                    </Col>
                }
                {checkActionAllowed(ACTION_BLOCK_TICKET_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={blockButtonType}
                                buttonClassName={'block-button'}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,blockButtonType);
                                }}
                                children={<ConfirmBody buttonType={blockButtonType}/>}
                                onOk={blockAction}
                                okButtonName={blockButtonType}
                            />
                    </Col>
                }
                {checkActionAllowed(ACTION_PHONE_BLOCK_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={phoneBlock}
                                buttonClassName={'phone-block'}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,phoneBlock);
                                }}                                
                                children={<ConfirmBody buttonType={phoneBlock} isShowMins={true}/>}
                                onOk={blockAction}
                                okButtonName={phoneBlock}
                            />
                    </Col>
                }
                <Col>
                    <BlockByAdminComponent 
                            selectedSchedule={selectedSchedule}
                            onSuccess={onSuccess}
                            selectedSeats={seats}
                            disableAdminBlock={disableSetPrice}
                    />
                </Col>
                {isAnyBlockedSeatExits&&
                    <Col>
                         <ReleaseSeatsComponent 
                            selectedSchedule={selectedSchedule}
                            onSuccess={onSuccess}
                            selectedSeats={seats}
                         />
                    </Col>
                }
                {checkActionAllowed(ACTION_PHONE_BLOCK_ALLOWED)&&isSendPhonePaymentLink&&
                    <Col>
                             <CustomModal 
                                 buttonName={sendPaymentLink}
                                 buttonClassName={'payment-link-btn'}
                                 onButtonClick={(e)=>{
                                     return handleSubmit(e,sendPaymentLink);
                                 }}                                
                                 children={<ConfirmBody buttonType={sendPaymentLink} isShowMins={true}/>}
                                 onOk={blockAction}
                                 okButtonName={sendPaymentLink}
                             />
                     </Col>
                }
                {checkActionAllowed(ACTION_PHONE_BLOCK_ALLOWED)&&isPhoneBlockSeat?
                    <Col>
                        <CopyPaymentLink />
                    </Col>
                    :null
                }
                {showPassengerDetails&&<Col>
                    <SeatPriceComponent 
                        travelDate={selectedDate}
                        scheduleId={selectedSchedule.schedule_id}
                        bus={selectedSchedule.bus}
                        description={selectedSchedule.description}
                        selectedSeats={seats}
                        onSuccess={(msg)=>{
                            message.success(msg);
                            onFareUpdateSuccess(selectedSchedule.trip_route_id);
                        }}
                        disableSetPrice={disableSetPrice}
                    />
                </Col>}
                {showPassengerDetails&&checkActionAllowed(ACTION_TO_VIEW_SET_GENDER_BTN)?
                <Col>
                    <ChangeGenderReservation 
                        disableSetPrice={disableSetPrice}
                        selectedSeats={seats}
                        tripId={selectedSchedule.trip_id}
                        onSuccess={onSuccess}
                    />
                </Col>
                :null
                }
            </Row>
        </Form>
         <div>
                {isPhoneBlockSeat&&
                    <h3>Release Time : {getLocalDateAndTime(timeOfRelease)}</h3>
                }
        </div>
    </div>
  );
};