import React, { useContext , useState, useEffect} from "react";
import { List , Row , Col , message, Button, Modal, Table, Tag, Input, Select, Space} from "antd";
import { isValidArray , isValid, isValidString, checkActionAllowed, isValidArrayWithValues} from "../../utils/utilities";
import './search-results.style.scss';
import DonutChartComponent from "../donut-chart-component/donut-chart.component";
import { SelectSeatsButton , CardDescription , RouteDetails, RenderNewTime} from './../common-components/search-result-item-card/search-result-item-card.component';
import { useDispatch } from "react-redux";
import { fetchSeatLayout , setSelectedSchedule} from './../../actions/home.action';
import { SeatLayoutContainer } from './../seat-layout/seat-layout-container.component';
import { PassengerForm } from '../passenger-form-component/passenger-form';
import { loadingAction } from './../../actions/loading-actions';
import { RenderFares } from './../common-components/search-result-item-card/search-result-item-card.component';
import { BlockAllSeatsComponent } from './../block-all-seats/block-all-seats.component';
import { PrintTripSheet } from "../print-trip-sheet/print-trip-sheet.component";
import { EditVechicleComponent } from './../edit-vechicle-component/edit-vechicle.component';
import { ChangeRoutesTimingsComponent } from './../change-routes-timings/change-routes-timings.component';
import { DeviceContext } from './../device-provider/device-provider';
import { ScrollableCalendar } from "../scrollable-calendar/scrollable-calendar.component";
import { ReleaseAllSeatsComponent } from "../release-all-seats/release-all-seats.component";
import { getVechicles } from "../edit-vechicle-component/edit-vechicle.module";
import { RevenueDetails } from "../revenue-details/revenue.component";
import { ViaRoutesSeatNumbers } from "../via-routes-seat-numbers/via-routes-seat-numbers";
import { updateExtraSeatCount } from "../../actions/passenger-form.action";
import { ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL } from "../../utils/constant";
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { updateTicketForExtraSeats } from "../../actions/ticket.action";

const { Option } = Select;

const getSeatInfoOfAllTrips=(searchResults)=>{
    let availableSeatCount = 0 , totalSeatCount = 0;
    if(isValidArray(searchResults)){
        searchResults.forEach(element => {
            let {
                totalSeats,
                vacant
            } = element.seatsAvailbility;
            availableSeatCount = availableSeatCount + vacant;
            totalSeatCount = totalSeatCount +totalSeats;

        });
    }
    return {
        availableSeatCount,
        totalSeatCount
    }
}

const getHeaderTxt=(searchResults)=>{
    let {
        availableSeatCount,
        totalSeatCount
    } = getSeatInfoOfAllTrips(searchResults);
    return `${searchResults.length} Trip(s) available with ${availableSeatCount}/${totalSeatCount} seats`;
}

const renderSeatLayout=(seats)=>{
    if(isValidArray(seats)){
        return <SeatLayoutContainer 
                    seatsData={seats}
                    blockedSeatAllowed={true}
                    showFarePopOver={true}
                    noOfSeatsAllowedToClick={10}
                />
    }else{
        return null
    } 
}

const getVechicleDetails = (schedule)=>{
    let vechicleId = null,phoneNo = null,vechicleInfoExits = false;
    if(isValidArray(schedule)){
        vechicleId = schedule.schedule_vechicle_id;
        phoneNo = schedule.schedule_vechicle_id;
        if(isValid(schedule.schedule_vechicle_id)){
            vechicleInfoExits = true;
        }
        if(isValid(schedule.vechicle_id)){
            vechicleId = schedule.vechicle_id;
            vechicleInfoExits = true;
        }
        if(isValid(schedule.phone_no)){
            phoneNo = schedule.phone_no;
        }
    }

    return [
        vechicleId,
        phoneNo,
        vechicleInfoExits
    ]
}

export const RenderSearchResults = ({
    setSchedule,
    searchResults,
    fetchSeats,
    seats,
    stopPoint,
    setOpenTripRouteId,
    openTripRouteId,
    setSeats,
    selectedDate,
    onFareUpdateSuccess,
    onDriverUpdateSuccess,
    isMobile,
    tabkey , setKey,
    refetchDetails,
    setSearchResults,
    seatsAvailbilityStatus,
    addExtraSeat,
    extraSeats
})=>{

    const [selectedTripRoute, setSelectedTripRoute]= useState(null);
    const [vechicles, setVechicles] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [isExtraSeatsModalVisible, setIsExtraSeatsModalVisible] = useState(false);

    useEffect(() => {
        if (isValid(seatsAvailbilityStatus)&&isValid(selectedTripRoute)) {
            searchResults.map((results) => {
                if (results.trip_route_id == selectedTripRoute) {
                    let seatsAvailbility=results.seatsAvailbility;
                    seatsAvailbility.booked = seatsAvailbilityStatus['booked'];
                    seatsAvailbility.vacant = seatsAvailbilityStatus['vacant'];
                    seatsAvailbility.blocked = seatsAvailbilityStatus['blocked'];
                    seatsAvailbility.adminBlocked = seatsAvailbilityStatus['adminBlocked'];
                    results.seatsAvailbility = seatsAvailbility;
                }
            })
            setSearchResults([]);
            const timeout = setTimeout(() => {
                setSearchResults([...searchResults])
                // setSelectedTripRoute(null);
            }, 1);
            return () => clearTimeout(timeout)
        }

        getVechicles((data)=>{
            setVechicles(data);
        });
    }, [seatsAvailbilityStatus, selectedTripRoute]);

    const renderAvailableSeats = (gender, bookedSeat) => {
        let seatAvailableOptions = [];

        // Add current seat as an option
        seatAvailableOptions.push(
            <Option key={bookedSeat.id} value={bookedSeat.seat_number}>
                {bookedSeat.seat_number}
            </Option>
        );

        // Add extra seat option if enabled
        if (bookedSeat.seat_number !== 'extra' && isValid(global.travelsAppConfig['EXTRA_SEAT_BOOKING']) && global.travelsAppConfig['EXTRA_SEAT_BOOKING'] === 1 && checkActionAllowed('EXTRA_SEATS_BOOKING_ALLOWED')) {
            seatAvailableOptions.push(
                <Option key={'extra'} value={'extra'}>{'Extra'}</Option>
            );
        }

        // Add available seats based on gender
        if (isValidArrayWithValues(seats)) {
            seats.forEach(seat => {
                if (seat.seat_number !== "Empty" && (!isValid(seat.seat_status) || seat.seat_status === "")) {
                    if (gender === 'F') {
                        if (seat.ladies_seat === 1 || seat.gents_seat === 0) {
                            seatAvailableOptions.push(
                                <Option key={seat.id} value={seat.seat_number}>
                                    {seat.seat_number}
                                </Option>
                            );
                        }
                    } else {
                        if (!(seat.ladies_seat === 1)) {
                            seatAvailableOptions.push(
                                <Option key={seat.id} value={seat.seat_number}>
                                    {seat.seat_number}
                                </Option>
                            );
                        }
                    }
                }
            });
        }

        return seatAvailableOptions;
    };

    const showExtraSeats = () => {
        setIsExtraSeatsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsExtraSeatsModalVisible(false);
    };

    const renderExtraSeatsModal = ()=>{
        if (isValidArrayWithValues(extraSeats)){
            let extraSeatsColumns = [
                {
                    title: 'PNR',
                    dataIndex: 'pnr',
                    key: 'pnr'
                },
                {
                    title: 'Name',
                    dataIndex: 'full_name',
                    key: 'full_name',
                    render: (text, record) => {
                        if (editingRow === record.id) {
                            return (
                                <Input
                                    defaultValue={text}
                                    onChange={(e) => record.full_name = e.target.value}
                                />
                            );
                        }
                        return text;
                    }
                },
                {
                    title: 'Fare',
                    dataIndex: 'fare',
                    key: 'fare'
                },
                {
                    title: 'Booked By',
                    dataIndex: 'agent_name',
                    key: 'agent_name',
                    filterSearch: true,
                    onFilter: (value, record) => record.agent_name.toLowerCase().includes(value.toLowerCase()),
                    filters: extraSeats.map(seat => ({ text: seat.agent_name, value: seat.agent_name }))
                },
                {
                    title: 'Pickup',
                    dataIndex: 'pickup_location_name',
                    key: 'pickup_location_name',
                    filterSearch: true,
                    onFilter: (value, record) => record.pickup_location_name.toLowerCase().includes(value.toLowerCase()),
                    filters: extraSeats.map(seat => ({ text: seat.pickup_location_name, value: seat.pickup_location_name }))
                },
                {
                    title: 'Drop',
                    dataIndex: 'drop_location_name',
                    key: 'drop_location_name',
                    filterSearch: true,
                    onFilter: (value, record) => record.drop_location_name.toLowerCase().includes(value.toLowerCase()),
                    filters: extraSeats.map(seat => ({ text: seat.drop_location_name, value: seat.drop_location_name }))
                },
                {
                    title: 'Remarks',
                    dataIndex: 'remarks',
                    key: 'remarks'
                },
                {
                    title: 'Status',
                    dataIndex: 'traveller_status',
                    key: 'traveller_status',
                    render: (text) => {
                        return <Tag color={text === 'Blocked' ? 'red' : 'green'}>{text}</Tag>
                    }
                },
                {
                    title: 'Seat',
                    dataIndex: 'seat_number',
                    key: 'seat_number',
                    render: (text, record) => {
                        if (editingRow === record.id) {
                            return (
                                <Select
                                    defaultValue={text}
                                    onChange={(value) => record.seat_number = value}
                                    style={{ width: '100%' }}
                                >
                                    {renderAvailableSeats(record.gender, record)}
                                </Select>
                            );
                        }
                        return text === 'extra' ? 'Extra' : text;
                    }
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => {
                        if (editingRow === record.id) {
                            return (
                                <Space>
                                    <SaveOutlined
                                        className="action-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setEditingRow(null);
                                            let ticket = {
                                                id: record.ticket_id,
                                                phoneNumber: record.phone_number,
                                                trip_id: record.trip_id,
                                                trip_route_id: record.trip_route_id,
                                                emailId: record.email_id,
                                                pickup_location: record.pickup_location,
                                                updated_by: global.userName
                                            }
                                            let travellers = [
                                                {
                                                    id: record.id,
                                                    fullName: record.full_name,
                                                    gender: record.gender,
                                                    seatNumber: record.seat_number,
                                                    pickup_location: record.pickup_location
                                                }
                                            ]
                                            let seats = [
                                                {
                                                    ticket_id: record.ticket_id,
                                                    seatNumber: record.seat_number,
                                                    tripId: record.trip_id
                                                }
                                            ]
                                            let recordData = {ticket, travellers, seats, websiteUpdate: false}
                                            updateTicketForExtraSeats(recordData, (response) => {
                                                message.success('Changes saved successfully');
                                            }, (error) => {
                                                message.error('Failed to save changes');
                                            });
                                        }}
                                    />
                                    <CloseOutlined
                                        className="action-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // Reset the record to its original state
                                            const originalRecord = extraSeats.find(seat => seat.id === record.id);
                                            Object.assign(record, originalRecord);
                                            setEditingRow(null);
                                        }}
                                    />
                                </Space>
                            );
                        }
                        return (
                            <EditOutlined
                                className="action-icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditingRow(record.id);
                                }}
                            />
                        );
                    }
                }
            ];
            return (
                <div className="marginTop marginBottom">
                    <Button onClick={showExtraSeats} className="outline-button">Extra Seats: {extraSeats.length}</Button>
                    <Modal
                        title="Extra Seats Details"
                        visible={isExtraSeatsModalVisible}
                        onCancel={handleModalClose}
                        footer={[
                            <Button key="close" onClick={handleModalClose}>
                                Close
                            </Button>
                        ]}
                        width={1000}
                    >
                        <Table
                            columns={extraSeatsColumns}
                            dataSource={extraSeats}
                            pagination={false}
                            rowClassName={(record) => record.id === editingRow ? 'editing-row' : ''}
                        />
                    </Modal>
                </div>
            )
        }else{
            return null;
        }
    }

    if(isValidArray(searchResults)&&searchResults.length>0){
        let isSeatsAvailable=isValidArray(seats)&&seats.length>0?true:false;
        return (
            <div className= "search-list-root">
                <List
                    dataSource={searchResults}
                    header={<div className="searchResultHeaderTxt">{getHeaderTxt(searchResults)}</div>}
                    renderItem={item => {
                        let  adminBlocked= 0 ,blocked=0,booked=0,vacant=0 , totalSeats;
                        if(isValidArray(item.seatsAvailbility)){
                            let seatsAvailbility=item.seatsAvailbility;
                            adminBlocked = seatsAvailbility.adminBlocked;
                            blocked = seatsAvailbility.blocked;
                            booked = seatsAvailbility.booked;
                            totalSeats = seatsAvailbility.totalSeats;
                            vacant = seatsAvailbility.vacant;
                        }
                        let isOpenSelectSeats=openTripRouteId===item.trip_route_id?true:false;
                        let vechicleId = null , phoneNo = null , vechicleInfoExits = false ;
                            if(isValidArray(item)&&isValidArray(item.trip)&&isValid(item.trip.start_date)){
                                [vechicleId,phoneNo,vechicleInfoExits] = getVechicleDetails(item);
                            }
                            if(isMobile){
                                return(
                                    <div className="search-item-root-div">
                                    <div className="search-item-header">
                                        <CardDescription 
                                            schedule={item}
                                        />
                                    </div>
                                    <div className="search-item-second-row">
                                                <RouteDetails 
                                                    schedule={item}
                                                    selectedDate={selectedDate}
                                                />
                                                <DonutChartComponent 
                                                    AdminBlocked={adminBlocked}
                                                    Blocked={blocked}
                                                    Booked={booked}
                                                    Vacant={vacant}
                                                    basefare={0}
                                                    sleeperFare={0}
                                                />
                                    </div>
                                    <div>
                                    <Row className="flexWrapInheriant">
                                                    <Col>
                                                        <RevenueDetails 
                                                            tripDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <PrintTripSheet 
                                                            tripId={item.trip.id}
                                                            selectedSchedule={item}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            tripSheetPermission = {item.trip_sheet_permission}
                                                        />
                                                    </Col>
                                                    <Col>
                                                    <ChangeRoutesTimingsComponent
                                                        travelDate = {item.trip.trip_start_date}
                                                        scheduleId={item.schedule_id}
                                                        tripId={item.trip.id}
                                                        onTimeUpdateSuccess={onFareUpdateSuccess}
                                                    />
                                                    </Col>
                                                    <Col>
                                                        <EditVechicleComponent 
                                                            scheduleDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            scheduleId={item.schedule_id}
                                                            selectedBus={item.bus}
                                                            selectedTrip={item.trip}
                                                            vechicleId={vechicleId}
                                                            phoneNo={phoneNo}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            busId={item.bus_id}
                                                            onVechicleUpdateSuccess={(vechicleId,phoneNo)=>{
                                                                item.vechicle_id = vechicleId;
                                                                item.phone_no = phoneNo;
                                                                onDriverUpdateSuccess(item.schedule_id,vechicleId,phoneNo);
                                                            }}
                                                            secondDriverName={item.second_driver_name}
                                                            driverName={item.driver_name}
                                                            secondPhoneNo={item.second_driver_phone_no}
                                                            vechicles={vechicles}
                                                            tripInstruction={item.trip_instruction}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <ViaRoutesSeatNumbers 
                                                          tripSheetPermission = {item.trip_sheet_permission}
                                                          tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                </Row>
                                    </div>
                                    <div className="search-item-fourth-row">
                                        <RenderFares 
                                            schedule={item}                                        
                                        />
                                    </div>
                                    <div className="search-item-5th-row">
                                        <SelectSeatsButton 
                                                refetchDetails={refetchDetails}
                                                vechicleInfoExits={vechicleInfoExits}
                                                showEditFare={checkActionAllowed(ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL)}
                                                resetSeats={()=>{
                                                    setSeats(null);
                                                }}
                                                fetchSeats={()=>{
                                                    setSeats(null); 
                                                    setOpenTripRouteId(item.trip_route_id);
                                                    fetchSeats(item.trip_route_id);
                                                    setSchedule(item);
                                                }}
                                                isOpenSelectSeats={isOpenSelectSeats}
                                                item={item}
                                                onFareUpdateSuccess={onFareUpdateSuccess}
                                                openTripRouteId={openTripRouteId}
                                                setOpenTripRouteId={setOpenTripRouteId}
                                                onSelectSchedule={(schedule)=>{
                                                    setSchedule(schedule);
                                                }}
                                                tabkey={tabkey} 
                                                setKey={setKey}
                                        />
                                    </div>
                                    {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&tabkey==="Select Seats"&&
                                    <div className="expandend-block">
                                        <div className="expand-root">
                                            <RenderNewTime 
                                                schedule={item}
                                            />   
                                            <div className="seat-block">
                                                {renderSeatLayout(seats)}
                                                <PassengerForm 
                                                    selectedSchedule={item}
                                                    stopPoint = {stopPoint}
                                                    fetchSeats={fetchSeats}
                                                    selectedDate={selectedDate}
                                                    setSelectedTripRoute = {setSelectedTripRoute}
                                                />                                           
                                            </div>
                                            {isValid(global.travelsAppConfig['EXTRA_SEAT_BOOKING'])&&global.travelsAppConfig['EXTRA_SEAT_BOOKING']==1&&checkActionAllowed('EXTRA_SEATS_BOOKING_ALLOWED')&&
                                                <>
                                                    {renderExtraSeatsModal()}
                                                    <div className="marginTop marginBottom">
                                                        <Button onClick={addExtraSeat}>Add Extra seat</Button>
                                                    </div>
                                                </>
                                            }
                                            {isSeatsAvailable&&
                                               <div>
                                                    <BlockAllSeatsComponent 
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                                    <ReleaseAllSeatsComponent
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                               </div>
                                            }     
                                        </div>
                                    </div>
                                    }
                                </div>
                                )
                            }
                            return(
                                <div className="search-item-root-div">
                                    <div className="search-item-header">
                                        <CardDescription 
                                            schedule={item}
                                        />
                                        <RenderFares 
                                            schedule={item}                                        
                                        />
                                    </div>
                                    <div className="search-item-second-row">
                                                <RouteDetails 
                                                    schedule={item}
                                                    selectedDate={selectedDate}
                                                />
                                                <Row className="flexWrapInheriant trip-time-vechile-edit-block">
                                                    <Col>
                                                        <RevenueDetails 
                                                            tripDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <PrintTripSheet 
                                                            tripId={item.trip.id}
                                                            selectedSchedule={item}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            tripSheetPermission = {item.trip_sheet_permission}
                                                        />
                                                    </Col>
                                                    <Col>
                                                    <ChangeRoutesTimingsComponent
                                                        travelDate = {item.trip.trip_start_date}
                                                        scheduleId={item.schedule_id}
                                                        tripId={item.trip.id}
                                                        onTimeUpdateSuccess = {onFareUpdateSuccess}
                                                    />
                                                    </Col>
                                                    <Col>
                                                        <EditVechicleComponent 
                                                            scheduleDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            scheduleId={item.schedule_id}
                                                            selectedBus={item.bus}
                                                            selectedTrip={item.trip}
                                                            vechicleId={vechicleId}
                                                            phoneNo={phoneNo}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            busId={item.bus_id}
                                                            onVechicleUpdateSuccess={(vechicleId,phoneNo)=>{
                                                                item.vechicle_id = vechicleId;
                                                                item.phone_no = phoneNo;
                                                                onDriverUpdateSuccess(item.schedule_id,vechicleId,phoneNo);
                                                            }}
                                                            secondDriverName={item.second_driver_name}
                                                            driverName={item.driver_name}
                                                            secondPhoneNo={item.second_driver_phone_no}
                                                            vechicles={vechicles}
                                                            tripInstruction={item.trip_instruction}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <ViaRoutesSeatNumbers 
                                                          tripSheetPermission = {item.trip_sheet_permission}
                                                          tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DonutChartComponent 
                                                    AdminBlocked={adminBlocked}
                                                    Blocked={blocked}
                                                    Booked={booked}
                                                    Vacant={vacant}
                                                    basefare={0}
                                                    sleeperFare={0}
                                                />
                                                <div></div>
                                    </div>
                                    <SelectSeatsButton 
                                                refetchDetails={refetchDetails}
                                                vechicleInfoExits={vechicleInfoExits}
                                                showEditFare={checkActionAllowed(ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL)}
                                                resetSeats={()=>{
                                                    setSeats(null);
                                                }}
                                                fetchSeats={()=>{
                                                    setSeats(null); 
                                                    setOpenTripRouteId(item.trip_route_id);
                                                    fetchSeats(item.trip_route_id);
                                                    setSchedule(item);
                                                }}
                                                isOpenSelectSeats={isOpenSelectSeats}
                                                item={item}
                                                onFareUpdateSuccess={onFareUpdateSuccess}
                                                openTripRouteId={openTripRouteId}
                                                setOpenTripRouteId={setOpenTripRouteId}
                                                onSelectSchedule={(schedule)=>{
                                                    setSchedule(schedule);
                                                }}
                                                tabkey={tabkey} 
                                                setKey={setKey}
                                            />
                                    {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&tabkey==="Select Seats"&&
                                    <div className="expandend-block">
                                        <div className="expand-root">
                                            <RenderNewTime 
                                                schedule={item}
                                            />   
                                            <div className="seat-block">
                                                {renderSeatLayout(seats)}
                                                <PassengerForm 
                                                    selectedSchedule={item}
                                                    fetchSeats={fetchSeats}
                                                    stopPoint = {stopPoint}
                                                    selectedDate={selectedDate}
                                                    onFareUpdateSuccess={onFareUpdateSuccess}
                                                    setSelectedTripRoute = {setSelectedTripRoute}
                                                />                                           
                                            </div>
                                            {isValid(global.travelsAppConfig['EXTRA_SEAT_BOOKING'])&&global.travelsAppConfig['EXTRA_SEAT_BOOKING']==1&&checkActionAllowed('EXTRA_SEATS_BOOKING_ALLOWED')&&
                                            <>
                                                {renderExtraSeatsModal()}
                                                <div className="marginTop marginBottom">
                                                    <Button onClick={addExtraSeat}>Add Extra seat</Button>
                                                </div>
                                            </>
                                            }
                                            {isSeatsAvailable&&
                                                <div>
                                                    <BlockAllSeatsComponent 
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                                    <ReleaseAllSeatsComponent
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                                </div>
                                            }     
                                        </div>
                                    </div>
                                    }
                                </div>
                            )
                    }}
                />
            </div>
        )
    }else{
        return <div>No trip is available . Please change criteria and search again.</div>;
    }

}
export const SearchResultsComponent = (props) => {
    
    const {
        schedules,
        showResults,
        seats,
        setSeats,
        openTripRouteId,
        setOpenTripRouteId,
        onFareUpdateSuccess,
        onDriverUpdateSuccess,
        onChangeDateStrip,
        selectedDate,
        refetchDetails,
        setSchedules
    } = props;

    const dispatch = useDispatch();
    const [ tabkey , setKey ] = useState(null);
    const [seatsAvailbilityStatus,setSeatAvailabilityStatus] = useState({});
    const [stopPoint, setStopPoint] = useState({});
    const [extraSeats, setExtraSeats] = useState([]);


    const {
        isMobile
    } = useContext(DeviceContext);

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }


    const fetchSeats=(tripRouteId)=>{
        setLoading(true);
        dispatch(fetchSeatLayout(tripRouteId,(seatsData)=>{
            let tempSeatAvailability = {'adminBlocked': 0 ,'blocked':0,'booked':0,'vacant':0,'totalSeats' : 0};
            let fetchedSeats = seatsData.seats;
            let extraSeats = isValidArrayWithValues(seatsData.extraSeats)?seatsData.extraSeats:[];
            fetchedSeats.map((seat) => {
                let seatStatus = seat.seat_status;
                if(["Empty", "empty"].indexOf(seat.seat_number) == -1){
                    tempSeatAvailability['totalSeats']++;
                    if(!isValidString(seatStatus)) {
                        tempSeatAvailability['vacant']++;
                    }
                    else {
                        if (seatStatus == "Confirmed") {
                            tempSeatAvailability['booked']++;
                        }
                        else {
                            if (isValid(seat.ticket_id)) {
                                tempSeatAvailability['blocked']++;
                            }
                            else {
                                tempSeatAvailability['adminBlocked']++;
                            }
                        }
                    }
                }
            })
            extraSeats.map((extraSeat) => {
                // Match pickup location
                const pickupMatch = seatsData.pickupDetails.find(
                    pickup => pickup.id === extraSeat.pickup_location
                );
                if (pickupMatch) {
                    extraSeat.pickup_location_name = pickupMatch.location;
                }

                // Match drop location
                const dropMatch = seatsData.dropDetails.find(
                    drop => drop.id === extraSeat.drop_location
                );
                if (dropMatch) {
                    extraSeat.drop_location_name = dropMatch.location;
                }
            });
            let tempStop = {'pickupDetails': seatsData.pickupDetails, 'dropDetails': seatsData.dropDetails};
            setStopPoint(tempStop);
            setSeatAvailabilityStatus({...tempSeatAvailability});
            setSeats(fetchedSeats);
            setExtraSeats(extraSeats);
            setLoading(false);
        },(errmsg)=>{
            message.error(errmsg);
            setLoading(false);
        }));
    }

    const setSchedule = (schedule)=>{
        dispatch(setSelectedSchedule(schedule));
    }

    const addExtraSeat = () => {
        dispatch(updateExtraSeatCount(true,1,null))
    }



    if(showResults){
            return (
                    <div>
                        <div className="scrollable-block-root">
                            <ScrollableCalendar 
                            changeScrollableDate={onChangeDateStrip}
                            selectedDate={selectedDate}
                            />
                        </div>
                        <RenderSearchResults 
                        searchResults={schedules}
                        seats={seats}
                        fetchSeats={fetchSeats}
                        stopPoint = {stopPoint}
                        setOpenTripRouteId={setOpenTripRouteId}
                        openTripRouteId={openTripRouteId}
                        setSeats={setSeats}
                        setLoading={setLoading}
                        setSchedule={setSchedule}
                        onFareUpdateSuccess={onFareUpdateSuccess}
                        onDriverUpdateSuccess={onDriverUpdateSuccess}
                        isMobile={isMobile}
                        selectedDate={selectedDate}
                        onChangeDateStrip={onChangeDateStrip}
                        tabkey={tabkey} 
                        setKey={setKey}
                        refetchDetails={refetchDetails}
                        setSearchResults={setSchedules}
                        seatsAvailbilityStatus = {seatsAvailbilityStatus}
                        addExtraSeat = {addExtraSeat}
                        extraSeats = {extraSeats}
                        />
                    </div>
            )
    }else{
        return null
    }
}