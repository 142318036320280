import React, { useState, useEffect } from "react";
import { Form, Input, Select, Row, Col, Button, message, Checkbox } from "antd";
import { checkActionAllowed, isValidArray, isValidArrayWithValues } from "../../../utils/utilities";
import { ACTION_TO_EDIT_VECHICLE_INFO_FROM_AGENT_PORTAL } from '../../../utils/constant';
import { getAPIDateFormat } from '../../../utils/date.utils';
import { updatePhoneNoandVechicleNo } from '../../edit-vechicle-component/edit-vechicle.module';
import { useDispatch } from "react-redux";
import { loadingAction } from '../../../actions/loading-actions';
import { StaffSelect } from "../../search-components/staff-select-component/staff-select.component";
import { ScheduleCalendar } from "../../shared/schedule-calendar/schedule-calendar.component";
import './render-tabs.style.scss';
import { getVechicles } from "../../edit-vechicle-component/edit-vechicle.module";
const { Option } = Select;

const renderVehicles = (vehicles, busId) => {
    let vehicleOptions = [<Option key={0} value={0}>None</Option>];
    if (isValidArray(vehicles) && vehicles.length > 0) {
        vehicles.forEach(element => {
            if (busId === element.bus_id) {
                vehicleOptions.push(
                    <Option key={element.id} value={element.id}>
                        {element.vechicle_no}
                    </Option>
                );
            }
        });
    }
    return vehicleOptions;
};

export const ScheduleVehicleComponent = (props) => {
    const {
        scheduleData,
        selectedDate,
    } = props;

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [currentSelectedDates, setCurrentSelectedDates] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const setLoading = (value) => {
        dispatch(loadingAction(value));
    };

    useEffect(()=>{
        getVechicles((data)=>{
            setVehicles(data);
        });
    },[]);

    const getVehicleNoFromId = (vehicleId) => {
        let vehicleNo = null;
        if (isValidArrayWithValues(vehicles)) {
            vehicles.map((vehicle) => {
                if (vehicleId == vehicle.id) {
                    vehicleNo = vehicle.vechicle_no;
                }
            });
        }
        return vehicleNo;
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                let vehicleId = values.vechicleId == 0 ? null : values.vechicleId;
                let vehicleNo = getVehicleNoFromId(vehicleId);
                let trip = {
                    id: null,
                    schedule_id: scheduleData.id,
                    vechicle_id: vehicleId,
                    vehicleNo,
                    phone_no: values.phoneNo,
                    driver_name: values.driverName,
                    second_driver_name: values.secondDriverName,
                    second_driver_phone_no: values.secondPhoneNo,
                    conductor_name: values.conductorName,
                    conductor_phone: values.conductorPhone,
                    operator_code: global.operatorCode,
                    doj: getAPIDateFormat(selectedDate),
                    trip_instruction: values.tripInstruction,
                    trip_ids: [],
                    selected_dates: {},
                    is_permanent: values.isPermanent ? 1 : 0
                };

                

                let selectedTripIds = [];
                let selectedDatesObject = {};
                scheduleData.trips.map((trip)=>{
                    if(currentSelectedDates.includes(getAPIDateFormat(trip.start_date))){
                        selectedTripIds.push(trip.id);
                        selectedDatesObject[trip.id] = getAPIDateFormat(trip.start_date);
                    }
                });

                trip.trip_ids = selectedTripIds;
                trip.selected_dates = selectedDatesObject;

                if (selectedTripIds.length > 0) {
                    setLoading(true);
                    updatePhoneNoandVechicleNo(trip, 
                        () => {
                            setLoading(false);
                        },
                        () => {
                            setLoading(false);
                        }
                    );
                }
                else {
                    message.error("Please select a valid date");
                    return;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (!checkActionAllowed(ACTION_TO_EDIT_VECHICLE_INFO_FROM_AGENT_PORTAL)) {
        return null;
    }

    return (
        <div className="extend-block-root">
            <ScheduleCalendar 
                selectedDate={selectedDate}
                onDatesChange={setCurrentSelectedDates}
                showLegend={true}
                scheduleData={scheduleData}
                loadCalendarData={true}
            />
            {scheduleData.trips_created === 1 &&
                <Row gutter={[16,16]} justify='center' align="middle">
                    <Col span={24}>
                        <div className="vehicle-info-container">
                            <Form
                                form={form}
                                name="vehicleInfo"
                                initialValues={{
                                    vehicleId: scheduleData.vechicle_id,
                                    phoneNo: scheduleData.phone_no,
                                    driverName: scheduleData.driver_name,
                                    secondDriverName: scheduleData.second_driver_name,
                                    secondPhoneNo: scheduleData.second_driver_phone_no,
                                    tripInstruction: scheduleData.trip_instruction
                                }}
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Vechicle #"
                                    name="vechicleId"
                                    rules={[{ required: true, message: 'Please select vechicle' }]}
                                >
                                    <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select a vechicle"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        bordered={true}
                                    >
                                        {renderVehicles(vehicles,scheduleData.bus_id)}
                                    </Select>
                                </Form.Item>
                                
                                <Form.Item
                                    label="Trip Instructions"
                                    name="tripInstruction"
                                    rules={[{ required: false }]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>

                                <Row gutter={[16,16]}>
                                    <Col xs={24} sm={12}>
                                        <StaffSelect 
                                            formName={"driverName"}
                                            isFormItem={true}
                                            isManditory={false}
                                            showLabel={true}
                                            showLabelName="Driver name-1"
                                            onChange={(value,info)=>{
                                                form.setFieldsValue({phoneNo:info.staff.phone_number});
                                            }}
                                            isOnlyDriver={true}
                                        /> 
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label="Driver 1 Phone #"
                                            name="phoneNo"
                                            rules={[{ required: true, message: 'Please enter phone number' }]}
                                        >
                                                <Input />
                                        </Form.Item> 
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col xs={24} sm={12}>
                                        <StaffSelect 
                                            formName={"secondDriverName"}
                                            isFormItem={true}
                                            isManditory={false}
                                            showLabel={true}
                                            showLabelName="Driver name-2"
                                            onChange={(value,info)=>{
                                                form.setFieldsValue({secondPhoneNo:info.staff.phone_number});
                                            }}
                                            isOnlyDriver={true}
                                        /> 
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label="Driver 2 Phone #"
                                            name="secondPhoneNo"
                                            rules={[{ required: false, message: 'Please enter phone number' }]}
                                        >
                                                <Input />
                                        </Form.Item>  
                                    </Col>
                                </Row>

                                <Row gutter={[16,16]}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label="Conductor Name"
                                            name="conductorName"
                                            rules={[{ required: false, message: 'Please enter conductor name' }]}
                                        >
                                            <Input placeholder="Conductor Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label="Conductor Phone #"
                                            name="conductorPhone"
                                            rules={[{ required: false, message: 'Please enter conductor phone number' }]}
                                        >
                                            <Input placeholder="Conductor Phone" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                            <Form.Item
                                                label="Permanent"
                                                name="isPermanent"
                                                valuePropName="checked"
                                            >
                                                <Checkbox />
                                            </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]} justify="center" align="middle">
                                    <Col>
                                        <Button type="primary" onClick={handleSubmit}>
                                            Update Vehicle Info
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
}; 