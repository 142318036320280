import React , {useState} from "react";
import { Row ,Col , Input , Button, message} from "antd";
import { updateAgentTransaction } from './../../actions/agents.actions';
import { EditOutlined } from '@ant-design/icons';
import './agent-transaction-edit.style.scss';

export const AgentTransactionEdit = (props) => {

    const {
        agentTransactionId,
        amount,
        onCallBack,
    } = props;
    let oldAmount = amount;
    const [price, setPrice] = useState(amount);
    const [editPrice, setEditPrice] = useState(false);

        return (
            <div className="post-paid-edit-root">
                <Row gutter={[16,16]}  align="middle">
                    {editPrice?
                        <Col>
                            <Input 
                                value={price}
                                onChange={(e)=>{
                                    setPrice(e.target.value);
                                }}
                            />
                        </Col>
                        :
                        <Col>
                            <Row justify="center" align="middle">
                                <Col>
                                    {price}
                                </Col>
                                <Col>
                                    <Button  
                                        type="text" 
                                        className="outline-button"  
                                        icon={<EditOutlined />} 
                                        onClick={()=>{
                                            setEditPrice(true);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                {editPrice&&
                    <Row gutter={[16,16]} justify="center">
                        <Col>
                            <Button className="small-button exit-btn"
                            onClick={()=>{
                                setEditPrice(false);
                                setPrice(oldAmount);
                            }}
                            >Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="small-button" 
                            onClick={()=>{
                                updateAgentTransaction(agentTransactionId,null,price,()=>{
                                    setEditPrice(false);
                                    onCallBack(agentTransactionId,price);
                                },(errorMessage)=>{
                                    message.error(errorMessage);
                                });
                            }}
                            >Save</Button>
                        </Col>
                    </Row>
                }
            </div>
        )
}