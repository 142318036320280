import React , { useState } from "react";
import { Button, Modal, Table } from "antd";
import './via-cities.style.scss';
import { isValid } from "../../utils/utilities";
import moment from 'moment';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { getCities, getViacitiesDetails } from "../../actions/admin.action";

const cityColumns =[
    {
        title: 'City',
        dataIndex:'city',
        key:'city',
        render: (text, record) => {
            const badges = [];
            if (record.isOrigin) badges.push(<span className="badge badge-origin">O</span>);
            if (record.isDestination) badges.push(<span className="badge badge-destination">D</span>);
            return (
                <div>
                    {text} {badges}
                </div>
            );
        },
    },
    {   
        title: 'Timing',
        dataIndex:'time',
        key:'time',
        render:(text)=>{
            return (
                <div>{moment(text).format('h:mm A')}</div>
            );
        },
    },
    {   
        title: 'Day',
        dataIndex:'day',
        key:'day',
        width:'100',
        render:(text)=>{
            return (
                <div>{`Day ${text+1}`}</div>
            );
        },
    }
]

export const ViaCitiesComponent = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cities, setCities] = useState([]);

    const {
        travelDate,
        scheduleId,
        className
    } = props;

    const dispatch = useDispatch();
    const setloading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const showModal = () => {
      setIsModalVisible(true);
      setloading(true);
      let date = null;
      if(isValid(travelDate)){
        date = moment(travelDate);
      }
      getViacitiesDetails(scheduleId,date,(data)=>{
        setCities(getCities(data));
        setloading(false);
        },
        ()=>{
            setloading(false);
        },0);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    
        return (
            <span>
                <Button onClick={showModal} className={`outline-button ${className}`}>Via</Button>
                 <Modal title="Via Cities" 
                 maskClosable={false}
                 visible={isModalVisible} 
                 onOk={handleOk} 
                 onCancel={handleCancel}
                 footer={null}
                 >
                     <Table 
                        dataSource={cities}
                        columns={cityColumns}
                        // showHeader={false}
                        pagination={false}
                     />
                </Modal>
            </span>
        )
}