import { message } from 'antd';
import { takeLatest, put , all ,fork,call } from 'redux-saga/effects';
import {
    CREATE_AGENT_TRANSACTION,
    GET_AGENT_PRIVILEGES,
    GET_AGENTS,
    GET_AGENTS_TRANSACTIONS,
    GET_OPERATOR_PRIVILEGE_ACTIONS,
    LOADING_ACTION,
    SET_AGENT_PRIVILEGES,
    SET_AGENTS
} from '../actions/action-types';

export function* getAgentsSaga(action) {
    try {
        yield put({
            type:LOADING_ACTION,
            payload:true
          })
        const {
                api,
                url,
                apiPayload
        } = {...action.payload};
          const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&apiResult.payload){
                let clientPayload=apiResult.payload;
                let agents = clientPayload.Payload.agents;
                yield put({
                    type:SET_AGENTS,
                    payload:{agents}
                  })
            }else{
                message.error("Failed to get agents")
            }
            yield put({
                type:LOADING_ACTION,
                payload:false
              })
    }
    catch (error) {
        yield put({
            type:LOADING_ACTION,
            payload:false
          })
        console.log("fetching :",{error});
        message.error("Failed to get agents")
    }
};


function* watchGetAgentsSaga() {
    yield takeLatest(GET_AGENTS, getAgentsSaga)
}

export function* getAgentsTransactions(action) {
  try {
      yield put({
          type:LOADING_ACTION,
          payload:true
        })
      const {
              api,
              url,
              apiPayload,
              onSuccess
      } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
          if(apiResult.status==="SUCCESS"&&apiResult.payload){
              let clientPayload=apiResult.payload;
              let agentsTransactions = clientPayload.Payload.agentsTransactions;
              onSuccess(agentsTransactions);
          }else{
              message.error("Failed to get agents")
          }
          yield put({
              type:LOADING_ACTION,
              payload:false
            })
  }
  catch (error) {
      yield put({
          type:LOADING_ACTION,
          payload:false
        })
      console.log("fetching :",{error});
      message.error("Failed to get agents")
  }
};


function* watchGetAgentTransactionsSaga() {
  yield takeLatest(GET_AGENTS_TRANSACTIONS, getAgentsTransactions)
}

export function* createAgentTransaction(action) {
  try {
      yield put({
          type:LOADING_ACTION,
          payload:true
        })
      const {
              api,
              url,
              apiPayload,
              onSuccess
      } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
          if(apiResult.status==="SUCCESS"&&apiResult.payload){
              onSuccess();
          }else{
              message.error("Failed to create agent transactions!")
          }
          yield put({
              type:LOADING_ACTION,
              payload:false
            })
  }
  catch (error) {
      yield put({
          type:LOADING_ACTION,
          payload:false
        })
      console.log("fetching :",{error});
      message.error("Failed to create agent transactions!")
  }
};

function* watchCreatAgentTransactionSaga() {
  yield takeLatest(CREATE_AGENT_TRANSACTION, createAgentTransaction)
}

export function* getAgentPrivilegesSaga(action) {
  try {
    const {
      api,
      url,
      apiPayload,
      onSuccess
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload); 
    if(apiResult.status==="SUCCESS"&&apiResult.payload&&apiResult.payload.Status==="SUCCESS"){
      let clientPayload=apiResult.payload;
      yield put({
        type:SET_AGENT_PRIVILEGES,
        payload:clientPayload.Payload
      })
      onSuccess(apiResult.payload);
    }else{
      message.error("Failed to get agent privileges!");
    }
  } 
  catch (error) {
    console.log("fetching :",{error});
  }
} 

function* watchGetAgentPrivilegesSaga() {
  yield takeLatest(GET_AGENT_PRIVILEGES, getAgentPrivilegesSaga);
}

export function* getOperatorPrivilegeActionsSaga(action) {
  try {
    const {
      api,
      url,
      apiPayload,
      onSuccess,
      onFailure
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
    if(apiResult.status==="SUCCESS"&&apiResult.payload&&apiResult.payload.Status==="SUCCESS"){
      let clientPayload=apiResult.payload;
      onSuccess(clientPayload.Payload);
    }else{
      onFailure("Failed to get operator privilege actions!");
    }
  }
  catch (error) {
    console.log("fetching :",{error});
  }
}

function* watchGetOperatorPrivilegeActionsSaga() {
  yield takeLatest(GET_OPERATOR_PRIVILEGE_ACTIONS, getOperatorPrivilegeActionsSaga);
} 

export default function* watchAgentsSagas() {
    yield all([
      fork(watchGetAgentsSaga),
      fork(watchCreatAgentTransactionSaga),
      fork(watchGetAgentTransactionsSaga),
      fork(watchGetAgentPrivilegesSaga),
      fork(watchGetOperatorPrivilegeActionsSaga)
  ])
}
  