import React, { useState } from 'react';
import { Collapse, Row, Col } from 'antd';
import { CalendarOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { getDate } from '../../../utils/date.utils';
import { DisplayCalendar } from '../display-calender/display-calender.component';
import moment from 'moment';

export const TimePanel = ({ item, index, selectedDate }) => {
    const [expandedCities, setExpandedCities] = useState([]);
    const [showCalendars, setShowCalendars] = useState({});
    
    const toggleCalendar = (cityName, locationName) => {
        setShowCalendars(prev => ({
            ...prev,
            [cityName+locationName]: !prev[cityName+locationName]
        }));
    };

    return (
        <div className="time-details" key={index}>
            {item.cities && (
                <div className="time-timeline">
                    <Row className="time-header">
                        <Col xs={8} md={4}>City</Col>
                        <Col xs={8} md={4}>Location</Col>
                        <Col xs={8} md={4}>Dates</Col>
                    </Row>
                    <Collapse
                        expandIconPosition="left"
                        ghost
                        className="custom-collapse"
                        expandIcon={() => null}
                        activeKey={expandedCities}
                        onChange={(keys) => setExpandedCities(keys)}
                    >
                        {Object.entries(item.cities).map(([cityName, cityData], cityIndex) => (
                            <Collapse.Panel 
                                key={cityName}
                                header={
                                    <Row align="middle" style={{ width: '100%' }}>
                                        <Col style={{ marginRight: '8px' }}>
                                            {expandedCities.includes(cityName) ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                                        </Col>
                                        <Col>{cityName}</Col>
                                    </Row>
                                }
                                className={`city-panel`}
                            >
                                {Object.entries(cityData.locations).map(([locationName, locationData], locationIndex) => (
                                    <Row key={locationName}
                                        style={{
                                            position: 'relative',
                                            paddingLeft: '24px'
                                        }}
                                        className="location-item"
                                    >
                                         <div style={{
                                            position: 'absolute',
                                            left: '12px',
                                            top: 0,
                                            bottom: 0,
                                            width: '1px',
                                            backgroundColor: '#d9d9d9'
                                        }} />
                                        <Col xs={8} md={4}></Col>
                                        <Col xs={16} md={8}>
                                            <div className="value-tag-container">
                                                <Row justify="space-between" style={{ width: '100%' }}>
                                                    <Col xs={12} md={12}>{locationName}</Col>
                                                    <Col xs={12} md={12}>
                                                    {locationData.dates.map(date => getDate(moment(date))).join(', ')}
                                                        <CalendarOutlined 
                                                            style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '16px', fontWeight: 'bold' }}
                                                            className={showCalendars[cityName+locationName] ? "expanded-calendar-icon" : "collapsed-calendar-icon"}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleCalendar(cityName, locationName);
                                                            }}
                                                        />
                                                    </Col>  
                                                </Row>
                                                {showCalendars[cityName+locationName] && (
                                                                <DisplayCalendar 
                                                                    selectedDate={selectedDate}
                                                                    dates={locationData.dates}
                                                                    isRemoved={false}
                                                                    flexEnd={true}
                                                                    isTimeChanges={true}
                                                                    hoverableContent={locationData}
                                                                    />
                                                        )}  
                                            </div>
                                        </Col>  
                                    </Row>
                                ))}
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </div>
            )}
        </div>
    );
};
