import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveOperators, updateTravelConfig, fetchTravelsDetailsForConfig, getDefaultOperatorConfig } from "../../actions/admin.action";
import { activeOperatorsSelector } from "../../selector/admin.selector";
import { Divider, Col, Form, Select, Row, Checkbox, Input ,Button, message} from 'antd';
import '../../styles/operator-config-page.style.scss';
import { isValidArray, isValid, isValidArrayWithValues } from "../../utils/utilities";
import { IntegrationEmailComponent } from './../../components/integration-component/integration.component';
const { Option } = Select;

const printOperatorOption = (option) => {
    return <Option  value={option.operator_code} data={option} key={option.operator_code}>{option.name}</Option>
};


export const OperatorConfigPage = () => {

    const dispatch = useDispatch();
    const { activeOperators, activeOperatorLoadedTime, configKeys } = useSelector(activeOperatorsSelector);
    const [selectedOperator,setSelectedOperator] = useState(null);

    const [form] = Form.useForm();
    const [configFormItems, setConfigFormItems] = useState([]);
    const [defaultOperatorConfig, setDefaultOperatorConfig] = useState({});
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedSubRegion, setSelectedSubRegion] = useState(null);
    const [dynamicPricingProvider, setDynamicPricingProvider] = useState([]);
    const [integrationModalOpen, setIntegrationModalOpen] = useState(false);

    useEffect(() => {
        const FIVE_MINUTES = 5 * 60 * 1000; // 5 minutes in milliseconds

        const shouldFetch = !isValid(activeOperators) ||
                           !activeOperatorLoadedTime ||
                           (Date.now() - activeOperatorLoadedTime) > FIVE_MINUTES;

        if (shouldFetch) {
            dispatch(getActiveOperators());
        }
        fetchTravelsDetailsForConfig(global.operator_code,(data)=>{
            setSelectedOperator(data);
        },(errmsg)=>{
            console.log("Error fetching travels details:",errmsg);
        });
        getDefaultOperatorConfig((data)=>{
            setDefaultOperatorConfig(data.defaultOperatorConfig);
            let regions = Object.keys(data.defaultOperatorConfig);
            setDefaultRegions(regions);
            setDynamicPricingProvider(data.dynamicPricingProviders);
        },(errmsg)=>{
            console.log("Error fetching default operator config:",errmsg);
        });
    }, []);

    useEffect(() => {
        if (isValidArray(configKeys)) {
            // Initialize categoryGroups with static form items
            const categoryGroups = {
                'Agent Portal': {
                    checkboxItems: [
                        <Col span={8} key="use_remarks">
                            <Form.Item label="Use Remarks" name="use_remarks" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="blocked_tickets">
                            <Form.Item label="Blocked tickets in trip sheet" name="blocked_tickets_in_trip_sheet" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="send_payment_link">
                            <Form.Item label="Send Payment Link" name="send_payment_link" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="show_fare">
                            <Form.Item label="Show Fare in Layout" name="show_fare_in_layout" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    ],
                    otherItems: [
                        <Col span={8} key="overdue_amount">
                            <Form.Item label="Overdue Amount" name="overdue_amount">
                                <Input />
                            </Form.Item>
                        </Col>
                    ]
                },
                'WhatsApp': {
                    checkboxItems: [
                        <Col span={8} key="whatsapp_message">
                            <Form.Item label="Whatsapp Message" name="whatsapp_message" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    ],
                    otherItems: [
                        <Col span={8} key="whatsapp_number">
                            <Form.Item label="Whatsapp Number" name="whatsapp_number">
                                <Input />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="whatsapp_image">
                            <Form.Item label="Whatsapp Image" name="whatsapp_image">
                                <Input />
                            </Form.Item>
                        </Col>
                    ]
                },
                'Backend': {
                    checkboxItems: [
                        <Col span={8} key="use_review">
                            <Form.Item label="Use Review Feature" name="is_use_review_feature" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="send_marketing">
                            <Form.Item label="Send Marketing SMS" name="send_marketing_sms" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="reschedule">
                            <Form.Item label="Reschedule Allowed" name="is_reschedule" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="both_decks">
                            <Form.Item label="Both Decks Together" name="both_decks_together" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    ],
                    otherItems: [
                        <Col span={8} key="time_required">
                            <Form.Item label="Time Required Before Close" name="time_required_before_close">
                                <Input />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="max_ladies">
                            <Form.Item label="Maximum Ladies Reservation" name="maximum_ladies_reservation">
                                <Input />
                            </Form.Item>
                        </Col>
                    ]
                },
                'Customer Website': {
                    checkboxItems: [],
                    otherItems: [
                        <Col span={8} key="about_tours">
                            <Form.Item label="About Tours" name="about_tours">
                                <Input />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="analytics">
                            <Form.Item label="Google Analytics Id" name="google_analytics_id">
                                <Input />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="contact_email">
                            <Form.Item label="Email Id" name="contact_email">
                                <Input />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="phone_number">
                            <Form.Item label="Phone Number" name="phone_number">
                                <Input />
                            </Form.Item>
                        </Col>
                    ]
                },
                'Dynamic pricing': {
                    checkboxItems: [
                        <Col span={8} key="dynamic_pricing">
                            <Form.Item label="Dynamic Pricing Enabled" name="dynamic_pricing_enabled" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    ],
                    otherItems: [
                        <Col span={8} key="provider">
                            <Form.Item label="Dynamic Pricing Provider" name="dynamic_pricing_provider">
                                <Select>
                                    {isValidArray(dynamicPricingProvider) && dynamicPricingProvider.map((provider)=>{
                                        return <Option value={provider.provider_name} key={provider.provider_name}>{provider.provider_name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="vendor_id">
                            <Form.Item label="Vender Id" name="vender_id">
                                <Input />
                            </Form.Item>
                        </Col>,
                        <Col span={8} key="vendor_key">
                            <Form.Item label="Vender Key" name="vender_key">
                                <Input />
                            </Form.Item>
                        </Col>
                    ]
                },
                'Apps': {
                    checkboxItems: [
                        <Col span={8}>
                            <Form.Item
                                label="Show Booking Form In Copilot"
                                name="show_booking_form_in_copilot"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    ],
                    otherItems: [
                        <Col span={8}>
                            <Form.Item
                                label="App URL"
                                name="app_url"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    ]
                },
                'Others': {
                    checkboxItems: [<Col span={8}>
                        <Form.Item
                            label="Multiple Partner"
                            name="multiple_partner"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>],
                    otherItems: [<Col span={8}>
                        <Form.Item
                                label="Copy Reschedule Policy"
                                name="rescheduleOperatorCode"
                                rules={[{ required: false }]}
                            >
                                <Select
                                    className="operatorSelect"
                                    showSearch
                                    placeholder="Select operator"
                                >
                                    {isValidArray(activeOperators)&&
                                        activeOperators.map(printOperatorOption)
                                    }
                                </Select>
                        </Form.Item>
                    </Col>]
                }
            };

            // Add dynamic items from configKeys to the appropriate categories
            configKeys.forEach((configKey) => {
                const { key_name, key_description, is_boolean, key_options, is_multiple_options, placeholder, category } = configKey;
                const label = isValid(key_description) ? key_description : key_name;
                const categoryName = isValid(category) ? category : 'Others'; // Default category

                if (!categoryGroups[categoryName]) {
                    categoryGroups[categoryName] = {
                        checkboxItems: [],
                        otherItems: []
                    };
                }

                const formItem = (
                    <Col span={8} key={key_name}>
                        <Form.Item
                            label={label}
                            name={key_name}
                            rules={[{ required: false }]}
                            valuePropName={is_boolean ? "checked" : "value"}
                        >
                            {is_boolean ? (
                                <Checkbox />
                            ) : isValid(key_options) ? (
                                <Select
                                    mode={is_multiple_options ? "multiple" : undefined}
                                    options={key_options.split(',').map(opt => ({
                                        label: opt.trim(),
                                        value: opt.trim()
                                    }))}
                                    style={{ minWidth: '120px' }}
                                    placeholder={placeholder || `Select ${label}`}
                                />
                            ) : (
                                <Input
                                    placeholder={placeholder || `Enter ${label}`}
                                />
                            )}
                        </Form.Item>
                    </Col>
                );

                if (is_boolean) {
                    categoryGroups[categoryName].checkboxItems.push(formItem);
                } else {
                    categoryGroups[categoryName].otherItems.push(formItem);
                }
            });

            // Render all categories
            const formSections = Object.entries(categoryGroups).map(([category, items]) => {
                if (items.checkboxItems.length === 0 && items.otherItems.length === 0) {
                    return null;
                }

                return (
                    <div className="blockAgentPortal" key={category}>
                        <Divider orientation="left">{category}</Divider>
                        <Row gutter={[16, 16]}>
                            {items.checkboxItems}
                            {items.otherItems}
                        </Row>
                    </div>
                );
            }).filter(Boolean);

            setConfigFormItems(formSections);
        }
    }, [configKeys, dynamicPricingProvider]);

    const onFinish = (values) => {
        // Check if dynamic pricing is enabled but provider is not selected
        if (values.dynamic_pricing_enabled && !values.dynamic_pricing_provider) {
            message.error('Please select Dynamic Pricing Provider');
            return;
        }

        if (!values.dynamic_pricing_enabled && values.dynamic_pricing_provider) {
            values.dynamic_pricing_provider = null;
        }
        // Separate config values from other form values
        const configValues = {};
        const otherValues = {};

        Object.entries(values).forEach(([key, value]) => {
            if (configKeys.some(config => config.key_name === key)) {
                // Convert boolean to 1/0 for config fields
                configValues[key] = value === true ? 1 : value === false ? 0 : value;
            } else {
                otherValues[key] = value;
            }
        });

        dispatch(updateTravelConfig(
            selectedOperator.operator_code,
            { travels_app_config: configValues, ...otherValues, region: selectedRegion, subRegion: selectedSubRegion },
            () => {
                message.success("Updated successfully.");
            },
            (errmsg) => {
                message.error(errmsg);
            }
        ));
    };

    const onFinishFailed=()=>{

    }

    useEffect(()=>{
        if(isValidArray(selectedOperator)){
            form.setFieldsValue({
                use_remarks:selectedOperator.use_remarks,
                blocked_tickets_in_trip_sheet:selectedOperator.blocked_tickets_in_trip_sheet == 1?true:false,
                overdue_amount:selectedOperator.overdue_amount,
                send_payment_link:selectedOperator.send_payment_link == 1?true:false,
                whatsapp_message:selectedOperator.whatsapp_message==1?true:false,
                whatsapp_number:selectedOperator.whatsapp_number,
                whatsapp_image:selectedOperator.whatsapp_image,
                time_required_before_close:selectedOperator.time_required_before_close,
                is_use_review_feature:selectedOperator.is_use_review_feature == 1?true:false,
                send_marketing_sms:selectedOperator.send_marketing_sms == 1 ? true:false,
                maximum_ladies_reservation:selectedOperator.maximum_ladies_reservation,
                is_reschedule:selectedOperator.is_reschedule == 1 ? true :false,
                app_url:selectedOperator.app_url,
                show_booking_form_in_copilot:selectedOperator.show_booking_form_in_copilot == 1 ? true : false,
                about_tours:selectedOperator.about_tours,
                google_analytics_id:selectedOperator.google_analytics_id,
                dynamic_pricing_enabled:selectedOperator.dynamic_pricing_enabled == 1?true:false,
                dynamic_pricing_provider:selectedOperator.dynamic_pricing_provider,
                vender_id:selectedOperator.vender_id,
                vender_key:selectedOperator.vender_key,
                admin_client_url:selectedOperator.admin_client_url,
                multiple_partner:selectedOperator.multiple_partner == 1 ? true:false,
                both_decks_together: selectedOperator.both_decks_together == 1 ? true : false,
                show_fare_in_layout: selectedOperator.show_fare_in_layout == 1 ? true : false,
                contact_email:selectedOperator.contact_email,
                phone_number: selectedOperator.phone_number
            });
        }
    },[selectedOperator]);

    useEffect(()=>{
        if (isValidArray(configKeys) && isValidArray(selectedOperator) && isValid(selectedOperator.travels_app_config)){
            const configValues = {};
            Object.entries(selectedOperator.travels_app_config).forEach(([key, value]) => {
                const configKey = configKeys.find(config => config.key_name === key);
                if (configKey) {
                    if (configKey.is_boolean) {
                        configValues[key] = value == 1;
                    } else if (configKey.is_multiple_options && isValid(value)) {
                        configValues[key] = value.split(',').map(v => v.trim());
                    } else {
                        configValues[key] = value;
                    }
                }
            });
            form.setFieldsValue(configValues);
        }
    }, [configKeys, selectedOperator]);

    const setFormWithDefaultConfig = () => {
        const booleanFieldValues = [
            'use_remarks',
            'blocked_tickets_in_trip_sheet',
            'send_payment_link',
            'whatsapp_message',
            'is_use_review_feature',
            'send_marketing_sms',
            'is_reschedule',
            'show_booking_form_in_copilot',
            'dynamic_pricing_enabled',
            'multiple_partner',
            'show_fare_in_layout',
            'both_decks_together'
        ];
        if(isValid(selectedRegion)){
            const defaultConfigList = defaultOperatorConfig[selectedRegion];
            if (isValidArrayWithValues(defaultConfigList)){
                let multipleFieldValues = {}
                defaultConfigList.forEach((config)=>{
                    const configKey = configKeys.find(trv_config => trv_config.key_name === config.key_name);
                    if(isValid(configKey)){
                        if(configKey.is_boolean){
                            multipleFieldValues[config.key_name] = config.key_value == 1 ? true : false;
                        }else if(configKey.is_multiple_options){
                            multipleFieldValues[config.key_name] = config.key_value.split(',').map(v => v.trim());
                        }else{
                            multipleFieldValues[config.key_name] = config.key_value;
                        }
                    }
                    else if(booleanFieldValues.includes(config.key_name)) {
                        // Handle checkbox fields
                        multipleFieldValues[config.key_name] = config.key_value == 1 ? true : false;
                    }
                    else{
                        multipleFieldValues[config.key_name] = config.key_value;
                    }
                });
                form.setFieldsValue(multipleFieldValues);
            }
        }
    }

    return (
        <div className="operator-config-page">
            <Form
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                {/* Dynamic config form sections */}
                {configFormItems}
                {isValidArray(defaultRegions) &&<Row justify="center" align="middle" gutter={[16, 16]}>
                            <Col>
                                <Select
                                    placeholder="Select Region"
                                    style={{ width: 200 }}
                                    onChange={(value) => setSelectedRegion(value)}
                                    value={selectedRegion}
                                >
                                    {defaultRegions.map((region) => (
                                        <Option key={region} value={region}>
                                            {region}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            {isValid(selectedRegion) && selectedRegion == 'Tamil Nadu' && <Col>
                                <Select
                                    placeholder="Select Sub Region"
                                    style={{ width: 200 }}
                                    onChange={(value) => setSelectedSubRegion(value)}
                                    value={selectedSubRegion}
                                >
                                        <Option key={'Chennai'} value={'Chennai'}>
                                            Chennai
                                        </Option>
                                        <Option key={'Coimbatore'} value={'Coimbatore'}>
                                            Coimbatore
                                        </Option>
                                </Select>
                            </Col>}
                            <Col>
                                <Button
                                    type="primary"
                                    disabled={!selectedRegion}
                                    onClick={() => {
                                        // Add your set default logic here
                                        setFormWithDefaultConfig();
                                        message.success(`Default configuration set from ${selectedRegion}`);
                                    }}
                                >
                                    Set Default
                                </Button>
                            </Col>
                        </Row>
                }
                <Row justify="center" align="middle" gutter={[16, 16]}>
                    <Col>
                        <Form.Item label={null}>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={null}>
                            <Button
                                type="danger"
                                onClick={() => setIntegrationModalOpen(true)}
                                disabled={!selectedOperator}
                            >
                                Integration E-mail
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <IntegrationEmailComponent
                integrationModalOpen={integrationModalOpen}
                setIntegrationModalOpen={setIntegrationModalOpen}
                selectedData={selectedOperator}
            />
        </div>
    );                      
}


