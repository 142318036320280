import React from "react";
import { Row , Col, message } from "antd";
import './render-fare.style.scss';
import { EditFareComponent } from "../../edit-fare-component/edit-fare.component";
import { useState } from "react";
import { useEffect } from "react";
import { isValid, isValidArray } from "../../../utils/utilities";
import { getAPIDateFormat } from "../../../utils/date.utils";

const getActiveDate = (selectedDates) =>{
    return selectedDates[0];
}

export const RenderScheduleFareBlock = (props) => {

        const{
            scheduleData,
            selectedDates
        } = props;

        const [currentDate , setCurrentDate] = useState(null);
        const [fetchDate , setFechDate] = useState(null);

        const onFareUpdateSuccess=()=>{
        }

        useEffect(()=>{
            if(isValidArray(selectedDates)&&selectedDates.length>0){
                setCurrentDate(getActiveDate(selectedDates));
            }
        },[selectedDates]);

        return(
            <Row gutter={[16,16]} justify="center" align="middle" flex={1}>
                <Col>
                    <EditFareComponent 
                        scheduleDescription={scheduleData.description}
                        travelDate={fetchDate}
                        scheduleId={scheduleData.id}
                        seatGroupIds={scheduleData.bus.seat_group_ids}
                        selectedBus={scheduleData.bus}
                        onFareUpdateSuccess={onFareUpdateSuccess}
                        supportedFare ={scheduleData.bus.supported_fare}
                        onCancel={()=>{
                        
                        }}
                        selectedDates={selectedDates}
                        showGetPriceButton={true}
                        tripDynamicPricing = {null}
                        onGetPriceClick={()=>{
                            if(isValid(currentDate)){
                                setFechDate(currentDate);
                            }else{
                                message.error("Please select active date.")
                            }
                        }}
                        showTravelDate={false}
                    />
                </Col>
            </Row>
        )
}
