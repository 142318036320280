import { GET_SERVICES_BY_CITY_URL, UPDATE_SERVICE_ACCESS_URL } from "../utils/api-url";
import { postCall } from "../utils/network.util";


export const getServicesByCity=(agentIds,onSuccess=()=>{},onFailure=()=>{})=>{
    var fetched = postCall(GET_SERVICES_BY_CITY_URL, {
        agentIds:agentIds,
        operatorCode:global.operatorCode
    });
     fetched.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.payload);
        }else{
          onFailure(data.payload.Message);
        }
      })
    .catch((reason)=> {
        onFailure("Failed to fetch services by city");
    })
  }

  export const updateServiceAccess=(data,onSuccess=()=>{},onFailure=()=>{})=>{
    var fetched = postCall(UPDATE_SERVICE_ACCESS_URL, {data});
    fetched.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.payload);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to update service access");
    })
  }   