import React, {useState, useEffect } from "react";
import '../trip-create.style.scss';
import { Tabs, Button ,message } from "antd";
import { isValid, isValidArray, isValidString } from "../../../utils/utilities";
import { TripStops } from "./trip-stops";
import { useDispatch, useSelector } from "react-redux";
import { getAddressLocations, insertTripStops, setActiveCity } from "../../../actions/trip-create.actions";
import { selectedCitiesSelector, selectedStopsSelector } from "../../../selector/trip-create.selector";

const { TabPane } = Tabs;

export const AddStops = (props) => {
    const {
        tripScheduleId,
        setShowVerifyPage,
        setHeaderStep,
        setEnableLocationPick,
        childRef
    }= props;

    const dispatch = useDispatch();

    const selectedCities = useSelector(selectedCitiesSelector);
    const selectedStops = useSelector(selectedStopsSelector);

    const [cityLength, setCityLength] = useState(0);
    const [addressLocation, setAddressLocation] = useState({});
    const [addressIds, setAddressIds] = useState({});
    const [isNextDisable, setIsNextDisable] = useState(true);


    const onSuccessAddress = (data) => {
        let addressList = data.addressLocations;
        let tempAddressLocation = {};
        let tempAddressIds = {...addressIds};
        addressList.map((address) => {
            let cityName = address.city;
            if (isValid(tempAddressLocation[cityName])){
                let tempAddressList = [...tempAddressLocation[cityName]];
                tempAddressList.push(address);
                tempAddressLocation[cityName] = tempAddressList;
            }
            else{
                let tempAddressList = [];
                tempAddressList.push(address);
                tempAddressLocation[cityName] = tempAddressList;
            }
            if (isValid(tempAddressIds[cityName])){
                let addressId = {...tempAddressIds[cityName]};
                addressId[address.name] = address.id;
                tempAddressIds[cityName] = addressId;
            }
            else {
                let addressId = {};
                addressId[address.name] = address.id;
                tempAddressIds[cityName] = addressId;
            }
        });
        setAddressLocation({...tempAddressLocation});
        setAddressIds({...tempAddressIds});
    }

    const renderData = (currentCityName) => {
    
        return (
            <div style = {{borderWidth: 'thin', borderStyle: 'solid', borderColor: '#C4C4C4'}}>
                <TripStops
                    addressLocation = {addressLocation[currentCityName]}
                    cityName = {currentCityName}
                    tripScheduleId = {tripScheduleId}
                    getCityAddress = {getCityAddress}
                    isModal={isModal} 
                    setModal={setModal}
                    onCallBackNextButton={(disableValue)=>{
                        setIsNextDisable(disableValue);
                    }}
                    setEnableLocationPick={setEnableLocationPick}
                    childRef={childRef}
                />
            </div>
        )
    }

    const onFailureAddress=(errMsg)=>{
        message.error(errMsg);
    }

    const getCityAddress = () => {
        let length = selectedCities.length;
        setCityLength(length);
        let cities = []
        selectedCities.map((city,index) => {
            if(isValid(city.city_name)){
                cities.push(city.city_name);
            }
        });
        if (length>0){
            dispatch(getAddressLocations(cities,false,onSuccessAddress,onFailureAddress));
        }
    }

    useEffect(() => {
        getCityAddress();
    },[selectedCities])

    const [activeKey, setActiveKey] = useState('0');
    const [isModal, setModal] = useState(false);

    const onKeyChange = (key) => {
        setModal(false);
        if (parseInt(key)!==cityLength){
            setActiveKey(key);
        }
        else{
            // Check if each city has at least one stop
            let hasEmptyCity = false;
            let emptyCityName = "";
            
            for (const element of selectedCities) {
                const cityName = element.city_name;
                if (!isValidString(cityName) || 
                    !isValidArray(selectedStops[cityName]) || 
                    !isValidArray(selectedStops[cityName].stops) || 
                    selectedStops[cityName].stops.length === 0) {
                    hasEmptyCity = true;
                    emptyCityName = cityName;
                    break;
                }
            }
            
            if (hasEmptyCity) {
                // Show warning if any city has no stops
                message.warning(`Please add at least one stop for ${emptyCityName}`);
                return;
            }
            
            let cityStopIds = {};
            selectedCities.map((element) => {
                let cityName = element.city_name;
                if(isValidString(cityName)&&isValidArray(selectedStops[cityName])){
                    let {
                        stops
                    } = selectedStops[cityName];
                    stops.map((stop) => {
                        let tempAddressId = stop.id;
                        if (isValid(cityStopIds[cityName])){
                            let tempCityStops = [...cityStopIds[cityName]]
                            tempCityStops.push({address_id:tempAddressId,time:stop.stop_time,day:stop.day});
                            cityStopIds[cityName] = tempCityStops;
                        }
                        else{
                            let tempCityStops = [];
                            tempCityStops.push({address_id:tempAddressId,time:stop.stop_time,day:stop.day});
                            cityStopIds[cityName] = tempCityStops;
                        }
                    })
                }
            });
            dispatch(insertTripStops(cityStopIds,tripScheduleId, (data)=> {setShowVerifyPage(true); setHeaderStep(3)}))
        }
    }

    const cityTabs = () => {
        let returnSteps = [];
        selectedCities.map((city, index) => {
            returnSteps.push(<TabPane tab={city.city_name} key={index} >
                                    {renderData(city.city_name)}
                                    <div className="flex-end-div">
                                        <Button 
                                        type="primary" 
                                        className="marginTop " 
                                        onClick= {()=>onKeyChange((index+1).toString())}
                                        disabled={isNextDisable||isModal?true:false}
                                        >
                                            {index<cityLength-1?'Next': 'Continue'}
                                        </Button>
                                    </div>
                            </TabPane>)
        });
        return returnSteps;
    }

    useEffect(()=>{
        dispatch(setActiveCity(selectedCities[activeKey]));
    },[activeKey]);


    return (
        <div >
            <div>
                <div>
                    <h3>
                        Add Stops
                    </h3>
                </div>
                <div className="create-trip-underline"></div>
            </div>
            <Tabs defaultActiveKey='0' activeKey={activeKey} onChange={onKeyChange} >
                {cityTabs()}
            </Tabs>
        </div>
    )
}