import { Button, message, Table,Row,Col } from "antd";
import React, { useEffect, useState } from "react";
import '../styles/incomplete-trip.style.scss';
import { isValidArray } from "../utils/utilities";
import { getInActiveSchedules } from './../actions/admin.action';
import { tripCreate } from './../components/app-header/app.header.module';
import {
    DeleteOutlined
  } from '@ant-design/icons';
import {
    removeSchedules
} from './../actions/admin.action';
import { useHistory } from "react-router-dom";
import { showConfirm } from "../components/custom-components/custom-component";
import { loadingAction } from "../actions/loading-actions";
import { useDispatch, useSelector } from "react-redux";
import { getBusDetailsAction, resetTripCreate } from "../actions/trip-create.actions";
import { tripCreateReducerSelector } from "../selector/trip-create.selector";
import { CloneScheduleComponent } from "../components/clone-schedule-component/clone-schedule.component";
import { EditTripComponent } from "../components/edit-trip/edit-trip-component";

const getOutPutFormated =(data)=>{
    let incompleted=[],completed=[];
    data.forEach(element => {
        if(element.is_trip_completed){
            completed.push(element);
        }else{
            incompleted.push(element);
        }
    });

    return {
        incompleted,completed
    }
}

const showTripCreateButton=()=>{
    if(tripCreate()){
        return true;
    }else{
        return false;
    }
}


const DrawLine =()=>{

    return(
        <div className="incomplete-trip-underline">

        </div>
    )
}

const Title = () =>{
        return (
            <div className="title-agent">
                <h3>
                    New Trip
                </h3>
                <DrawLine />
            </div>
        )
}

export const IncompleteTripPage = (props) => {

    const [inCompleteTrips , setIncompleteTrips]=useState([]);
    const [completedTrips , setCompleteTrips]=useState([]);
    const [selectedTripIds,setSelectedTripIds]=useState([]);

    const dispatch = useDispatch();
    const tripCreateReducer = useSelector(tripCreateReducerSelector);

    let { busesData } = tripCreateReducer

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=(data)=>{
        if(isValidArray(data.schedules)){
            let{
                incompleted,
                completed
            } = getOutPutFormated(data.schedules);
            setIncompleteTrips([...incompleted]);
            setCompleteTrips([...completed]);
            setLoading(false);
        }else{
            setIncompleteTrips([]);
            setCompleteTrips([]);
            setLoading(false);
        }
    }
    let history = useHistory();

    const onFailure=(errorMessage)=>{
        setLoading(false);
        message.error(errorMessage);
    }

    const tripCreatePage = () =>{
        history.push("/trip-create");
        dispatch(resetTripCreate());
    }

    useEffect(()=>{
        reload();
        dispatch(getBusDetailsAction());
    },[]);

    const reload =()=>{
        setLoading(true);
        getInActiveSchedules(onSuccess,onFailure);
    }


    const columns = [
        {
            title:"Trip Description",
            dataIndex:"description",
            key:"description"
        },
        {
            title:"Origin",
            dataIndex:'origin',
            key:"origin"
        },
        {
            title:"Destination",
            dataIndex:"destination",
            key:"destination"
        },
        {
            title:"Action",
            dataIndex:"action",
            render:(text, record)=>{
                if(record.is_trip_completed){
                return(<Row gutter={[16,16]}>
                            <Col>
                                <EditTripComponent 
                                    scheduleId = {record.id}
                                />
                            </Col>
                            <Col>
                                <CloneScheduleComponent 
                                    busesData={busesData}
                                    formData={record}
                                    onCloneSuccess={reload}
                                    isReturnSchedule={false}
                                />
                            </Col>
                            <Col>
                                <CloneScheduleComponent
                                    busesData={busesData}
                                    formData={record}
                                    onCloneSuccess={reload}
                                    isReturnSchedule={true}
                                />
                            </Col>
                       </Row>)
                }else{
                    return (<Row>
                                <Col>
                                    <EditTripComponent 
                                        scheduleId = {record.id}
                                    />
                                </Col>
                          </Row>);
                }
            }
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedTripIds(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
      };

    const onDelete=()=>{
        if(selectedTripIds.length>0){
            showConfirm(()=>{
                removeSchedules(selectedTripIds,(successMessage)=>{
                    message.success(successMessage);
                    getInActiveSchedules(onSuccess,onFailure);
                },(errorMessage)=>{
                    message.error(errorMessage);
                })
            },"Are you sure to delete trip?");
        }else{
            message.warning("Please select atleast one incompleted trip");
        }
    }

    return (
        <div className="incomplete-page-root">
            <div className="incomplete-page-inner-div">
                <Title />
                {showTripCreateButton()&&
                <div>
                    <Button onClick= {()=> tripCreatePage()}>Create New</Button>
                </div>
                }
                <div>
                    <h3>
                        In complete trips
                    </h3>
                </div>
                <div>
                    <Button
                        icon={<DeleteOutlined style={{fontSize:'30px'}}/>}
                        className="outline-button"  
                        onClick={onDelete}
                    />
                </div>
                <Table 
                    columns={columns}
                    dataSource={inCompleteTrips}
                    pagination={false}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection
                      }}
                    rowKey={(record) => {
                        return record.id;
                    }}
                />
                 <div>
                    <h3>
                        In active trips
                    </h3>
                </div>
                <Table 
                    columns={columns}
                    dataSource={completedTrips}
                    pagination={false}
                    // rowSelection={{
                    //     type: 'checkbox',
                    //     ...rowSelection
                    //   }}
                    rowKey={(record) => {
                        return record.id;
                    }}
                />
            </div>
        </div>
    )
}