import { UPDATE_PARTNER_URL } from "../../utils/api-url";
import { postCall } from "../../utils/network.util";
import { checkActionAllowed } from "../../utils/utilities";
import { ADMIN_CHANGE_SEAT_LAYOUT } from "../../utils/constant";
export const updatePartner=(tripId,partnerCode,onSuccess=()=>{},onFailure=()=>{})=>{
    var updateAction = postCall(UPDATE_PARTNER_URL, {
        tripId:tripId,
        partnerCode:partnerCode,
        operator_code:global.operatorCode,
        updatedBy:global.userName
      });
      updateAction.then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                onSuccess(clientPayload.Message);
            }else{
                onFailure(clientPayload.Message);
            }
        }
      })
      .catch((reason)=> {
        console.error({reason});
        onFailure("Failed to update partner!");
      })
}

export const isChangeLayoutTabAllowed = ()=> {
  if(checkActionAllowed(ADMIN_CHANGE_SEAT_LAYOUT)){
      return true;
  }else{
      return false;
  }
}