import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgentColorDetails, updateAgentColors } from "../../actions/agent-colour.action";
import { agentColorDetailsSelector } from "../../selector/agent-colour.selector";
import { Form, Input, Button } from 'antd';
import '../../styles/colour-config-page.style.scss';

export const ColourConfigPage = () => {
    const dispatch = useDispatch();
    const agentColorDetails = useSelector(agentColorDetailsSelector);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getAgentColorDetails(global.operatorCode));
    }, [dispatch]);

    useEffect(() => {
        if (agentColorDetails) {
            const {
                operator_code,
                primary_color,
                secondary_color,
                default_button_bg_color,
                default_button_txt_color,
                header_bg_color,
                header_txt_color,
                drop_down_menu_bg_color,
                drop_down_menu_txt_color,
                drop_down_selected_bg_color,
                drop_down_selected_txt_color,
            } = agentColorDetails;

            form.setFieldsValue({
                operatorCode: operator_code,
                primaryColor: primary_color,
                secondaryColor: secondary_color,
                defaultButtonBgColor: default_button_bg_color,
                defaultButtonTxtColor: default_button_txt_color,
                headerBgColor: header_bg_color,
                headerTxtColor: header_txt_color,
                dropDownMenuBgColor: drop_down_menu_bg_color,
                dropDownMenuTxtColor: drop_down_menu_txt_color,
                dropDownSelectedBgColor: drop_down_selected_bg_color,
                dropDownSelectedTxtColor: drop_down_selected_txt_color
            });
        }
    }, [agentColorDetails, form]);

    const onFinish = (values) => {
        const { operatorCode, ...colorData } = values;
        dispatch(updateAgentColors(
            global.operatorCode, 
            colorData,
            () => {},
            () => {},
        ));
    };

    return (
        <div className="colour-config-page">
            <Form
                form={form}
                onFinish={onFinish}
                name="colorConfig"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 4 }}
                initialValues={{ operatorCode: global.operatorCode }}
            >

                {[
                    { label: "Primary color", name: "primaryColor" },
                    { label: "Secondary color", name: "secondaryColor" },
                    { label: "Default button bg color", name: "defaultButtonBgColor" },
                    { label: "Default button text color", name: "defaultButtonTxtColor" },
                    { label: "Header bg color", name: "headerBgColor" },
                    { label: "Header text color", name: "headerTxtColor" },
                    { label: "Drop down menu bg color", name: "dropDownMenuBgColor" },
                    { label: "Drop down menu text color", name: "dropDownMenuTxtColor" },
                    { label: "Drop down selected bg color", name: "dropDownSelectedBgColor" },
                    { label: "Drop down selected text color", name: "dropDownSelectedTxtColor" }
                ].map(({ label, name }) => (
                    <Form.Item
                        key={name}
                        label={label}
                        name={name}
                        rules={[{ required: true, message: `Please select the ${label.toLowerCase()}!` }]}
                    >
                        <Input 
                            type="color"
                        />
                    </Form.Item>
                ))}

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Update Colors
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};


