/**
 * Loading related actions can be created here
 */

import { GET_AGENTS , CREATE_AGENT_TRANSACTION , GET_AGENTS_TRANSACTIONS,GET_AGENTS_ACTIONS, GET_USER_ACTIONS, GET_AGENT_PRIVILEGES, GET_OPERATOR_PRIVILEGE_ACTIONS } from "./action-types";
import { GET_AGENTS_URL , CREATE_AGENT_TRANSACTION_URL , GET_AGENTS_TRANSACTIONS_URL, UPDATE_AGENT_TYPE_URL, UPDATE_AGENT_TRANSACTION_STATUS_URL, GET_AGENTS_BY_OPERATOR_CODE_URL, GET_PREPAID_OTA_ACCOUNT_URL, GET_SRMS_PREPAID_OTA_ACCOUNT_URL, GET_AGENT_ACTIONS_URL, GET_USER_ACTIONS_URL, DELETE_USER_PRIVILEGE_URL, INSERT_USER_PRIVILEGE_URL, GET_AGENT_PRIVILEGES_URL, GET_OPERATOR_PRIVILEGE_ACTIONS_URL, INSERT_OPERATOR_PRIVILEGE_ACTION_URL, DELETE_OPERATOR_PRIVILEGE_ACTION_URL, CREATE_AGENT_PRIVILEGE_URL} from "./../utils/api-url";
import { getCall, postCall } from "../utils/network.util";
import { getSrmsMavenAgentId } from "../app.config";

export const getAgents = (prePaidOnly=false,postPaidOnly=false,onSuccess=()=>{},onFailure=()=>{})=>{
    return{
        type:GET_AGENTS,
        payload: {
            url:GET_AGENTS_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              prePaidOnly:prePaidOnly,
              postPaidOnly:postPaidOnly,
              privilege:global.privilege,
              userName:global.userName
            },
            onSuccess:onSuccess,
            onFailure:onFailure   
        }
    }
}

export const getAgentActions = (onSuccess=()=>{},onFailure=()=>{})=>{
  var fetchedData = getCall(GET_AGENT_ACTIONS_URL, {
    operator_code:global.operatorCode
  });
  fetchedData.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to get agent actions.")
    }

  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to get agent actions!");
  })
}

export const getUserActions = (userName,privilege,onSuccess=()=>{},onFailure=()=>{})=>{
  var fetchedData = postCall(GET_USER_ACTIONS_URL, {
    operator_code:global.operatorCode,
    userName:userName,
    privilege:privilege
  });
  fetchedData.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to get user actions.")
    }

  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to get user actions!");
  })
}

export const insertUserActions = (userName,privilege,onSuccess=()=>{},onFailure=()=>{})=>{
  var insertedData = postCall(INSERT_USER_PRIVILEGE_URL, {
    operator_code:global.operatorCode,
    userName:userName,
    agentPrivilege:privilege
  });
  insertedData.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to get user actions.")
    }

  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to get user actions!");
  })
}

export const deleteUserActions = (userName,privilege,onSuccess=()=>{},onFailure=()=>{})=>{
  var deletedData = postCall(DELETE_USER_PRIVILEGE_URL, {
    operator_code:global.operatorCode,
    userName:userName,
    agentPrivilege:privilege
  });
  deletedData.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to get user actions.")
    }

  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to get user actions!");
  })
}

export const getAgentsTransactions = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{})=>{
    return{
        type:GET_AGENTS_TRANSACTIONS,
        payload: {
            url:GET_AGENTS_TRANSACTIONS_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              startDate:startDate,
              endDate:endDate
            },
            onSuccess:onSuccess,
            onFailure:onFailure   
        }
    }
}

export const createAgentTransaction = ({
    agentId, 
    date, 
    amount, 
    paymentType, 
    status, 
    refId,
    description
},onSuccess=()=>{},onFailure=()=>{}) =>{
    return {
        type:CREATE_AGENT_TRANSACTION,
        payload:{
            url:CREATE_AGENT_TRANSACTION_URL,
            api:postCall,
            apiPayload:{
                operatorCode:global.operatorCode,
                data:{
                    agentId:agentId, 
                    date:date,
                    amount:amount,
                    paymentType:paymentType,
                    status:status,
                    refId:refId,
                    description:description
                }
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
}


export const updateAgentType=(agentId,postPaidLimit,onSuccess=()=>{},onFailure=()=>{})=>{
    var updated = postCall(UPDATE_AGENT_TYPE_URL, {
        agentId: agentId,
        postPaidLimit : postPaidLimit
      });
      updated.then((data) => {
        if(data){
          onSuccess();
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to update agent");
      })
}


export const updateAgentTransaction=(agentTransactionId,status,amount,onSuccess=()=>{},onFailure=()=>{})=>{
  var updated = postCall(UPDATE_AGENT_TRANSACTION_STATUS_URL, {
      agentTransactionId : agentTransactionId,
      status:status,
      amount:amount
    });
    updated.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
        onSuccess();
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Falied to update transaction");
    })
}

export const getAgentsByOperatorCode=(onSuccess=()=>{},onFailure=()=>{})=>{
  var updated = postCall(GET_AGENTS_BY_OPERATOR_CODE_URL, {
    operatorCode:global.operatorCode,
    });
    updated.then((data) => {
      if(data){
        onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to update agent");
    })
}

export const getPrepaidOTAAccountData=(agentId, onSuccess=()=>{},onFailure=()=>{})=>{
  let srmsAgentId = getSrmsMavenAgentId();
  var fetchB2B = postCall(GET_PREPAID_OTA_ACCOUNT_URL, {
    operatorCode:global.operatorCode,
    agentId: agentId
  });
  var fecthSRMS = postCall(GET_SRMS_PREPAID_OTA_ACCOUNT_URL, {
    operatorCode:global.operatorCode,
    agentId: srmsAgentId
  });
  Promise.all([fetchB2B,fecthSRMS]).then(function(promiseData) {
    let b2bData = promiseData[0];
    let srmsData = promiseData[1];
    if(b2bData.status == "SUCCESS"&&srmsData.status == "SUCCESS"&&b2bData.payload.Status == "SUCCESS"&&srmsData.payload.Status == "SUCCESS"){
      let finalData = {
        amountDeducted:b2bData.payload.Payload.amountDeducted+srmsData.payload.Payload.amountDeducted,
        balanceAmount:b2bData.payload.Payload.balanceAmount+srmsData.payload.Payload.balanceAmount,
        cancelledCount:b2bData.payload.Payload.cancelledCount+srmsData.payload.Payload.cancelledCount,
        confirmedCount:b2bData.payload.Payload.confirmedCount+srmsData.payload.Payload.confirmedCount,
        dayStartBalance:b2bData.payload.Payload.dayStartBalance+srmsData.payload.Payload.dayStartBalance,
        topUpForToday:b2bData.payload.Payload.topUpForToday+srmsData.payload.Payload.topUpForToday
      }
      onSuccess(finalData);
    }else{
      onFailure("Failed to get agent");
    }
  }).catch((reason)=> {
    onFailure("Failed to get agent");
  })
  .catch((reason)=> {
    onFailure("Failed to get agent");
  })
}

export const getAgentPrivileges = (onSuccess=()=>{},onFailure=()=>{})=>{
  return {
    type:GET_AGENT_PRIVILEGES,
    payload:{
      url:GET_AGENT_PRIVILEGES_URL+`?isAgentsPage=true`,
      api:getCall,
      apiPayload:{
        operatorCode:global.operatorCode,
        isAgentsPage:false
      },
      onSuccess:onSuccess,
      onFailure:onFailure
    }
  }
}

export const getOperatorPrivilegeActions = (privilege,onSuccess=()=>{},onFailure=()=>{})=>{
  return {
    type:GET_OPERATOR_PRIVILEGE_ACTIONS,
    payload:{
      url:GET_OPERATOR_PRIVILEGE_ACTIONS_URL,
      api:postCall,
      apiPayload:{
        operatorCode:global.operatorCode,
        privilege:privilege
      },
      onSuccess:onSuccess,
      onFailure:onFailure
    }
  }
}

export const insertOperatorPrivilegeAction= (privilege,agentAction,onSuccess=()=>{},onFailure=()=>{})=>{
  var insertedData = postCall(INSERT_OPERATOR_PRIVILEGE_ACTION_URL, {
    operatorCode:global.operatorCode,
    privilege:privilege,
    agentAction:agentAction
  });
  insertedData.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to get user actions.")
    }
  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to get user actions!");
  })
}

export const deleteOperatorPrivilegeAction = (privilege,agentAction,onSuccess=()=>{},onFailure=()=>{})=>{
  var deletedData = postCall(DELETE_OPERATOR_PRIVILEGE_ACTION_URL, {
    operatorCode:global.operatorCode,
    privilege:privilege,
    agentAction:agentAction
  });
  deletedData.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to get user actions.")
    }
  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to get user actions!");
  })
}

export const createAgentPrivilege = (privilege,onSuccess=()=>{},onFailure=()=>{})=>{
  var createAgentPrivilege = postCall(CREATE_AGENT_PRIVILEGE_URL, {
    operatorCode:global.operatorCode,
    privilege:privilege
  });

  createAgentPrivilege.then((data) => {
    if(data.status == "SUCCESS"&&data.payload.Status == "SUCCESS"){
      onSuccess(data.payload.Payload);
    }else{
      onFailure("Unable to create agent privilege.")
    }
  })      
  .catch((reason)=> {
    console.error({reason});
    onFailure("Failed to create agent privilege!");
  })
}
