/**
 * @author sajocmathew
 * @description Reducer for the components in the page where the agent colours are configured
 */
import {
    RESET_ALL,
    SET_AGENT_COLOURS
} from '../actions/action-types';

const initialState = {
    agentColorDetails: null
}

export const agentColourReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AGENT_COLOURS:
            return {
                ...state,
                agentColorDetails: action.payload?.Payload || action.payload
            };

        case RESET_ALL:
            return {
                ...state,
                agentColorDetails: null
            };

        default:
            return state;
    }
}
  