import React, { useEffect, useState } from 'react';
import '../styles/service-access.style.scss';
import { getAgents } from '../actions/agents.actions';
import { agentsSelector } from '../selector/agents.selector';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { getServicesByCity, updateServiceAccess } from '../actions/service_access_actions';
import { ServicesList } from '../components/services-list/services-list.component';
import { loadingAction } from '../actions/loading-actions';
import { SingleUserSelect } from '../components/users-select/single-user-select.component';

const DrawLine =()=>{

    return(
        <div className="service-access-underline">

        </div>
    )
}
const Title = () =>{
    return (
        <div className="title-service-access">
            <h3>
                Service Access
            </h3>
            <DrawLine />
        </div>
    )
}

export const ServiceAccessPage = () => {

    const agents = useSelector(agentsSelector);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [servicesData, setServicesData] = useState({});

    const handleUserChange = (values) => {
        setSelectedUsers([values]);
        refetchServicesByCity([values]);
    };

    const dispatch = useDispatch();

    const onSuccess =()=>{

    }

    const onFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    const onSuccessGetServicesByCity = (data)=>{
        setServicesData(data);
        setLoading(false);
    }

    useEffect(()=>{
        dispatch(getAgents(false,false,onSuccess,onFailure));
    },[]);


    const refetchServicesByCity = (agentIds)=>{
        if (!agentIds || agentIds.length === 0) {
            message.warning('Please select users');
            return;
        }
        setLoading(true);
        getServicesByCity(agentIds,onSuccessGetServicesByCity,onFailure);
    }

    const handleUpdateServices = (changes) => {
        // API call to update services with changes
        updateServiceAccess(changes,onSuccessUpdateServices,onFailureUpdateServices);
    };

    const onSuccessUpdateServices = ()=>{
        message.success('Services updated successfully');
        refetchServicesByCity(selectedUsers);
    }

    const onFailureUpdateServices = (errMsg)=>{
        message.error(errMsg);
    }   
    const handleReset = () => {
        setSelectedUsers([]);
    };

    const setLoading = (bool)=>{
        dispatch(loadingAction(bool));
    }
    
    return (    
        <div className="service-access-page-root">
            <div className="service-access-page-inner-div">
                <Title />
                <SingleUserSelect 
                    agents={agents} 
                    selectedUsers={selectedUsers} 
                    onChange={handleUserChange} 
                    // Note: commenting below logic as we are not using multiple select
                    // renderBtnToGetServices={() => (
                    //     <Button onClick={refetchServicesByCity} className='get-services-btn'>
                    //         Get Services
                    //     </Button>
                    // )}
                />
                <ServicesList 
                    servicesData={servicesData}
                    selectedUsers={selectedUsers}
                    onUpdateServices={handleUpdateServices}
                    onReset={handleReset}
                />
            </div>
        </div>
    );
};
