import React ,{useContext} from 'react'
import { GoogleMap, useJsApiLoader ,Marker } from '@react-google-maps/api';
import { isValid, isValidArray } from '../../utils/utilities';
import { Directions } from './directions.component';
import googleDot from './../../images/google-dot.png';
import './map.component.style.scss';
import { DeviceContext } from './../../components/device-provider/device-provider';
import { useSelector } from 'react-redux';
import { originSelector , destinationSelector , wayPointsSelector , zoomSelector , centerSelector, preserveViewportSelector } from '../../selector/map-reducer.selector';
import { Button } from 'antd';


function MyComponent(props) {


  const {
    isMobile
  }=useContext(DeviceContext);

  let containerStyle = {
    width: '65vw',
    height: '75vh'
  };

  if(isMobile){
    containerStyle['width']='92vw';
    containerStyle['height']='100vh';
  }

    const {
        onMarkerDragEnd,
        enableLocationPick,
        setEnableLocationPick,
        onClickMap,
        onCancel
    } = props;

  const center = useSelector(centerSelector);
  const origin = useSelector(originSelector);
  const zoom = useSelector(zoomSelector);
  const destination = useSelector(destinationSelector);
  const wayPoints = useSelector(wayPointsSelector);
  const preserveViewport = useSelector(preserveViewportSelector);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${global.googleMapsApi}`
  })

  const onDragEnd =(coord,index)=>{
    let{
      latLng
    } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    onMarkerDragEnd(lat,lng,index);
  }

  return isLoaded ? (
        <div className='hightlight-div'>
            {enableLocationPick&&
               <div className='map-top-header'>
                  Click any where on map to set the location
                </div>
            }
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: isValid(center) ? center.lat : 13.0694,
                lng: isValid(center) ? center.lng : 80.1948
              }}              
              defaultZoom={zoom}
              zoom={zoom}
              onClick={(e)=>{
                if(enableLocationPick){
                  onClickMap(e.latLng.lat(),e.latLng.lng());
                }
              }}
              options={{
                mapId: 'map-id',
                cameraControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
                
              }}
            >
              { /* Child components, such as markers, info windows, etc. */ }
              {isValid(origin)&&isValid(origin.lat)&&
              <Marker
                  shape="MarkerShapeRect"
                  icon = {{
                    url: googleDot,
                    scale: 10,
                    fillOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#5384ED',
                    strokeColor: '#ffffff',
                  }}
                  key={'origin'}
                  position={origin}
                  label={{
                    text:origin.markerText,
                    className:"marker-label",
                    color:'black'
                  }}
                  draggable={origin.draggable}
                  onClick={() => {
                      
                  }}
                  onDragEnd={(coor)=>{
                    onDragEnd(coor,0)
                  }}
                  onDrag={(coor)=>{
                    onDragEnd(coor,0)
                  }}
              >
              </Marker>
                }
            {isValid(destination)&&isValid(destination.lat)&&
                <Marker
                  shape="MarkerShapeRect"
                  icon = {{
                    url: googleDot,
                    scale: 10,
                    fillOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#5384ED',
                    strokeColor: '#ffffff',
                  }}
                  draggable={destination.draggable}
                  key={'destination'}
                  position={destination}
                  label={{
                    text:destination.markerText,
                    className:"marker-label",
                    color:'black'
                  }}
                  onClick={() => {
                      
                  }}
                  onDragEnd={(coor)=>{
                    let index =destination.markerText - 1;
                    onDragEnd(coor,index)
                  }}
                  onDrag={(coor)=>{
                    let index =destination.markerText - 1;
                    onDragEnd(coor,index)
                  }}
                >
              </Marker>
              }
              {wayPoints.map((marker, index) => (
              isValid(marker)&&isValid(marker.location)&&isValid(marker.location.lat)&&
              <Marker
                  shape="MarkerShapeRect"
                  icon = {{
                    url: googleDot,
                    scale: 10,
                    fillOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#5384ED',
                    strokeColor: '#ffffff',
                  }}
                  key={index}
                  position={marker.location}
                  label={{
                    text:marker.location.markerText,
                    className:"marker-label",
                    color:'black'
                  }}
                  draggable={marker.location.draggable}
                  onDragEnd={(coor)=>{
                    let index =marker.location.markerText - 1;
                    onDragEnd(coor,index)
                  }}
                  onDrag={(coor)=>{
                    let index =marker.location.markerText - 1;
                    onDragEnd(coor,index)
                  }}
              >
              </Marker>
              ))}
              {isValidArray(origin)&&isValid(origin.lat)&&isValidArray(destination)&&isValid(destination.lat)&&
                <Directions origin={origin} destination={destination} wayPoints={wayPoints} preserveViewport={preserveViewport}/>
              }
            </GoogleMap>
            {enableLocationPick&&
            <div className='map-footer-header'>
              <Button  
              className='exit-btn'
              onClick={()=>{
                setEnableLocationPick(false);
                onCancel()
              }}
              >
                  Cancel
              </Button>
              <Button className='set-location'
              onClick={()=>{
                setEnableLocationPick(false);
              }}
              >
                  Set Location
              </Button>
            </div>
            }
        </div>
  ) : <></>
}

export default React.memo(MyComponent);

//Note refs: https://stackoverflow.com/questions/64165413/react-google-maps-api-directionsservice-keeps-rerendering-itself
// https://codesandbox.io/s/eloquent-hopper-16k2e?file=/src/App.js:288-431
// https://stackoverflow.com/questions/64165413/react-google-maps-api-directionsservice-keeps-rerendering-itself