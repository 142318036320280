import React from 'react';
import { Collapse, Row, Col } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getDate } from '../../../utils/date.utils';
import './cities-removed.style.scss';
import { DisplayCalendar } from '../display-calender/display-calender.component';

const CityPanel = ({ item, selectedDate }) => {
    const [expandedCities, setExpandedCities] = React.useState([]);
    const [showCalendars, setShowCalendars] = React.useState({});

    const toggleCalendar = (cityName) => {
        setShowCalendars(prev => ({
            ...prev,
            [cityName]: !prev[cityName]
        }));
    };

    return (
        <div className="cities-removed-details">
            <Row className="cities-header">
                <Col xs={12} md={8}>City</Col>
                <Col xs={12} md={8}>Dates Removed</Col>
            </Row>
            <Collapse
                expandIconPosition="left"
                ghost
                className="custom-collapse"
                expandIcon={() => null}
                activeKey={expandedCities}
                onChange={(keys) => setExpandedCities(keys)}
            >
                {item.cities.map((cityData, idx) => (
                    <div key={idx} className="city-item">
                        <Row align="middle">
                            <Col xs={12} md={8}>{cityData.city}</Col>
                            <Col xs={12} md={8}>
                                {cityData.datesRemoved.map(date => getDate(moment(date))).join(', ')}
                                <CalendarOutlined 
                                    style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '16px', fontWeight: 'bold' }}
                                    className={showCalendars[cityData.city] ? "expanded-calendar-icon" : "collapsed-calendar-icon"}
                                    onClick={() => toggleCalendar(cityData.city)}
                                />
                                {showCalendars[cityData.city] && (
                                    <div className="cities-removed-calendar-container" >
                                        <DisplayCalendar 
                                            selectedDate={selectedDate}
                                            dates={cityData.datesRemoved}
                                            isRemoved={true}
                                            flexEnd={false}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                ))}
            </Collapse>
        </div>
    );
};

export const CitiesRemovedTab = ({ selectedDate, data }) => {
    return (
        <div className="cities-removed-tab">
            {data && data.length > 0 ? (
                <Collapse
                    expandIconPosition="left"
                    ghost
                >
                    {data.map((item, index) => (
                        <Collapse.Panel 
                            key={index}
                            header={`${index + 1}. ${item.description}`}
                        >
                            <CityPanel 
                                item={item}
                                selectedDate={selectedDate}
                            />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <p className="no-data">No cities removed for the selected date.</p>
            )}
        </div>
    );
}; 