import React,{useState} from "react";
import { Button, message,Checkbox, Modal } from "antd";
import { showConfirm } from './../custom-components/custom-component';
import { handleBlockByAdminSeats } from './block-by-admin.module';
import { isValid, isValidArray,checkActionAllowed } from "../../utils/utilities";
import { ACTION_BLOCK_BY_ADMIN_SEATS_ALLOWED, ACTION_PERMANENT_ADMIN_BLOCK } from './../../utils/constant';
import './block-by-admin.style.scss';

export const BlockByAdminComponent = (props) => {

    const{
        selectedSchedule,
        selectedSeats,
        onSuccess,
        disableAdminBlock
    }=props;

    const [isPermanentBlock,setIsPermanentBlock] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onFailure=()=>{
        message.error("Failed to block trips!")
    }

    const handleOk = () => {
        if(isValidArray(selectedSchedule)&&isValidArray(selectedSchedule.trip)&&isValid(selectedSchedule.trip.id)){
            let tripId=selectedSchedule.trip.id;
            let tripRouteId = selectedSchedule.trip_route_id;
            handleBlockByAdminSeats(tripId,tripRouteId,selectedSeats,isPermanentBlock,onSuccess,onFailure);
        }else{
            message.error("Trip not selected!")
        }
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    if(checkActionAllowed(ACTION_BLOCK_BY_ADMIN_SEATS_ALLOWED)){
        return (
            <div>
                <Button onClick={()=>setIsModalOpen(true)} disabled={disableAdminBlock} className="admin-block">
                    Admin Block
                </Button>
                <Modal visible={isModalOpen} title="Block Seats" onOk={handleOk} onCancel={handleCancel} cancelButtonProps={{className:"exit-btn"}} >
                    <div>
                        <span>Are you sure to block seats?</span>
                        {checkActionAllowed(ACTION_PERMANENT_ADMIN_BLOCK)&&<div style={{ marginTop: '10px' }}>
                            <Checkbox
                                checked={isPermanentBlock}
                                onChange={(e) => setIsPermanentBlock(e.target.checked)}
                            >
                                Permanent Block
                            </Checkbox>
                        </div>}
                    </div>
                </Modal>
            </div>
        )
    }else{
        return null
    }
}