import React ,  { useEffect , useState} from "react";
import './remove-cities.style.scss';
import {  Button , Modal, Table , Row , Col, message, Typography} from 'antd';
import { useDispatch } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";
import { getCities, getViacitiesDetails, reActivateTripRoutesByTripId } from "../../actions/admin.action";
import moment from "moment";
import { isValidArray, isValid } from "../../utils/utilities";

export const RemoveCities = (props) => {
    const{
        schedule
    } =props;

    const { Text } = Typography;

    const dispatch = useDispatch();
    const [cities, setCities] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys	,setSelectedRowKeys	] = useState([]);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const verifyCityValidation = (isRemove,selectedRowKeys) => {
        let cityPosition = [] , isDayOneCityExits = false;
        cities.map((city) => {
            if (selectedRowKeys.includes(city.city) && city.is_active == isRemove) {
                cityPosition.push(city.city);
            }
            if(isRemove && city.day == 0 && (city.is_active == 1 && !selectedRowKeys.includes(city.city))){
                isDayOneCityExits = true;
            }
        });
        return {cityValidation:cityPosition,isDayOneCityExits};
    }

    const formatCityPositioning = (isAdd) => {
        let cityPosition = [];
        cities.map((city) => {
            var isInserted = false;
            if (isAdd) {
                if (selectedRowKeys.includes(city.city) && (city.is_active == false)) {
                    cityPosition.push(city.city);
                    isInserted = true;
                }
            }
            else {
                if (selectedRowKeys.includes(city.city) && (city.is_active)) {
                    isInserted = true;
                }
            }
            if (!isInserted) {
                if (city.is_active) {
                    cityPosition.push(city.city);
                }
            }
        })
        return cityPosition;
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys	:selectedRowKeys	
      };


    const getCitiesAction=()=>{
        setLoading(true);
        getViacitiesDetails(schedule.schedule_id,moment(schedule.trip.start_date),(data)=>{
            setCities(getCities(data));
            setLoading(false);
        },()=>{
            setLoading(false);
        },1);
    }

    const updateCityRoute = (isAdd = true) => {
        let {cityValidation,isDayOneCityExits} = verifyCityValidation(!isAdd,selectedRowKeys);
        if(isDayOneCityExits || isAdd){
            if (cityValidation.length!== selectedRowKeys.length) {
                if (isAdd) {
                    message.error("Cannot add cities which is already added.");
                }
                else {
                    message.error("Cannot remove cities which is already removed.");
                }
            }
            else if (selectedRowKeys.length == 0) {
                message.warn("Please select cities to add/remove");
            }
            else {
                let cityPosition = formatCityPositioning(isAdd);
                if (isValidArray(cityPosition)&&cityPosition.length>1) {
                    reActivateTripRoutesByTripId(schedule.trip.id,cityPosition,()=>{
                        setLoading(false);
                        setSelectedRowKeys([]);
                        getCitiesAction();
                        handleOk();
                    },()=>{
                        setLoading(false);
                        handleOk();
                    });
                }
                else{
                    message.warning("Trip should have atleast 2 cities.");
                }
            }
        }else{
            if(!isDayOneCityExits){
                message.warning("Atleast one city should be there in day 1");
            }else{
                message.warning("Unable to perform action.");
            }
        }
    }

    useEffect(()=>{
        getCitiesAction();
    },[]);


    return (
        <div>
            <Button
                className="bordered-btn send-driver-details-btn"  
                onClick={()=>{
                    showModal();
                }}
            >
                Manage Cities
            </Button>
            <Modal
                    title={"Manage Cities"}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer= {[
                        <Button key="exit" className="exit-btn" onClick= {handleCancel}>Exit</Button>,
                        <Button key="add" onClick = {()=> updateCityRoute(true)}>Add Cities</Button>,
                        <Button key="remove" onClick = {()=> updateCityRoute(false)}>Remove Cities</Button>
                    ]}
                >
                 <Row justify="center" align="center" gutter={[16,16]}>
                    <Col>
                        <Table 
                        dataSource={cities}
                        columns={[
                            {
                                title: 'City',
                                dataIndex: 'city',
                                key: 'city',
                                render : (text, record, index) => {
                                    if(isValid(record.is_active)){
                                        return  <Text style= {{fontWeight: 'bold'}}>{text}</Text>
                                    }else{
                                        return  <Text style = {{fontStyle: 'italic'}}>{text} (removed)</Text>
                                    }
                                }
                            }
                        ]}
                        rowSelection={rowSelection}
                        pagination={false}
                        rowKey={(record) => {
                            return record.city;
                        }}
                    />
                    </Col>
                 </Row>
            </Modal>    
        </div>
    )
}
