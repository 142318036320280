/**
 * @author Manne Hareesh
 * @description Reducer for the components in Category page
 */
import {
    RESET_ALL,
    SET_BUS_OPERATORS,
    SET_SELECTED_OPERATOR
 } from '../actions/action-types';
  
  const initialState = {
      activeOperators: null,
      configKeys: null,
      activeOperatorLoadedTime: null,
      selectedOperator: null
  }
  
  export const adminReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  
          
        case SET_BUS_OPERATORS :
            newState["activeOperators"] = action.payload.travelsInfo;
            newState["configKeys"] = action.payload.configKeys;
            newState["activeOperatorLoadedTime"] = new Date();
            return newState;

        case SET_SELECTED_OPERATOR :
            newState["selectedOperator"] = action.payload;
            return newState;

        case RESET_ALL :
            newState["activeOperators"] = undefined;
            return newState;
  
          default:
              return state;
      }
  }
  