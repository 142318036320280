// ticket.trip_id = schedule.trip.id;
// ticket.trip_route_id = schedule.trip.trip_route_id;
// ticket.pickupLocation = boardingPoint;
// ticket.dropLocation = droppingPoint;
// ticket.emailId = emailId;
// ticket.phoneNumber = phoneNumber;
// ticket.agent_id = currentUser.id;
// ticket.userName = currentUser.user_name;
// ticket.payment_type = paymentType;
// ticket.direct_discount = totalDiscount;
// let seats = [];
// Object.keys(seatDetails).forEach((seatNumber) => {
//   let seatInfo = seatDetails[seatNumber];
//   let seat = new Seat();
//   seat.id = seatInfo.id;
//   seat.tripId = schedule.trip.id;
//   seat.seatNumber = seatNumber;
//   seat.ladiesSeat = seatInfo.sex === "F" ? true : false;
//   seats.push(seat);
//   let traveller = new Traveller();
//   traveller.fullName = seatInfo.name;
//   traveller.gender = seatInfo.sex;
//   traveller.age = seatInfo.age;
//   travellers.push(traveller);
// });
// fetch(BASE_URL + "/api/blockFireboltTicket", {
//   method: "POST",
//   headers: { "Content-Type": "application/json" },
//   body: JSON.stringify({
//     ticket: ticket,
//     returnTicket: null,
//     seats: seats,
//     returnSeats: null,
//     travellers: travellers,
//     websiteBooking: false,
//   }),
// })



/**
 * Selected Schedule
 */
// {
// api_fare: 900
// arrival_time: "05:00:00"
// available_seats: 26
// available_window_seats: 17
// blocked_seat_numbers: "17,18"
// bus: {description: "HI-TECH< A/C Sleeper  ", seat_type: "Sleeper", coach_type: "HI-TECH<", isAc: 1, name: null, …}
// bus_description: "HI-TECH< A/C Sleeper  "
// bus_id: 36
// bus_name: null
// cancellationPolicies: []
// cgst_percentage: 2.5
// coach_type: "HI-TECH<"
// departure_time: "18:00:00"
// description: "Agra Delhi 06:00 PM HI-TECH< A/C Sleeper   "
// destination: "Delhi"
// discount: 0
// discounted_cost_with_gst: 945
// discounted_cost_without_gst: 900
// distance: 600
// dropTimings: [{…}]
// end_time: "05:00:00"
// fare: 900
// id: 310
// igst_percentage: 0
// isAc: 1
// is_active: 1
// name: ""
// operator_name: "Kavitha Travels Name"
// origin: "Agra"
// pickupTimings: [{…}]
// schedule_id: 135
// seat_type: "Sleeper"
// sgst_percentage: 2.5
// start_date: "2021-08-27T00:00:00.000Z"
// start_time: "18:00:00"
// total_api_ticket_cost: 945
// travels_id: 16
// travels_route_id: null
// travels_trip_id: null
// travels_trip_route_id: null
// trip: {id: 2307, start_date: "2021-08-27T00:00:00.000Z", available_seats: 26, available_window_seats: 17, travels_trip_id: null, …}
// trip_id: 2307
// trip_route_fare: 900
// trip_route_id: 10663
// trip_route_total_ticket_cost: 945
// visible_outside: 1
// }
import React from 'react';
import { Table } from 'antd';
import { isValidArray , isValid, isValidNumber, isValidArrayWithValues } from '../../utils/utilities';
import { getValueFromPercentage, roundNumber } from './../../utils/math.utils';
import { getAPIDateFormat } from '../../utils/date.utils';

export const getDetailsToBookTicket=(formValues,selectedSchedule,datetime,isSendPaymentLink,discountValue,oldTicketId=null,agentsList=[])=>{
  let ticket={},seats=[],travellers=[], extraSeatsTravellers = [];
    ticket.trip_id = selectedSchedule.trip.id;
    ticket.trip_route_id = selectedSchedule.trip.trip_route_id;
    ticket.pickupLocation = formValues.boardingPointId;
    ticket.dropLocation = formValues.dropPointId;
    ticket.emailId = formValues.emailId;
    ticket.travelDate = getAPIDateFormat(selectedSchedule.trip.start_date)
    ticket.phoneNumber = '';
    ticket.oldTicketId = oldTicketId;
    if(isValid(formValues.phoneNumber)){
      ticket.phoneNumber = formValues.phoneNumber;
    }
    ticket.agent_id = global.agentId;
    let updatedBy = null;
    if(isValid(formValues.agentId)){
      ticket.agent_id = formValues.agentId;
      ticket.userName = agentsList.find(agent => agent.id === formValues.agentId)?.name || null;
      updatedBy = global.userName;
    }else{
      ticket.userName = global.userName;
    }
    ticket.updated_by = updatedBy;
    ticket.remarks = formValues.remarks;
    let ticketUIAmount = 0;

    if (isValidArrayWithValues(formValues.passengerDetails)){
      ticket.clientSideDiscount = discountValue;
      formValues.passengerDetails.forEach((passenger)=>{
      let age = 0 , fullName = '';
      if(isValid(passenger.age)){
        age = passenger.age  ;
      }
      if(isValid(passenger.fullName)){
        fullName=passenger.fullName;
      }      
      ticketUIAmount+=passenger.ticketCost;
        travellers.push({
            fullName:fullName,
            gender:passenger.gender,
            age:age,
            // vaild only for agent booking for some one else
            updated_by:updatedBy
        });
        seats.push({
          id : passenger.id,
          tripId : selectedSchedule.trip.id,
          ui_fare : passenger.fare,
          seatNumber : passenger.seatNumber,
          ladiesSeat : passenger.gender === "F" ? true : false
        })
      })
    }

    if (isValidArrayWithValues(formValues.extraSeatDetails)) {
      formValues.extraSeatDetails.map((extraSeat) => {
        ticketUIAmount+=extraSeat.extra_fare;
        extraSeatsTravellers.push({
          fullName:extraSeat.extra_fullName,
          gender:isValid(extraSeat.extra_gender)?extraSeat.extra_gender:'M',
          age:isValid(extraSeat.extra_age)?extraSeat.extra_age:0,
          fare: extraSeat.extra_fare,
          remarks: extraSeat.extra_remarks,
          is_extra_seat: true
        });
      })
    }

    ticket['cost'] = ticketUIAmount;

    return {
        ticket: ticket,
        returnTicket: null,
        seats: seats,
        returnSeats: null,
        travellers: travellers,
        extraSeatsTravellers: extraSeatsTravellers,
        websiteBooking: false,
        datetime:datetime,
        isSendPaymentLink:isSendPaymentLink
      }
}

export const getTicketFare=(seats,clientSideDiscount,bus, extraSeatInfoList)=>{
  let baseFare=0,cgst=0,sgst=0 , discountedCostWithoutGst=0;
  if(isValidArray(seats)&&seats.length>0){
    let discountCostPerSeat = clientSideDiscount/seats.length;
    seats.forEach(element => {
      baseFare=baseFare+element.fare;
      cgst=cgst+element.cgstOfDiscountedPrice;
      sgst=sgst+element.sgstOfDiscountedPrice;
      discountedCostWithoutGst=discountedCostWithoutGst+element.discountedCostWithoutGst-discountCostPerSeat;
      if(isValidNumber(discountedCostWithoutGst)){
        cgst = getValueFromPercentage(discountedCostWithoutGst,bus.cgst_percentage);
        sgst = getValueFromPercentage(discountedCostWithoutGst,bus.sgst_percentage);
      }
    });
  }

  if (isValidArrayWithValues(extraSeatInfoList)) {
    extraSeatInfoList.map((extraSeats) => {
      baseFare=baseFare+extraSeats.extra_fare;
      discountedCostWithoutGst=discountedCostWithoutGst+extraSeats.extra_fare;
      if(isValidNumber(discountedCostWithoutGst)){
        cgst = getValueFromPercentage(discountedCostWithoutGst,bus.cgst_percentage);
        sgst = getValueFromPercentage(discountedCostWithoutGst,bus.sgst_percentage);
      }
    })
  }

  return{
    baseFare:roundNumber(baseFare),
    cgst:roundNumber(cgst),
    sgst:roundNumber(sgst),
    discountedCostWithoutGst:roundNumber(discountedCostWithoutGst),
    totalCost:roundNumber(discountedCostWithoutGst+cgst+sgst)
  }
}



const getBoardingLocation=(boardingPoints,boardingPointId)=>{
  let location ='';
  if(isValidArray(boardingPoints)&&boardingPoints.length>0){
    boardingPoints.forEach((row)=>{
      if(boardingPointId===row.id){
        location=row.location
      }
    })
    return location
  }else{
    return null
  }

}

export const PassengerSeatsTable=({formValues,boardingPoints,discountValue,bus})=>{
  let {
    passengerDetails,
    boardingPointId
  }=formValues;
  let location = getBoardingLocation(boardingPoints,boardingPointId);
  let discountPerSeat = 0; 
  if(isValidNumber(discountValue)&&isValidArrayWithValues(passengerDetails)){
     discountPerSeat = discountValue / passengerDetails.length; 
  }
  let hideGenderFromBooking = global.travelsAppConfig['HIDE_GENDER_FROM_BOOKING']==1;
  let hideAgeFromBooking = global.travelsAppConfig['HIDE_AGE_FROM_BOOKING']==1;
  let hideEmailFromBooking = global.travelsAppConfig['HIDE_EMAIL_FROM_BOOKING']==1;

  let columns = [
    {
      title: 'Seat no',
      dataIndex: 'seatNumber',
      key: 'seatNumber',
    }
  ];
  let combinedTitleName = "Name";
  if(!hideAgeFromBooking){
    combinedTitleName += " Age";
  }
  if(!hideGenderFromBooking){
    combinedTitleName += " Sex";
  }
  columns.push({
    title: combinedTitleName,
    render: (record) => (
      <React.Fragment>
        {record.fullName}
        {!hideAgeFromBooking&&record.age&&<><br />{record.age}</>}
        {!hideGenderFromBooking&&record.gender&&<><br />{record.gender}</>}
      </React.Fragment>
    ),
    responsive: ["xs"]
  });
  columns.push({
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      responsive: ["sm"],  
      render: (fullName) => (
        <div className='passengerName'>
            {fullName}
        </div>
      )
  });
  if (!hideAgeFromBooking) {
    columns.push({
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      responsive: ["sm"]
    });
  }
  if (!hideGenderFromBooking) {
    columns.push({
      title: 'Sex',
      dataIndex: 'gender',
      key: 'gender',
      responsive: ["sm"]
    });
  }
  columns.push({
    title: 'Amount Paid',
    dataIndex: 'discountedCostWithoutGst',
    key: 'discountedCostWithoutGst',
    render : (text, record, index) => {
        let discountedCostWithoutGst = record.discountedCostWithoutGst - discountPerSeat; 
        let cgstOfDiscountedPrice = 0;
        let sgstOfDiscountedPrice = 0;
        if(isValidArray(bus)){
          cgstOfDiscountedPrice = getValueFromPercentage(discountedCostWithoutGst,bus.cgst_percentage);
          sgstOfDiscountedPrice = getValueFromPercentage(discountedCostWithoutGst,bus.sgst_percentage);
        }
        return roundNumber(discountedCostWithoutGst+cgstOfDiscountedPrice+sgstOfDiscountedPrice);
    }
  });

  columns.push({
    title: 'Boarding',
    render: (record) => (
        <React.Fragment>
          {location}
        </React.Fragment>
      ),
    key: 'Boarding',
  });

  if(isValidArray(passengerDetails)){
    return <Table dataSource={passengerDetails} columns={columns} pagination={false}/>
  }else{
    return null
  }
}


export const getAnyBlockedSeatsExit=(seats)=>{
  let bool = false;
    if(isValidArray(seats)&&seats.length){
      seats.forEach(element => {
        if(element.seatStatus==="Blocked"){
          bool=true;
        }
      });

    }
  return bool;
}