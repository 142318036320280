import {
    SET_MASTER_CITIES,
    BUS_TABLE_LOADING,
    BUSES_INFO,
    RESET_ALL,
    IS_TRIP_CITY_CREATED,
    SET_TRIP_SCHEDULE_ID,
    SET_SELECTED_CITIES,
    SET_SELECTED_STOPS,
    SET_ACTIVE_CITY,
    SET_TRIP_DATA,
    ON_CHANGE_SCHEDULE_FORM,
    SET_ACTIVE_STOP,
    SET_REORDER_ACTIVE_STOPS,
    SET_ON_MARKER_DRAG_END,
    SET_MAP_CENTER,
    SET_CITIES_WITH_STOPS,
    RESET_TRIP_CREATE,
    SET_CACHE_ADDRESS,
    SET_ADDRESS_FROM_CACHE,
    SET_STANDARD_STOPS
} from '../actions/action-types';
import { getTripStops, getWayPointsCities, getWayPointsInAddStops, getWayPointsInVerifyPage } from '../components/trip-create/trip-create.module';

import {isValid , isValidArray, isValidIndex, isValidNumber, sortJsonObject} from '../utils/utilities';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    let [removed] = result.splice(startIndex, 1);
    if(!isValid(removed)){
        removed = {};
    }
    result.splice(endIndex, 0, removed);
    return result;
  };

const scheduleState = {
                    id:null,
                    startTime:null,
                    name:'',
                    fare:null,
                    fareJson:null,
                    busId:null,
                    busSelected:{},
                    blockedSeatNumbers:null,
                    exitingTripStopsAddressIds:[],               
} 

const initialState = {
    masterCities: [],
    busesData: [],
    loading:true,
    isTripCityCreated: false,
    tripScheduleId: null,
    selectedCities:[],
    selectedStops:{},
    activeCity:null,
    activeStops:[],
    wayPoints: [],
    origin: null,
    destination:null,
    zoom: 13,
    center:{lat:13.0694,lng:80.1948},
    preserveViewport:false,
    schedule:{
        id:null,
        startTime:null,
        name:'',
        fare:null,
        fareJson:null,
        busId:null,
        busSelected:{},
        blockedSeatNumbers:null
    },
    citiesWithStops:{},
    cacheAddress:null,
    cacheAddressIndex:null,
    standardAddressStops:null
}

export const tripCreateReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {

        case SET_CACHE_ADDRESS :{
            let {
                cacheAddress,
                cacheAddressIndex   
            } = action.payload;
            newState["cacheAddress"]= cacheAddress;
            newState["cacheAddressIndex"]= cacheAddressIndex;  
            
            return newState;

        }

        case SET_STANDARD_STOPS:{
            let {
                standardAddressStops   
            } = action.payload;
            newState["standardAddressStops"]= standardAddressStops;  
            return newState;
        }

        case SET_ADDRESS_FROM_CACHE:{

            let cacheAddressIndex = newState["cacheAddressIndex"];
            let cacheAddress = newState["cacheAddress"];
            let activeStops = newState["activeStops"];
            if(isValidIndex(cacheAddressIndex)){
                activeStops[cacheAddressIndex] = cacheAddress;
                newState.activeStops = activeStops;
            }
            newState["cacheAddress"]= null;
            newState["cacheAddressIndex"]= null;  
            let {
                wayPoints,
                origin,
                destination,
                zoom,
                center
            } = getWayPointsInAddStops(activeStops);
            newState["wayPoints"]= wayPoints;
            newState["origin"]= origin;
            newState["destination"]= destination;
            newState["zoom"]= zoom;
            newState.center = center;
            return newState;

        }

        case SET_CITIES_WITH_STOPS:{
            let {
                citiesWithStops
            } = action.payload;
            newState.citiesWithStops = citiesWithStops;
            newState.preserveViewport = false;
            let {
                wayPoints,
                origin,
                destination,
                zoom
            } = getWayPointsInVerifyPage(citiesWithStops);
            newState["wayPoints"]= wayPoints;
            newState["origin"]= origin;
            newState["destination"]= destination;
            newState["zoom"]= zoom;

            return newState;
        }

        case SET_MAP_CENTER :{
            let {
                lat,lng
            } = action.payload;
            newState["center"] = {lat,lng};
            return newState;
        }

        case SET_ON_MARKER_DRAG_END :{
            let {
                lat,lng,index
            } = action.payload;
            let activeStops = newState.activeStops;
            newState.preserveViewport = true;
            activeStops[index]['latitude']=lat;
            activeStops[index]['longitude']=lng;
            newState.activeStops = activeStops;
            let {
                wayPoints,
                origin,
                destination,
                zoom,
                center
            } = getWayPointsInAddStops(activeStops);
            newState["wayPoints"]= wayPoints;
            newState["origin"]= origin;
            newState["destination"]= destination;
            newState["zoom"]= zoom;
            newState.center = center;
            return newState;
        }

        case SET_REORDER_ACTIVE_STOPS :{
            let {
                sourceIndex,
                destinationIndex
            } = action.payload;
            let activeStops = newState.activeStops;
            let reOrderedActiveStops = reorder(activeStops,sourceIndex,destinationIndex);
            newState.activeStops = reOrderedActiveStops;
            newState.preserveViewport = false;
            let {
                wayPoints,
                origin,
                destination,
                zoom,
                center
            } = getWayPointsInAddStops(reOrderedActiveStops);
            newState["wayPoints"]= wayPoints;
            newState["origin"]= origin;
            newState["destination"]= destination;
            newState["zoom"]= zoom;
            newState.center = center;
            return newState;

        }

        case SET_ACTIVE_STOP :{
            let {
                address,
                index
            } = action.payload;
            let activeStops = newState.activeStops;
            if(!isValidArray(activeStops)){
                activeStops=[]
            }
            if(!isValidNumber(index)){
                index = activeStops.findIndex(function(item, i){
                    return item.id === address.id
                });
            }
            if(isValidArray(address)){
                if(isValidNumber(index)&&isValid(activeStops[index])){
                    activeStops[index]=address;
                }else{
                    activeStops.push(address);
                }
            }else{
                activeStops.splice(index, 1);
            }
            let {
                wayPoints,
                origin,
                destination,
                zoom,center
            } = getWayPointsInAddStops(activeStops);
           
            newState.preserveViewport = false;
            let activeCity = newState.activeCity;
            newState["wayPoints"]= wayPoints;
            newState["origin"]= origin;
            newState["destination"]= destination;
            newState["zoom"]= zoom;
            newState["center"] = center;
            newState["activeStops"] = [...activeStops];
            if(!isValidArray(newState["selectedStops"][activeCity.city_name])){
                newState["selectedStops"][activeCity.city_name]={
                    ...activeCity,
                    stops:[]
                }
            }
            newState["selectedStops"][activeCity.city_name].stops = [...activeStops]; 
            return newState;
        }

        case ON_CHANGE_SCHEDULE_FORM:{
            let {
                startTime,
                busId,
                fare,
                fareJson
            } = action.payload;
            if(isValid(startTime)){
                newState.schedule.startTime = startTime;
            }
            if(isValid(busId)){
                newState.schedule.busId = busId;
            }
            if(isValid(fare)){
                newState.schedule.fare = fare;
            }
            if(isValid(fareJson)){
                newState.schedule.fareJson = fareJson; 
            }
            return newState;
        }


        case SET_TRIP_DATA:{
            let tripsData = action.payload.tripsData;
            let {
                tripCities,
                tripStops,
                scheduleData
            } = tripsData;
            if(isValidArray(scheduleData.schedule)){
                let schedule = scheduleData.schedule;
                newState.tripScheduleId = schedule.id;
                newState.schedule.id = schedule.id;
                newState.schedule.startTime = schedule.start_time;
                newState.schedule.busId = schedule.bus_id;
                newState.schedule.fare = schedule.fare;
                newState.schedule.fareJson = schedule.fare_json;
                newState.schedule.busSelected = scheduleData.bus;
                newState.schedule.name = schedule.name;
                newState.schedule.blockedSeatNumbers  = schedule.blocked_seat_numbers;

            }
            if(isValidArray(tripCities)){
                let tempTripCities = []
                tripCities.map((data, index) => {
                    tempTripCities.push({
                        'latitude': data.latitude,
                        'longitude': data.longitude,
                        'city_name': data.city_name
                    })
                });
                newState['selectedCities'] = tempTripCities;
                let {
                    wayPoints,
                    origin,
                    destination,
                    zoom,
                    center
                } = getWayPointsCities(tripCities);
                newState.preserveViewport = false;
                newState["wayPoints"]= wayPoints;
                newState["origin"]= origin;
                newState["destination"]= destination;
                newState["zoom"]= zoom;
                newState["center"]={...center};
            }
            if(isValidArray(tripStops)){
                let {selectedStops,exitingTripStopsAddressIds} = getTripStops(tripStops);
                newState['selectedStops'] = selectedStops;
                newState['exitingTripStopsAddressIds'] = exitingTripStopsAddressIds;
            }
            return newState;
        }

        case SET_ACTIVE_CITY:{
            let {
                activeCity
            } = action.payload;
            
            let cityCenter = {lat:activeCity.latitude,lng:activeCity.longitude};
            newState["activeCity"] = activeCity;
            let activeStops = [];
            if(
                isValid(activeCity.city_name)&&
                isValidArray(newState.selectedStops[activeCity.city_name])&&
                isValidArray(newState.selectedStops[activeCity.city_name].stops)){
                activeStops = newState.selectedStops[activeCity.city_name].stops;
            }
            newState["activeStops"] = [...activeStops];
            let {
                wayPoints,
                origin,
                destination,
                zoom,
                center
            } = getWayPointsInAddStops(activeStops,cityCenter);

            newState.preserveViewport = false;
            newState["wayPoints"]= [...wayPoints];
            newState["origin"]= {...origin};
            newState["destination"]= {...destination};
            newState["zoom"]= zoom;
            newState["center"]={...center};
            return newState;
        }

        case SET_MASTER_CITIES:
            newState["masterCities"]= action.payload;
            return newState;

        case BUSES_INFO:
            newState["busesData"] = sortJsonObject(action.payload,'description',false);
            return newState;

        case BUS_TABLE_LOADING :
            newState["loading"] = action.payload;
            return newState;

        case IS_TRIP_CITY_CREATED :
            newState["isTripCityCreated"] = action.payload;
            return newState;

        case SET_TRIP_SCHEDULE_ID :
            newState['tripScheduleId'] = action.payload;
            return newState;

        case SET_SELECTED_CITIES:{
            let selectedCities = action.payload.selectedCities;
            newState['selectedCities'] = selectedCities;
            let {
                wayPoints,
                origin,
                destination,
                zoom,
                center
            } = getWayPointsCities(selectedCities);
            newState.preserveViewport = false;
            newState["wayPoints"]= wayPoints;
            newState["origin"]= origin;
            newState["destination"]= destination;
            newState["zoom"]= zoom;
            newState["center"]={...center};
            return newState;
        }

        case SET_SELECTED_STOPS:
            newState['selectedStops'] = action.payload.selectedStops;
            return newState;

        case RESET_TRIP_CREATE :{
            newState = {
                masterCities: [],
                busesData: [],
                loading:true,
                isTripCityCreated: false,
                tripScheduleId: null,
                selectedCities:[],
                selectedStops:{},
                activeCity:null,
                activeStops:[],
                wayPoints: [],
                origin: null,
                destination:null,
                zoom: 13,
                center:{lat:13.0694,lng:80.1948},
                preserveViewport:false,
                schedule:{
                    ...scheduleState
                },
                citiesWithStops:{}
            };
            return newState
        }
        
        case RESET_ALL :{
            newState = {
                masterCities: [],
                busesData: [],
                loading:true,
                isTripCityCreated: false,
                tripScheduleId: null,
                selectedCities:[],
                selectedStops:{},
                activeCity:null,
                activeStops:[],
                wayPoints: [],
                origin: null,
                destination:null,
                zoom: 13,
                center:{lat:13.0694,lng:80.1948},
                preserveViewport:false,
                schedule:{
                    ...scheduleState
                },
                citiesWithStops:{}
            };
            return newState
        }
        default:
            return state;
    }
}