import { addMonth, getAPIDateFormat, incrementDays, noOfDaysInMonth } from "../../../utils/date.utils";
import moment from 'moment';
import { isValid, isValidArray, checkActionAllowed } from "../../../utils/utilities";
import { ADMIN_ACTION_TRIP_DETAILS_VIEW } from "../../../utils/constant";
import { postCall } from "../../../utils/network.util";
import { DE_REGISTER_REDBUS_DP_ABOUT_SCHEDULE_URL, REGISTER_REDBUS_DP_ABOUT_SCHEDULE_URL } from "../../../utils/api-url";

export const informRedBusAboutRegister=(scheduleId,startDate,onSuccess=()=>{},onFailure=()=>{})=>{
    var informed = postCall(REGISTER_REDBUS_DP_ABOUT_SCHEDULE_URL, {
        scheduleId: scheduleId,
        startDate:getAPIDateFormat(startDate),
        updatedBy: global.userName 
      });
      informed.then((data) => {
        if(data.status == "SUCCESS" && data.payload.Status == "SUCCESS"){
          onSuccess(data.payload.Message);
        }else{
            onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to register schedule");
      })
}

export const informRedBusAboutDeRegister=(scheduleId,onSuccess=()=>{},onFailure=()=>{})=>{
    var informed = postCall(DE_REGISTER_REDBUS_DP_ABOUT_SCHEDULE_URL, {
        scheduleId: scheduleId,
        updatedBy: global.userName 
      });
      informed.then((data) => {
        if(data.status == "SUCCESS" && data.payload.Status == "SUCCESS"){
            onSuccess(data.payload.Message);
        }else{
            onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to de register schedule");
      })
}

export const formateDataByDate=(data, demandDays=[])=>{
    let formateData = {};
    if(isValidArray(data)&&data.length>0){
        data.forEach((trip)=>{
            let apiStartDate = getAPIDateFormat(trip.start_date);
            formateData[apiStartDate] = trip;
        });
        demandDays.map((date) => {
            if (isValid(formateData[date])) {
                formateData[date]['demand_days'] = 1;
            }
            else {
                formateData[date] = {'demand_days':1};
            }
        })

    }

    return formateData;
}

const getMinFare=(schedule)=>{
    let minFare = 2000;
    let fareJson = schedule.fare_json;
    if(isValid(fareJson)){
        fareJson = JSON.parse(fareJson);
        for (const key in fareJson) {
            if (Object.hasOwnProperty.call(fareJson, key)) {
                const fare = fareJson[key];
                if(minFare>fare || !isValid(minFare)){
                    minFare=fare;
                }
            }
        }
    }
    if (schedule.supported_fare == 1) {
      if (minFare > schedule.ticket_cost) {
        minFare = schedule.ticket_cost;
      }
    }
    return minFare;
}

export const getActiveStatus=(openCalenderData,apidate)=>{
    let cutRouteEnabledClassName = '';

    if(isValidArray(openCalenderData[apidate])){
        let presentData = openCalenderData[apidate];
        if(presentData.cut_route_enabled == 1){
            cutRouteEnabledClassName="cut-route-enabled";
        }
        let minFare = getMinFare(presentData);
        let confirmedSeats = presentData.confirmed_seats;
            if(presentData.visible_outside==1 && presentData.is_active==1){
                let percentageValue = presentData.confirmed_seats / presentData.number_of_seats;
                return {
                    className:isValid(presentData.demand_days)&&presentData.demand_days==1?'active-schedule active-demand':'active-schedule',
                    onlyDateClassName:'active-date-underline',
                    styles:{
                        background: `rgba(17, 110, 238, ${percentageValue})`
                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }else if (presentData.visible_outside==0 && presentData.is_active==1) {
                return {
                    className : isValid(presentData.demand_days)&&presentData.demand_days==1?'disabled-api-schedule active-demand':"disabled-api-schedule",
                    onlyDateClassName : '',
                    styles:{

                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }else if (presentData.is_active==0) {
                return {
                    className : isValid(presentData.demand_days)&&presentData.demand_days==1?'blocked-schedule active-demand':"blocked-schedule",
                    onlyDateClassName:'',
                    styles:{

                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }else if (isValid(presentData.demand_days)&&presentData.demand_days==1) {
                return {
                    className : "active-demand",
                    onlyDateClassName:'',
                    styles:{
                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }
    }else{
        return {
            className : "not-active-status",
            onlyDateClassName : '',
            styles :{

            },
            minFare:null,
            confirmedSeats:0,
            cutRouteEnabledClassName
        }
    }
}

export const checkToViewTripDetailsTab = () => {
    if(checkActionAllowed(ADMIN_ACTION_TRIP_DETAILS_VIEW)){
        return true;
    }
    else {
        return false;
    }
}

export const checkRedbusDpEnabled = (schedule) =>{
    if(global.dynamicPricingProvider == "Redbus"){
        return true;
    }else{
        return false;
    }
}