import React from 'react';
import { Modal, Tooltip, message } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import { isValid, isValidArray, isValidString, checkActionAllowed } from './../../utils/utilities';
import { SeatClickTicketPopUp } from './../../components/ticket-summary-components/seat-click-ticket-popup.component';
import { ShowMultiplePassengers } from './show-multiple-passengers.component';
import { ACTION_SEAT_NUMBER_UPDATE_ALLOWED, ACTION_BOOK_BBO_TICKET_ALLOWED, ACTION_BOOK_BBA_TICKET_ALLOWED, TOOLTIP_HIDE_BOOK_INFO_OTHER_USER, getSameUser } from './../../utils/constant';
import { getLocalDateAndTime, getTimeStampInReadableFormat } from './../../utils/date.utils';

export const Seat = props => {
    const {
        classname,
        object: seat,
        onSeatCLick,
        onSeatDrop,
        isSelected,
        showFarePopOver = false,
        dontAllowSelection = false,
        showFareInLayout = false,
        changeOldLayout = false,
        oldSelectedSeats = [],
        seatMapping = {},
        isDragDropRequired = true
    } = props;    

    const isDragDropAllowed = isDragDropRequired && isValid(global.allowedActions?.[ACTION_SEAT_NUMBER_UPDATE_ALLOWED]);
    const isConfirmed = seat.seat_status === "Confirmed";
    const isConfirmedOrBlocked = ['Confirmed', 'Blocked'].includes(seat.seat_status);
    const isVacant = !isConfirmedOrBlocked;
    const isMultiplePassengers = isValidArray(seat.passengers) && seat.passengers.length > 0;

    const boardedElement = React.useMemo(() => {
        if (!seat.boarded) return null;
        return seat.sleeper ?
        <div style={{marginTop: '8px'}}>👋</div> :
        <div style={{position:'relative',fontSize:'8px',top:-16,left:9}}>👋</div>;
    }, [seat.boarded, seat.sleeper, seat.gender]);

    // Validate gender compatibility
    const isGenderCompatible = (draggedSeat, targetSeat) => {
        if ((draggedSeat.gender == 'M' && targetSeat.ladies_seat) || (draggedSeat.gender == 'F' && targetSeat.gents_seat)) {
            let adjacentSeat = seatMapping[targetSeat.adjacent_seat_number];
            if (adjacentSeat.ticket_id == draggedSeat.ticket_id) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    };

    const getReserveTranformValue =(val)=>{
        if(isValid(val)){
            return -val+'deg';
        }
        return '0deg';
    }

    const seatNoStyles = React.useMemo(() => ({
        transform: `rotate(${getReserveTranformValue(seat.seat_orientation)})`
    }), [seat.seat_orientation]);

    const getGender=(seatGender)=>{
        if(isValid(seatGender)&&seatGender==="F"){
            return "("+seatGender+")";
        }
        else{
            return '';
        }
    }

    // Drag configuration for Confirmed/Blocked seats
    const [{ isDragging }, drag] = useDrag({
        type: 'SEAT',
        item: () => isConfirmed && !isMultiplePassengers? seat : null,
        canDrag: () => isDragDropAllowed && isConfirmed && !isMultiplePassengers,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    // Drop configuration for vacant seats
    const [{ isOver }, drop] = useDrop({
        accept: 'SEAT',
        canDrop: (draggedItem) => {
            return isDragDropAllowed &&
                   isVacant &&
                   draggedItem.seat_number !== seat.seat_number &&
                   isGenderCompatible(draggedItem, seat);
        },
        drop: (draggedItem) => {
            if (!isGenderCompatible(draggedItem, seat)) {
                message.error('Gender restriction: Cannot move this passenger to selected seat');
            }
            Modal.confirm({
                title: 'Confirm Seat Change',
                content: `Are you sure you want to move seat ${draggedItem.seat_number} to ${seat.seat_number}?`,
                onOk: () => onSeatDrop && onSeatDrop(draggedItem, seat)
            });
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    // Combine drag and drop refs
    const ref = (node) => {
        drag(drop(node));
    };

    // Handle click events
    const handleClick = (e) => {
        e.stopPropagation();
        if (seat.seat_status === "Blocked") {
            handleBlockedSeatClick(e);
            return;
        }

        if (!changeOldLayout && !isDragging && onSeatCLick && isVacant && !dontAllowSelection) {
            onSeatCLick(seat);
            return;
        }

        if (changeOldLayout && !["Confirmed", "Blocked"].includes(seat.seat_status)) {
            message.error("Cannot select empty seats for change seat layout!");
            return;
        }

        if (changeOldLayout && ["Confirmed", "Blocked"].includes(seat.seat_status)) {
            onSeatCLick(seat);
            return;
        }
    };

    const handleBlockedSeatClick = (e) => {
        const hasTicketId = isValid(seat.ticket_id);
        const isCurrentUserBlocker = global.userName === seat.blocked_by;

        if (hasTicketId) {
            if (isCurrentUserBlocker || global.allowedActions[ACTION_BOOK_BBO_TICKET_ALLOWED]) {
                onSeatCLick(seat);
            } else {
                message.error("You don't have permission to book seat!");
            }
        } else if (global.allowedActions[ACTION_BOOK_BBA_TICKET_ALLOWED]) {
            onSeatCLick(seat);
        } else {
            message.error("You don't have permission to book seat!");
        }
    }

    // Early return for Empty seats
    if (seat.seat_number === "Empty") {
        return <div className={seat.sleeper ? 'sleeperGap' : 'seatGap'} />;
    }

    const seatContent = (
        <div
            ref={ref}
            className={`${classname} ${isSelected ? 'selected' : ''} ${isDragging ? 'dragging' : ''} ${oldSelectedSeats.includes(seat.seat_number) ? 'opacity-seat' : ''}`}
            style={{
                opacity: oldSelectedSeats.includes(seat.seat_number)||isDragging ? 0.5 : 1,
                cursor: isDragDropAllowed && isConfirmed ? 'move' : 'pointer',
                backgroundColor: isOver ? 'rgba(94, 166, 15, 0.3)' : undefined
            }}
            onClick={handleClick}
            data-testid={seat.seat_number}
            id={seat.seat_number}
        >
            <div className="seat-number">
                {seat.seat_number}
                {boardedElement}
                {showFareInLayout && isVacant && (
                    <div className="seat-fare">{seat.amount_for_travels}</div>
                )}
            </div>
            <div className="seat-gender" style={seatNoStyles}>{getGender(seat.gender)}</div>
            {isMultiplePassengers && seat.passengers.length > 1 && (
                <ShowMultiplePassengers passengers={seat.passengers} />
            )}
        </div>
    );


    const wrappedContent = () => {        
        if (!isDragging && ["Blocked", "Confirmed"].includes(seat.seat_status)||changeOldLayout) {
            let toolTipContent = getTicketInfoInfo(seat, seat.passengers, seat.seat_status == "Blocked" ? "Blocked" : "Booked");
            return (
                <Tooltip title={<TooltipContent {...toolTipContent} changeOldLayout={changeOldLayout} />} trigger="hover">
                    {seatContent}
                </Tooltip>
            );
        }
        else {
            return showFarePopOver && !isDragging ? (
                <Tooltip title={`Fare: ${seat.fare}`} trigger="hover">
                    {seatContent}
                </Tooltip>
            ) : seatContent;
        }
    }

    // Show ticket popup only for Confirmed seats
    if (isDragDropRequired && !changeOldLayout && seat.seat_status === "Confirmed" && isPassengerEntryExits(seat)) {
        return (
            <SeatClickTicketPopUp
                ticketId={getTicketIds(seat)}
                seatNumber={seat.seat_number}
                agentId={seat.agent_id}
            >
                {wrappedContent()}
            </SeatClickTicketPopUp>
        );
    }

    return wrappedContent();
};

// Helper functions remain the same
const isPassengerEntryExits = (object) => {
    const { full_name, passengers } = object;
    if (isValid(full_name)) return true;
    return isValidArray(passengers) && passengers.some(ele => isValid(ele.full_name));
};

const getTicketIds = (seat) => {
    const { passengers, ticket_id } = seat;
    if (isValidArray(passengers)) {
        return passengers.map(p => p.ticket_id);
    }
    return [ticket_id];
};

const getTicketInfoInfo = (object, passengers, displayStatus) => {
    let bookedName = displayStatus, pickUp = '', dropUp = '', amountPaid = '' , bookingTime = '';
    if (isValidArray(passengers) && passengers.length >= 1) {
        const passenger = passengers[0];
        let sameInfo = false;
        if (checkActionAllowed(TOOLTIP_HIDE_BOOK_INFO_OTHER_USER)){
            if (getSameUser(passenger.agent_id)){
                sameInfo = true;
            }
            else {
                for (let i = 0; i < passengers.length; i++) {
                    const passInfo = passengers[i];                    
                    if (getSameUser(passInfo.agent_id)){
                        bookedName = `${displayStatus} by ${object.blocked_by} for ${passInfo.full_name} (${passInfo.phone_number})`;
                        pickUp = passInfo.pickup_location_name ? `Pick up: ${passInfo.pickup_location_name}` : '';
                        pickUp += passInfo.pickup_timing ? `, ${getTimeStampInReadableFormat(passInfo.pickup_timing)}` : '';
                        dropUp = passInfo.drop_location_name ? `Drop: ${passInfo.drop_location_name}` : '';
                        dropUp += passInfo.drop_timing ? `, ${getTimeStampInReadableFormat(passInfo.drop_timing)}` : '';
                        amountPaid = passInfo.amount_paid ? `Amount Paid: ${passInfo.amount_paid}` : '';
                        bookingTime = passInfo.booking_time ? `${displayStatus} Time: ${getLocalDateAndTime(passInfo.booking_time)}` : '';
                        break;
                    }
                }
            }
        }
        else {
            sameInfo = true;
        }
        if (sameInfo){
            bookedName = `${displayStatus} by ${object.blocked_by} for ${passenger.full_name} (${passenger.phone_number})`;
            pickUp = passenger.pickup_location_name ? `Pick up: ${passenger.pickup_location_name}` : '';
            pickUp += passenger.pickup_timing ? `, ${getTimeStampInReadableFormat(passenger.pickup_timing)}` : '';
            dropUp = passenger.drop_location_name ? `Drop: ${passenger.drop_location_name}` : '';
            dropUp += passenger.drop_timing ? `, ${getTimeStampInReadableFormat(passenger.drop_timing)}` : '';
            amountPaid = passenger.amount_paid ? `Amount Paid: ${passenger.amount_paid}` : '';
            bookingTime = passenger.booking_time ? `${displayStatus} Time: ${getLocalDateAndTime(passenger.booking_time)}` : '';
        }
    } else {
        let showInfo = true;
        if (checkActionAllowed(TOOLTIP_HIDE_BOOK_INFO_OTHER_USER)){
            if (!getSameUser(object.agent_id)){
                showInfo = false;
            }
        }
        if (showInfo){
            bookedName = (object.full_name === null || object.phone_number === null)
                ? `${displayStatus} by ${object.blocked_by}`
                : `${displayStatus} by ${object.blocked_by} for ${object.full_name} (${object.phone_number})`;
            pickUp = object.pickup_location ? `Pick up: ${object.pickup_location}` : '';
            pickUp += object.pickup_timing ? `, ${getTimeStampInReadableFormat(object.pickup_timing)}` : '';
            dropUp = object.drop_location ? `Drop: ${object.drop_location}` : '';
            dropUp += object.drop_timing ? `, ${getTimeStampInReadableFormat(object.drop_timing)}` : '';
            amountPaid = object.amount_paid ? `Amount Paid: ${object.amount_paid}` : '';
            bookingTime = object.booking_time ? `${displayStatus} Time: ${getLocalDateAndTime(object.booking_time)}` : '';
        }
    }

    return { bookedName, pickUp, dropUp, amountPaid, bookingTime };
};

const TooltipContent = ({ bookedName, pickUp, dropUp, amountPaid, changeOldLayout , bookingTime}) => {
    let toolTipContent = [];
    if (changeOldLayout) {
        toolTipContent.push(bookedName);
    }
    else {
        toolTipContent.push(bookedName, pickUp, dropUp, bookingTime, amountPaid);
    }
    return (
        <div>
            {toolTipContent.map((item, index) => (
                isValidString(item) && (
                <React.Fragment key={index}>
                    <span>{item}</span><br />
                </React.Fragment>
            )
        ))}
        </div>
    )
}
