import React from 'react';
import { Calendar } from 'antd';
import { getDate, getMonth } from '../../../utils/date.utils';

export const DisplayCalendar = ({ selectedDate, dates, isRemoved , flexEnd =false, isTimeChanges = false}) => {
    
    return (
        <div className="stops-removed-calendar-container" style={flexEnd ? {justifyContent: 'flex-end'} : {}}>
            <Calendar 
                fullscreen={false}
                style={{ 
                    width: '240px',
                    height: '240px',
                    margin: '2px 0',
                    backgroundColor: '#F4F6F9',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                }}
                headerRender={({ value }) => (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '8px',
                        fontWeight: 'bold'
                    }}>
                        {value.format('MMM-YYYY')}
                    </div>
                )}
                dateFullCellRender={(date) => {
                    let day = getDate(date);
                    let month = getMonth(date);
                    let currentMonth = getMonth(selectedDate);
                    if(month === currentMonth) {
                        let classNameExt = isRemoved ? 'removed-date' : 'added-date';
                        classNameExt = isTimeChanges ? 'time-changes-date' : classNameExt;
                        let className = dates.includes(date.format('YYYY-MM-DD')) ? classNameExt : 'not-removed-date';
                        return (
                            <div className={className} style={{
                                height: '28px',
                                width: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '0 auto',
                                borderRadius: '5%'
                            }} title={"content"}>
                                {day}
                                <div className={"statusClassName"}></div>
                            </div>
                        );
                    }
                    return null;
                }}
                monthFullCellRender={() => null}
                className="custom-calendar"
            />
        </div>
    );
};