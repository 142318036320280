import React , {useState} from 'react';
import { Button, Modal, Row  , Col , Table, message} from 'antd';
import { sendNotificationToRedBus } from './red-bus-activation.module';
import { useEffect } from 'react';
import { getViaCities } from '../../actions/home.action';
import { isValid, checkActionAllowed } from '../../utils/utilities';
import { loadingAction } from '../../actions/loading-actions';
import { useDispatch } from 'react-redux';
import { ACTION_SEND_OTA_EMAILS } from '../../utils/constant';


export const RedBusActivation = (props) => {


    const {
        scheduleId,
        emailOTAs
    } = props;


    const [rowKeys,setRowKeys] = useState([]);
    const [selectedRows,setSelectedRows] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [routes, setRoutes] = useState([]);

    const dispatch = useDispatch();

    useEffect(()=>{
        if(isValid(scheduleId)){
            getViaCities(scheduleId,(data)=>{
                setRoutes(data);
            },()=>{
            });
        }
    },[scheduleId]);

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=()=>{
        handleOk();
        setSelectedRows([]);
        setRowKeys([]);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleEmail = () => {
        if (selectedRows.length>0) {
            emailOTAs(selectedRows);
            setIsModalVisible(false);
        }
        else {
            message.warn("Please select atleast one route to send email")
        }
    }

    const onSubmit=(e)=>{
        e.preventDefault();
        setLoading(true);
        sendNotificationToRedBus(selectedRows,onSuccess,onFailure);
    }

    let columns =[
        {
            title: 'Origin',
            dataIndex: 'origin',
            key: 'origin'
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination'
        }
    ];

    const rowSelection = {
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
         setRowKeys(selectedRowKeys);
         setSelectedRows(selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.name === 'Disabled User', // Column configuration not to be checked
          name: record.name,
        }),
      };

  return (
      <div>
            <Row justify="center" align="middle">
                <Button type="primary" onClick={showModal}>
                    Inform OTA
                </Button>
            </Row>
          <Modal title="OTA Alert" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
            <Table
                rowSelection={rowSelection}
                rowKey={record => record.origin+record.destination}
                dataSource={routes}
                columns={columns}
                pagination={false}
                />
             <Row gutter={[16,16]} style={{paddingTop:'10px'}} justify="end">
                 <Col>
                    <Button onClick={handleCancel} className="exit-btn">Close</Button>
                 </Col>
                 <Col>
                    <Button onClick={onSubmit} type="primary">Redbus Activate</Button>
                 </Col>
                 {checkActionAllowed(ACTION_SEND_OTA_EMAILS)&&<Col>
                    <Button onClick={handleEmail} type="primary">Email OTA</Button>
                 </Col>}
             </Row>
          </Modal>
      </div>
  );
};