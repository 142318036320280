import React, { useContext, useEffect, useState } from "react";
import { Button, Calendar, Col, Row, Select, Typography } from "antd";
import { addMonth, getDates, getAPIDateFormat, getDateMonthInReadable, subtractMonth, firstDayOfMonth, lastDayOfMonth, getDate, getMonth } from "../../../utils/date.utils";
import { isValid, isValidArray, removeArrayIndexValue } from "../../../utils/utilities";
import { DeviceContext } from "../../device-provider/device-provider";
import './schedule-calendar.style.scss';
import { getSchedulesByIdForDates, getDemandDaysAction } from "../../../actions/admin.action";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { formateDataByDate } from "../../schedule-components/render-tabs/render-tabs.module";
import { CalenderColorLegend } from "../../schedule-components/calender-color-legend/calender-color-legend";

const { Text } = Typography;
const { Option } = Select;

const getActiveStatus=(openCalenderData,apidate)=>{
    let cutRouteEnabledClassName = '';

    if(isValidArray(openCalenderData[apidate])){
        let presentData = openCalenderData[apidate];
        if(presentData.cut_route_enabled == 1){
            cutRouteEnabledClassName="cut-route-enabled";
        }
        let minFare = getMinFare(presentData);
        let confirmedSeats = presentData.confirmed_seats;
            if(presentData.visible_outside==1 && presentData.is_active==1){
                let percentageValue = presentData.confirmed_seats / presentData.number_of_seats;
                return {
                    className:isValid(presentData.demand_days)&&presentData.demand_days==1?'active-schedule active-demand':'active-schedule',
                    onlyDateClassName:'active-date-underline',
                    styles:{
                        background: `rgba(17, 110, 238, ${percentageValue})`
                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }else if (presentData.visible_outside==0 && presentData.is_active==1) {
                return {
                    className : isValid(presentData.demand_days)&&presentData.demand_days==1?'disabled-api-schedule active-demand':"disabled-api-schedule",
                    onlyDateClassName : '',
                    styles:{

                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }else if (presentData.is_active==0) {
                return {
                    className : isValid(presentData.demand_days)&&presentData.demand_days==1?'blocked-schedule active-demand':"blocked-schedule",
                    onlyDateClassName:'',
                    styles:{

                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }else if (isValid(presentData.demand_days)&&presentData.demand_days==1) {
                return {
                    className : "active-demand",
                    onlyDateClassName:'',
                    styles:{
                    },
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                }
            }
    }else{
        return {
            className : "not-active-status disable-clicking",
            onlyDateClassName : '',
            styles :{

            },
            minFare:null,
            confirmedSeats:0,
            cutRouteEnabledClassName
        }
    }
}

const getMinFare=(schedule)=>{
    let minFare = 2000;
    let fareJson = schedule.fare_json;
    if(isValid(fareJson)){
        fareJson = JSON.parse(fareJson);
        for (const key in fareJson) {
            if (Object.hasOwnProperty.call(fareJson, key)) {
                const fare = fareJson[key];
                if(minFare>fare || !isValid(minFare)){
                    minFare=fare;
                }
            }
        }
    }
    if (schedule.supported_fare == 1) {
      if (minFare > schedule.ticket_cost) {
        minFare = schedule.ticket_cost;
      }
    }
    return minFare;
}

export const ScheduleCalendar = ({
    selectedDate,
    onDatesChange,
    showLegend = false,
    renderGetStops = null,
    scheduleData = null,
    loadCalendarData = false
}) => {
    const { isMobile } = useContext(DeviceContext);
    const [currentDate, setCurrentDate] = useState(selectedDate);
    const [nextDate, setNextDate] = useState(selectedDate);
    const [currentSelectedDates, setCurrentSelectedDates] = useState([]);
    const [openCalenderData, setOpenCalenderData] = useState({});
    const [demandDays, setDemandDays] = useState(null);
    const dispatch = useDispatch();

    let currentMonth = getMonth(currentDate);
    let nextMonth = getMonth(nextDate);

    useEffect(() => {
        setCurrentDate(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        let secondDate = addMonth(currentDate, 1);
        setNextDate(secondDate);
    }, [currentDate]);

    useEffect(() => {        
        if (loadCalendarData && scheduleData) {
            reload();
        }
    }, [loadCalendarData, currentDate, scheduleData]);

    const reload = () => {
        let sqlStartDate = firstDayOfMonth(currentDate);
        let sqlNextDate = lastDayOfMonth(currentDate);
        if (!isMobile) {
            sqlNextDate = lastDayOfMonth(addMonth(currentDate, 1));
        }
        setLoading(true);
        let originList = ["All", scheduleData.origin];
        let destinaltionList = ["All", scheduleData.destination];
        getDemandDaysAction(originList, destinaltionList, (demandElements) => {
            setDemandDays(demandElements);
            getSchedulesByIdForDates(scheduleData.id, sqlStartDate, sqlNextDate,
                (activeData) => onSuccess(activeData, demandElements['dates']),
                onFailure);
        }, (err) => { console.log(err) });
    }

    const onSuccess = (activeData, demandDates) => {
        setLoading(false);
        setOpenCalenderData(formateDataByDate(activeData, demandDates));
        setCurrentSelectedDates([]);
    }

    const setLoading = (value) => {
        dispatch(loadingAction(value));
    }

    const onFailure = () => {
        setLoading(false);
    }

    const onPreviousClick = () => {
        if (isMobile) {
            setCurrentDate(subtractMonth(currentDate, 1));
        } else {
            setCurrentDate(subtractMonth(currentDate, 2));
        }
    }

    const onNextClick = () => {
        if (isMobile) {
            setCurrentDate(addMonth(currentDate, 1));
        } else {
            setCurrentDate(addMonth(currentDate, 2));
        }
    }

    const handleDateSelect = (date) => {
        let apidate = getAPIDateFormat(date);
        if (!isValidArray(openCalenderData[apidate])) {
            return;
        }

        let oldDates = currentSelectedDates;
        let index = oldDates.indexOf(apidate);
        if (index === -1) {
            oldDates.push(apidate);
        } else {
            removeArrayIndexValue(oldDates, index);
        }
        setCurrentSelectedDates([...oldDates]);
        onDatesChange([...oldDates]);
    }

    const handleQuickSelect = (value) => {
        let dates = getDates(value);        
        const validDates = dates.filter(date => 
            isValidArray(openCalenderData[date])
        );
        setCurrentSelectedDates(validDates);
        onDatesChange(validDates);
    }


    const renderDateCell = (date, displayMonth, selectedDates) => {
            let day = getDate(date);
            let month = getMonth(date);
            if(month===displayMonth){
                let apidate = getAPIDateFormat(date);
                let {
                    className,
                    styles,
                    onlyDateClassName,
                    minFare,
                    confirmedSeats,
                    cutRouteEnabledClassName
                } = getActiveStatus(openCalenderData,apidate);
                let index = currentSelectedDates.indexOf(apidate);
                let selectedClassName = index!=-1?"schedule-date-selected":"";
                return (
                    <div 
                    title={`Confirmed Seats:${confirmedSeats}`} 
                    className={`current-date ${className} edit-fare-date-block ${cutRouteEnabledClassName}`} style={styles}>
                        <div className={`${selectedClassName} ${onlyDateClassName}`}>{day}</div>
                        <div className="min-fare-in-calender">{minFare}</div>
                    </div>
                )
            }else{
                return (
                    <div className={`next-month-date`}>
                        <div>{day}</div>
                    </div>
                )
            }
    };

    return (
        <>
            <Row gutter={[16, 16]} justify="center" align="middle">
                <Col>
                    <div className="site-extended-calendar">
                        <Calendar 
                            value={currentDate}
                            fullscreen={false}
                            headerRender={() => (
                                <Row gutter={[16,16]} align="middle">
                                    <Col>
                                        <Button type="link" className="previous-calender-btn" onClick={onPreviousClick}>
                                            <Text className="normal-txt">Previous</Text>
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Text strong>{getDateMonthInReadable(currentDate)}</Text>
                                    </Col>  
                                    {isMobile &&
                                        <Col>
                                            <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                <Text className="normal-txt">Next</Text>
                                            </Button>
                                        </Col>
                                    }      
                                </Row>
                            )}
                            dateFullCellRender={(date) => renderDateCell(date, currentMonth, currentSelectedDates)}
                            onSelect={handleDateSelect}
                        />
                    </div>
                </Col>
                {!isMobile &&
                    <Col>
                        <div className="site-extended-calendar">
                            <Calendar 
                                value={nextDate}
                                fullscreen={false}
                                headerRender={() => (
                                    <Row gutter={[16,16]} align="middle" justify="end">
                                        <Col>
                                            <Text strong>{getDateMonthInReadable(nextDate)}</Text>
                                        </Col>
                                        <Col>
                                            <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                <Text className="normal-txt">Next</Text>
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                dateFullCellRender={(date) => renderDateCell(date, nextMonth, currentSelectedDates)}
                                onSelect={handleDateSelect}
                            />
                        </div>
                    </Col>
                }
            </Row>
            <Row gutter={[16,16]} justify="center" align="middle">
                <Col>
                    <Select
                        placeholder="Select Dates"
                        className="schedule-date-select"
                        onChange={handleQuickSelect}
                    >
                        <Option value={'today'}>Today</Option>
                        <Option value={'tomorrow'}>Tomorrow</Option>
                        <Option value={'thisweek'}>This Week</Option>
                        <Option value={'thismonth'}>This Month</Option>
                        <Option value={'nextmonth'}>Next Month</Option>
                        <Option value={'weekdays'}>Week Days (This Month)</Option>
                        <Option value={'weekends'}>Weekend Days (This Month)</Option>
                        <Option value={'nextmonthweekdays'}>Week Days (Next Month)</Option>
                        <Option value={'nextmonthweekends'}>Weekend Days (Next Month)</Option>
                    </Select>
                </Col>
                <Col>
                    {renderGetStops && renderGetStops()}
                </Col>
            </Row>
            {showLegend && (
                <Row justify="space-around" align="center">
                    <Col>
                        <CalenderColorLegend />
                    </Col>
                </Row>
            )}
        </>
    );
} 