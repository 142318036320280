import React ,{useContext,useEffect,useState} from 'react'
import { GoogleMap, useJsApiLoader  , Marker} from '@react-google-maps/api';
import './map.component.style.scss';
import { DeviceContext } from './../../../components/device-provider/device-provider';
import googleDot from './../../../images/google-dot.png';
import { isValid } from './../../../utils/utilities';
import { Button } from "antd";


let zoom = 13 ;

function MyComponent(props) {

  const {
    isMobile
  }=useContext(DeviceContext);

  let containerStyle = {
    width: '65vw',
    height: '65vh'
  };

  if(isMobile){
    containerStyle['width']='92vw';
    containerStyle['height']='100vh';
  }

    const {
        enableLocationPick,
        latLng,
        onMarkerDragEnd,
        onCancel,
        setEnableLocationPick,
        center
    } = props;

    const [cacheLatLng,setCacheLatLng] = useState({});


    useEffect(()=>{
      setCacheLatLng(latLng);
  },[latLng]);


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${global.googleMapsApi}`
  });

  const onDragEnd =(coord)=>{
    let{
      latLng
    } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();
    onMarkerDragEnd(lat,lng);
  }

  return isLoaded ? (
        <div className='hightlight-div'>
            {enableLocationPick&&
               <div className='map-top-header'>
                  Click any where on map to set the location
                </div>
            }
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: center.lat || 13.0694,
                lng: center.lng || 80.1948
              }}              
              defaultZoom={zoom}
              zoom={zoom}
              onClick={(e)=>{
                if(enableLocationPick){
                  setCacheLatLng({latitude:e.latLng.lat(),longitude:e.latLng.lng()});
                }
              }}
            >
               {isValid(cacheLatLng)&&isValid(cacheLatLng.latitude)&&isValid(cacheLatLng.longitude)&&
                <Marker
                    shape="MarkerShapeRect"
                    icon = {{
                      url: googleDot,
                      scale: 10,
                      fillOpacity: 1,
                      strokeWeight: 2,
                      fillColor: '#5384ED',
                      strokeColor: '#ffffff'
                    }}
                    key={'origin'}
                    position={{
                      lat:cacheLatLng.latitude,
                      lng:cacheLatLng.longitude
                    }}
                    // label={{
                    //   // text:'',
                    //   // className:"marker-label",
                    //   // color:'black'
                    // }}
                    draggable={true}
                    onClick={() => {
                        
                    }}
                    onDragEnd={onDragEnd}
                    onDrag={onDragEnd}
                >
                </Marker>
              }

            </GoogleMap>
            {enableLocationPick&&
            <div className='map-footer-header'>
              <Button  
              className='cancel-button'
              onClick={()=>{
                setEnableLocationPick(false);
                setCacheLatLng(latLng);
                onCancel(latLng.latitude,latLng.longitude);
              }}
              >
                  Cancel
              </Button>
              <Button className='set-location'
              onClick={()=>{
                setEnableLocationPick(false);
              }}
              >
                  Set Location
              </Button>
            </div>
            }
        </div>
  ) : <></>
}

export default React.memo(MyComponent);

//Note refs: https://stackoverflow.com/questions/64165413/react-google-maps-api-directionsservice-keeps-rerendering-itself
// https://codesandbox.io/s/eloquent-hopper-16k2e?file=/src/App.js:288-431
// https://stackoverflow.com/questions/64165413/react-google-maps-api-directionsservice-keeps-rerendering-itself