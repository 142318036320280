
import { Button , message } from "antd";
import React  from "react";
import { getScheduleTripCityAction, recreateScheduleCities } from "../../actions/admin.action";
import { loadingAction } from "../../actions/loading-actions";
import { setTripData } from "../../actions/trip-create.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { showConfirm } from "../custom-components/custom-component";


export const EditTripComponent =(props)=>{

    const {
        scheduleId
    } = props;

    let history = useHistory();

    const dispatch = useDispatch();
    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const recreateTripForAgentPortal = () =>{
        setLoading(true);
        recreateScheduleCities(scheduleId,()=>{
            updateCreateTrip(scheduleId);
        },(errMsg)=>{
            setLoading(false);
            message.error(errMsg);
        })
    }

    const onSuccessTripCity = (data) =>{
        setLoading(false);
        if(data.tripCities.length==0){
            showConfirm(recreateTripForAgentPortal,"Trip not created from agent portal","Are you sure to recreate trip for agent portal?")
        }
        else{
            dispatch(setTripData(data));
            history.push({
                pathname:"/trip-create"
            });
        }
    }

    const onFailureTripCity= (err) => {
        message.error(err);
    }


    const updateCreateTrip = (scheduleId) => {
        setLoading(true);
        getScheduleTripCityAction(scheduleId,onSuccessTripCity,onFailureTripCity)
    }
     
   
    return(
        <div>
            <Button onClick={()=> updateCreateTrip(scheduleId)}>Edit</Button>
        </div>  
    )
}