/**
 * @author Manne Hareesh
 * @description Reducer for the components in Category page
 */
 import {
    RESET_ALL,
    SET_AGENTS,
    SET_AGENT_PRIVILEGES
 } from '../actions/action-types';
  
  const initialState = {
      agents: undefined,
      agentPrivileges: undefined
  }
  
  export const agentsReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  
          
        case SET_AGENTS :
            newState["agents"] = action.payload.agents;
            return newState;

        case SET_AGENT_PRIVILEGES :
            newState["agentPrivileges"] = action.payload;
            return newState;

        case RESET_ALL :
            newState["agents"] = undefined;
            newState["agentPrivileges"] = undefined;
            return newState;
  
          default:
              return state;
      }
  }
  