/**
 * @author Rejo Varghese
 * @description RTO Trip Sheet
 */
import React, { useState, useRef } from 'react'
import { Button, Modal, Checkbox, Row as RowAnt, Col, Divider, Radio, InputNumber, message } from 'antd';
import { isValidArray, isValidArrayWithValues, isValid } from '../../utils/utilities';
import { Document, Page,  StyleSheet,PDFDownloadLink } from '@react-pdf/renderer';
import { convertDateStringToDate, convertTimeStringToDate, getLocalDate, getLocalDateAndTime, getLocalTime } from '../../utils/date.utils';
import Title from './common/Title';
import Row from './common/Row';
import Subtext from './common/Subtext';
import Table from './common/Table';
import SubTextBold from './common/SubTextBold';
import { View } from '@react-pdf/renderer';
import { CancelledTripSheetPdfComponent } from '../../components/cancelled-trip-sheet-report/cancelled-trip-sheet-report.pdf';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor:'#FFFFFF'
  },
  boardingBlock:{
    paddingTop:10
  },
  colblock:{
    justifyContent:'space-between'
  },
  summaryTxt:{
    textAlign:'center'
  },
  summaryTxtBlock:{
    justifyContent:'center'
  },
  availableTxt:{
    textAlign:'center'
  },
  availableTxtBlock:{
    justifyContent:'center',
    paddingTop:10
  },
  adminBlockedSeatsTxtBlock:{
    justifyContent:'center'
  },
  adminBlockedSeatsTxt:{
    textAlign:'center'
  },
  cancellationDetailsBlock:{
    justifyContent:'center',
    paddingTop:10
  },
  cancellationDetailsTxt:{
    textAlign:'center'
  },
  agentInfoTxtBlock:{
    justifyContent:'center',
    paddingTop:10
  },
  agentInfoTxt:{
    textAlign:'center'
  },
  dropPointSummaryTxt:{
    textAlign:'center'
  },
  dropPointSummaryTxtBlock:{
    justifyContent:'center',
    paddingTop:10
  },
});


const renderTables=(agentRecords,agentRecordColumns)=>{
  let agentRows=[];
  agentRecords.forEach((row,index) => {
    let agentCells=[];
    agentRecordColumns.forEach((column)=>{
      agentCells.push(
        <Table.Cell style={{width:column.pdfWidth}}>
          {column.pdfRender({text:row[column.dataIndex],record:row,index})}
        </Table.Cell> 
      );
    });
    agentRows.push(
      <Table.Row key={row.id}>
        {agentCells}
      </Table.Row>
    )
  });
  return(
    <Table>
      <Table.Header>
          {agentRecordColumns.map(column => (
            <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
          ))}
      </Table.Header>
      <Table.Body>
            {agentRows}
      </Table.Body>
    </Table>
  )
}

// Create Document Component
const MyDocument = ({
  dropPointsColumns,
  dropPointSeatNumber,
  showSpecificAgent,
  agentsTableData,
  agentTableColums,
  showAllAgentsTable,
  adminBlockedSeats,
  isRenderAvailableSeatsShow,
  isRenderAdminBlockedAvailableSeats,
  avaliableSeats,avaliableSeatsColumns,
  tripSheetColumns,tripSheetByBoardingPoints,
  vacantSeatCount,bookedSeatsCount,notBoardedColumns,notBoardedSeats,
  selectedSchedule,vechicleInfo,showTripSheetInSingleTable}) => {
    

    let renderTripSheet=()=>{
      let allTables = [] , totalTickets = [];
      if(showTripSheetInSingleTable){
        let ticketRows=[];
        for (const key in tripSheetByBoardingPoints) {
          if (Object.hasOwnProperty.call(tripSheetByBoardingPoints, key)) {
            const ticketsArray = tripSheetByBoardingPoints[key].boradingTickets;
            totalTickets=totalTickets.concat(ticketsArray);
          }
        }
        allTables.push(
          <Row style={styles.boardingBlock}>
              <View>
                  <SubTextBold>No of tickets({totalTickets.length})</SubTextBold>
              </View>
          </Row>
        );
        totalTickets.forEach((row,index) => {
          let ticketCells=[];
          tripSheetColumns.forEach((column)=>{
            ticketCells.push(
              <Table.Cell style={{width:column.pdfWidth}}>
                {column.pdfRender({text:row[column.dataIndex],record:row,index})}
              </Table.Cell> 
            );
          });
          ticketRows.push(
            <Table.Row key={row.id}>
              {ticketCells}
            </Table.Row>
          )
        });
        allTables.push(<Table>
          <Table.Header>
              {tripSheetColumns.map(column => (
                <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
              ))}
          </Table.Header>
          <Table.Body>
                {ticketRows}
          </Table.Body>
        </Table>);
      }else{
        for (const key in tripSheetByBoardingPoints) {
          if (Object.hasOwnProperty.call(tripSheetByBoardingPoints, key)) {
            let ticketRows=[];
            const ticketsArray = tripSheetByBoardingPoints[key].boradingTickets;
            const boardingTime = tripSheetByBoardingPoints[key].boardingTime;
            const landmark = tripSheetByBoardingPoints[key].landmark;

            allTables.push(
              <Row style={styles.boardingBlock}>
                {isValid(key)&&isValid(boardingTime)&&key!=""&&
                  <View>
                      <SubTextBold style={{paddingRight:10}}>Boarding: {key}-{boardingTime}{isValid(landmark)?", Landmark: "+landmark:""}</SubTextBold>
                  </View>
                }
                  <View>
                      <SubTextBold>No of tickets({ticketsArray.length})</SubTextBold>
                  </View>
              </Row>
            )
            ticketsArray.forEach((row,index) => {
              let ticketCells=[];
              tripSheetColumns.forEach((column)=>{
                ticketCells.push(
                  <Table.Cell style={{width:column.pdfWidth}}>
                    {column.pdfRender({text:row[column.dataIndex],record:row,index})}
                  </Table.Cell> 
                );
              });
              ticketRows.push(
                <Table.Row key={row.id}>
                  {ticketCells}
                </Table.Row>
              )
            });
            allTables.push(<Table>
              <Table.Header>
                  {tripSheetColumns.map(column => (
                    <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
                  ))}
              </Table.Header>
              <Table.Body>
                    {ticketRows}
              </Table.Body>
            </Table>);
          }
        }
      }
       
      return allTables;
    }

    return(
        <Document>
          <Page style={styles.page}>
            <Row>
              <Subtext>
                {global.operatorName}
              </Subtext>
            </Row>
            <Row>
              <Title>Trip Sheet - {global.userName}</Title>
            </Row>
            <Row style={{paddingTop:10}}>
                <SubTextBold>Trip Name : </SubTextBold>
                <Subtext>{selectedSchedule.description}</Subtext>
            </Row>
            <Row style={styles.colblock}>
              <View>
                <Row>
                  <SubTextBold>From : </SubTextBold>
                  <Subtext> {selectedSchedule.origin}</Subtext>
                </Row>
              </View>
              <View>
                <Row>
                  <SubTextBold>Travel Date : </SubTextBold>
                  <Subtext>{getLocalDate(convertDateStringToDate(selectedSchedule.trip.start_date.split('T')[0]))}</Subtext>
                </Row>
              </View>
            </Row>
            <Row style={styles.colblock}>
              <View>
                  <Row>
                    <SubTextBold>To : </SubTextBold>
                    <Subtext>{selectedSchedule.destination}</Subtext>
                  </Row>
              </View>
              <View>
                <Row>
                  <SubTextBold>Dep Time : </SubTextBold>
                  <Subtext>{getLocalTime(convertTimeStringToDate(selectedSchedule.start_time))}</Subtext>
                </Row>
              </View>
            </Row>
            <Row style={styles.colblock}>
              <View>
                <Row>
                  <SubTextBold>Vehicle # : </SubTextBold>
                  <Subtext>{vechicleInfo.vechicle_no}</Subtext>
                </Row>
              </View>
            </Row>
            {isValid(vechicleInfo.driver_name)&&
              <Row style={styles.colblock}>
                <View>
                  <Row>
                    <SubTextBold>Driver Name : </SubTextBold>
                    <Subtext>{vechicleInfo.driver_name}</Subtext> 
                  </Row>
                </View>
                <View>
                    <Row>
                      <SubTextBold>Driver phone # : </SubTextBold>
                      <Subtext>{vechicleInfo.phone_no}</Subtext>
                    </Row>
                </View>
              </Row>
            }
            {isValid(vechicleInfo.second_driver_name)&&
              <Row style={styles.colblock}>
                <View>
                    <Row>
                        <SubTextBold>Second Driver Name : </SubTextBold>
                        <Subtext>{vechicleInfo.second_driver_name}</Subtext>
                    </Row>
                </View>
                <View>
                    <Row>
                      <SubTextBold>Second Driver phone # : </SubTextBold>
                      <Subtext>{vechicleInfo.second_driver_phone_no}</Subtext>
                    </Row>
                </View>
              </Row>
            }
            <Row style={styles.colblock}>
              <View>
                <Row>
                  <SubTextBold>No of booked seats : </SubTextBold>
                  <Subtext>{bookedSeatsCount}</Subtext>
                </Row>
              </View>
              <View>
                <Row>
                  <SubTextBold>Vacant Seats : </SubTextBold>
                  <Subtext>{vacantSeatCount}</Subtext>
                </Row>
              </View>
              </Row>
              <Row>
                  <SubTextBold>Generated day/time : </SubTextBold>
                  <Subtext>{getLocalDateAndTime(new Date())}</Subtext>
              </Row>
              {isValid(selectedSchedule.trip.trip_instruction) && 
                <Row>
                  <SubTextBold>Trip Instructions : </SubTextBold>
                  <Subtext>{selectedSchedule.trip.trip_instruction}</Subtext>
                </Row>
              }
              {renderTripSheet()}
              <Row style={styles.summaryTxtBlock}>
                <View>
                    <SubTextBold style={styles.summaryTxt}>Summary</SubTextBold>
                </View>
              </Row>
              {isRenderAvailableSeatsShow&&
                <View>
                    <Row style={styles.availableTxtBlock}>
                      <View>
                        <SubTextBold style={styles.availableTxt}>Avaliable Seats</SubTextBold>
                      </View>
                    </Row>
                  {renderTables(avaliableSeats,avaliableSeatsColumns)}
                </View>
              }
              {isRenderAdminBlockedAvailableSeats&&
                <View>
                    <Row style={styles.adminBlockedSeatsTxtBlock}>
                      <View>
                        <SubTextBold style={styles.adminBlockedSeatsTxt}>Admin Blocked Seats</SubTextBold>
                      </View>
                    </Row>
                    {renderTables(adminBlockedSeats,avaliableSeatsColumns)}
                </View>
              }
              <CancelledTripSheetPdfComponent
                  tripId={selectedSchedule.trip_id}
              />
              {isValidArray(notBoardedSeats)&&notBoardedSeats.length>0&&
                <View>
                <Row style={styles.dropPointSummaryTxtBlock}>
                    <SubTextBold style={styles.dropPointSummaryTxt}>Not boarded Seats</SubTextBold>
                </Row>
                {renderTables(notBoardedSeats,notBoardedColumns)}
              </View>
              }
              {showAllAgentsTable&&
                <View>
                  {showSpecificAgent?
                  <Row style={styles.agentInfoTxtBlock}>
                      <SubTextBold style={styles.agentInfoTxt}>All Agents</SubTextBold>
                  </Row>
                  :
                  <Row style={styles.agentInfoTxtBlock}>
                      <SubTextBold style={styles.agentInfoTxt}>Agent info</SubTextBold>
                  </Row>
                  }
                  {renderTables(agentsTableData,agentTableColums)}
                </View>
              }
               {isValidArray(dropPointSeatNumber)&&
                <View>
                  <Row style={styles.dropPointSummaryTxtBlock}>
                      <SubTextBold style={styles.dropPointSummaryTxt}>Drop Point Summary</SubTextBold>
                  </Row>
                  {renderTables(dropPointSeatNumber,dropPointsColumns)}
                </View>
              }
          </Page>
        </Document>
      )
};

const generateTripColumns = (tripSheetColumns, selectedColumns, fareOption, fare) => {
  let tempTripSheet = [], fareDataIndex = ["ticket_cost", "fare", "amt"], fareEntered = false;
  tripSheetColumns.map((column, index) => {
    if (column.title=="SN" || selectedColumns.includes(column.dataIndex)) {
      tempTripSheet.push(column);
    }
    else if (!fareEntered && fareDataIndex.includes(column.dataIndex)){
      fareEntered = true;
      if (fareOption !="no_fare") {
        let tempColumn = {...column};
        tempColumn['title'] = "Fare"
        if (fareOption == "manual_fare") {
          tempColumn['render']= (text,record) => (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word',width:40}}>
                  {fare}
                </div>
          );
          tempColumn['pdfRender'] = ({text}) => `${fare}`;
        }
        tempTripSheet.push(tempColumn);
      }
    }
  })
  return tempTripSheet;
}

const generateBoardingPoints = (tripSheetByBoardingPoints, selectedSchedule, avoidViaRoutes) => {
  let tempBoardingPoints = {};
  let origin = selectedSchedule.scheduleOrigin;
  let destination = selectedSchedule.scheduleDestination;
  let startTime = getLocalTime(convertTimeStringToDate(selectedSchedule.trip_start_time));

  if (avoidViaRoutes) {
    let count = 0;
    for (const key in tripSheetByBoardingPoints) {
      let tempBoarding = {...tripSheetByBoardingPoints[key]};
      let tempBoardingTickets = [...tempBoarding.boradingTickets];
      tempBoardingTickets.map((ticket) => {
        ticket.drop_location = destination;
        ticket.drop_landmark = '';
        ticket.destination = destination;
        ticket.drop_city = destination;
      })
      if (count == 0) {
        tempBoardingPoints[origin] = {
          boardingTime: startTime,
          landmark: null,
          boradingTickets: []
        };
      }
      tempBoardingPoints[origin].boradingTickets = tempBoardingPoints[origin].boradingTickets.concat(tempBoardingTickets);
      count++;
    }
  }
  else {
    tempBoardingPoints = tripSheetByBoardingPoints;
  }

  return tempBoardingPoints;
}

export const RTOTripSheet = (props) => {
    const {
        agentsTableData,
        showSpecificAgent,
        agentTableColums,
        showAllAgentsTable,
        vacantSeatCount,bookedSeatsCount,
        operatorCity,selectedSchedule,vechicleInfo,
        dropPointsColumns,
        tripSheetColumns,
        tripSheetByBoardingPoints,
        avaliableSeats,
        avaliableSeatsColumns,
        isRenderAdminBlockedAvailableSeats,
        isRenderAvailableSeatsShow,
        adminBlockedSeats,
        dropPointSeatNumber,
        notBoardedColumns,
        notBoardedSeats,
        showTripSheetInSingleTable,
        showColumn
    } = props;

    const setDefaultCheckboxes =() => {
      let defaultCheckboxes = [];
      if(showColumn('pnr')){
        defaultCheckboxes.push('pnr');
      }
      if(showColumn('booking_time')){
        defaultCheckboxes.push('booking_time');
      }
      if(showColumn('agent')){
        defaultCheckboxes.push('agent');
      }
      if(showColumn('full_name')){
        defaultCheckboxes.push('full_name');
      }
      if(showColumn('age')){
        defaultCheckboxes.push('age');
      }
      if(showColumn('gender')){
        defaultCheckboxes.push('gender');
      }
      if(showColumn('phone_number')){
        defaultCheckboxes.push('phone_number');
      }
      if(showColumn('seat_number')){
        defaultCheckboxes.push('seat_number');
      }
      if(showColumn('location')){
        defaultCheckboxes.push('location');
      }
      if(showColumn('drop_location')){
        defaultCheckboxes.push('drop_location');
      }
      return defaultCheckboxes;
    }

    const [rtoClicked, setRTOClicked] = useState(false);
    const [selectedCheckBox, setCheckbox] = useState(setDefaultCheckboxes());
    const [radioValue, setRadioValue] = useState('no_fare');
    const [fareValue, setFareValue] = useState(null);
    const [avoidViaRoutes, setAvoidViaRoutes] = useState(false);
    const [tripColumns, setTripColumns] = useState([]);
    const [boardingPoints, setBoardingPoints] = useState([]);
    const buttonRef = useRef();

    function resolveAfter2Seconds() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve('resolved');
          buttonRef.current.click();
        }, 2000);
      });
    }
    
    async function asyncCall() {
      await resolveAfter2Seconds();
    }

    const handleOk = () => {
        setTripColumns([]);
        if (isValidArrayWithValues(selectedCheckBox)&&isValid(radioValue)) {
            if (radioValue == "manual_fare" && !isValid(fareValue)) {
                message.error("Please enter value for manual fare");
            }
            else {
                let generatedTripColumns = generateTripColumns(tripSheetColumns,selectedCheckBox, radioValue, fareValue);
                setTripColumns(generatedTripColumns);
                let generatedBoardingPoints = generateBoardingPoints(tripSheetByBoardingPoints, selectedSchedule, avoidViaRoutes);
                setBoardingPoints(generatedBoardingPoints);
                asyncCall();
                setRTOClicked(false);
            }
        }
        else {
            message.error("Please select the appropriate values");
        }
    };
    const handleCancel = () => {
        setRTOClicked(false);
    };
    const onCheckBoxChange = (checkedValues) => {
        setCheckbox(checkedValues)
    };

    const onRadioChange = (e) => {
        setRadioValue(e.target.value);
    };

    const onNumberChange = (num) => {
        setFareValue(num);
    };

    const openModal = () => {
        setRTOClicked(true);
    }

    if(!isValidArray(selectedSchedule)){
        return null;
    }

    return (
        <div>
            <Button type= "primary" onClick={openModal}>
                RTO Trip Sheet
            </Button>
            <Modal title="RTO Trip Sheet" visible={rtoClicked} onOk={handleOk} onCancel={handleCancel}>
                <p style= {{fontWeight: 'bold'}}>Please select the fields you want to print</p>
                <Checkbox.Group
                    style={{
                    width: '100%',
                    }}
                    onChange={onCheckBoxChange}
                    value={selectedCheckBox}
                >
                    <RowAnt>
                        {showColumn('pnr')&&<Col span={8}>
                            <Checkbox value="pnr">PNR</Checkbox>
                        </Col>}
                        {showColumn('booking_time')&&<Col span={8}>
                            <Checkbox value="booking_time">Booking Time</Checkbox>
                        </Col>}
                        {showColumn('agent')&&<Col span={8}>
                            <Checkbox value="agent">Agent</Checkbox>
                        </Col>}
                        {showColumn('full_name')&&<Col span={8}>
                            <Checkbox value="full_name">Full Name</Checkbox>
                        </Col>}
                        {showColumn('age')&&<Col span={8}>
                            <Checkbox value="age">Age</Checkbox>
                        </Col>}
                        {showColumn('gender')&&<Col span={8}>
                            <Checkbox value="gender">Gender</Checkbox>
                        </Col>}
                        {showColumn('phone_number')&&<Col span={8}>
                            <Checkbox value="phone_number">Phone</Checkbox>
                        </Col>}
                        {showColumn('seat_number')&&<Col span={8}>
                            <Checkbox value="seat_number">Seat</Checkbox>
                        </Col>}
                        {showColumn('location')&&<Col span={8}>
                            <Checkbox value="location">Boarding Point</Checkbox>
                        </Col>}
                        {showColumn('drop_location')&&<Col span={8}>
                            <Checkbox value="drop_location">Drop Point</Checkbox>
                        </Col>}
                    </RowAnt>
                </Checkbox.Group>
                <Divider />
                <RowAnt>
                  <p style= {{fontWeight: 'bold'}}>Fare:</p>
                    <Radio.Group onChange={onRadioChange} value={radioValue} style= {{marginLeft: 20}}>
                        <Radio value={'no_fare'}>No Fare</Radio>
                        <Radio value={'actual_fare'}>Actual Fare</Radio>
                        <Radio value={'manual_fare'}>Manual Fare</Radio>
                    </Radio.Group>
                    {radioValue=="manual_fare"&&
                    <InputNumber onChange = {onNumberChange} />
                    }
                </RowAnt>
                <Divider />
                <div>
                    <p style= {{fontWeight: 'bold'}}>Via Routes</p>
                    <Checkbox onChange={(e) => setAvoidViaRoutes(e.target.checked)}>
                        Avoid via routes
                    </Checkbox>
                </div>
            </Modal>
            <PDFDownloadLink document={<MyDocument 
              dropPointSeatNumber={dropPointSeatNumber}
              agentsTableData={agentsTableData}
              showSpecificAgent={showSpecificAgent}
              agentTableColums={agentTableColums}
              showAllAgentsTable={false}
              adminBlockedSeats={adminBlockedSeats}
               avaliableSeats={avaliableSeats}
               tripSheetByBoardingPoints={boardingPoints}
               vacantSeatCount={vacantSeatCount} 
               bookedSeatsCount={bookedSeatsCount} 
               vechicleInfo={vechicleInfo} 
               operatorCity={operatorCity} 
               selectedSchedule={selectedSchedule} 
               dropPointsColumns={dropPointsColumns}
               tripSheetColumns={tripColumns}
               avaliableSeatsColumns={avaliableSeatsColumns}
               isRenderAdminBlockedAvailableSeats={isRenderAdminBlockedAvailableSeats}
               isRenderAvailableSeatsShow={isRenderAvailableSeatsShow}
               notBoardedColumns={notBoardedColumns}
               notBoardedSeats={notBoardedSeats}
               showTripSheetInSingleTable={showTripSheetInSingleTable}
               />} fileName={`trip-sheet-${selectedSchedule.description}.pdf`}>
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
              <Button ghost ref= {buttonRef}>

              </Button>
            </PDFDownloadLink>
        </div>
    )
}