import React from 'react';
import { TimePicker, Input, Checkbox, Row, Col,Typography, Divider , Steps, Button, InputNumber, Switch} from 'antd';
import moment from 'moment';
import './clone-schedule.style.scss';
import { getTimeStampInReadableFormat } from '../../utils/date.utils';
import { isValidArray } from '../../utils/utilities';
import { EditOutlined } from '@ant-design/icons';

const { Step } = Steps;

const { Text } = Typography;
const Step2Component = ({ scheduleData, setScheduleData }) => {

    const handleCityDataChange = (cityIndex, stopIndex, field, value) => {
        const updatedScheduleData = [...scheduleData];
        const city = updatedScheduleData[cityIndex];

        if (stopIndex === -1) {
            // This is a city-level change
            city[field] = value;
        } else {
            // This is a stop-level change

            if(field === 'is_main_stop'){
                city.stops.forEach(stop => {
                    stop.is_main_stop = false;
                });
            }
            if(city.stops[stopIndex].is_main_stop){
                if(field === 'day'){
                    city[field] = city.day+value;
                }else{
                    city[field] = value;
                }
            }            
            city.stops[stopIndex][field] = value;
        }
        setScheduleData([...updatedScheduleData]);
    };

    // Replace the individual handlers with these wrapper functions
    const handleTimeChange = (cityIndex, stopIndex, newTime) => {
        const timeString = newTime ? newTime.format('HH:mm:ss') : null;        
        handleCityDataChange(cityIndex, stopIndex, 'timing', timeString);
    };

    const handleDayChange = (cityIndex, stopIndex, newDay) => {
        handleCityDataChange(cityIndex, stopIndex, 'day', newDay);
    };

    const handleTripClosePoint = (cityIndex, stopIndex, checked) => {
        handleCityDataChange(cityIndex, stopIndex, 'trip_close_point', checked);
    };

    const handleMainStop = (cityIndex, stopIndex, checked) => {
        handleCityDataChange(cityIndex, stopIndex, 'is_main_stop', checked);
    };

    const handleEditCity = (cityIndex) => {
        handleCityDataChange(cityIndex, -1, 'is_edit', true);
    }
    
    const renderHeader = (city,cityIndex) => {
        return (
            <Row key={cityIndex} className="stop-item" flex={true} gutter={[16,16]}>
                <Col span={6}>{city.city}</Col>
                <Col span={18}> 
                    <Row justify="space-between" align='middle' >
                        <Col span={10}>
                        {city.is_edit?          
                             <TimePicker
                                value={moment(city.timing, 'HH:mm:ss')}
                                onSelect={(time) => handleTimeChange(cityIndex, -1, time)}
                                format="hh:mm A"
                                style={{ width: 100 }}
                                className='remove-clear-icon'  
                                popupClassName={"time-picker-to-not-display-footer"}
                            />
                        :
                            <div>
                                {getTimeStampInReadableFormat(city.timing)}
                            </div>
                        }
                        </Col>
                        <Col span={4} offset={1}>
                        {city.is_edit?  
                            <InputNumber
                                value={city.day+1}
                                onChange={(value) =>{ 
                                    let newValue = value - 1;
                                    handleDayChange(cityIndex, -1, newValue)
                                }}
                                style={{ width: 40 }}
                            />
                        :
                            <div>
                                {city.day+1}
                            </div>
                        }
                        </Col>
                        <Col span={4} offset={1}>   </Col>
                        <Col span={4}>
                            <EditOutlined 
                            style={{fontSize:18}}   
                            onClick={()=>{
                                handleEditCity(cityIndex);
                            }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
    const renderInfo = (stop,cityIndex,stopIndex,showMainStop,cityDay,isOrigin,isDestination) => {
        return (
            <Row key={stopIndex} className="stop-item" gutter={[16,16]} flex='1'>
                <Col span={6}>
                    {stop.location}
                </Col>
                <Col span={18}>
                    <Row justify="space-between">
                        <Col span={10}>  
                            <TimePicker
                                value={moment(stop.timing, 'HH:mm:ss')}
                                onSelect={(time) => handleTimeChange(cityIndex, stopIndex, time)}
                                format="hh:mm A"
                                style={{ width: 100 }}
                                className='remove-clear-icon'  
                                popupClassName={"time-picker-to-not-display-footer"}
                            />
                        </Col>
                        <Col span={4} offset={1}>
                            <InputNumber
                                value={cityDay+stop.day+1}
                                onChange={(value) => {
                                    let newValue = value - cityDay - 1;
                                    handleDayChange(cityIndex, stopIndex, newValue)
                                }}
                                style={{ width: 40 }}
                            />
                        </Col>
                        <Col span={4} offset={1}>
                        {showMainStop?
                            <Switch checked={stop.is_main_stop} onChange={(value) => handleMainStop(cityIndex, stopIndex, value)} size="small"/>
                        :
                            null
                        }
                        </Col>
                        <Col span={4}>
                            {isOrigin?
                            <Checkbox
                                checked={stop.trip_close_point}
                                onChange={(e) => handleTripClosePoint(cityIndex, stopIndex, e.target.checked)}
                            />  
                            :
                            null
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const renderSteps = (scheduleData) => {
        let steps = []; 
        if(isValidArray(scheduleData)){
            scheduleData.forEach((city, cityIndex) => {
                let stops = [];
                let showMainStop = city.stops.length>1?true:false;
                city.stops.forEach((stop, stopIndex) => {
                    stops.push(renderInfo(stop,cityIndex,stopIndex,showMainStop,city.day,city.isOrigin,city.isDestination));
                });
                steps.push(<Step key={cityIndex} title={renderHeader(city,cityIndex)} description={stops} disabled={true}/>);
            });
        }
        return steps;
    };

    return (
        <div className="schedule-container">
            <Row justify="space-between" className='step2-header'>
                <Col span={6}>
                    <Text style={{ fontWeight: 'bold' }}>
                        City/Stop Name
                    </Text>
                </Col>
                <Col span={18}>
                    <Row justify="space-between">
                        <Col span={10}>
                            <Text style={{ fontWeight: 'bold' }}>
                                Timing
                            </Text>
                        </Col>
                        <Col span={4} offset={1}>
                            <Text style={{ fontWeight: 'bold' }}>
                                Day
                            </Text>
                        </Col>
                        <Col span={4} offset={1}>
                            <Text style={{ fontWeight: 'bold' }}>
                               
                            </Text>
                        </Col>
                        <Col span={4}>
                            <Text style={{ fontWeight: 'bold' }}>
                                TCP
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider className="clone-schedule-divider"/>
            <Steps direction={'vertical'} current={null}>
                {renderSteps(scheduleData)}
            </Steps>
        </div>
    );
};

export default Step2Component;
