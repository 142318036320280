import React, {useState, useEffect} from "react";
import { Modal, Form, InputNumber, Divider, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';   
import { createTripExpense, getTripExpenseDetails ,resetTripExpense,updateTripExpense } from "../../../actions/trip-expense.actions";
import { tripExpenseDetailsSelector } from "../../../selector/trip-expense.selector";


export const TripExpenseComponent = (props) => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if(isModalOpen){
      dispatch(getTripExpenseDetails(props.tripId));
    }
  }, [props.tripId,isModalOpen]);


  const tripExpense = useSelector(tripExpenseDetailsSelector);


  useEffect(() => {
    if(tripExpense){
      form.setFieldsValue({
        bata:tripExpense.bata,
        toll:tripExpense.toll,
        pooja:tripExpense.pooja,
        bus_expense:tripExpense.bus_expense,
        misc:tripExpense.misc
      });
    }

  }, [tripExpense]);

  


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    dispatch(resetTripExpense());
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleUpdate = () => {
    form.validateFields()
      .then(values => {
        console.log('Success:', values);
        setIsModalOpen(false);
        form.resetFields();
        if(tripExpense&&tripExpense.id){
          dispatch(updateTripExpense(props.tripId,values));
        }else{
          dispatch(createTripExpense(props.tripId,values));
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });


  };

  // Calculate total whenever form values change
  const handleValuesChange = (changedValues, allValues) => {
    const sum = Object.values(allValues).reduce((acc, val) => acc + (val || 0), 0);
    setTotal(sum);
  };

  return (
    <>
      <Button type="primary" 
      className="bordered-btn send-driver-details-btn"
      onClick={showModal}>
        Trip Expense
      </Button>
      <Modal
        title="Trip Expense"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          onValuesChange={handleValuesChange}
        >
          <Form.Item name="bata" label="Bata">
            <InputNumber
              formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/₹\s?|(,*)/g, '')}
              style={{ width: '100px' }}
              min={0}
            />
          </Form.Item>
          <Form.Item name="toll" label="Toll">
            <InputNumber
              formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/₹\s?|(,*)/g, '')}
              style={{ width: '100px' }}
              min={0}
            />
          </Form.Item>
          <Form.Item name="pooja" label="Pooja">
            <InputNumber
              formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/₹\s?|(,*)/g, '')}
              style={{ width: '100px' }}
              min={0}
            />
          </Form.Item>
          <Form.Item name="bus_expense" label="Bus Expenses">
            <InputNumber
              formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/₹\s?|(,*)/g, '')}
              style={{ width: '100px' }}
              min={0}
            />
          </Form.Item>
          <Form.Item name="misc" label="Misc">
            <InputNumber
              formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/₹\s?|(,*)/g, '')}
              style={{ width: '100px' }}
              min={0}
            />
          </Form.Item>

          <Divider style={{ borderColor: 'black', borderWidth: 2 }} />
          
          <Form.Item label="Total" style={{ fontWeight: 'bold' }}>
            <span>₹ {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
          </Form.Item>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button key="cancel" onClick={handleCancel}
            className="exit-btn"
            >
              Exit
            </Button>
              <Button key="update" type="primary" onClick={handleUpdate}>
              {tripExpense&&tripExpense.id?"Update":"Create"}
            </Button>
          </div>
      </Modal>
    </>
  );
};