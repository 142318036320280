import { message ,Select,Row,Col, Button, Checkbox, Modal, Input} from "antd";
import React, { useEffect, useState } from "react";
import '../../styles/user-actions.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { createAgentPrivilege, deleteOperatorPrivilegeAction, deleteUserActions, getAgentActions, getAgentPrivileges, getAgents, getOperatorPrivilegeActions, getUserActions, insertOperatorPrivilegeAction, insertUserActions } from "../../actions/agents.actions";
import { agentPrivilegesSelector, agentsSelector } from "../../selector/agents.selector";
import { isValid, isValidArray, isValidString } from "../../utils/utilities";
import { loadingAction } from "../../actions/loading-actions";
import { showConfirm } from "../../components/custom-components/custom-component";

const { Option } = Select;

const ActionButtons = ({ actions, agent, privilege, setLoading, refetch }) => {
    const { pActions, oActions, uActions, gActions } = actions;
    const isAgentSelected = isValid(agent);
    const isPrivilegeSelected = isValid(privilege);

    const handleActionChange = (checked, element, formatedStr) => {
        const config = isAgentSelected ? {
            deleteAction: deleteUserActions,
            insertAction: insertUserActions,
            identifier: agent.user_name,
        } : {
            deleteAction: deleteOperatorPrivilegeAction,
            insertAction: insertOperatorPrivilegeAction,
            identifier: privilege,
        };

        const selected = isAgentSelected ? agent : privilege;

        showConfirm(
            () => {
                setLoading(true);
                const action = checked ? config.insertAction : config.deleteAction;
                action(
                    config.identifier,
                    element.action_name,
                    () => {
                        setLoading(false);
                        refetch(selected);
                    },
                    () => setLoading(false)
                );
            },
            'Alert',
            `Are you sure to ${checked ? 'add' : 'remove'} action ${formatedStr}?`,
            'Ok'
        );
    };

    const renderCheckbox = (element) => {
        const formatedStr = isValidString(element.action_description) ? element.action_description : formateString(element.action_name);
        const actionName = element.action_name;

        // Common case - disabled checkbox when no selection
        if (!isAgentSelected && !isPrivilegeSelected) {
            return <Checkbox disabled>{formatedStr}</Checkbox>;
        }

        // Handle privileged actions that are always checked and disabled
        if ((isAgentSelected && pActions.indexOf(actionName) !== -1) || 
            (isAgentSelected && oActions.indexOf(actionName) !== -1) ||
            (isPrivilegeSelected && pActions.indexOf(actionName) !== -1)) {
            return <Checkbox disabled checked>{formatedStr}</Checkbox>;
        }

        // Handle user actions
        if (isAgentSelected) {
            const isUserAction = uActions.indexOf(actionName) !== -1;
            return (
                <Checkbox 
                    checked={isUserAction}
                    disabled={false}
                    onChange={() => handleActionChange(
                        !isUserAction, 
                        element, 
                        formatedStr
                    )}
                >
                    {formatedStr}
                </Checkbox>
            );
        }

        // Handle operator privilege actions
        if (isPrivilegeSelected) {
            const isOperatorAction = oActions.indexOf(actionName) !== -1;
            return (
                <Checkbox 
                    checked={isOperatorAction}
                    onChange={(e) => {
                        if (isOperatorAction !== e.target.checked) {
                            handleActionChange(e.target.checked, element, formatedStr)
                        }
                    }}
                >
                    {formatedStr}
                </Checkbox>
            );
        }
    };

    const renderSection = (actions, title, subSection = null) => {
        if (!actions || actions.length === 0) return null;

        const rows = [];
        let currentRow = [];

        actions.forEach((element, index) => {
            currentRow.push(
                <Col span={8} key={element.action_name}>
                    {renderCheckbox(element)}
                </Col>
            );

            // Create a new row after every 3 items or at the end
            if (currentRow.length === 3 || index === actions.length - 1) {
                rows.push(
                    <Row gutter={[16, 16]} key={`row-${rows.length}`}>
                        {currentRow}
                    </Row>
                );
                currentRow = [];
            }
        });

        return (
            <div className="action-section" key={title}>
                {subSection==null?<div className="section-title">{title}</div>:
                <div className="sub-section-title">{subSection}</div>}
                {rows}
            </div>
        );
    };

    // Organize actions by section and subsection
    const sections = Object.entries(gActions).map(([key, actions]) => {
        return Object.entries(actions).map(([subkey, subActions]) => {
            if (key === "Others" && subkey === "Others") {
                return null; // Handle Others section separately
            }
            let isSubSection = subkey != "Others";
            return renderSection(subActions, key, isSubSection?subkey:null);
        });
    });

    // Handle Others section
    const othersSection = renderSection(gActions["Others"]?.["Others"], "Others");

    return (
        <div className="actions-container">
            {sections}
            {othersSection}
        </div>
    );
};

const formateString=(string)=>{
    var newString = ''
    var currentWord = '';

    for (var j = 0; j <= string.length; j++) {
      if(string[j]=='_'&&currentWord.toLowerCase()!='allowed'){
        newString=newString+currentWord.charAt(0) + currentWord.substring(1).toLowerCase()+" ";
        currentWord='';
      }else {
        currentWord=currentWord+string[j];
      }
    }
    return newString;
  }


const renderOptions=(agents)=>{
    let agentsArray=[];
    if(isValidArray(agents)&&agents.length>0){
        agents.forEach(element => {
            agentsArray.push(<Option searchValue ={element.name} value={element.id} agent={element}>{`${element.name}`}</Option>);
        });
    }
    return agentsArray;
}

const renderPrivilegeOptions=(privileges)=>{
    let privilegesArray=[];
    if(isValidArray(privileges)&&privileges.length>0){
        privileges.forEach(element => {
            privilegesArray.push(<Option searchValue ={element.privilege} value={element.privilege} privilege={element}>{`${element.privilege}`}</Option>);
        });
    }
    return privilegesArray;
}

const getFormatedData=(data)=>{
    let formatedInfo = {
        "Others":{
            "Others":[]
        }
    };
    
    if(isValidArray(data)){
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if(isValid(element.action_section)){
                if(!isValid(formatedInfo[element.action_section])){
                    formatedInfo[element.action_section] = {
                        "Others":[]
                    };
                }
                if(isValid(element.action_subsection)){
                    if(!isValidArray(formatedInfo[element.action_section][element.action_subsection])){
                        formatedInfo[element.action_section][element.action_subsection] = [];
                    }
                    formatedInfo[element.action_section][element.action_subsection].push(element);
                }else{
                    formatedInfo[element.action_section]["Others"].push(element);
                }
            }else{
                formatedInfo["Others"]["Others"].push(element);
            }
        }
        return formatedInfo;
    }else{
        return {};
    }
}

export const UserActionsPage = (props) => {

    const dispatch = useDispatch();

    const agents = useSelector(agentsSelector);
    const [globalActions,setGlobalActions] = useState({});
    const [selectedAgent,setSelectedAgent] = useState(null);
    const [selectedPrivilege,setSelectedPrivilege] = useState(null);
    const agentPrivileges = useSelector(agentPrivilegesSelector);
    const [actions,setActions] = useState({
        pActions:[],
        oActions:[],
        uActions:[],
        gActions:[]
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newPrivilege, setNewPrivilege] = useState('');

    const onSuccess =()=>{

    }

    const onFailure=(errMsg)=>{
        message.error(errMsg);
    }

    const getAgentsDispatch = () =>{
        dispatch(getAgents(false,false,onSuccess,onFailure));
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{
        getAgentsDispatch();
        getAgentActions((info)=>{
            setGlobalActions(getFormatedData(info));
            setActions({
                pActions:[],
                oActions:[],
                uActions:[],
                gActions:getFormatedData(info)
            });
        },()=>{
            setActions({
                pActions:[],
                oActions:[],
                uActions:[],
                gActions:{}
            });
        });
        dispatch(getAgentPrivileges());
    },[]);


    useEffect(()=>{
        if(isValid(selectedAgent)){
            fetchUserActions(selectedAgent);
        }
    },[selectedAgent]);

    useEffect(()=>{
        if(isValid(selectedPrivilege)){
            fetchOperatorPrivilegeActions(selectedPrivilege);
        }
    },[selectedPrivilege]);

    const fetchUserActions=(agent)=>{
        resetSelectedData();
        setLoading(true);
        getUserActions(agent.user_name,agent.privilege,(info)=>{
            setActions({
                pActions:[...info.privilegeActions],
                oActions:[...info.operatorPrivilegeActions],
                uActions:[...info.userActions],
                gActions:globalActions
            });
            setLoading(false);
        },(errMsg)=>{
            message.error(errMsg);
            setLoading(false);
        });     
    }

    const fetchOperatorPrivilegeActions=(privilege)=>{
        resetSelectedData();
        setLoading(true);
        dispatch(getOperatorPrivilegeActions(privilege,(info)=>{
            setActions({
                pActions:[...info.privilegeActions],
                oActions:[...info.operatorPrivilegeActions],
                uActions:[],
                gActions:globalActions
            });
            setLoading(false);

        },(errMsg)=>{
            message.error(errMsg);
            setLoading(false);
        }));     
    }   

    const resetSelectedData=()=>{
        setActions({
            pActions:[],
            oActions:[],
            uActions:[],
            gActions:globalActions
        });
    }

    const handleCreatePrivilege = () => {
        if (!newPrivilege.trim()) {
            message.error('Please enter a privilege name');
            return;
        }
        createAgentPrivilege(newPrivilege,()=>{
            message.success('Privilege created successfully');
            setIsModalVisible(false);
            setNewPrivilege('');
            dispatch(getAgentPrivileges());
        },()=>{
            message.error('Failed to create privilege');
        });
    };

    return (
        <div className="user-action-page-root">
            <Row gutter={[16,16]}>
                <Col>
                <Row gutter={[16,16]}>
                <Col>
                    <div className="user-action-header">
                        Privileges
                    </div>
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select a privilege"
                      optionFilterProp="childrens"
                      filterOption={(input, option) =>
                          option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      bordered={true}
                      className='passengerNameLabel'
                      onChange={(value,{privilege})=>{   
                        setSelectedPrivilege(value);
                        setSelectedAgent(null);                
                     }}
                     value={selectedPrivilege}
                     dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Button 
                            type="primary" 
                            style={{ margin: 8 }}
                            onClick={() => {
                                setIsModalVisible(true);
                            }}
                          >
                            Create New Privilege
                          </Button>
                        </>
                      )}
                    >
                        {renderPrivilegeOptions(agentPrivileges)}
                    </Select>
                </Col>
                <Col>
                    <div className="user-action-header">
                        Users
                    </div>
                    <Select
                        size="large"
                        showSearch
                        placeholder="Select a agent"
                        optionFilterProp="childrens"
                        filterOption={(input, option) =>
                            option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        bordered={true}
                        className='passengerNameLabel'
                        onChange={(value,{agent})=>{     
                            setSelectedAgent(agent);
                            setSelectedPrivilege(null);
                        }}
                        value={selectedAgent?.id}
                    >
                        {renderOptions(agents)}                    
                    </Select>
                </Col>
            </Row>
            </Col>
            <Col align="middle" justify="center"   style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div className="user-action-header">
                    {selectedAgent&&selectedAgent.privilege&&`User Privilege: ${selectedAgent.privilege}`}
                </div>
            </Col>
        </Row>
                    <ActionButtons 
                        actions={actions}
                        agent={selectedAgent}
                        privilege={selectedPrivilege}
                        setLoading={setLoading}
                        refetch={(selected) => {
                            if (isValid(selectedAgent)) {
                                fetchUserActions(selected);
                            } else if (isValid(selectedPrivilege)) {
                                fetchOperatorPrivilegeActions(selected);
                            }
                        }}
                    />
            <Modal
                title="Create New Privilege"
                visible={isModalVisible}
                onOk={handleCreatePrivilege}
                onCancel={() => {
                    setIsModalVisible(false);
                    setNewPrivilege('');
                }}
            >
                <Input
                    placeholder="Enter privilege name"
                    value={newPrivilege}
                    onChange={(e) => setNewPrivilege(e.target.value)}
                />
            </Modal>
        </div>
    )
}