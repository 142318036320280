import React from 'react';
import { CheckSquareFilled, MinusSquareFilled, BorderOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './main.style.scss';

export const ThreeStateCheckbox = ({ state, onChange, disabled, className }) => {
    const getIcon = () => {
        switch (state) {
            case true:
                return (
                    <CheckSquareFilled 
                        className={`marginRight default-checkbo-btn ${className || ''}`}
                        style={{ 
                            fontSize: '18px',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            opacity: disabled ? 0.5 : 1
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            !disabled && onChange(false)
                        }}
                    />
                );
            case false:
                return (
                    <BorderOutlined 
                        className={`marginRight default-checkbo-btn ${className || ''}`}
                        style={{ 
                            fontSize: '18px',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            opacity: disabled ? 0.5 : 1
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            !disabled && onChange(true)
                        }}
                    />
                );
            default: // intermediate/null state
                return (
                    <MinusSquareFilled 
                        className={`marginRight default-checkbo-btn ${className || ''}`}
                        style={{ 
                            fontSize: '18px',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            opacity: disabled ? 0.5 : 1
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            !disabled && onChange(true)
                        }}
                    />
                );
        }
    };

    return getIcon();
};

ThreeStateCheckbox.propTypes = {
    state: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

ThreeStateCheckbox.defaultProps = {
    disabled: false,
    className: ''
}; 