import React, { useEffect, useState } from 'react';
import { Button, List, Row,Col,Slider, message } from 'antd';
import { getTimeStampInReadableFormat , getMinsBetweenTwoTimes , getHoursAndMinsFromMins} from '../../utils/date.utils';
import { CloseOutlined } from '@ant-design/icons';
import regenaratePng from './../../images/regenarate.png';
import { useDispatch } from 'react-redux';
import { loadingAction } from '../../actions/loading-actions';
import { isValidArray } from '../../utils/utilities';
import { showConfirm } from '../custom-components/custom-component';

let minsJson = {
    0:120,
    25:180,
    50:240,
    75:300,
    100:360
}

const marks = {
    0   : '2',
    25  : '3',
    50  : '4',
    75  : '5',
    100 : '6'
  };

const getRoutes=(tripCitiesWithStops,minimumMinsTocreateRoute,avoidRoutes)=>{

    let routes=[];
    if(isValidArray(tripCitiesWithStops)&&tripCitiesWithStops.length>0){
        for (let i = 0; i < tripCitiesWithStops.length; i++) {
            const origininfo = tripCitiesWithStops[i];
            for (let j = i+1; j < tripCitiesWithStops.length; j++) {
                const destinationInfo = tripCitiesWithStops[j];
                var duration = getMinsBetweenTwoTimes(origininfo.timing, destinationInfo.timing);
                let mins = 0, perdaymin = 1440;
                let originDay = origininfo.day-1, destinationDay = destinationInfo.day-1;
                
                if(originDay !== destinationDay) {
                    // Calculate total minutes for all days between origin and destination
                    mins = (destinationDay - originDay) * perdaymin;
                    duration = duration + mins;
                }
                if(Number(minimumMinsTocreateRoute)<Number(duration)&&avoidRoutes.indexOf(`${origininfo.city}${destinationInfo.city}`)===-1){
                    routes.push({
                        origin:origininfo.city,
                        destination:destinationInfo.city,
                        start_time:origininfo.timing,
                        end_time:destinationInfo.timing,
                        origin_day:origininfo.day,
                        destination_day:destinationInfo.day,
                        durationInMins:duration,
                        duration:getHoursAndMinsFromMins(duration),
                        origin_latitude:origininfo.latitude,
                        origin_longitude:origininfo.longitude,
                        destination_latitude:destinationInfo.latitude,
                        destination_longitude:destinationInfo.longitude 
                    })
                }
            }
        }
    }

    return routes;
}

export const FinalSummary = (props) => {

    const{
        tripCitiesWithStops,
        routes,
        setRoutes,
    } = props;

    
    const [minimumMinsTocreateRoute , setminimumMinsTocreateRoute] = useState(120);
    const [avoidRoutes , setAvoidRoutes] = useState([]);
    

    useEffect(()=>{
        setRoutes(getRoutes(tripCitiesWithStops,minimumMinsTocreateRoute,avoidRoutes))
    },[tripCitiesWithStops,minimumMinsTocreateRoute,avoidRoutes]);

    
    return (
        <div className='final-summary-root'>
                <Slider 
                    color="green"
                    className='slider-default'
                    marks={marks} 
                    defaultValue={2} 
                    style={{width:'250px'}} 
                    step={25} 
                    tooltipVisible={false} 
                    trackStyle={{

                    }}
                    onChange={(value)=>{
                        setminimumMinsTocreateRoute(minsJson[value]);
                    }}
                    />
                <div className='final-summary-sub-header'>
                    <h2>Routes</h2>
                    <Button 
                    icon={<img src={regenaratePng} />} 
                    type="text" className='outline-button refresh-button'
                    onClick={()=>{
                        setAvoidRoutes([]);
                    }}
                    ></Button>
                </div>
                <div>
                <List
                    dataSource={routes}
                    header={null}
                    renderItem={item => {
                            return(
                                <div className='route-list-main'>
                                    <div className='route-list-item'>
                                        <div className='routes-list-root'>
                                            <div className='list-item-header'>
                                                <h3 className='marginRight'>{item.origin}</h3>
                                                <h3>{item.destination}</h3>
                                            </div>
                                            <div className='list-item-sub-header'>
                                                <div className='marginRight'>{getTimeStampInReadableFormat(item.start_time)}</div>
                                                <div className='marginRight'>{`${item.duration}`}</div>
                                                <div>{getTimeStampInReadableFormat(item.end_time)}</div>
                                            </div>
                                            <div className='list-item-sub-header'>
                                                <div className='marginRight'>Day {item.origin_day+1}</div>
                                                <div>Day {item.destination_day+1}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <Button 
                                    icon={<CloseOutlined />} 
                                    type="text" 
                                    className="outline-button remove-button"
                                    onClick={()=>{
                                        showConfirm(()=>{
                                            avoidRoutes.push(`${item.origin}${item.destination}`);
                                            setAvoidRoutes([...avoidRoutes]);
                                        },"Remove route",`Are you sure to remove route from ${item.origin} to ${item.destination}`);
                                    }}
                                    ></Button>
                                </div>
                            )
                    }}
                    />
                </div>
        </div>
    )
}