/**
 * @author Rejo Varghese
 * @description Component to allow refund
 */
import React, { useState, useRef } from 'react';
import { Button, Popover, Row, Col, InputNumber, Popconfirm, message } from 'antd';
import { isValid, isValidNumber } from '../../utils/utilities';
import { setExceptionalRefundByTicketId } from './seat-click-ticket.module';


const RefundModal=({discountValue,handleCancel,handleApply})=>{

    let ref=useRef(null);

    const [confirmText, setConfirmText] = useState("Are you sure to refund?")
    const confirm = () => {
        let refundValue = isValid(ref.current.value)?ref.current.value:discountValue;
        if (refundValue>0) {
            handleApply(refundValue);
        }
        else {
            message.error("Refund amount should be greater than 0")
        }
    };
    const getText=() => {
        let refundValue = isValidNumber(ref.current.value)?ref.current.value:discountValue;
        setConfirmText('Are you sure to refund ₹'+refundValue+'?')
    }

    return(
        <div className="discount-modal-root">
                    <div>
                        <Row>
                            <Col>
                                <div className='fontWeightBold'>
                                    Refund
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col>
                                <InputNumber defaultValue={discountValue} ref={ref}/>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button onClick={handleCancel} className="exit-btn">Cancel</Button>
                            </Col>
                            <Col>
                                <Popconfirm placement="top" title={confirmText} onConfirm={confirm} okText="Yes" cancelText="No">
                                    <Button onClick={()=> getText()}>Apply</Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    </div>
        </div>
    )
}

export const RefundComponent = (props) => {
    const {
        ticketInfo
    } = props;

    const [visible, setVisible] = useState(false);
    const [discountValue, setDiscountValue] = useState(0);

    const handleCancel = () =>{
        setVisible(false);
    }

    const onSuccess = () => {
        message.success("Refund given successfully");
    }

    const onFailure = (errMsg) => {
        message.error(errMsg);
    }

    const handleApply = (value) =>{
        setDiscountValue(value);
        handleCancel();
        setExceptionalRefundByTicketId(ticketInfo.ticket.id,value, onSuccess, onFailure);
    }

    return (
        <Popover
            content={<RefundModal
                discountValue={discountValue} 
                handleCancel={handleCancel}
                handleApply={handleApply}
            />}
            title="Exceptional Refund" trigger="click" visible= {visible}
        >
            <Button onClick= {()=> {setVisible(true)}}>Refund</Button>
        </Popover>
    )

}