import React, { useEffect, useState } from "react";
import { Select,Form } from 'antd';
import { isValid, isValidArray } from "../../../utils/utilities";
import { getStaffDetails } from "./staff-select.module";

const { Option } = Select;

const renderStaff=(data,isOnlyDriver)=>{
    let staffArray=[];
    if(isValidArray(data)&&data.length>0){
        data.forEach(element => {
            if(isValidArray(element)&&isValid(element.name)){
                if(!isOnlyDriver||(isOnlyDriver&&element.staff_type === "Driver")){
                    staffArray.push(<Option value={element.name} staff={element}>{element.name}</Option>)
                }
            }else{
                staffArray.push(<Option value={element} staff={element}>{element}</Option>)

            }
        });
    }
    return staffArray;
}

export const StaffSelect = ({
    data=[],
    showLabel=true,
    isManditory=true,
    selectStaff=null,
    isFormItem=true,
    onChange=(value)=>{},
    isOnlyDriver=false,
    showLabelName="Driver Name",
    formName="driverName"
}) => {

    const [staff,setStaff] = useState(data);

    useEffect(()=>{
        if(isValidArray(data)&&data.length>0){
            setStaff(data);
        }else{
            getStaffDetails((info)=>{
                setStaff(info);
            },()=>{})
        }
    },[]);

if(isFormItem){
        return (
            <Form.Item
            name={formName}
            label={showLabel?showLabelName:''}
            rules={[{ required: isManditory, message: 'Please select staff type!' }]}
            >
                <Select
                    size="large"
                    showSearch
                    allowClear
                    placeholder="Select a staff type"
                    optionFilterProp="childrens"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    bordered={true}
                    defaultValue={selectStaff}
                    style={{minWidth:'200px'}}
                    onChange={onChange}
                >
                    {renderStaff(staff,isOnlyDriver)}
                </Select>
            </Form.Item>
        )
    }else{
        return (
                 <Select
                    size="large"
                    showSearch
                    allowClear
                    placeholder="Select a staff type"
                    optionFilterProp="childrens"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    bordered={true}
                    defaultValue={selectStaff}
                    onChange={onChange}
                    style={{minWidth:'200px'}}
                >
                    {renderStaff(staff,isOnlyDriver)}
                </Select>
        )
    }
}