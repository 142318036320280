import React, {Fragment, useEffect, useState} from 'react';
import {generateSeatLayout} from "./seat-layout.module";
import Icon from '@ant-design/icons';
import SteeringWheel from './../../images/steering-wheel.svg'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isValidArrayWithValues } from '../../utils/utilities';
export const SeatLayout = props => {
    const{
        seatGridLayout
    } ={...props}
    const{
        lowerSeatGrid,
        upperSeatGrid,
        col_count,
        maximumRowNumberByColumnForLower,
        maximumRowNumberByColumnForUpper,
        bothDecksTogether = false
    } ={...seatGridLayout}

    const [lowerSeatList,updateLowerSeatList] = useState([]);
    const [upperSeatList,updateUpperSeatList] = useState([]);
    const [showLowerSeatLayout, updateShowLowerSeatLayout] = useState(false);
    const [showUpperSeatLayout, updateShowUpperSeatLayout] = useState(false);
    // const [showSeatLayout, updateShowSeatLayout] = useState(false);


    useEffect(() => {
        let lowerArray = [];
        let upperArray = [];
        
        if(lowerSeatGrid?.length > 1) {
            lowerArray = generateSeatLayout(lowerSeatGrid, maximumRowNumberByColumnForLower, col_count);
            updateLowerSeatList(lowerArray);
            updateShowLowerSeatLayout(true);
        }
        
        if(upperSeatGrid?.length > 1) {
            upperArray = generateSeatLayout(upperSeatGrid, maximumRowNumberByColumnForUpper, col_count);
            updateUpperSeatList(upperArray);
            updateShowUpperSeatLayout(true);
        }
    }, [seatGridLayout]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Fragment>
            {bothDecksTogether ? (
                <div className="layout-container">
                    <div className="seats-container">
                        {/* First column with labels */}
                        <div className="column-wrapper">
                            {isValidArrayWithValues(lowerSeatList) && isValidArrayWithValues(upperSeatList) && <div className="deck-label">LD</div>}
                            {lowerSeatList[0]}
                        </div>
                        <div className="column-wrapper">
                            {isValidArrayWithValues(upperSeatList) && isValidArrayWithValues(lowerSeatList) && <div className="deck-label">UD</div>}
                            {upperSeatList[0]}
                        </div>

                        {/* Remaining columns of lower deck */}
                        {Array.from({ length: col_count - 1 }).map((_, index) => (
                            <div className="column-wrapper" key={`lower-${index + 1}`}>
                                {isValidArrayWithValues(lowerSeatList) && isValidArrayWithValues(upperSeatList) && lowerSeatList[index + 1].props.seats.length > 0 && <div className="deck-label">LD</div>}
                                {lowerSeatList[index + 1]}
                            </div>
                        ))}

                        {/* Remaining columns of upper deck (excluding column 1) */}
                        {Array.from({ length: col_count - 2 }).map((_, index) => (
                            <div className="column-wrapper" key={`upper-${index + 2}`}>
                                {isValidArrayWithValues(upperSeatList) && isValidArrayWithValues(lowerSeatList) && upperSeatList[index + 2].props.seats.length > 0 && <div className="deck-label">UD</div>}
                                {upperSeatList[index + 2]}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <>
                    {showLowerSeatLayout &&
                        <SeatContainer deck="lower deck" seats={lowerSeatList} showDeckName={showUpperSeatLayout}/>
                    }
                    {showUpperSeatLayout &&
                        <SeatContainer deck="upper deck" seats={upperSeatList} showDeckName={showUpperSeatLayout}/>
                    }
                </>
            )}
            </Fragment>
        </DndProvider>
    );
};

SeatLayout.defaultProps = {

};

SeatLayout.propTypes = {

};

/* To render container */
export const SeatContainer = (props) => {
    const {
        seats,
        showDeckName
    } = {...props};

    const [showContainer, updateContainer] = useState(seats.length > 0 ? true : false);
    useEffect(() => {
        if(seats.length > 0){
           updateContainer(true);
         }
    },[seats]);

    const {deck} = props;

    return (
        <div className="layout-container">
            {showDeckName&&<div className="deck-info">
                {deck}
            </div>}
            {deck=="lower deck"?
            <div style= {{display: 'inline-block'}}>
                <div style= {{float: 'right', marginBottom: 10, marginRight: 4, marginTop: showDeckName?-20:0}}>
                    <Icon component={() => (<img src={SteeringWheel} style={{width:'23px',padding:'1px'}} />)} />
                </div>
            </div>:<div style= {{marginTop: 15}} />}
            {
            showContainer &&
            <div className="seats-container">
                {seats}
            </div>
            }
        </div>
    );
};

/* To render seats column */
export const SeatColumn = (props) => {
    return (
        <div className="seats-column">
            {props.seats}
        </div>
    );
};

/* To render empty column */
export const EmptyColumn = (props) => {
    return (
        <div className="seats-column-empty"/>
    );
};
