import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { config } from './config'

const styles = StyleSheet.create({
  subtext: {
    color: config.grayColor,
    fontSize: config.normalFontSize + 2,
    marginTop:2  
  }
})

const Subtext = ({ children, style }) => (
    <Text style={[styles.subtext, style]}>{children}</Text>
)

export default Subtext
