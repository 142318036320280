import React , {useState} from "react";
import { Switch , Row ,Col , Input , Button, message} from "antd";
import { updateAgentType } from './../../actions/agents.actions';
import { isValidNumber } from "../../utils/utilities";
import { EditOutlined } from '@ant-design/icons';
import './post-paid-edit.style.scss';

export const PostPaidEdit = (props) => {

    const {
        agentId,
        onCallBack,
        postPaidLimit
    } = props;
    
    const [price, setPrice] = useState(postPaidLimit);
    const [editPrice, setEditPrice] = useState(false);

    let isPostPaidAmountLimitUser = isValidNumber(postPaidLimit)?true:false;

        return (
            <div className="agent-transaction-edit-root">
                <Row gutter={[16,16]}>
                    <Col>
                        <span>
                            Credit Limit:
                        </span>
                    </Col>
                    <Col>
                        <Switch 
                                defaultChecked={isPostPaidAmountLimitUser}
                                checkedChildren="yes" 
                                unCheckedChildren="no" 
                                onChange={(value,e)=>{
                                    let postPaidLimit = price ;
                                    if(value){
                                        postPaidLimit = 0;
                                        setPrice(0);

                                    }else{
                                        postPaidLimit = null;
                                        setPrice(0);
                                    }
                                    updateAgentType(agentId,postPaidLimit,()=>{
                                        onCallBack(agentId,postPaidLimit);
                                    },(errorMessage)=>{
                                        message.error(errorMessage);
                                    });
                                }}
                        />
                    </Col>
                </Row>
                {isPostPaidAmountLimitUser&&
                <Row gutter={[16,16]}  align="middle">
                    <Col>
                        <span>Limit Amount: </span>
                    </Col>
                    {editPrice?
                        <Col>
                            <Input 
                                value={price}
                                onChange={(e)=>{
                                    setPrice(e.target.value);
                                }}
                            />
                        </Col>
                        :
                        <Col>
                            <Row justify="center" align="middle">
                                <Col>
                                    {price}
                                </Col>
                                <Col>
                                    <Button  
                                        type="text" 
                                        className="outline-button"  
                                        icon={<EditOutlined />} 
                                        onClick={()=>{
                                            setEditPrice(true);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                }
                {editPrice&&
                    <Row gutter={[16,16]} justify="center">
                        <Col>
                            <Button className="small-button exit-btn"
                            onClick={()=>{
                                setEditPrice(false);
                            }}
                            >Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="small-button" 
                            onClick={()=>{
                                updateAgentType(agentId,price,()=>{
                                    setEditPrice(false);
                                    onCallBack(agentId,price);
                                },(errorMessage)=>{
                                    message.error(errorMessage);
                                });
                            }}
                            >Save</Button>
                        </Col>
                    </Row>
                }
            </div>
        )
}