import { Button, DatePicker, Table , Row , Col , Form, message} from "antd";
import React, { useEffect, useState , useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransactonsFormPage } from "../components/transaction-form/transaction-form.component";
import { agentsSelector } from "../selector/agents.selector";
import '../styles/agent-transactions.style.scss';
import { isValid, isValidArray } from "../utils/utilities";
import { getAgents ,getAgentsTransactions , updateAgentTransaction} from "../actions/agents.actions";
import moment from 'moment';
import { getAPIDateFormat, getUIDateFormat } from "../utils/date.utils";
import { useLocation } from "react-router-dom";
import { AGENT_TRANSACTION_COMPLETED, AGENT_TRANSACTION_FAILED } from "../utils/constant";
import { loadingAction } from './../actions/loading-actions';
import { AgentTransactionEdit } from './../components/agent-transaction-edit/agent-transaction-edit.component';

const { RangePicker } = DatePicker;


const DrawLine =()=>{

    return(
        <div className="agents-transactions-underline">

        </div>
    )
}

const Title = () =>{
        return (
            <div className="title-agent-transactions">
                <h3>
                    Agents Transactions
                </h3>
                <DrawLine />
            </div>
        )
}

export const AgentTransactonsPage = (props) => {

    const agents = useSelector(agentsSelector);
    const [ showTransactionForm , setShowTransactionForm] = useState(false);
    const [ agentTransactions , setAgentTransactions] = useState([]);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const searchRef = useRef(null);

    let today = getUIDateFormat(moment().utcOffset("+05:30"),'-');

    const columns =[
        {
            title:'Sl.no',
            key:'index',
            render : (text, record, index) => index+1
        },
        {
            title:"Date",
            key:'date',
            dataIndex:'date',
            render:(text,row) =>{
                if(isValid(text)){
                    return (getUIDateFormat(text,'-'));
                }else{
                    return null;
                }
               
            }
        },
        {
            title:"Agent Name",
            key:'agent_name',
            dataIndex:'agent_name'
        },
        {
            title:"Description",
            key:'description',
            dataIndex:'description'
        },
        {
            title:"Amount",
            key:'amount',
            dataIndex:'amount',
            render:(text,row) =>{
                if(today === getUIDateFormat(row.date,'-')){
                    return (
                        <AgentTransactionEdit 
                            agentTransactionId={row.id}
                            amount={text}
                            onCallBack={(agentTransactionId,amount)=>{
                                agentTransactions.forEach((element)=>{
                                    if(element.id===agentTransactionId){
                                        element.amount = amount;
                                    }
                                })
                                setAgentTransactions([...agentTransactions]);
                            }}
                        />
                    )
                }else{
                    return text;
                }
            }
        },
        {
            title:"Status",
            key:'status',
            dataIndex:'status',
            render:(text,row) =>{
                if(text === "Pending"){
                    return (
                        <div>
                            <Row>
                                {text}    
                            </Row>   
                            <Row gutter={[16,16]}>
                                <Col>
                                    <Button 
                                    size={"small"} 
                                    className="small-button" 
                                    onClick={()=>{
                                        updateTransaction(row.id,AGENT_TRANSACTION_FAILED);
                                    }}>Failed</Button>
                                </Col>
                                <Col>
                                    <Button 
                                    size={"small"} 
                                    className="small-button"
                                    onClick={()=>{
                                        updateTransaction(row.id,AGENT_TRANSACTION_COMPLETED);
                                    }}
                                    >Complete</Button>
                                </Col>
                            </Row>
                        </div>);
                }else{
                    return text;
                }
               
            }
        },
        {
            title:"Payment Type",
            key:'payment_type',
            dataIndex:'payment_type'
        },
        {
            title:"Ref ID",
            key:'ref_id',
            dataIndex:'ref_id'
        }
    ];

    const location = useLocation()
    let agentId = null;
    if(isValid(location.state)){
        agentId = location.state.agentId;
    }


    const setLoading =(bool)=>{
        dispatch(loadingAction(bool));
    }
    
    const getAgentsDispatch = () =>{
        dispatch(getAgents(true,true));
    }

    const getAgentsTransactionsDispatch = (startDate,endDate) =>{
        dispatch(getAgentsTransactions(startDate,endDate,(data)=>{
            setAgentTransactions(data);
        },()=>{}))
    }

    useEffect(()=>{
        if(!isValidArray(agents)||agents.length==0){
            getAgentsDispatch();
        }
    },[]);

    const onFinish=(values)=>{
        let {
            dateRange
        } = values;
        let startDate = getAPIDateFormat(dateRange[0]);
        let endDate = getAPIDateFormat(dateRange[1]);
        getAgentsTransactionsDispatch(startDate,endDate);
    }

    const updateTransaction=(agentTransactionId,status)=>{
        setLoading(true);
        updateAgentTransaction(agentTransactionId,status,null,()=>{
            message.success("Transaction updated successfully");
            searchRef.current.click();            
            setLoading(false);
        },()=>{
            setLoading(false);
        })
    }

    return (
        <div className="agents-page-root">
            <div className="agents-page-inner-div">
                <Title />
                <div>
                    <TransactonsFormPage
                        setShowTransactionForm={setShowTransactionForm}
                        showTransactionForm={showTransactionForm}
                        agents={agents}
                        selectedAgentId={agentId}
                    />
                </div>
                <div className="marginBottom">
                    <Form
                        form={form}
                        initialValues={{ dateRange :[moment().utcOffset("+05:30"),moment().utcOffset("+05:30")]}}
                        className = "agent-form"
                        onFinish={onFinish}
                    >
                        <Row gutter={[16,16]}>
                            <Col>
                                <Form.Item
                                    label="Date"
                                    rules={[{ required: true, message: 'Please input date' }]}
                                    name="dateRange"
                                    >
                                    <RangePicker renderExtraFooter={() => null} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button htmlType="submit" ref={searchRef}>Search</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div>
                    <Table 
                        columns={columns}
                        dataSource={agentTransactions}
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    );
}