import React from 'react';
import { Document, Page,  StyleSheet,PDFDownloadLink, View } from '@react-pdf/renderer';
import Title from './common/Title';
import Subtext from './common/Subtext';
import SubTextBold from './common/SubTextBold';
import { Button } from 'antd';
import { roundNumber } from '../../utils/math.utils';
import { getLocalDate, getLocalDateAndTime } from '../../utils/date.utils';
import { isValidArray } from '../../utils/utilities';
let paddingForCell= 5;


// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor:'#FFFFFF'
  },
  // Add new table styles
  tableContainer: {
    borderColor:'#000000',
    borderTopWidth:1,
    borderLeftWidth:1,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    borderBottomColor:'#000000',
    borderBottomWidth:1
  },
  tableCell: {
    padding: paddingForCell,
    borderColor:'#000000',
    borderRightWidth:1
  },
  tableCellWithOutPadding:{
    borderColor:'#000000',
    borderRightWidth:1
  },
  tableCellLast: {
    padding: paddingForCell,
    borderColor:'#000000',
    borderRightWidth:1
  },
  // Standardized table cell styles
  cellBase: {
    borderColor: '#000000',
    borderRightWidth: 1,
  },
  cellWithPadding: {
    padding: paddingForCell,
  },
  cellContent: {
    width: '50%',
    justifyContent: 'center',
  },
  cellText: {
    textAlign: 'center',
  },
  // Column widths
  colGstNumber: { width: "24%" },
  colSection: { width: "22%" },
  colTotal: { width: "10%" },
  eColGstNumber: { width: "24%" },
  eColSection: { width: "19%" }
});

const ReportHeader = ( {filters} ) => {
  return (
    <View>
      <Title>GST Filing Report</Title>
      <View style={{flexDirection:'row'}}>
        <SubTextBold>From Date :</SubTextBold>
        <Subtext>{filters.fromDate}</Subtext>
      </View>
      <View style={{flexDirection:'row'}}> 
        <SubTextBold>To Date :</SubTextBold>
        <Subtext>{filters.toDate}</Subtext>
      </View>
      <View style={{flexDirection:'row'}}>
        <SubTextBold>Generated Time :</SubTextBold>
        <Subtext>{filters.generatedTime}</Subtext>
      </View>
      <View style={{flexDirection:'row'}}>
        <SubTextBold>Agent Privilege :</SubTextBold>
        <Subtext>{global.privilege}</Subtext>
      </View>
    </View>
  )
}

const TableCell = ({ children, style, noPadding }) => (
  <View style={[
    styles.cellBase,
    !noPadding && styles.cellWithPadding,
    style
  ]}>
    {children}
  </View>
);

const SplitCell = ({ confirmCount, cancelCount ,colSection}) => (
  <View style={[styles.cellBase, colSection, { flexDirection: 'row', textAlign: 'center' }]}>
    <View style={[styles.cellBase, styles.cellContent]}>
      <Subtext style={styles.cellText}>{confirmCount}</Subtext>
    </View>
    <View style={styles.cellContent}>
      <Subtext style={styles.cellText}>{cancelCount}</Subtext>
    </View>
  </View>
);

const GstSummaryTable = ({ gstSummary }) => {
  // Calculate totals
  const totals = gstSummary.reduce((acc, curr) => {
    return {
      ecommConfirm: acc.ecommConfirm + curr.ecomm.confirmCount,
      ecommCancel: acc.ecommCancel + curr.ecomm.cancelCount,
      nonAcConfirm: acc.nonAcConfirm + curr.nonAc.confirmCount,
      nonAcCancel: acc.nonAcCancel + curr.nonAc.cancelCount,
      acConfirm: acc.acConfirm + curr.ac.confirmCount,
      acCancel: acc.acCancel + curr.ac.cancelCount,
      total: acc.total + curr.total.count,
      redbusConfirm: acc.redbusConfirm + curr.redbus.confirmCount,
      redbusCancel: acc.redbusCancel + curr.redbus.cancelCount,
      abhibusConfirm: acc.abhibusConfirm + curr.abhibus.confirmCount,
      abhibusCancel: acc.abhibusCancel + curr.abhibus.cancelCount,
      paytmConfirm: acc.paytmConfirm + curr.paytm.confirmCount,
      paytmCancel: acc.paytmCancel + curr.paytm.cancelCount,
      qwikbusConfirm: acc.qwikbusConfirm + curr.qwikbus.confirmCount,
      qwikbusCancel: acc.qwikbusCancel + curr.qwikbus.cancelCount
    };
  }, {
    ecommConfirm: 0, ecommCancel: 0,
    nonAcConfirm: 0, nonAcCancel: 0,
    acConfirm: 0, acCancel: 0,
    total: 0,
    redbusConfirm: 0, redbusCancel: 0,
    abhibusConfirm: 0, abhibusCancel: 0,
    paytmConfirm: 0, paytmCancel: 0,
    qwikbusConfirm: 0, qwikbusCancel: 0,
  });
  

  return (
    <View style={{marginTop: 10}}>
      <SubTextBold>GST Summary</SubTextBold>
      <View style={styles.tableContainer}>
        {/* First Header */}
        <View style={styles.tableRow}>
          <TableCell style={[styles.colGstNumber, styles.cellText]}>
            <SubTextBold>GST Number</SubTextBold>
          </TableCell>
          <TableCell style={[styles.colSection, styles.cellText]}>
            <SubTextBold>E-commerce</SubTextBold>
          </TableCell>
          <TableCell style={[styles.colSection, styles.cellText]}>
            <SubTextBold>Non-AC</SubTextBold>
          </TableCell>
          <TableCell style={[styles.colSection, styles.cellText]}>
            <SubTextBold>AC</SubTextBold>
          </TableCell>
          <TableCell style={[styles.colTotal, styles.cellText]}>
            <SubTextBold>Total</SubTextBold>
          </TableCell>
        </View>

        {/* Second Header */}
        <View style={styles.tableRow}>
          <TableCell style={styles.colGstNumber} />
          {['E-commerce', 'Non-AC', 'AC'].map(section => (
            <View style={[styles.cellBase, styles.colSection, { flexDirection: 'row' }]}>
              <SubTextBold style={[styles.cellBase, styles.cellContent, styles.cellText]}>Confirmed</SubTextBold>
              <SubTextBold style={[styles.cellContent, styles.cellText]}>Cancelled</SubTextBold>
            </View>
          ))}
          <TableCell style={styles.colTotal} />
        </View>

        {/* Data Rows */}
        {gstSummary.map((row, index) => (
          <View style={styles.tableRow} key={index}>
            <TableCell style={styles.colGstNumber}>
              <Subtext>{row.gstnumber}</Subtext>
            </TableCell>
            <SplitCell confirmCount={row.ecomm.confirmCount} cancelCount={row.ecomm.cancelCount} colSection={styles.colSection} />
            <SplitCell confirmCount={row.nonAc.confirmCount} cancelCount={row.nonAc.cancelCount} colSection={styles.colSection} />
            <SplitCell confirmCount={row.ac.confirmCount} cancelCount={row.ac.cancelCount} colSection={styles.colSection} />
            <TableCell style={styles.colTotal}>
              <Subtext style={styles.cellText}>{row.total.count}</Subtext>
            </TableCell>
          </View>
        ))}
        <View style={styles.tableRow}>
          <TableCell style={styles.colGstNumber} />
          <SplitCell confirmCount={totals.ecommConfirm} cancelCount={totals.ecommCancel} colSection={styles.colSection} />
          <SplitCell confirmCount={totals.nonAcConfirm} cancelCount={totals.nonAcCancel} colSection={styles.colSection} />
          <SplitCell confirmCount={totals.acConfirm} cancelCount={totals.acCancel} colSection={styles.colSection} />
          <TableCell style={styles.colTotal}>
            <Subtext style={styles.cellText}>{totals.total}</Subtext>
          </TableCell>
          </View>
      </View>
      <View style={{marginTop: 10}}>
        <SubTextBold>E-Commerce</SubTextBold>
        {/* e-commerce table first header */}
        <View style={styles.tableContainer}>
          <View style={styles.tableRow}>
            <TableCell style={[styles.eColGstNumber, styles.cellText]}>
              <SubTextBold>GST Number</SubTextBold>
            </TableCell>
            <TableCell style={[styles.eColSection, styles.cellText]}>
              <SubTextBold>Redbus</SubTextBold>
            </TableCell>
            <TableCell style={[styles.eColSection, styles.cellText]}>
              <SubTextBold>Abhibus</SubTextBold>
            </TableCell>
            <TableCell style={[styles.eColSection, styles.cellText]}>
              <SubTextBold>Paytm</SubTextBold>
            </TableCell>
            <TableCell style={[styles.eColSection, styles.cellText]}>
              <SubTextBold>Qwikbus</SubTextBold>
            </TableCell>
          </View>
          {/* e-commerce table second header */}
          <View style={styles.tableRow}>
            <TableCell style={styles.eColGstNumber} />
            {['Redbus', 'Abhibus', 'Paytm', 'Qwikbus'].map(section => (
              <View style={[styles.cellBase, styles.eColSection, { flexDirection: 'row' }]}>
                <SubTextBold style={[styles.cellBase, styles.cellContent, styles.cellText]}>Confirmed</SubTextBold>
                <SubTextBold style={[styles.cellContent, styles.cellText]}>Cancelled</SubTextBold>
              </View>
            ))}
          </View>
           {/* Data Rows */}
        {gstSummary.map((row, index) => (
          <View style={styles.tableRow} key={index}>
            <TableCell style={styles.eColGstNumber}>
              <Subtext>{row.gstnumber}</Subtext>
            </TableCell>
            <SplitCell confirmCount={row.redbus.confirmCount} cancelCount={row.redbus.cancelCount} colSection={styles.eColSection} />
            <SplitCell confirmCount={row.abhibus.confirmCount} cancelCount={row.abhibus.cancelCount} colSection={styles.eColSection} />
            <SplitCell confirmCount={row.paytm.confirmCount} cancelCount={row.paytm.cancelCount} colSection={styles.eColSection} />
            <SplitCell confirmCount={row.qwikbus.confirmCount} cancelCount={row.qwikbus.cancelCount} colSection={styles.eColSection} />
          </View>
        ))}
        <View style={styles.tableRow}>
            <TableCell style={styles.eColGstNumber}>
              <Subtext>Total</Subtext>
            </TableCell>
            <SplitCell confirmCount={totals.redbusConfirm} cancelCount={totals.redbusCancel} colSection={styles.eColSection} />
            <SplitCell confirmCount={totals.abhibusConfirm} cancelCount={totals.abhibusCancel} colSection={styles.eColSection} />
            <SplitCell confirmCount={totals.paytmConfirm} cancelCount={totals.paytmCancel} colSection={styles.eColSection} />
            <SplitCell confirmCount={totals.qwikbusConfirm} cancelCount={totals.qwikbusCancel} colSection={styles.eColSection} />
          </View>
        </View>
      </View>
      <View>
        {gstSummary.map((row, index) => (
          <View key={index} style={{marginTop: 10}}>
              <SubTextBold>{row.gstnumber=="nogstnumber"?"No GST Number":row.gstnumber}</SubTextBold>
              <View style={styles.tableContainer}>
                <View style={styles.tableRow}>
                  <TableCell style={{width: "40%"}}>
                    <SubTextBold>Details</SubTextBold>
                  </TableCell>
                  <TableCell style={{width: "15%"}}>
                    <SubTextBold>Fare</SubTextBold>
                  </TableCell>
                  <TableCell style={{width: "15%"}}>
                    <SubTextBold>CGST</SubTextBold>
                  </TableCell>
                  <TableCell style={{width: "15%"}}>
                    <SubTextBold>SGST</SubTextBold>
                  </TableCell>
                  <TableCell style={{width: "15%"}}>
                    <SubTextBold>Total Gst</SubTextBold>
                  </TableCell>
                </View>
                {/* Data Rows */}
                {row.gstList.map((gstinfo, gstindex) => (
                  <View style={styles.tableRow} key={gstindex}>
                    <TableCell style={{width: "40%"}}>
                      {gstinfo.isBold?<SubTextBold strong>{gstinfo.details}</SubTextBold>:<Subtext>{gstinfo.details}</Subtext>}
                    </TableCell>
                    <TableCell style={{width: "15%"}}>
                    {gstinfo.isBold?<SubTextBold strong>{roundNumber(gstinfo.discounted_fare)}</SubTextBold>:<Subtext>{roundNumber(gstinfo.discounted_fare)}</Subtext>}
                    </TableCell>
                    <TableCell style={{width: "15%"}}>
                    {gstinfo.isBold?<SubTextBold strong>{roundNumber(gstinfo.cgst)}</SubTextBold>:<Subtext>{roundNumber(gstinfo.cgst)}</Subtext>}
                    </TableCell>
                    <TableCell style={{width: "15%"}}>
                    {gstinfo.isBold?<SubTextBold strong>{roundNumber(gstinfo.sgst)}</SubTextBold>:<Subtext>{roundNumber(gstinfo.sgst)}</Subtext>}
                    </TableCell>
                    <TableCell style={{width: "15%"}}>
                    {gstinfo.isBold?<SubTextBold strong>{roundNumber(gstinfo.total_gst)}</SubTextBold>:<Subtext>{roundNumber(gstinfo.total_gst)}</Subtext>}
                    </TableCell>
                  </View>
                ))}
              </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const GstTicketsTable = ({ gstTickets }) => {
  return (
    <View style={{marginTop: 10}}>
      <SubTextBold>Detailed ticket GST report</SubTextBold>
      <View style={styles.tableContainer}>
        <View style={styles.tableRow}>
          <TableCell style={{width: "5%"}}>
            <SubTextBold>SN</SubTextBold>
          </TableCell>
          <TableCell style={{width: "5%"}}>
            <SubTextBold>Agent Name</SubTextBold>
          </TableCell>
          <TableCell style={{width: "10%"}}>
            <SubTextBold>PNR</SubTextBold>
          </TableCell>
          <TableCell style={{width: "10%"}}>
            <SubTextBold>Seat Count</SubTextBold>
          </TableCell>
          <TableCell style={{width: "5%"}}>
            <SubTextBold>Fare</SubTextBold>
          </TableCell>
          <TableCell style={{width: "5%"}}>
            <SubTextBold>GST</SubTextBold>
          </TableCell>
          <TableCell style={{width: "10%"}}>
            <SubTextBold>Amount Paid</SubTextBold>
          </TableCell>
          <TableCell style={{width: "10%"}}>
            <SubTextBold>Booking Time</SubTextBold>
          </TableCell>
          <TableCell style={{width: "10%"}}>
            <SubTextBold>Travel Date</SubTextBold>
          </TableCell>
          <TableCell style={{width: "30%"}}>
            <SubTextBold>Trip Description</SubTextBold>
          </TableCell>
        </View> 
        {gstTickets.map((row, index) => (
          <View style={styles.tableRow} key={index}>
            <TableCell style={{width: "5%"}}>
              <Subtext>{index+1}</Subtext>
            </TableCell>
            <TableCell style={{width: "5%"}}>
              <Subtext>{row.agent_name}</Subtext>
            </TableCell>
            <TableCell style={{width: "10%"}}>
              <Subtext>{row.pnr}</Subtext>
            </TableCell>
            <TableCell style={{width: "10%"}}>
              <Subtext>{row.seat_count}</Subtext>
            </TableCell>
            <TableCell style={{width: "5%"}}>
              <Subtext>{roundNumber(row.discounted_fare)}</Subtext>
            </TableCell>
            <TableCell style={{width: "5%"}}>
              <Subtext>{roundNumber(row.gst)}</Subtext>
            </TableCell>
            <TableCell style={{width: "10%"}}>
              <Subtext>{roundNumber(row.amount_paid)}</Subtext>
            </TableCell>
            <TableCell style={{width: "10%"}}>
              <Subtext>{getLocalDateAndTime(row.booking_time)}</Subtext>
            </TableCell>
            <TableCell style={{width: "10%"}}>
              <Subtext>{getLocalDate(row.travel_date)}</Subtext>
            </TableCell>
            <TableCell style={{width: "30%"}}>
              <Subtext>{row.description}</Subtext>
            </TableCell>
          </View>
        ))}
        </View>
    </View>
  );
};

// Create Document Component
const MyDocument = ({
  filters,
  gstSummary,
  gstTickets
}) => {
    

    return(
        <Document>
          <Page style={styles.page}>
                <ReportHeader 
                    filters={filters}
                />
                {isValidArray(gstSummary) && gstSummary.length > 0 &&
                  <GstSummaryTable 
                    gstSummary={gstSummary}
                  />
                }
                {isValidArray(gstTickets) && gstTickets.length > 0 &&
                  <GstTicketsTable 
                    gstTickets={gstTickets}
                  />
                }
          </Page>
        </Document>
      )
};

export const GstFilingReportPdf = ({
    showGstFilingReport,
    gstTickets,
    gstSummary,
    filters
}) =>{
  if(!showGstFilingReport){
    return null;
  }
    return (
        <div>
            <PDFDownloadLink document={<MyDocument 
                filters={filters}
                gstSummary={gstSummary}
                gstTickets={gstTickets}
             />} fileName={`gst-filing-report.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
              <Button>
                Export To Pdf
              </Button>
            </PDFDownloadLink>
        </div>
    )
}
