import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import {
    FETCH_MASTER_CITIES,
    SET_MASTER_CITIES,
    GET_GOOGLE_DISTANCE_MATRIX,
    GET_BUSES_ACTION,
    BUS_TABLE_LOADING,
    BUSES_INFO,
    CREATE_TRIP_DATA,
    IS_TRIP_CITY_CREATED,
    SET_TRIP_SCHEDULE_ID,
    UPDATE_TRIP_DATA, GET_ADDRESS_LOCATIONS, INSERT_TRIP_STOPS, GET_STANDARD_STOPS, SET_STANDARD_STOPS, LOADING_ACTION, CLONE_TRIP, CLONE_AS_RETURN_TRIP,
    CREATE_SCHEDULE_BY_STOPS
} from '../actions/action-types';
import { message } from 'antd';
import {isValid} from './../utils/utilities';

export  function* cloneTrip(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
      const {
          api,
          url ,
          apiPayload,
          onSuccess
          } = {...action.payload};
      const apiResults = yield call(api , url,apiPayload);
      if(apiResults.status=="SUCCESS"){
          let clientPayload = apiResults.payload;
          if(clientPayload.Status=='SUCCESS'){
             message.success(clientPayload.Message);
             onSuccess();
            }else{
                yield put({
                    type: LOADING_ACTION,
                    payload: false
                });
            message.error(clientPayload.Message);
          }
        }else{
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
          message.error("Unable to clone trip`");
        }
     }catch(error){
          console.error("Error in clone trip",error);
      }  
  }
  
  function* watchCloneTripSaga () {
    yield takeLatest(CLONE_TRIP,cloneTrip)
  }
  
  export  function* createScheduleByStops(action){
    try{
      yield put({
        type: LOADING_ACTION,
        payload: true
    });
      const {
          api,
          url ,
          apiPayload,
          onSuccess
          } = {...action.payload};
      const apiResults = yield call(api , url,apiPayload);
      if(apiResults.status=="SUCCESS"){
          let clientPayload = apiResults.payload;
          if(clientPayload.Status=='SUCCESS'){
             message.success(clientPayload.Message);
             yield put({
                type: LOADING_ACTION,
                payload: false
            });
             onSuccess();
            }else{
            yield put({
                type: LOADING_ACTION,
                payload: false
            });
            message.error(clientPayload.Message);
          }
        }else{
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
        message.error("Unable to clone as return trip`");
        }
     }catch(error){
          console.error("Error in clone as return trip",error);
      }  
  }
  
  function* watchCreateScheduleByStopsSaga () {
    yield takeLatest(CREATE_SCHEDULE_BY_STOPS,createScheduleByStops)
  }

export function* fetchMasterCities(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status==='SUCCESS'){
           let serverPayload=clientPayload.Payload;
            yield put({
                type: SET_MASTER_CITIES,
                payload: serverPayload.cities
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting trip expene",error);
    }
}

export function* getDataBetweenCitiesSaga(action) {
    try {
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&apiResult.payload){
                if(isValid(apiResult.payload.message)){
                    message.error(apiResult.payload.message);
                }
                let clientPayload=apiResult.payload;
                onSuccess(clientPayload);
            }else{
                message.error("Failed to get schedules");
            }
    }
    catch (error) {
        console.log("fetching schedules",{error});
        message.error("Failed to get schedules");
    }
};


export function* getBusDetails(action) {
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        yield put({
            type: BUS_TABLE_LOADING,
            payload: true
        });
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status=="SUCCESS"){
          let clientPayload = apiResult.payload;
          if(clientPayload.Status=='SUCCESS'){
            let serverPayload = clientPayload.Payload
            yield put({
                type: BUSES_INFO,
                payload: serverPayload.buses
            });
            yield put({
                type: BUS_TABLE_LOADING,
                payload: false
            });
          }else{
            message.error(clientPayload.Message);
          }
        }

    }
    catch(error){
        console.error("Error in buses",error);
    }
}

export function* createTripDataSaga(action) {
    try {
        const {api, url, apiPayload , onSuccess,onFailure} = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if (apiResult.status=="SUCCESS"&&apiResult.payload.Status == "SUCCESS"){
            let serverPayload = apiResult.payload.payload;
            yield put ({
                type: IS_TRIP_CITY_CREATED,
                payload: true
            });
            yield put ({
                type: SET_TRIP_SCHEDULE_ID,
                payload: serverPayload.scheduleId
            });
            onSuccess(serverPayload.scheduleId);
            message.success("Trip Created successfully");
        }
        else{
            yield put ({
                type: IS_TRIP_CITY_CREATED,
                payload: false
            });
            message.error("Trip creation is unsuccessful");
            onFailure();
        }
    }
    catch(error) {
        yield put ({
            type: IS_TRIP_CITY_CREATED,
            payload: false
        })
        console.error("Error in creating trip", error);
    }
}

export function* updateTripDataSaga(action) {
    try {
        const {api, url, apiPayload ,  onSuccess,onFailure} = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if (apiResult.status=="SUCCESS"&&apiResult.payload.Status == "SUCCESS"){
            yield put ({
                type: IS_TRIP_CITY_CREATED,
                payload: true
            })
            message.success("Trip Updated successfully");
            onSuccess();
        }
        else{
            yield put ({
                type: IS_TRIP_CITY_CREATED,
                payload: false
            })
            message.error("Trip updation is unsuccessful");
            onFailure();
        }
    }
    catch(error) {
        yield put ({
            type: IS_TRIP_CITY_CREATED,
            payload: false
        })
        console.error("Error in updating trip", error);
    }
}

export function* getAddressLocationsSaga(action) {
    try {
        const {
            api,
            url,
            apiPayload,
            onSuccess,
            onFailure
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&apiResult.payload.Status === "SUCCESS"){
            if(isValid(apiResult.payload.message)){
                    onFailure(apiResult.payload.message);
                }
                let clientPayload=apiResult.payload;
                onSuccess(clientPayload.Payload);
            }else{
                onFailure("Failed to get address location");
            }
    }
    catch (error) {
        console.log("fetching address location",{error});
        message.error("Failed to get address location");
    }
};

export function* getStandardStopsSaga(action) {
    try {
        yield put({
            type:LOADING_ACTION,
            payload:true
          })
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&apiResult.payload.Status === "SUCCESS"){
                let clientPayload=apiResult.payload.Payload;
                yield put ({
                    type: SET_STANDARD_STOPS,
                    payload: {
                        standardAddressStops:clientPayload.addressLocations
                    }
                })
            }else{
                message.error("Failed to get standard locations");
            }
            yield put({
                type:LOADING_ACTION,
                payload:false
              });
    }
    catch (error) {
        console.log("fetching standard locations",{error});
        message.error("Failed to get standard locations");
        yield put({
            type:LOADING_ACTION,
            payload:false
          });
    }
};


export function* insertTripStopsSaga(action) {
    try {
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&apiResult.payload.Status === "SUCCESS"){
                if(isValid(apiResult.payload.message)){
                    message.error(apiResult.payload.message);
                }
                let clientPayload=apiResult.payload;
                onSuccess(clientPayload.Payload);
            }else{
                message.error("Failed to insert trip stops");
            }
    }
    catch (error) {
        console.log("insert trip stops",{error});
        message.error("Failed to inserttrip stops");
    }
};

function* watchInsertTripStopsSaga() {
    yield takeLatest(INSERT_TRIP_STOPS, insertTripStopsSaga)
}

function* watchGetAddressLocationsSaga() {
    yield takeLatest(GET_ADDRESS_LOCATIONS, getAddressLocationsSaga)
}

function* watchGetStandardStopsSagasSaga() {
    yield takeLatest(GET_STANDARD_STOPS, getStandardStopsSaga)
}

function * watchUpdateTripSaga() {
    yield takeLatest(UPDATE_TRIP_DATA, updateTripDataSaga)
}

function* watchCreateTripSaga() {
    yield takeLatest(CREATE_TRIP_DATA,createTripDataSaga)
}


function* watchGetBusDetails () {
    yield takeLatest(GET_BUSES_ACTION,getBusDetails)
}

function* watchGetDataBetweenCitiesSaga() {
    yield takeLatest(GET_GOOGLE_DISTANCE_MATRIX, getDataBetweenCitiesSaga)
}

function* watchfetchMasterCitiesSaga () {
    yield takeLatest(FETCH_MASTER_CITIES,fetchMasterCities)
}



export default function*  watchTripCreatePageSagas() {
    yield all([
        fork(watchfetchMasterCitiesSaga),
        fork(watchGetDataBetweenCitiesSaga),
        fork(watchGetBusDetails),
        fork(watchCreateTripSaga),
        fork(watchUpdateTripSaga),
        fork(watchGetAddressLocationsSaga),
        fork(watchInsertTripStopsSaga),
        fork(watchGetStandardStopsSagasSaga),
        fork(watchCreateScheduleByStopsSaga),
        fork(watchCloneTripSaga)
    ])
}


