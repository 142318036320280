export const runSplashScreenSelector=state=>state.userReducer.runSplashScreen;

export const userLoginSelector=state=>state.userReducer.isLoggedIn;

export const userInfoSelector=state=>{
    return{
        isLoggedIn:state.userReducer.isLoggedIn,
        userName:state.userReducer.user.userName,
        phoneNumber:state.userReducer.user.phoneNumber,
        operatorCode:state.userReducer.user.operatorCode,
        operatorName:state.userReducer.user.operatorName,
        travelsId:state.userReducer.user.travelsId,
        privilege:state.userReducer.user.privilege,
        name:state.userReducer.user.name,
        location:state.userReducer.user.location,
        accessCode:state.userReducer.user.accessCode,
        displayName: state.userReducer.user.displayName
    }
};

export const userPartnerSelectors = state => state.userReducer.partnerDetails;

export const userNameSelector=state=>state.userReducer.user.userName;

export const travelsDetailsSelector=state=>state.userReducer.travelsDetails;
