import { Modal, Button, Select, Form, Input, TimePicker, Steps, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { isValid, isValidArray } from '../../utils/utilities';
import moment from 'moment';
import { get24HoursTime } from '../../utils/date.utils';
import { useDispatch } from 'react-redux';
import { createScheduleByStops } from '../../actions/trip-create.actions';
import { getScheduleStopsForSchedule } from '../../actions/admin.action';
import Step2Component from './step-2.component';
import './clone-schedule.style.scss';
import { FinalSummary } from './final-summary.component';

const setMainStops = (data) => {
    data.forEach(city => {
        city.stops.forEach((stop,stopIndex) => {
            if(stopIndex===0){
                stop.is_main_stop = true;
            }else{
                stop.is_main_stop = false;
            }
        });
    });
    return data;
}

const { Option } = Select;
const { Step } = Steps;

export const CloneScheduleComponent = (props) => {
    const {
        busesData,
        formData,
        onCloneSuccess,
        isReturnSchedule
    } = props;
    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [scheduleStops, setScheduleStops] = useState([]);
    const [routes,setRoutes] = useState([]);
    const [values,setValues] = useState(null);

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        if(isModalVisible){
            fillForm();
        }
    }, [formData,isModalVisible]);

    const fillForm = () => {
        if (isValidArray(formData) && isValid(formData.id)) {
            let {
                bus_id,
                name,
                end_time,
                start_time
            } = formData;
            let time = isReturnSchedule ? end_time : start_time;
            form.setFieldsValue({
                start_time: moment(time, 'HH:mm'),
                name: name,
                bus_id: bus_id
            });
        }
    };

    const handleNext = () => {
        if(currentStep===0){
            form.validateFields()
                .then(values => {
                let startTime = get24HoursTime(values.start_time);
                setValues(values);
                // Fetch schedule stops for step 2
                getScheduleStopsForSchedule(
                    formData.id,
                    startTime,
                    values.bus_id,
                    values.name,
                    isReturnSchedule,
                    (data) => {                      
                        setScheduleStops(setMainStops(data));
                        setCurrentStep(1);
                    },
                    (error) => {
                        message.error(error);
                    }
                );
            })
            .catch(errorInfo => {
                console.log({ errorInfo });
            });
        }else if(currentStep===1){
            // Validate that all stops have timing, day and tripClosePoint set
            let hasError = false;
            let errorMessage = '';
            let hasTripClosePoint = false;

            scheduleStops.forEach((city, cityIndex) => {
                city.stops.forEach((stop, stopIndex) => {
                    if (!stop.timing) {
                        hasError = true;
                        errorMessage = `Timing is missing for ${stop.location} in ${city.city}`;
                    }
                    if (!stop.day && stop.day !== 0) {
                        hasError = true; 
                        errorMessage = `Day is missing for ${stop.location} in ${city.city}`;
                    }
                    // Check if this stop is marked as a trip close point
                    if (stop.trip_close_point) {
                        hasTripClosePoint = true;
                    }
                });
            });
            
            // Check if at least one stop has tripClosePoint set
            if (!hasTripClosePoint) {
                hasError = true;
                errorMessage = 'At least one stop must be marked as Trip Close Point';
            }

            if (hasError) {
                message.error(errorMessage);
                return;
            }
            setCurrentStep(2);
        }else if(currentStep===2){
            handleFinish();
        }
    };

    const handleBack = () => {
        if(currentStep===2){
            setCurrentStep(1);
        }else{
            setCurrentStep(0);
            setScheduleStops([]);
        }
    };

    const handleFinish = () => {  
        dispatch(createScheduleByStops(scheduleStops,routes,values.bus_id,formData.fare,formData.fare_json,values.name,formData.id,()=>{
            setIsModalVisible(false);
            onCloneSuccess();
        },(error)=>{
            message.error(error);
        }));
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentStep(0);
        setScheduleStops([]);
        form.resetFields();
    };

    const renderStep1 = () => (
        <Form
            form={form}
            name="basic"
            initialValues={{ name: '' }}
        >
            <Form.Item
                label="Select bus"
                name="bus_id"
                rules={[{ required: true, message: 'Please select bus!' }]}
            >
                <Select
                    showSearch
                    placeholder="Select bus"
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {busesData.map(bus => (
                        <Option value={bus.id} data={bus} key={bus.description}>
                            {bus.description}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Trip Identifier"
                name="name"
                rules={[{ required: false, message: 'Please input identifier!' }]}
            >
                <Input placeholder="Trip identifier" />
            </Form.Item>
            <Form.Item
                label="Start Time"
                name="start_time"
                rules={[{ required: true, message: 'Please select start time!' }]}
            >
                <TimePicker format="hh:mm A" 
                 className='remove-clear-icon'  
                 popupClassName={"time-picker-to-not-display-footer"}
                 onSelect={(value)=>{
                    form.setFieldsValue({
                        start_time: value
                    });
                 }}
                />
            </Form.Item>
        </Form>
    );

    const renderStep2 = () => (
        <div>
          <Step2Component
            scheduleData={scheduleStops}
            setScheduleData={setScheduleStops}
            isReturnSchedule={isReturnSchedule}
          />
        </div>
    );

    const renderStep3 = () => (
        <FinalSummary
            tripCitiesWithStops={scheduleStops}
            routes={routes}
            setRoutes={setRoutes}
        />
    );

    const renderStep=()=>{
        switch(currentStep){
            case 0:
                return renderStep1();
            case 1:
                return renderStep2();
            case 2:
                return renderStep3();
            default:
                return null;
        }
    }   

    const renderFooter1=()=>{
        return (
            <Button key="cancel" className="exit-btn" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button
                key="next"
                type="primary"
                onClick={handleNext}
            >
                Next
            </Button>
        )
    }

    const renderFooter2=()=>{
        return [
            <Button key="cancel" className="exit-btn" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button key="back" className="exit-btn" onClick={handleBack}>
                Back
            </Button>,
            <Button
                key="next"
                type="primary"
                onClick={handleNext}
            >
                Next
            </Button>
        ]               
    }

    const renderFooter3=()=>{
        return [
            <Button key="cancel" className="exit-btn" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button key="back" className="exit-btn" onClick={handleBack}>
                Back
            </Button>,
            <Button
                key="next"
                type="primary"
                onClick={handleFinish}
            >
                Create
            </Button>
        ]
    }

    const renderFooter=()=>{
        switch(currentStep){
            case 0:
                return renderFooter1();
            case 1:
                return renderFooter2();
            case 2:
                return renderFooter3();
            default:
                return null;
        }
    }
    return (
        <>
            <Button
                style={{ background: 'orange', borderColor: 'orange', color: '#FFFFFF' }}
                onClick={() => setIsModalVisible(true)}
            >
                {isReturnSchedule ? "Create Return Trip" : "Clone Trip"}
            </Button>
            <Modal
                title={isReturnSchedule ? "Clone as return trip" : "Clone trip"}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={renderFooter()}
            >
                <Steps current={currentStep} style={{ marginBottom: 24 }}>
                    <Step title="Basic Info" />
                    <Step title="Review Stops" />
                    <Step title="Review Routes" />
                </Steps>
                {renderStep()}
            </Modal>
        </>
    );
};
