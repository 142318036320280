import { UPDATE_ADVANCE_BOOKING_DAYS_URL } from '../../../utils/api-url';
import { postCall } from '../../../utils/network.util';

export const updateAdvanceBookingDays = async (scheduleId, days) => {
    try {
        const response = await postCall(UPDATE_ADVANCE_BOOKING_DAYS_URL, {
            scheduleId,
            advanceBookingDays: days
        });
        
        if (response?.status === "SUCCESS" && response?.payload?.Status === "SUCCESS") {
            return response;
        }
        
        throw new Error(
            response?.payload?.Message || 
            'Failed to update advance booking days'
        );
    } catch (error) {
        if (error instanceof Error) {
            throw error;
        }
        throw new Error(
            typeof error === 'string' ? error : 'Failed to update advance booking days'
        );
    }
}; 