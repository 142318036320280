import React from 'react'
import { StyleSheet , Image } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  image: {
    width: 150, 
    height: 50 ,
    objectFit: 'contain',
  }
})

const ImageRender = ({src, style}) => {
  // Handle Google Cloud Storage URLs by adding public access
  const processedSrc = src.includes('storage.googleapis.com') 
    ? `${src}?public=true` 
    : src;

  // Add error handling and ensure source is valid
  try {
    if (!processedSrc) {
      console.warn('Image source is missing');
      return null;
    }

    return (
      <Image 
        src={processedSrc} 
        style={{...styles.image, ...style}} 
        cache={false}
      />
    );
  } catch (error) {
    console.error('Error rendering image:', error);
    return null;
  }
}

export default ImageRender
