import React, {useState} from "react";
import { Button, message, Checkbox, Modal } from "antd";
import { handleReleaseSeats } from './release-seats.module';
import { isValid, isValidArray,checkActionAllowed } from "../../utils/utilities";
import { ACTION_RELEASE_TICKET_ALLOWED, ACTION_PERMANENT_ADMIN_BLOCK_RELEASE } from './../../utils/constant';

export const ReleaseSeatsComponent = (props) => {

    const{
        selectedSchedule,
        selectedSeats,
        onSuccess
    }=props;

    // const onSuccess=()=>{
    //     fetchSeats(selectedSchedule.trip_route_id);
    // }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPermanentRelease, setIsPermanentRelease] = useState(false);

    const onFailure=()=>{
        message.error("Failed to block trips!")
    }


    const handleOk = () => {
        if(isValidArray(selectedSchedule)&&isValidArray(selectedSchedule.trip)&&isValid(selectedSchedule.trip.id)){
            let tripId=selectedSchedule.trip.id;
            let tripRouteId = selectedSchedule.trip_route_id;
            handleReleaseSeats(tripId,tripRouteId,selectedSeats,isPermanentRelease,onSuccess,onFailure);
        }
        else{
            message.error("Trip not selected!")
        }
        setIsModalOpen(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }
    
    if(checkActionAllowed(ACTION_RELEASE_TICKET_ALLOWED)){
        return (
            <div>
                <Button onClick={()=>setIsModalOpen(true)}>
                    Release
                </Button>
                <Modal visible={isModalOpen} title="Release Seats" onOk={handleOk} onCancel={handleCancel} >
                    <div>
                        <span>Are you sure to release seats?</span>
                        {checkActionAllowed(ACTION_PERMANENT_ADMIN_BLOCK_RELEASE)&&<div style={{ marginTop: '10px' }}>
                            <Checkbox
                                checked={isPermanentRelease}
                                onChange={(e) => setIsPermanentRelease(e.target.checked)}
                            >
                                Permanent Release
                            </Checkbox>
                        </div>}
                    </div>
                </Modal>
            </div>
        )
    }else{
        return null
    }
}