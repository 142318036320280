import React, { useState } from 'react';
import { Collapse, Table } from 'antd';
import { TimePanel } from './time-panel.component';

export const TimeChangesTab = ({ selectedDate,data }) => {

    return (
        <div className="time-changes-tab">
           {data && data.length > 0 ? (
                <Collapse
                    expandIconPosition="left"
                    ghost
                >
                    {data.map((item, index) => (
                        <Collapse.Panel 
                            key={index}
                            header={`${index + 1}. ${item.description}`}
                        >
                            <TimePanel item={item} index={index} selectedDate={selectedDate} />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <p className="no-data">No time changes for the selected month.</p>
            )}
        </div>
    );
}; 