import React, { useContext, useState, useEffect } from "react";
import { Button , Typography , Divider , List, message } from "antd";
import { checkActionAllowed, isValid, isValidArray } from "../../utils/utilities";
import { ACTION_CHANGE_BUS_ALLOWED, isQBUserCheck } from './../../utils/constant';
import { SearchScheduleComponent } from './../search-components/search-schedule-component/search-schedule.component';
import { getAPIDateFormat , incrementDays} from "../../utils/date.utils";
import './change-bus.style.scss';
import DonutChartComponent  from "../donut-chart-component/donut-chart.component";
import { useDispatch } from "react-redux";
import { fetchSeatLayout , setSelectedSchedule} from './../../actions/home.action';
import { SeatLayoutContainer } from './../seat-layout/seat-layout-container.component';
import { PassengerForm } from './passenger-form.component';
import { loadingAction } from './../../actions/loading-actions';
import { SelectSeatsButton , CardDescription , RouteDetails, RenderFares , RenderNewTime} from './../common-components/search-result-item-card/search-result-item-card.component';
import { DeviceContext } from "../device-provider/device-provider";
import { getConfirmedPassengersCount } from "./change-bus.module";


const validateSearchResults =(data,tripRouteId)=>{
    let isValidResults = false;
    data.forEach((element)=>{
        if(element.trip_route_id !== tripRouteId){
            isValidResults = true;
        }
    });
    return isValidResults;
}

const renderSeatLayout=(seats,noOfSeatsAllowedToClick,onSeatClickSuccess)=>{
    if(isValidArray(seats)){
        return <SeatLayoutContainer 
                seatsData={seats}
                noOfSeatsAllowedToClick={noOfSeatsAllowedToClick}
                blockedSeatAllowed={false}
                onSeatClickSuccess={onSeatClickSuccess}
                isDragDropRequired={false}
            />
    }else{
        return null
    } 
}

const { Text , Title} = Typography;

const DrawLine =()=>{

    return(
        <div className="select-bus-underline">

        </div>
    )
}


export const ChangeBusButton = (props) => {
    
    const {
        setShowChangeBus,
        showChangeBus
    } = props;
    
    if(checkActionAllowed(ACTION_CHANGE_BUS_ALLOWED) || isQBUserCheck()){
        return (
            <div className="select-seats-block">
                <Button 
                onClick={()=>{
                        setShowChangeBus(!showChangeBus);
                }}>
                    Change Bus
                </Button>
            </div>
        )
    }else{
        return null
    }
}

export const RenderSearchResults = ({
    noOfSeatsAllowedToClick,
    isMobile,setSchedule,searchResults,fetchSeats,seats,setOpenTripRouteId,openTripRouteId,oldPassengers,
    onSeatClickSuccess,oldTicket,agent,oldTrip,oldSchedule,oldRoute,setSeats,
    tabkey , setKey, setSearchResults, oldTicketDetails, stopPoint
})=>{

    const [selectedTripRoute, setSelectedTripRoute]= useState(null);
    const [confirmedTravellers, setConfirmedTravellers] = useState(noOfSeatsAllowedToClick)

    useEffect(() => {
        if (isValid(selectedTripRoute)) {
            searchResults.map((results) => {
                if (results.trip_route_id == selectedTripRoute) {
                    let seatsAvailbility=results.seatsAvailbility;
                    seatsAvailbility.booked = seatsAvailbility.booked+confirmedTravellers;
                    seatsAvailbility.vacant = seatsAvailbility.vacant-confirmedTravellers;
                    results.seatsAvailbility = seatsAvailbility;
                }
            })
            setConfirmedTravellers(0);
            setSearchResults([]);
            const timeout = setTimeout(() => {
                setSearchResults([...searchResults])
                setSelectedTripRoute(null);
            }, 1);
            return () => clearTimeout(timeout)
        }
    }, [selectedTripRoute]);

    if(isValidArray(searchResults)&&searchResults.length>0){
        return (
            <div>
                {!validateSearchResults(searchResults,oldTicket.trip_route_id)&&
                    <div>No trip is available for re-scheduling. Please change criteria and search again.</div>
                }
                <List
                    dataSource={searchResults}
                    renderItem={item => {
                        let  adminBlocked= 0 ,blocked=0,booked=0,vacant=0 , totalSeats;
                        if(isValidArray(item.seatsAvailbility)){
                            let seatsAvailbility=item.seatsAvailbility;
                            adminBlocked = seatsAvailbility.adminBlocked;
                            blocked = seatsAvailbility.blocked;
                            booked = seatsAvailbility.booked;
                            totalSeats = seatsAvailbility.totalSeats;
                            vacant = seatsAvailbility.vacant;
                        }
                        let isOpenSelectSeats=openTripRouteId===item.trip_route_id?true:false;
                        if(oldTicket.trip_route_id === item.trip_route_id){
                            return null;
                        }else{
                            if(isMobile){
                                return(
                                    <div className="search-item-root-div">
                                    <div className="search-item-header">
                                        <CardDescription 
                                            schedule={item}
                                        />
                                    </div>
                                    <div className="search-item-second-row">
                                                <RouteDetails 
                                                    schedule={item}
                                                    selectedDate={item.trip.start_date}
                                                />
                                                <DonutChartComponent 
                                                    AdminBlocked={adminBlocked}
                                                    Blocked={blocked}
                                                    Booked={booked}
                                                    Vacant={vacant}
                                                    basefare={0}
                                                    sleeperFare={0}
                                                />
                                    </div>
                                    <div className="search-item-fourth-row">
                                        <RenderFares 
                                            schedule={item}                                        
                                        />
                                          <SelectSeatsButton 
                                                resetSeats={()=>{
                                                    setSeats(null);
                                                }}
                                                fetchSeats={()=>{
                                                    setSeats(null); 
                                                    setOpenTripRouteId(item.trip_route_id);
                                                    fetchSeats(item.trip_route_id);
                                                    setSchedule(item);
                                                }}
                                                isOpenSelectSeats={isOpenSelectSeats}
                                                item={item}
                                                openTripRouteId={openTripRouteId}
                                                setOpenTripRouteId={setOpenTripRouteId}
                                                tabkey={tabkey} 
                                                setKey={setKey}
                                            />
                                    </div>
                            
                                    {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&
                                    <div className="expandend-block">
                                        <div className="expand-root">
                                            <RenderNewTime 
                                                schedule={item}
                                            />   
                                            {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&tabkey==="Select Seats"&&
                                                <div className="seat-block">
                                                    {renderSeatLayout(seats,noOfSeatsAllowedToClick,onSeatClickSuccess)}
                                                    <PassengerForm 
                                                    oldPassengers={oldPassengers}
                                                    pickupTimings={item.pickupTimings}
                                                    dropTimings={item.dropTimings}
                                                    selectedDate={item.trip.start_date}
                                                    selectedSchedule={item}
                                                    oldTicket={oldTicket}
                                                    agent = {agent}
                                                    fetchSeats={fetchSeats}
                                                    oldTrip={oldTrip}
                                                    oldSchedule={oldSchedule}
                                                    oldRoute={oldRoute}
                                                    setSelectedTripRoute = {setSelectedTripRoute}
                                                    oldTicketDetails = {oldTicketDetails}
                                                    stopPoint = {stopPoint}
                                                    />
                                                </div>
                                                }
                                        </div>
                                    </div>
                                    }
                                </div>
                                )
                            }
                            return(
                                <div className="search-item-root-div">
                                    <div className="search-item-header">
                                        <CardDescription 
                                            schedule={item}
                                        />
                                        <RenderFares 
                                            schedule={item}                                        
                                        />
                                    </div>
                                    <div className="search-item-second-row">
                                                <RouteDetails 
                                                    schedule={item}
                                                    selectedDate={item.trip.start_date}
                                                />
                                                <DonutChartComponent 
                                                    AdminBlocked={adminBlocked}
                                                    Blocked={blocked}
                                                    Booked={booked}
                                                    Vacant={vacant}
                                                    basefare={0}
                                                    sleeperFare={0}
                                                />
                                                <div></div>
                                    </div>
                                    <SelectSeatsButton 
                                                resetSeats={()=>{
                                                    setSeats(null);
                                                }}
                                                fetchSeats={()=>{
                                                    setSeats(null); 
                                                    if(openTripRouteId === item.trip_route_id){
                                                        setOpenTripRouteId(null);
                                                        setSchedule(null);
                                                    }else{
                                                        setOpenTripRouteId(item.trip_route_id);
                                                        fetchSeats(item.trip_route_id);
                                                        setSchedule(item);
                                                    }
                                                }}
                                                isOpenSelectSeats={isOpenSelectSeats}
                                                item={item}
                                                openTripRouteId={openTripRouteId}
                                                setOpenTripRouteId={setOpenTripRouteId}
                                                tabkey={tabkey} 
                                                setKey={setKey}
                                            />
                                    {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&tabkey==="Select Seats"&&
                                    <div className="seat-block">
                                        {renderSeatLayout(seats,noOfSeatsAllowedToClick,onSeatClickSuccess)}
                                        <PassengerForm 
                                        oldPassengers={oldPassengers}
                                        pickupTimings={item.pickupTimings}
                                        dropTimings={item.dropTimings}
                                        selectedDate={item.trip.start_date}
                                        selectedSchedule={item}
                                        oldTicket={oldTicket}
                                        agent={agent}
                                        fetchSeats={fetchSeats}
                                        oldTrip={oldTrip}
                                        oldSchedule={oldSchedule}
                                        oldRoute={oldRoute}
                                        setSelectedTripRoute = {setSelectedTripRoute}
                                        oldTicketDetails = {oldTicketDetails}
                                        stopPoint = {stopPoint}
                                        />
                                    </div>
                                    }
                                </div>
                            )
                        }
                    }}
                />
            </div>
        )
    }else{
        return <div>No trip is available for re-scheduling. Please change criteria and search again.</div>;
    }

}
export const ChangeBusComponent = (props) => {
    
    const {
        showChangeBus,
        ticketDetails
    } = props;

    const dispatch = useDispatch();

    const [seats,setSeats] = useState(undefined);
    const [stopPoint, setStopPoint] = useState({});
    const [openTripRouteId,setOpenTripRouteId] = useState(undefined);
    const [ tabkey , setKey ] = useState(null);

    const {
        isMobile
    } = useContext(DeviceContext);

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }


    const fetchSeats=(tripRouteId)=>{
        setLoading(true);
        dispatch(fetchSeatLayout(tripRouteId,(seatsData)=>{
            setSeats(seatsData.seats);
            let tempStop = {'pickupDetails': seatsData.pickupDetails, 'dropDetails': seatsData.dropDetails};
            setStopPoint(tempStop);
            setLoading(false);
        },(errmsg)=>{
            message.error(errmsg);
            setLoading(false);
        }));
    }

    const [searchResults , setSearchResults] = useState([]);

    const onSeatClickSuccess =(seat)=>{
    }

    const setSchedule = (schedule)=>{
        dispatch(setSelectedSchedule(schedule));
    }

    if(showChangeBus){
        if(isValidArray(ticketDetails)&&isValidArray(ticketDetails.ticket)&&isValid(ticketDetails.ticket.id)){
            let origin = ticketDetails.route.origin;
            let destination = ticketDetails.route.destination;
            let date = getAPIDateFormat(ticketDetails.trip.start_date);
            if(ticketDetails.route.origin_day>0){
                date = incrementDays(date,ticketDetails.route.origin_day);
            }
            let oldPassengers = ticketDetails.traveller;
            let oldTicket = ticketDetails.ticket;
            let agent = ticketDetails.agent;
            let noOfSeatsAllowedToClick = getConfirmedPassengersCount(oldPassengers);

            return (
                <div>
                    <Divider style={{ borderTop: '1px solid #929292' }}/>
                    <Title level={5}>Select Bus</Title>
                    <DrawLine />
                    <div className="marginTop">
                        <SearchScheduleComponent 
                            origin={origin}
                            destination={destination}
                            date={date}
                            isMobileFriendly={true}
                            onSearch={(data)=>{
                                setSearchResults(data);
                                setSeats(null);
                                setOpenTripRouteId(null);
                                setLoading(false);
                            }}
                            isSearchClickTigger={true}
                            isGetSeatAvailibity={true}
                            />
                    </div>
                    <div>
                        <RenderSearchResults 
                        searchResults={searchResults}
                        seats={seats}
                        stopPoint = {stopPoint}
                        fetchSeats={fetchSeats}
                        setOpenTripRouteId={setOpenTripRouteId}
                        openTripRouteId={openTripRouteId}
                        oldPassengers={oldPassengers}
                        onSeatClickSuccess={onSeatClickSuccess}
                        oldTicket={oldTicket}
                        agent = {agent}
                        oldTrip={ticketDetails.trip}
                        oldSchedule={ticketDetails.schedule}
                        oldRoute={ticketDetails.route}
                        setSeats={setSeats}
                        setLoading={setLoading}
                        setSchedule={setSchedule}
                        isMobile={isMobile}
                        noOfSeatsAllowedToClick={noOfSeatsAllowedToClick} 
                        tabkey={tabkey}
                        setKey={setKey}
                        setSearchResults = {setSearchResults}
                        oldTicketDetails = {ticketDetails}
                        />
                    </div>
                </div>
            )
        }else{
            return(
                <div>
                    Unable to get ticket details!
                </div>
            )
        }
    }else{
        return null
    }
}