import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusDetailsAction } from "../../actions/trip-create.actions";
import { tripCreateReducerSelector } from "../../selector/trip-create.selector";
import { Button, Modal, Select, message,Row,Col } from "antd";
import { getSeatLayOut } from "../../actions/admin.action";
import './change-seat-layout.style.scss';
import { SeatLayoutContainer } from "../seat-layout/seat-layout-container.component";
import { changeSeatLayoutReq } from "./change-seat-layout.module";
import { loadingAction } from "../../actions/loading-actions";
import { showConfirm } from "../custom-components/custom-component";
import { isValid, getValuesOfJSonObject } from "../../utils/utilities";
import { fetchSeatLayout } from "../../actions/home.action";
import { passengerInfoSelector } from "../../selector/passenger-form.selector";
import { resetPassengerForm } from "../../actions/ticket.action";

const {
    Option
} = Select;

const printBusesOption = (option) => {
    return <Option  value={option.id} data={option} key={option.description}>{option.description}</Option>
};

export const ChangeSeatLayout = (props) => {

    const{
        tripId,
        tripRouteId
    }=props;

    const [isModalVisible,setModalVisible] = useState(false);
    const [seatLayoutData,setSeatLayoutData] = useState([]);
    const [busId,setBusId] = useState(null);
    const [currentSeatLayoutData,setCurrentSeatLayoutData] = useState([]);
    const [layoutMapping, setLayoutMapping] = useState({});
    const [oldLayoutNoSeats, setOldLayoutNoSeats] = useState(1);
    const [reservedSeats, setReservedSeats] = useState({});
    const [adjacentNewSeats, setAdjacentNewSeats] = useState({})
    const [oldSelectedSeats, setOldSelectedSeats] = useState([]);

    const dispatch = useDispatch();

    const handleOk=()=>{
       
    }
    const {
        selectedSeats
    } = useSelector(passengerInfoSelector);

    const handleCancel=()=>{
        setModalVisible(false);
    }

    useEffect(() => {
        if (isValid(selectedSeats)) {
            let seatsArray=getValuesOfJSonObject(selectedSeats);
            let seatLength = seatsArray.length;
            let oldSeat, newSeat, gender, seatStatus;
            if (seatLength==1) {
                setOldLayoutNoSeats(0);
            }
            else if (seatLength>1) {
                seatsArray.map((seat)=> {
                    if(["Confirmed","Blocked"].includes(seat.seatStatus)) {
                        oldSeat = seat.seatNumber;
                        gender = seat.gender;
                        seatStatus = seat.seatStatus;
                    }
                    else {
                        newSeat = seat.seatNumber;
                    }
                })
                let tempLayoutMapping = layoutMapping;
                if (isValid(oldSeat)&&isValid(newSeat)) {
                    tempLayoutMapping[oldSeat] = newSeat;
                    setLayoutMapping(tempLayoutMapping);
                    formatNewSeatLayout(oldSeat, newSeat, gender, seatStatus, false);
                    setOldSelectedSeats(Object.keys(tempLayoutMapping));
                }
                dispatch(resetPassengerForm());
                setOldLayoutNoSeats(1);
            }
        }
    }, [selectedSeats])

    useEffect(() => {
        if (isValid(tripRouteId)) {
            dispatch(fetchSeatLayout(tripRouteId,(seatsData)=>{
                setCurrentSeatLayoutData(seatsData.seats);
                let tempReserved = {};
                seatsData.seats.map((seat) => {
                    if (["Confirmed","Blocked"].includes(seat.seat_status)) {
                        tempReserved[seat.seat_number]= seat;
                    }
                })
                setReservedSeats(tempReserved);
            },()=>{}));
        }
    },[tripRouteId])
    
    useEffect(()=>{
        dispatch(getBusDetailsAction());
    },[]);

    const formatNewSeatLayout = (oldSeat, newSeat, gender, seatStatus, isReset=false) => {
        let tempSeatLayout = [...seatLayoutData];
        let prevSeat = `(${oldSeat})`
        let assignSeatNum = newSeat+prevSeat;
        tempSeatLayout.map((seat) => {
            let seatNum = seat.seat_number;
            let originalNum = seat.original_seat_number;
            if (!isReset) {
                if (seatNum.includes(prevSeat)&&originalNum!=newSeat) {
                    seat.seat_number = originalNum;
                    seat.gender = null;
                    seat.seat_status = '';
                    seat.blocked_by = '';
                    seat.full_name = '';
                    seat.phone_number = '';
                    if(isValid(seat.passengers)) {
                        delete seat.passengers;
                    }
                }
                if (originalNum==newSeat) {
                    let oldSeatData = reservedSeats[oldSeat];
                    seat.seat_number = assignSeatNum;
                    seat.gender = gender;
                    seat.seat_status = seatStatus;
                    seat.blocked_by = oldSeatData.blocked_by;
                    seat.full_name = oldSeatData.full_name;
                    seat.phone_number = oldSeatData.phone_number;
                    if(isValid(oldSeatData.passengers)) {
                        seat.passengers = oldSeatData.passengers;
                    }
                }
            }
            else {
                seat.seat_number = originalNum;
                seat.gender = null;
                seat.seat_status = '';
                seat.blocked_by = '';
                seat.full_name = '';
                seat.phone_number = '';
                if(isValid(seat.passengers)) {
                    delete seat.passengers;
                }
            }
        })
        setSeatLayoutData(tempSeatLayout);
    }

    const setLoading=(val)=>{
        dispatch(loadingAction(val));
    }

    const tripCreateReducer = useSelector(tripCreateReducerSelector);
    let { busesData } = tripCreateReducer;

    const onSuccess=(data)=>{
        let fetchedSeatLayout = [...data.seat_layout];
        let tempAdjacentSeats = {};
        fetchedSeatLayout.map((seat) => {
            seat.original_seat_number = seat.seat_number;
            tempAdjacentSeats[seat.seat_number] = seat.adjacent_seat_number;
        })
        setSeatLayoutData(fetchedSeatLayout);
        setAdjacentNewSeats(tempAdjacentSeats);
    }

    const onFailure=()=>{

    }

    const handleReset=()=>{
        let tempOldSeatLayout = [...currentSeatLayoutData];
        setCurrentSeatLayoutData([]);
        formatNewSeatLayout(null,null,null,null,true);
        dispatch(resetPassengerForm());
        setOldSelectedSeats([]);
        setLayoutMapping({});
        setOldLayoutNoSeats(1);
        setCurrentSeatLayoutData(tempOldSeatLayout);
    }

    const validateSeatLayout=()=>{
        let oldReservedSeats = Object.keys(reservedSeats), mappedReservedSeats = Object.keys(layoutMapping);
        if (oldReservedSeats.length == mappedReservedSeats.length) {
            let seatDataMapping = {}, dupSeat = [], newSeatsList =[], mismatchSeatList = [];
            if (mappedReservedSeats.length>0) {
                mappedReservedSeats.map((seat, ind) => {
                    let insertAdjacentSeat = true;
                    if (ind==0) {
                        seatDataMapping['adjacentSeats'] = {};
                    }
                    let newSeat = layoutMapping[seat];
                    if (newSeatsList.includes(newSeat)&&!dupSeat.includes(newSeat)) {
                        dupSeat.push(newSeat);
                    }
                    newSeatsList.push(newSeat);
                    seatDataMapping[newSeat] = reservedSeats[seat];
                    if (newSeat!=adjacentNewSeats[newSeat]&&newSeatsList.includes(adjacentNewSeats[newSeat])) {
                        let compareSeat = seatDataMapping[newSeat], adjacentCompareSeat = seatDataMapping[adjacentNewSeats[newSeat]], isAdminBlocked = false;
                        if ((compareSeat.seat_status=="Blocked"&&compareSeat.ticket_id=="")||(adjacentCompareSeat.seat_status=="Blocked"&&adjacentCompareSeat.ticket_id=="")) {
                            isAdminBlocked = true;
                        }
                        if (!isAdminBlocked&&(compareSeat.gender!=adjacentCompareSeat.gender)) {
                            if (compareSeat.ticket_id!=adjacentCompareSeat.ticket_id){
                                mismatchSeatList.push(newSeat);
                                mismatchSeatList.push(adjacentNewSeats[newSeat]);
                                insertAdjacentSeat= false;
                            }
                        }
                    }
                    if (insertAdjacentSeat&&!newSeatsList.includes(adjacentNewSeats[newSeat])) {
                        seatDataMapping['adjacentSeats'][newSeat] = adjacentNewSeats[newSeat];
                    }
                })
                Object.keys(seatDataMapping['adjacentSeats']).map((newSeat) => {
                    if (newSeatsList.includes(seatDataMapping['adjacentSeats'][newSeat])) {
                        delete seatDataMapping['adjacentSeats'][newSeat];
                    }
                })
            }
            if ((dupSeat.length==0&&mismatchSeatList.length==0)||mappedReservedSeats.length==0) {
                return ({status:true,message:"Seat Layout Changed succuessfully!", seatDataMapping:seatDataMapping})
            }
            else {
                let msg;
                if(dupSeat>0) {
                    msg = `Seat numbers ${dupSeat} is mapped for more than one seat.`;
                }
                else {
                    msg = `Gender mismatch for new seat layout in seat numbers ${mismatchSeatList}. Kindly select different seats.`
                }
                // message.error(msg);
                return ({status:false,message:msg})
            }
        }
        else {
            let leftSeats = oldReservedSeats.filter((seat)=> !mappedReservedSeats.includes(seat));
            let msg = `Kindly map seat numbers ${leftSeats} from old layout to new layout.`;
            // message.error(msg);
            return ({status:false,message:msg})
        }
    }

    return(
        <div className="">
                {/* <div className="selectBusTxt">Select Bus</div> */}
                <Button 
                className="bordered-btn send-driver-details-btn"  
                onClick={()=>{
                    setModalVisible(true);
                }}
                >
                    Change Layout
                </Button>
                <Modal
                      title={"Change Seat Layout"}
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      width="95%"
                      style={{ maxWidth: 1200 }}
                      footer={null}
                >
                    <Select
                        showSearch
                        className="changeBusSelect marginBottom"
                        placeholder="Select bus"
                        style={{ width: '100%' }}
                        onChange={(value)=>{
                            setBusId(value);
                            setSeatLayoutData([]);
                            getSeatLayOut(value,onSuccess,onFailure);
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {busesData.map(printBusesOption)}
                    </Select>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} sm={24} md={11}>
                            <div className="layout-section">
                                <span style={{fontWeight:'bold'}}>Current Layout</span>
                                <div className="seat-container">
                                    <SeatLayoutContainer
                                        seatsData={currentSeatLayoutData}
                                        showLegend={false}
                                        showFarePopOver={false}
                                        blockedSeatAllowed={true}
                                        noOfSeatsAllowedToClick={oldLayoutNoSeats}
                                        changeOldLayout={true}
                                        oldSelectedSeats={oldSelectedSeats}
                                        isDragDropRequired={false}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11}>
                            <div className="layout-section">
                                <span style={{fontWeight:'bold'}}>New Layout</span>
                                <div className="seat-container">
                                    <SeatLayoutContainer
                                        seatsData={seatLayoutData}
                                        showLegend={false}
                                        showFarePopOver={false}
                                        blockedSeatAllowed={false}
                                        noOfSeatsAllowedToClick={oldLayoutNoSeats==0?2:0}
                                        isDragDropRequired={false}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {seatLayoutData.length>0 && (
                        <>
                        <Row justify="center" style={{ marginTop: 16 }}>
                            <Col>
                                <Button 
                                    style={{ marginRight: 8 }}
                                    onClick={() => {
                                        let tempLayoutMapping = {};
                                        Object.keys(reservedSeats).forEach(oldSeat => {
                                            // Find matching seat by row, column and deck in new layout
                                            const oldSeatData = reservedSeats[oldSeat];
                                            const matchingSeat = seatLayoutData.find(
                                                seat => seat.seat_row_number === oldSeatData.seat_row_number && 
                                                       seat.seat_column_number === oldSeatData.seat_column_number &&
                                                       seat.upper_deck === oldSeatData.upper_deck
                                            );
                                            
                                            if (matchingSeat) {
                                                tempLayoutMapping[oldSeat] = matchingSeat.original_seat_number;
                                                formatNewSeatLayout(
                                                    oldSeat,
                                                    matchingSeat.original_seat_number,
                                                    oldSeatData.gender,
                                                    oldSeatData.seat_status,
                                                    false
                                                );
                                            }
                                        });
                                        let tempOldSeatLayout = [...currentSeatLayoutData];
                                        setCurrentSeatLayoutData([]);
                                        setCurrentSeatLayoutData(tempOldSeatLayout);
                                        setLayoutMapping(tempLayoutMapping);
                                        setOldSelectedSeats(Object.keys(tempLayoutMapping));
                                        
                                        if (Object.keys(tempLayoutMapping).length > 0) {
                                            message.success(`${Object.keys(tempLayoutMapping).length} seats mapped successfully!`);
                                        } else {
                                            message.error("No matching seats found based on position.");
                                        }
                                    }}
                                >
                                    Positional Transfer
                                </Button>
                                <Button 
                                    style={{ marginRight: 8 }}
                                    onClick={() => {
                                        let tempLayoutMapping = {};
                                        Object.keys(reservedSeats).forEach(oldSeat => {
                                            // Find matching seat number in new layout
                                            const matchingSeat = seatLayoutData.find(
                                                seat => seat.original_seat_number === oldSeat
                                            );
                                            if (matchingSeat) {
                                                tempLayoutMapping[oldSeat] = matchingSeat.original_seat_number;
                                                formatNewSeatLayout(
                                                    oldSeat,
                                                    matchingSeat.original_seat_number,
                                                    reservedSeats[oldSeat].gender,
                                                    reservedSeats[oldSeat].seat_status,
                                                    false
                                                );
                                            }
                                        });
                                        let tempOldSeatLayout = [...currentSeatLayoutData];
                                        setCurrentSeatLayoutData([]);
                                        setCurrentSeatLayoutData(tempOldSeatLayout);
                                        setLayoutMapping(tempLayoutMapping);
                                        setOldSelectedSeats(Object.keys(tempLayoutMapping));
                                        
                                        if (Object.keys(tempLayoutMapping).length > 0) {
                                            message.success(`${Object.keys(tempLayoutMapping).length} seats mapped successfully!`);
                                        } else {
                                            message.error("Seat numbers don't match between layouts.");
                                        }
                                    }}
                                >
                                    Auto Map Seats
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={{ marginRight: 8 }}
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginTop: 16 }}>
                            <Col>
                                <Button 
                                    style={{ marginRight: 8 }}
                                    onClick={handleCancel}
                                    className="exit-btn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{ marginRight: 8 }}
                                    onClick={()=>{
                                        let validateSeatLayoutData = validateSeatLayout();
                                        if(validateSeatLayoutData.status){
                                            showConfirm(()=>{
                                                setLoading(true);
                                                changeSeatLayoutReq(tripId,busId,validateSeatLayoutData.seatDataMapping,()=>{
                                                    setLoading(false);
                                                    dispatch(resetPassengerForm());
                                                    setOldLayoutNoSeats(0);
                                                    setSeatLayoutData([]);
                                                    handleCancel();
                                                    message.success("Seat Layout Changed succuessfully!");
                                                },(err)=>{
                                                    message.error(err);
                                                    setLoading(false);
                                                });
                                            },"Are you sure to change seat layout?");
                                        }
                                        else{
                                            message.error(validateSeatLayoutData.message);
                                        }
                                    }}
                                >
                                    Change Layout
                                </Button>
                            </Col>
                        </Row>
                        </>
                    )}
                </Modal>
        </div>
    )
}
