import React , { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Collapse, Divider, InputNumber, message, Modal, Row, Select, TimePicker,Typography, Switch, Tooltip } from "antd";
import './new-timing.style.scss';
import { getScheduleStopsByTripIdWithCities, resetToDefaultTimings, updateStopTimingsOfTrip } from "../../../actions/home.action";
import { checkActionAllowed, deleteIndexValue, getArrayfromString, isValid, isValidArray, isValidNumber } from "../../../utils/utilities";
import { getReadableDate, getTimeStampInReadableFormat, getAPIDateFormat } from "../../../utils/date.utils";
import TicMarkIcon from './../../../images/tic-mark.png';
import Icon from '@ant-design/icons';
import { showConfirm } from "../../custom-components/custom-component";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { UPDATE_IN_NEW_TIME_TAB } from "../../../utils/constant";
import moment from "moment";
import ZoomComponent from './../../custom-components/zoom-component/zoom.component';
import { DeviceContext } from "../../device-provider/device-provider";
import {
    MinusSquareOutlined,
    MinusSquareFilled,
    PlusSquareOutlined,
    EditOutlined,
    DeleteOutlined,
    CheckSquareFilled,
    PlusOutlined
} from '@ant-design/icons';
import { getAddressLocations } from "../../../actions/trip-create.actions";
import { CreateStandardStopsPage } from "../../../pages/create-standard-stop.page";
import { ScheduleCalendar } from '../../shared/schedule-calendar/schedule-calendar.component';

const { 
    Panel
} = Collapse;

const {
    Title ,
    Text
} = Typography ;

const {
    Option
} = Select;

const getExistingLocations=(stopsWithCities)=>{
    let locations = [];
    if(isValidArray(stopsWithCities)){
        for (let index = 0; index < stopsWithCities.length; index++) {
            const element = stopsWithCities[index];
            let {
                stops
            } = element;
            for (let index = 0; index < stops.length; index++) {
                const stop = stops[index];
                locations.push(stop.displayName);
            }
        }
        return locations;
    }else{
        return [];
    }
}

const showResetBtn=(citiesWithStops)=>{
    let atleastonepointedited = false;
    citiesWithStops.forEach((cityObject)=>{

        cityObject.stops.forEach((stopObject)=>{
            
            if(stopObject.isEdit == true || stopObject.isActive == 0){
                atleastonepointedited = true;
            }

           
        })
    });
    return atleastonepointedited;
}

const showCheckBoxForEdit=()=>{
    if(checkActionAllowed(UPDATE_IN_NEW_TIME_TAB)){
        return true;
    }else{
        return false;
    }
}


const getChangedStops = (citiesWithStops, originalStops) => {
    const changedStops = {};
    let hasChanges = false;

    const checkFieldChanges = (current, original, fieldsToCheck, cityDay = 0) => {
        const changes = {};
        let hasFieldChanges = false;

        for (const [field, value] of Object.entries(fieldsToCheck)) {
            if (!original || (field === 'isActive' ? 
                Boolean(value) !== Boolean(original[field]) : 
                field === 'day' ?
                    // For day field, subtract cityDay before comparison
                    (Math.max(value, 0) - cityDay) !== (original[field] || 0) :
                    value !== original[field])) {
                // For day field, store the value after subtracting cityDay
                changes[field] = field === 'day' ? Math.max(value - cityDay, 0) : value;
                hasFieldChanges = true;
            }
        }

        return { changes, hasFieldChanges };
    };

    citiesWithStops.forEach((currentCity, cityIndex) => {
        const originalCity = originalStops[cityIndex];
        const cityName = currentCity.displayName;
        let allStopsInactive = true;
        
        // Get city day from first stop
        const cityDay = currentCity.stops[0]?.day || 0;

        // Process stops first to determine city active status
        const cityStopChanges = {};
        currentCity.stops.forEach((currentStop, stopIndex) => {
            const originalStop = originalCity?.stops[stopIndex];
            
            if (currentStop.isActive) {
                allStopsInactive = false;
            }

            const fieldsToCheck = {
                timing: currentStop.timing,
                day: Math.max(currentStop.day, 0),
                isActive: Boolean(currentStop.isActive),
                tripClosePoint: currentStop.tripClosePoint || 0
            };

            let { changes: stopChanges, hasFieldChanges } = 
                checkFieldChanges(currentStop, originalStop, fieldsToCheck, cityDay);

            // Add new stop specific fields
            if (currentStop.isNewStop) {
                Object.assign(stopChanges, {
                    isNewStop: true,
                    selectedStopId: currentStop.selectedStopId,
                    phoneNumber: currentStop.phoneNumber,
                    landlineNumber: currentStop.landlineNumber,
                    isBoarding: currentStop.isBoarding || false,
                    isDrop: currentStop.isDrop || false
                });
                hasFieldChanges = true;
            }

            if (hasFieldChanges) {
                if (!changedStops[cityName]) {
                    changedStops[cityName] = { stops: {} };
                }
                cityStopChanges[currentStop.displayName] = stopChanges;
                hasChanges = true;
            }
        });

        // Process city changes
        if (Object.keys(cityStopChanges).length > 0) {
            const firstStop = currentCity.stops[0];
            const originalFirstStop = originalCity?.stops[0];

            const cityFieldsToCheck = {
                timing: firstStop.timing,
                day: Math.max(firstStop.day, 0),
                isActive: !allStopsInactive // Set city isActive based on stops status
            };

            const { changes: cityChanges, hasFieldChanges: hasCityChanges } = 
                checkFieldChanges(cityFieldsToCheck, originalFirstStop, cityFieldsToCheck);

            if (hasCityChanges) {
                changedStops[cityName].city = cityChanges;
            }
            changedStops[cityName].stops = cityStopChanges;
        }
    });

    return { changedStops, hasChanges };
};

const TimePickerComponent = ({ value, onSelect, showError, timeExits, isMobile }) => (
    <TimePicker 
        size={isMobile ? "small" : "small"}
        className={`${showError && !timeExits ? "remove-clear-icon errorInput" : "remove-clear-icon"} ${isMobile ? "mobile-time-picker" : ""}`}
        popupClassName={isMobile ? "mobile-time-picker-popup time-picker-to-not-display-footer" : "time-picker-to-not-display-footer"}
        use12Hours 
        format='h:mm A'  
        minuteStep={5} 
        value={isValid(value) ? moment(value, 'HH:mm') : null}
        onSelect={onSelect}
        style={{ width: '100%' }}
    />
);

const StopNameDisplay = ({ displayName, isBoarding, isDrop }) => (
    <span style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
        {displayName}
        {(isBoarding || isDrop) && (
            <span style={{ marginLeft: '4px' }}>
                {isBoarding && (
                    <span style={{ 
                        backgroundColor: '#1890ff',
                        color: 'white',
                        padding: '0 4px',
                        borderRadius: '2px',
                        fontSize: '12px',
                        marginRight: '4px'
                    }}>
                        B
                    </span>
                )}
                {isDrop && (
                    <span style={{ 
                        backgroundColor: '#52c41a',
                        color: 'white',
                        padding: '0 4px',
                        borderRadius: '2px',
                        fontSize: '12px'
                    }}>
                        D
                    </span>
                )}
            </span>
        )}
    </span>
);

const CityCheckbox = ({ isActive, cityIndex, onToggle, isShowCheckBox=true }) => {
    if (!isShowCheckBox) return null;

    const handleClick = (e) => {
        // Prevent event from bubbling up to parent elements
        e.stopPropagation();
        onToggle(!isActive, cityIndex);
    };

    if (!isActive) {
        return (
            <MinusSquareFilled 
                className="marginRight"
                style={{ 
                    color: '#ff4d4f',
                    fontSize: '18px',
                    cursor: 'pointer'
                }}
                onClick={handleClick}
            />
        );
    }

    return (
        <CheckSquareFilled 
            className="marginRight default-checkbo-btn"
            style={{ 
                fontSize: '18px',
                cursor: 'pointer'
            }}
            onClick={handleClick}
        />
    );
};


const TimeAdjustmentModal = ({ visible, onClose, onApply }) => {
    const [operation, setOperation] = useState('+');
    const [minutes, setMinutes] = useState(0);

    return (
        <Modal
            title="Adjust Timings"
            visible={visible}
            onCancel={onClose}
            onOk={() => {
                onApply(operation, minutes);
                onClose();
            }}
            okText="Apply"
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Select
                    defaultValue="+"
                    style={{ width: '100%' }}
                    onChange={(value) => setOperation(value)}
                >
                    <Option value="+">Add Time (+)</Option>
                    <Option value="-">Subtract Time (-)</Option>
                </Select>
                <InputNumber
                    min={0}
                    placeholder="Enter minutes"
                    style={{ width: '100%' }}
                    onChange={(value) => setMinutes(value)}
                />
            </div>
        </Modal>
    );
};

const DayInputComponent = ({ value, onChange, showError, dayExits, isMobile }) => (
    <InputNumber
        className={`${showError && !dayExits ? "dayInput errorInput" : "dayInput"} ${isMobile ? "mobile-day-input" : ""}`}
        size="small"
        defaultValue={value}
        onChange={onChange}
        style={{ width: '80%' }}
    />
);

export const NewTimingTab = (props) => {

    let {
        scheduleId,
        tripId,
        travelDate,
        scheduleDescription,
        showUpdateBtns,
        selectedDate,
        importFrom,
        scheduleData,
        isStopsOpened
    } = props;

    if(isValid(travelDate)){
        scheduleDescription = scheduleDescription + `(DOJ:${getReadableDate(travelDate)})`;
    }

    const [citiesWithStops,setCitiesWithStops]=useState([]);
    const [originalStops,setOriginalStops]=useState([]);
    const [existingLocations,setExistingLocations]=useState([]);
    const [standardStopsWithCities,setStandardStopsWithCities]=useState({});
    const [isOpenNewStop,setIsOpenNewStop]=useState(false);
    const [selectedCity,setSelectedCity]=useState(null);
    const [selectedCityIndex,setSelectedCityIndex]=useState(null);
    const [selectedStopIndex,setSelectedStopIndex]=useState(null);
    const [showError , setShowError] = useState(false);
    const [currentSelectedDates, setCurrentSelectedDates] = useState(isValid(travelDate) ? [getAPIDateFormat(travelDate)] : []);
    const [isPermanentChange, setIsPermanentChange] = useState(false);
    const [isDaySpecificExits, setIsDaySpecificExits] = useState(false);
    const [isTimeAdjustmentVisible, setIsTimeAdjustmentVisible] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
        if(importFrom === "SearchPage"){
            refetchDetails();
        }
    },[scheduleId,tripId]);

    useEffect(() => {
        // Check if any stop in originalStops has a tripId
        const hasTripId = originalStops.some(city => Array.isArray(city.tripIds) && city.tripIds.length > 0);
        setIsDaySpecificExits(hasTripId); // Set isDaySpecificExits based on the condition
    }, [originalStops]); // Dependency on originalStops

    const refetchDetails=()=>{
        setLoading(true);
        getScheduleStopsByTripIdWithCities(scheduleId, tripId, currentSelectedDates, onSuccess, onFailure);
    }

    const resetFromUI =()=>{
        setCitiesWithStops([...originalStops]);
    }

    const {
        isMobile
    }=useContext(DeviceContext);


    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=(data)=>{
        setCitiesWithStops(data.citiesWithStops);
        var obj2 = JSON.parse(JSON.stringify(data.citiesWithStops)); 
        setExistingLocations(getExistingLocations(obj2));
        setOriginalStops(obj2);
        setLoading(false);
    }
    
    const onFailure=(msg)=>{
        message.error(msg);
        setLoading(false);
    }

    const onClickStopCheckBox=(value,cityIndex,stopIndex)=>{
        citiesWithStops[cityIndex].stops[stopIndex].isActive = value;
        setCitiesWithStops([...citiesWithStops]);
    }

    const onClickStopEditCheckBox=(value,cityIndex,stopIndex)=>{
        citiesWithStops[cityIndex].stops[stopIndex].isEdit = value;
        setCitiesWithStops([...citiesWithStops]);
    }

    const updateStopsDate = (value, cityIndex, stopIndex, label, selectedStop=null) => {
  
            if (label === 'tripClosePoint' && value === 1) {
                // Reset all other tripClosePoints to 0
                citiesWithStops.forEach((city) => {
                    city.stops.forEach((stop) => {
                        stop.tripClosePoint = 0;
                    });
                });
            }

            if (label === 'selectedStopId' && citiesWithStops[cityIndex].stops[stopIndex].isNewStop) {
                if (selectedStop) {
                    // Get default phone numbers from global travels phone number
                    const phoneNumbers = getArrayfromString(global.travelsPhoneNumber);
                    const defaultPhoneNumber = phoneNumbers[0] || null;
                    const defaultLandlineNumber = phoneNumbers[1] || null;
                                        
                    citiesWithStops[cityIndex].stops[stopIndex] = {
                        ...citiesWithStops[cityIndex].stops[stopIndex],
                        selectedStopId: value,
                        city: selectedStop.city,
                        displayName: selectedStop.name,
                        isActive: 1,
                        isStop: true,
                        timing: citiesWithStops[cityIndex].stops[stopIndex].timing || null,
                        tripClosePoint: selectedStop.tripClosePoint || 0,
                        isBoarding: citiesWithStops[cityIndex].isBoarding || false,
                        isDrop: citiesWithStops[cityIndex].isDrop || false,
                        phoneNumber: isValid(selectedStop.data.phone_number) ? selectedStop.data.phone_number : defaultPhoneNumber,
                        landlineNumber: isValid(selectedStop.data.landline_number) ? selectedStop.data.landline_number : defaultLandlineNumber
                    };
                }
            } else {
                citiesWithStops[cityIndex].stops[stopIndex][label] = value;
            }
        setCitiesWithStops([...citiesWithStops]);
    };

    const removeStopFromUI=(cityIndex,stopIndex)=>{
        citiesWithStops[cityIndex].stops = deleteIndexValue(citiesWithStops[cityIndex].stops,stopIndex);
        setCitiesWithStops([...citiesWithStops]);
    }

    // const renderStandardStops=(city)=>{
    //     let standardStopsOptions =[];
    //     if(isValid(standardStopsWithCities[city])){
    //         let standardStops = standardStopsWithCities[city].standardStops;
    //         if(isValidArray(standardStops)&&standardStops.length>0){
    //         standardStops.map((address)=>{
    //             if(existingLocations.indexOf(address.name) == -1){
    //                 standardStopsOptions.push(<Option key = {address.id} name={address.name} id={address.id} value= {address.id} data={address}>{address.name}</Option>);
    //             }
    //         });
    //   }
    //   return standardStopsOptions;
    // }

    const printAdressLocation = (city) => {
        let normalStops = [] ;
        if(isValid(standardStopsWithCities)&&isValid(standardStopsWithCities[city])){
            let addressLocation = standardStopsWithCities[city].addressLocation;
            if(isValidArray(addressLocation)&&addressLocation.length>0){
                addressLocation.map((address) => {
                    normalStops.push(<Option key = {address.id} name={address.name} id={address.id} value= {address.id} data={address}>{address.name}</Option>);
                });
            }
        }
        return normalStops;
    }

    const printStandardStops = (city) =>{
      let standardStopsOptions =[];
      if(isValid(standardStopsWithCities)&&isValid(standardStopsWithCities[city])){
        let standardStops = standardStopsWithCities[city].standardStops;
        if(isValidArray(standardStops)&&standardStops.length>0){
            standardStops.map((address)=>{
            standardStopsOptions.push(<Option key = {address.id} name={address.name} id={address.id} value= {address.id} data={address}>{address.name}</Option>);
            });
        }
      }
      return standardStopsOptions;
    }

    const renderNewStopRow = (cityIndex, stopIndex, city, stop) => {
        let stopExits = false, dayExits = false, timeExits = false;
        if (isValid(stop.selectedStopId)) {
            stopExits = true;
        }
        if (isValidNumber(stop.day) && stop.day >= 0) {
            dayExits = true;
        }
        if (isValid(stop.timing)) {
            timeExits = true;
        }

        return (
            <div className="new-timing-tab-block">
                <Row className={'stop-row'} gutter={0} justifyContent="space-around">
                    {isMobile ?null :<Col span={4}></Col>}
                    <Col span={isMobile ? 12 : 4} className="stop-name-col">
                        <Select
                            showSearch
                            optionFilterProp="children"
                            size="small"
                            placeholder="Select stop"
                            className={showError && !stopExits ? "selectStop errorInput" : "selectStop"}
                            value={isValid(stop.selectedStopId) ? stop.selectedStopId : null}
                            dropdownRender={menu => (
                                <div className="createStopDiv">
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Button
                                        className="createStopBtn"
                                        size="small"
                                        onClick={() => {
                                            setSelectedCity(city);
                                            setSelectedCityIndex(cityIndex);
                                            setSelectedStopIndex(stopIndex);
                                            setIsOpenNewStop(true);
                                        }}
                                    >
                                        Create New Stop
                                    </Button>
                                </div>
                            )}
                            onSelect={(value, data) => {
                                updateStopsDate(data.id, cityIndex, stopIndex, 'selectedStopId', data);
                            }}
                            style={{ width: '90%' }}
                        >
                            {printAdressLocation(city)}
                            <Select.OptGroup label="Standard stops">{printStandardStops(city)}</Select.OptGroup>
                        </Select>
                    </Col>
                    <Col span={isMobile ? 6 : null} className="time-col-in-stops">
                        <div style={{ 
                            width: isMobile ? '80px' : '130px',
                            marginRight: '10px'
                        }}>
                            <TimePickerComponent
                                value={stop.timing}
                                onSelect={(date) => {
                                    let time = date.format("HH:mm:ss");
                                    updateStopsDate(time, cityIndex, stopIndex, 'timing');
                                }}
                                showError={showError}
                                timeExits={timeExits}
                                isMobile={isMobile}
                            />
                        </div>
                    </Col>
                    <Col span={isMobile ? 2 : 4} className="day-col">
                        <div style={{ width: isMobile ? '32px' : '30px' }}>
                            <DayInputComponent
                                value={stop.day + 1}
                                onChange={(value) => {
                                    updateStopsDate(value - 1, cityIndex, stopIndex, 'day');
                                }}
                                showError={showError}
                                dayExits={dayExits}
                                isMobile={isMobile}
                            />
                        </div>
                    </Col>
                    <Col span={isMobile ? 2 : 4} className="action-col">
                        <div className="action-wrapper">
                            <Button
                                type="text"
                                className="outline-button edit-col"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    removeStopFromUI(cityIndex, stopIndex);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                    <Row className="mobile-phone-row margin-bottom-10">
                        <Col offset={4}>
                            <InputNumber
                                placeholder="Phone Number"
                                className={showError && !dayExits ? "phoneInput errorInput" : "phoneInput"}
                                size="small"
                                value={stop.phoneNumber}
                                onChange={(value) => {
                                    updateStopsDate(value, cityIndex, stopIndex, 'phoneNumber');
                                }}
                                style={{ width: '100%', minWidth: '100px' }}
                            />
                        </Col>
                    </Row>
            </div>
        );
    };

    const renderRow = (stop, cityIndex, stopIndex) => {
        let className = '';
        
        if (stop.isActive == 0) {
            className = 'inactive-stop';
        }

        // Check if fields are valid when in edit mode
        const timeExits = isValid(stop.timing);
        const dayExits = isValidNumber(stop.day) && stop.day >= 0;
        const showFieldError = showError && stop.isEdit;

        const renderTimingColumn = () => {
            if (stop.isEdit) {
                return (
                    <div style={{ 
                        width: isMobile ? '80px' : '130px',
                        marginRight: '10px'
                    }}>
                        <TimePickerComponent
                            value={stop.timing}
                            onSelect={(date) => {
                                let time = date.format("HH:mm:ss");
                                updateStopsDate(time, cityIndex, stopIndex, 'timing');
                            }}
                            showError={showFieldError}
                            timeExits={timeExits}
                            isMobile={isMobile}
                        />
                    </div>
                );
            }

            return (
                <div style={{ 
                    width: isMobile ? '100px' : '130px',
                    marginRight: '10px'
                }}>
                    {getTimeStampInReadableFormat(stop.timing)}
                    {Array.isArray(stop.timings) && stop.timings.length > 1 && (
                        <Tooltip 
                            title={stop.timings.map(t => 
                                `${Array.isArray(t.tripStartDates) ? 
                                    t.tripStartDates.map(date => date).join(', ') : 
                                    t.tripStartDates}: ${getTimeStampInReadableFormat(t.timing)}`
                            ).join('\n')}
                            placement="right"
                        >
                            <div style={{ color: '#1B6BE3', fontSize: '12px' }}> (Multiple)</div>
                        </Tooltip>
                    )}
                </div>
            );
        };

        return (
            <Row className={`stop-row ${className}`} gutter={0}>
                {isMobile ?null // Remove the initial spacing for mobile
                :
                <Col span={4}></Col>
                }
                <Col span={isMobile ? 12 : 4} className="stop-name-col">
                    <div className="stop-city-col">
                        <Row className="description-row">
                            <Col span={isMobile?3:4}>
                                {renderCheckBox(stop.isActive, cityIndex, stopIndex)}
                            </Col>
                            <Col span={16} offset={0}>
                                <StopNameDisplay 
                                    displayName={stop.displayName}
                                    isBoarding={stop.isBoarding}
                                    isDrop={stop.isDrop}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={isMobile ? 6 : null} className="time-col-in-stops" >
                        {renderTimingColumn()}
                </Col>
                <Col span={isMobile ? 2 : 4} className="day-col">

                    <div style={{ 
                        width: isMobile ? '32px' : '30px'
                    }}>
                        {stop.isEdit ?
                            <DayInputComponent
                                value={stop.day + 1}
                                onChange={(value) => {
                                    updateStopsDate(value - 1, cityIndex, stopIndex, 'day');
                                }}
                                showError={showFieldError}
                                dayExits={dayExits}
                                isMobile={isMobile}
                            />
                            :
                            <div className="day-display">
                                {stop.day + 1}
                            </div>
                        }
                    </div>
                </Col>
                <Col span={isMobile ? 2 : 4} className="action-col">
                    <div className="action-wrapper">
                        <div className="trip-close-checkbox">
                            {stop.isEdit ?
                                <Checkbox
                                    checked={stop.tripClosePoint === 1}
                                    onChange={(e) => {
                                        updateStopsDate(e.target.checked ? 1 : 0, cityIndex, stopIndex, 'tripClosePoint');
                                    }}
                                />
                                :
                                (stop.tripClosePoint === 1 ?
                                    <div className="tick-icon">
                                        <Icon component={() => (<img src={TicMarkIcon} style={{width:'14px'}} />)} />
                                    </div>
                                    :
                                    <div className="tick-icon-placeholder"></div>
                                )
                            }
                        </div>
                        {isShowCheckBox && !stop.isEdit &&
                            <Button
                                type="text" 
                                className={`outline-button edit-col ${isMobile ? 'edit-col-mobile' : ''}`} 
                                icon={<EditOutlined />}
                                onClick={() => {

                                    onClickStopEditCheckBox(true, cityIndex, stopIndex)
                                }}
                            ></Button>
                        }
                    </div>
                </Col>
            </Row>
        )
    }

    const separateAddressLocation=(addressLocations)=>{
        let addressLocation = [];
        let standardStops = [];
        addressLocations.forEach(location=>{
            if(isValid(location.operator_code)){
                addressLocation.push(location);
            }else{
                standardStops.push(location);
            }
        });
        
        return {addressLocation,standardStops};
    }   

    const reSetStandaredCities=(city,cityIndex)=>{
        dispatch(getAddressLocations([city],true,(data)=>{
            standardStopsWithCities[citiesWithStops[cityIndex].city] = separateAddressLocation(data.addressLocations); 
            setStandardStopsWithCities({...standardStopsWithCities});
            setIsOpenNewStop(false);
        },(errMsg)=>{
            message.error(errMsg);
        }));
    }

    const onClickAddStop=(cityIndex,citystop)=>{
        citiesWithStops[cityIndex].stops.push({isNewStop:true,isBoarding:citystop.isBoarding,isDrop:citystop.isDrop});
        if(!isValidArray(standardStopsWithCities[citiesWithStops[cityIndex].displayName])){
           reSetStandaredCities(citiesWithStops[cityIndex].displayName,cityIndex);
        }
        setCitiesWithStops([...citiesWithStops]);
    }

    const handleCityActiveChange = (value, cityIndex) => {
        const updatedCities = [...citiesWithStops];
        updatedCities[cityIndex].isActive = value;
        // Update all stops under this city
        updatedCities[cityIndex].stops.forEach(stop => {
            stop.isActive = value;
        });
        setCitiesWithStops(updatedCities);
    }

    const renderAllCitiesWithStops=()=>{
        let components=[];
        if(isValidArray(citiesWithStops)&&citiesWithStops.length>0){

            citiesWithStops.forEach((citystop,cityIndex)=>{
                let {
                    stops,
                    displayName
                } = citystop;
                
                if(isValidArray(stops)&&stops.length>0){
                    let collapseAbleComponent=[];
                    stops.forEach((stop,stopIndex)=>{
                        if(stop.isNewStop == true){
                            collapseAbleComponent.push(renderNewStopRow(cityIndex,stopIndex,displayName,stop));
                        }else{
                            collapseAbleComponent.push(renderRow(stop,cityIndex,stopIndex));
                        }
                    });
                    components.push(<Collapse 
                    collapsible={displayName} 
                    ghost className="stop-collapse new-timing-body"
                    expandIcon={({ isActive }) => (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <CityCheckbox 
                                isActive={citystop.isActive}
                                cityIndex={cityIndex}
                                onToggle={handleCityActiveChange}
                                isShowCheckBox={isShowCheckBox}
                            />
                            {isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                        </span>
                    )}
                    >
                                        <Panel 
                                        key={cityIndex}
                                        header={
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                {displayName}
                                            </span>
                                        }
                                        >
                                            {collapseAbleComponent}
                                            <Row>
                                                <Col span={4}>
                                                
                                                </Col>
                                                <Col>
                                                {isShowCheckBox &&
                                                <div>
                                                    <Button className="addStopBtn" size="small" onClick={()=>{
                                                        onClickAddStop(cityIndex,citystop);
                                                    }}>Add stop</Button>
                                                </div>
                                                }
                                                </Col>
                                            </Row>

                                        </Panel>
                                    </Collapse>);
                }
            })
        }
        return components;
    }

    const onClickReset=()=>{
        showConfirm(()=>{
            if(isDaySpecificExits){
                setLoading(true);
                resetToDefaultTimings(scheduleId,currentSelectedDates,resetSuccess,resetFailure);
            }else{
                resetFromUI();
            }

        },"Reset","Are you sure to reset day specific time?");
    }

    const resetSuccess=()=>{
        message.success("Successfully removed day specific timings!");
        refetchDetails(); 
    }

    const resetFailure=()=>{
        message.error("Failed removed day specific timings!");
        setLoading(false);
    }

    const onClickUpdateTimings = () => {
        // Validate all fields first
        let hasError = false;
        let allBoardingStopsInactive = true;

        citiesWithStops.forEach(city => {
            city.stops.forEach(stop => {
                if (stop.isNewStop) {
                    // Check mandatory fields for new stops
                    if (!stop.selectedStopId || !isValid(stop.phoneNumber)) {
                        hasError = true;
                    }
                }                
                if(!stop.timing || !isValidNumber(stop.day) || stop.day < 0){
                    hasError = true;
                }
                // Check if any boarding stop is active
                if (city.isBoarding && stop.isActive) {
                    allBoardingStopsInactive = false;
                }
            });
        });

        if (hasError) {
            setShowError(true);
            message.error("Please fill all mandatory fields (Stop, Time, Day, and Phone Number)");
            return;
        }

        if (allBoardingStopsInactive) {
            message.error("At least one boarding stop must be active");
            return;
        }

        const { changedStops, hasChanges } = getChangedStops(citiesWithStops, originalStops);
        
        if (!hasChanges) {
            message.warning("No changes detected. Please modify the stops before updating.");
            return;
        }
        setLoading(true);
        
        updateStopTimingsOfTrip(
            scheduleId,
            currentSelectedDates,
            changedStops,
            isPermanentChange,
            updateSuccess,
            updateFailure
        );
        setShowError(false);
    };

    const updateSuccess=()=>{
        setLoading(false);
        message.success("Successfully updated timings!");
        refetchDetails(); 
    }

    const updateFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    let isShowCheckBox=showCheckBoxForEdit();

    const renderCheckBox = (value=true, cityIndex, stopIndex) => {
        if (isShowCheckBox) {            
            if (!value) {
                return (
                    <MinusSquareFilled 
                        className="marginRight"
                        style={{ 
                            color: '#ff4d4f',
                            fontSize: '18px',  // Match default antd checkbox size
                            cursor: 'pointer'
                        }}
                        onClick={(e) => {
                            onClickStopCheckBox(true, cityIndex, stopIndex);
                        }}
                    />
                );
            }
            return (
                <CheckSquareFilled 
                    className="marginRight default-checkbo-btn"
                    style={{ 
                        fontSize: '18px',  // Match default antd checkbox size
                        cursor: 'pointer'
                    }}
                    onClick={(e) => {
                        onClickStopCheckBox(false, cityIndex, stopIndex);
                    }}
                />
            );
        }
        return null;
    }

    const adjustTimings = (operation, minutes) => {
        const updatedStops = citiesWithStops.map(city => ({
            ...city,
            stops: city.stops.map(stop => {
                if (!stop.timing) return stop;
                
                // Create independent copies of the time
                const currentTime = moment(stop.timing, 'HH:mm:ss');
                const newTime = moment(stop.timing, 'HH:mm:ss');
                
                // Apply the operation to newTime
                if (operation === '+') {
                    newTime.add(minutes, 'minutes');
                } else {
                    newTime.subtract(minutes, 'minutes');
                }
                
                // Calculate day adjustment based on time crossing midnight
                let dayAdjustment = 0;
                const currentHour = currentTime.hour();
                const newHour = newTime.hour();
                
                if (operation === '+') {
                    if (currentHour > newHour || (currentHour === newHour && currentTime.minute() > newTime.minute())) {
                        dayAdjustment = 1;
                    }
                } else {
                    if (currentHour < newHour || (currentHour === newHour && currentTime.minute() < newTime.minute())) {
                        dayAdjustment = -1;
                    }
                }
                
                return {
                    ...stop,
                    timing: newTime.format('HH:mm:ss'),
                    day: Math.max(0, (stop.day || 0) + dayAdjustment) // Ensure day doesn't go below 0
                };
            })
        }));
        setCitiesWithStops(updatedStops);
    };

    const renderActionButtons = () => (
        <Row justify="center" align="middle" gutter={[16,16]}>
            <Col>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => setIsTimeAdjustmentVisible(true)}
                >
                    Adjust Times
                </Button>
            </Col>
            <Col>
                {isDaySpecificExits||showResetBtn(citiesWithStops)?
                    <Button 
                        className="exit-btn"
                        onClick={onClickReset}
                    >Reset To Original Time</Button>
                    :null
                }
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>Permanent Change</span>
                <Switch 
                    checked={isPermanentChange}
                    onChange={(checked) => setIsPermanentChange(checked)}
                    style={{ marginRight: 16 }}
                />
            </Col>
            <Col>
                <Button onClick={onClickUpdateTimings}>
                    Update
                </Button>
            </Col>
        </Row>
    );

    return (
        <ZoomComponent isMobile={isMobile}>
            <div className="new-timing-root">
                <Row>
                    <Col>
                        <Title level={3}>
                            Manage Stops
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Title level={5}>
                            {`${scheduleDescription}`}
                        </Title>
                    </Col>
                </Row>
                {isValid(scheduleId) && importFrom === "schedulePage" && (
                    <Row gutter={[16, 16]} align="middle">
                        <Col flex="auto">
                            <ScheduleCalendar 
                                selectedDate={selectedDate || travelDate || new Date()}
                                onDatesChange={setCurrentSelectedDates}
                                showLegend={true}
                                scheduleData={scheduleData}
                                loadCalendarData={isStopsOpened}
                                renderGetStops={()=>{
                                    return(
                                        <Button 
                                            type="primary"
                                            onClick={() => {
                                                if (currentSelectedDates.length === 0) {
                                                    message.warning('Please select dates first');
                                                    return;
                                                }   
                                                refetchDetails();
                                            }}
                                        >
                                            Get Stops
                                        </Button>
                                    )   
                                }}
                            />
                        </Col>
                    </Row>
                )}

                {/* Only show stops section and buttons if citiesWithStops has data */}
                {isValidArray(citiesWithStops) && citiesWithStops.length > 0 && (
                    <>
                        <Row className="heading-new-timing">
                            {isMobile ?
                                null // Remove the initial spacing for mobile
                                :
                                <Col span={4}></Col>
                            }
                            <Col span={isMobile ? 12 : 4}>
                                <div className="city-col">
                                    Stop
                                </div>
                            </Col>
                            <Col span={isMobile ? 6 : null}>
                                <div style={{ 
                                    width: isMobile ? '80px' : '130px',
                                    marginRight: '10px'
                                }}>
                                    Time
                                </div>
                            </Col>
                            <Col span={isMobile ? 2 : 4}>
                                <div style={{ width: isMobile ? '35px' : '30px' }}>
                                    Day
                                </div>
                            </Col>
                            <Col span={isMobile ? 2 : 4}>
                                <div className="">
                                    {isMobile ? "TC" : "Trip Close"}
                                </div>
                            </Col>
                        </Row>
                        <Divider className="dividerCol" />
                        <div className="new-timing-body">
                            {renderAllCitiesWithStops()}
                        </div>
                        {showUpdateBtns && isShowCheckBox && renderActionButtons()}
                    </>
                )}

                <TimeAdjustmentModal
                    visible={isTimeAdjustmentVisible}
                    onClose={() => setIsTimeAdjustmentVisible(false)}
                    onApply={adjustTimings}
                />
                <Modal
                    title={null} 
                    className="createNewStopModal"
                    modalTitle="Delay Notification"
                    visible={isOpenNewStop} 
                    width="95vw"
                    onOk={()=>{
                        setIsOpenNewStop(false)
                    }} 
                    onCancel={()=>{
                        setIsOpenNewStop(false)
                    }} 
                    centered={true}
                    footer={null}
                    cancelButtonProps={{className:"exit-btn"}}
                >
                    <div>
                        <CreateStandardStopsPage 
                            fromModal={true} 
                            selectedCity={selectedCity} 
                            onSuccessRefresh={(id)=>{
                                reSetStandaredCities(selectedCity,selectedCityIndex);
                                updateStopsDate(id,selectedCityIndex,selectedStopIndex,'selectedStopId');
                            }}
                            operatorCode={global.operatorCode}
                            onFailure={(errMsg)=>{
                                message.error(errMsg);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </ZoomComponent>
    )
}